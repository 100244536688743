import { Box, Container, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Button, Drawer } from "antd";
import { color, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { MdOutlineCloseFullscreen } from "react-icons/md";
import { useInView } from "react-intersection-observer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useNavigate } from "react-router-dom";
import behavio from "../assets/behavio.jpg";
import computer from "../assets/computer.jpg";
import education from "../assets/education.jpg";
import graduate from "../assets/graduate.jpg";
import health from "../assets/health.jpg";
import law from "../assets/law.jpg";
import livestock from "../assets/livestock.jpg";
import management from "../assets/management.jpg";
import nursing from "../assets/nursing.jpeg";
import Head from "../components/Head";
import Header from "../components/Header";
import FOBS from "../components/faculties/FOBS";
import FOCSE from "../components/faculties/FOCSE";
import FOEL from "../components/faculties/FOEL";
import FOGS from "../components/faculties/FOGS";
import FOHS from "../components/faculties/FOHS";
import FOL from "../components/faculties/FOL";
import FOM from "../components/faculties/FOM";
import FON from "../components/faculties/FON";
import KSLM from "../components/faculties/KSLM";
import Footer from "../components/footer";
import "./Faculties.css";

const Faculties = () => {
  const [open1, setOpen1] = useState(false);
  const showDrawer1 = () => {
    setOpen1(true);
  };

  const [open2, setOpen2] = useState(false);
  const showDrawer2 = () => {
    setOpen2(true);
  };

  const [open3, setOpen3] = useState(false);
  const showDrawer3 = () => {
    setOpen3(true);
  };

  const [open4, setOpen4] = useState(false);
  const showDrawer4 = () => {
    setOpen4(true);
  };

  const [open5, setOpen5] = useState(false);
  const showDrawer5 = () => {
    setOpen5(true);
  };

  const [open6, setOpen6] = useState(false);
  const showDrawer6 = () => {
    setOpen6(true);
  };

  const [open7, setOpen7] = useState(false);
  const showDrawer7 = () => {
    setOpen7(true);
  };

  const [open8, setOpen8] = useState(false);
  const showDrawer8 = () => {
    setOpen8(true);
  };

  const [open9, setOpen9] = useState(false);
  const showDrawer9 = () => {
    setOpen9(true);
  };

  const [ref1, inView1] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView1) {
    }
  }, [inView1]);

  const [ref2, inView2] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView2) {
    }
  }, [inView2]);

  const [ref3, inView3] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView3) {
    }
  }, [inView3]);

  const [ref4, inView4] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView4) {
    }
  }, [inView4]);

  const [ref5, inView5] = useInView({ threshold: 0.2 });
  const [ref6, inView6] = useInView({ threshold: 0.2 });
  const [ref7, inView7] = useInView({ threshold: 0.2 });
  const [ref8, inView8] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView8) {
    }
  }, [inView8]);

  useEffect(()=> {
    if (inView7){

    }
  },[inView7])

  useEffect(() => {
    if (inView5) {
    }
  }, [inView5]);

  useEffect(() => {
    if (inView6) {
    }
  }, [inView6]);

  const buttonStyles1 = {
    marginTop: "2em",
    backgroundColor: "transparent",
    border: "2px solid #002FFF", // Blue outline
    color: "#002FFF", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };

  const buttonStyles2 = {
    marginTop: "1em",
    backgroundColor: "transparent",
    border: "2px solid #002FFF", // Blue outline
    color: "#002FFF", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const navigate = useNavigate();
  const handleBtnClick = (path) => {
    navigate(path);
  };

  const drawerWidth = window.innerWidth < 600 ? "70%" : "50%";

  return (
    <div className="faculty">
      <Header />

      <div className="head-div">
        <Head />
      </div>

      <motion.div
        ref={ref1}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView1
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        <Container>
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "35px",
                  marginTop: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Exploring the Multidisciplinary Marvels of Our University:
                Unveiling the Faculties Shaping Tomorrow's Leaders
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </motion.div>

      <motion.div
        ref={ref2}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView2
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        <Container>
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Typography className="fac-des">
                "Discover KIU University, your gateway to a brighter future. Our
                university features eight fully operational faculties, each
                housing multiple departments. With a dedicated team of
                accomplished staff, we're here to empower you to reach your
                potential. At KIU, we believe in multidisciplinary learning,
                equipping you to tackle the challenges of a rapidly changing
                world. Join us and let's shape your promising future together."
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </motion.div>

      <motion.div
        ref={ref3}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView3
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        <div className="fac-horizontal-divider"></div>
      </motion.div>

      <motion.div
        ref={ref4}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView4
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        <Container>
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "35px",
                  marginBottom: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Faculties
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </motion.div>

      <Container>
        <Grid container="container" spacing={1}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer1}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={nursing}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">Faculty Of Nursing</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open1}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen1(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen1(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FON />
          </Drawer>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer2}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={health}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">Faculty Of Health Sciences</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open2}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen2(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen2(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FOHS />
          </Drawer>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={1}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer3}
              style={{
                cursor: "pointer",
                minHeight: "100%",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={management}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">Faculty Of Management</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open3}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen3(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen3(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FOM />
          </Drawer>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer4}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={education}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">Faculty Of Education & Languages</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open4}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen4(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen4(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FOEL />
          </Drawer>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={1}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer5}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={computer}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">
                  Faculty Of Computer Science & Engineering
                </h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open5}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen5(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen5(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FOCSE />
          </Drawer>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer6}
              style={{
                cursor: "pointer",
                minHeight: "100%",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={graduate}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">Faculty Of Graduate Studies</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open6}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen6(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen6(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FOGS />
          </Drawer>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={1}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer7}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={behavio}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">Faculty Of Behavioural Sciences</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open7}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen7(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen7(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FOBS />
          </Drawer>
{/* 
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer8}
              style={{
                cursor: "pointer",
                minHeight: "100%",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={livestock}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">
                  KIU School Of Agriculture And Livestock Management
                </h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open8}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen8(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen8(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <KSLM />
          </Drawer> */}
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer9}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={law}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">Faculty of Law</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open9}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen9(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen9(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FOL />
          </Drawer>
        </Grid>
      </Container>

      {/* <div className="fac-divider"></div> */}

      <Container>
        {/* <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer9}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={law}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">Faculty of Law</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open9}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen9(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen9(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FOL />
          </Drawer>
        </Grid> */}
      </Container>

      <motion.div
        ref={ref5}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView5
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        <div className="fac-horizontal-divider"></div>
      </motion.div>

      <motion.div
        ref={ref7}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView7
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        <Container>
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "35px",
                  marginBottom: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
               Faculty Advisors
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </motion.div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            {/* <h3>
            Senior Advisor<br/>
            Faculty of Nursing and<br/>
            Faculty of Graduate Studies
            </h3> */}
            <Box
              sx={{
                width: "200px",
                height: "250px",
                my: 1.5
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/homepage/1731920168434_Picture1.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <Box>

            <h3 className="fac-de-pos">Prof. S.S.P. Warnakulasuriya</h3>
            <p className="fac-de-pos1">Senior advisor - Faculty of Nursing & Faculty of Graduate Studies</p>
            <p className="fac-de-pos2">
            PhD (USJP).
MNSc (Adelaide-Australia), 
PGDCP (Colombo).
BScN (Hons.) (OUSL).
RN, RMN, Dip in Teaching and Supervision.                                                        

            </p>
            </Box>

            <Box sx={{
              mt: 2,
              '& p': {
                color: '#77807E',
              },
            }}>
            <p>Email:&nbsp;<a href="mailto:advisor_fon@kiu.ac.lk" target='_blank'>advisor_fon@kiu.ac.lk</a></p>
            <Button
                style={buttonStyles2}
              
                onClick={() => {
                const link = "https://scholar.google.com/citations?user=ZY6EIWAAAAAJ&hl=en"

                window.open(link, "_blank")}
                  
                }
              >
                View Publications
              </Button>
            {/* <p><a href="https://scholar.google.com/citations?user=ZY6EIWAAAAAJ&hl=en"  target='_blank'>Publications</a></p> */}
            </Box>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Career Summary</h3>
            <p className="fac-de-career">
            Prof. SSP Warnakulasooriya is the Professor in Nursing and Dean of the Faculty of Nursing of the university of Colombo. He entered the nursing profession in 1987. He holds a BSc with second class upper division Honours in Nursing from the Open University of Sri Lanka, a Master of Science in Nursing from the University of Adelaide in Australia, and a PhD from the University of Sri Jayewardenepura.
            Professor Warnakulasuriya holds several firsts in the field of nursing. He is the first Sri Lankan nursing professional to hold a PhD from a Sri Lankan University. He is the first Sri Lankan Nursing Professional to have been appointed Professor on merit. He is also the first Sri Lankan nursing professional to have been elected a Dean in a Faculty. Prof. Warnakulasuriya has received 16 research awards including three Presidential Awards for research from the government of Sri Lanka and three professional awards for his contribution to nursing profession from professional associations including the prestigious “Florence Nightingale Lifetime Award” awarded by the Sri Lanka Nursing Association. He has a h- index of 10 and his work has been cited more than 850 times.
            </p>
            {/* <h3 className="fac-de-pub">Contact: +947699928636 </h3> */}
           
          </Grid>

          {/* <Grid
            item="item"
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Publications</h3>
            <ul className="fac-de-pub-list">
              <li>
              https://scholar.google.com/citations?user=ZY6EIWAAAAAJ&hl=en 
              </li>
              <Button
                style={buttonStyles1}
                onClick={() => handleBtnClick("/Research#researchSection")}
              >
                View More
              </Button>
            </ul>
          </Grid> */}
        </Grid>
      </Container>

      <div className="fac-divider"></div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            {/* <h3>
            Senior Advisor<br/>
            Faculty of Nursing and<br/>
            Faculty of Graduate Studies
            </h3> */}
            <Box
              sx={{
                width: "200px",
                height: "250px",
                my: 1.5
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/homepage/1731923597360_Picture2.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <Box>

            <h3 className="fac-de-pos">Professor Wasantha Seneviratne</h3>
            <p className="fac-de-pos1">Senior advisor - Faculty of Law</p>
            <p className="fac-de-pos2">
            Attorney-at-Law.
MPhil, PhD.
                                                    

            </p>
            </Box>

            <Box sx={{
              mt: 2,
              '& p': {
                color: '#77807E',
              },
            }}>
            <p>Email:&nbsp;<a href="mailto:advisor_law@kiu.ac.lk" target='_blank'>advisor_law@kiu.ac.lk</a></p>
            <Button
                style={buttonStyles2}
              
                onClick={() => {
                const link = "https://scholar.google.com/citations?hl=en&user=YAPR2JcAAAAJ "

                window.open(link, "_blank")}
                  
                }
              >
                View Publications
              </Button>
            {/* <p><a href="https://scholar.google.com/citations?user=ZY6EIWAAAAAJ&hl=en"  target='_blank'>Publications</a></p> */}
            </Box>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Career Summary</h3>
            <p className="fac-de-career">
            Professor Wasantha Seneviratne, an Attorney-at-Law with an MPhil and PhD, is a distinguished academic and legal expert specializing in Public and International Law. Also is the Chair Professor of Public and International Law at the, Faculty of Law, University of Colombo and Director of the Centre for the Study of Human Rights, Faculty of Law , University of Colombo. With nearly 28 years of experience in teaching, research, and publication in International Law, Human Rights Law, and Humanitarian Law, she teaches at both undergraduate and postgraduate levels in various institutions in Sri Lanka. Prof. Seneviratne has authored and edited numerous scholarly book chapters and articles in local and international journals, focusing on areas such as Theories of Public International Law, humanitarian interventions, the responsibility to protect, and post-conflict humanitarian law. She serves as Editor-in-Chief of the Sri Lanka Journal of International Law, a refereed journal published by the University of Colombo. Her community engagement includes knowledge dissemination to armed forces and police. Her contributions extend to serving as a Member of the Expert Committee for drafting a New Constitution, Team Leader, and member of the National Monitoring Committee on Child Rights and the Law Reforms Committee of the National Child Protection Authority. She was also part of the Former National Steering Committee on Police Reforms and is the State Volume Editor for the Encyclopedia of Public International Law in Asia: Sri Lanka Volume by Brill Publishers.
            </p>
            {/* <h3 className="fac-de-pub">Contact: +947699928636 </h3> */}
           
          </Grid>

        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            {/* <h3>
            Senior Advisor<br/>
            Faculty of Nursing and<br/>
            Faculty of Graduate Studies
            </h3> */}
            <Box
              sx={{
                width: "200px",
                height: "250px",
                my: 1.5
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/homepage/1731924226878_Picture3.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <Box>

            <h3 className="fac-de-pos">Dr. R. M. S. Rathnayake</h3>
            <p className="fac-de-pos1">Senior advisor - faculty of computer science and Engineering </p>
            <p className="fac-de-pos2">
            PhD (National University of Singapore)
BSc Engineering Hons (Moratuwa)
            </p>
            </Box>

            <Box sx={{
              mt: 2,
              '& p': {
                color: '#77807E',
              },
            }}>
            <p>Email:&nbsp;<a href="mailto:advisor_foc@kiu.ac.lk" target='_blank'>advisor_foc@kiu.ac.lk</a></p>
            <Button
                style={buttonStyles2}
              
                onClick={() => {
                const link = "https://scholar.google.com/citations?user=3wDaamEAAAAJ&hl=en "

                window.open(link, "_blank")}
                  
                }
              >
                View Publications
              </Button>
            {/* <p><a href="https://scholar.google.com/citations?user=ZY6EIWAAAAAJ&hl=en"  target='_blank'>Publications</a></p> */}
            </Box>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Career Summary</h3>
            <p className="fac-de-career">
            Dr. R. M. S. Rathnayake is an educator and a researcher interested in computer systems teaching and research, especially focusing on cloud computing, networking, high-performance computing, security, and trusted computing. He has more than a decade of experience in teaching and research at the university level and a wide range of experience as a consultant for multiple government and corporate institutions.In addition to his full-time role as a Senior Lecturer attached to the Department of Computer Science Engineering at University of Moratuwa, he also serves as the Director for IT Services, University of Moratuwa and as a Director/Board Member of LK Domain Registry.
Dr. Rathnayake has published in reputed international conferences including SC, ICPP, and holds the Associate Membership of Institution of Engineers Sri Lanka (IESL), and the full memberships of Institution of Electrical and Electronics Engineers (IEEE) and the Association of Computer Machinery (ACM).

            </p>
            {/* <h3 className="fac-de-pub">Contact: +947699928636 </h3> */}
           
          </Grid>

        </Grid>
      </Container>

      <motion.div
        ref={ref8}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView8
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        <div className="fac-horizontal-divider"></div>
      </motion.div>


      

      <motion.div
        ref={ref6}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView6
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        <Container>
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "35px",
                  marginBottom: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Faculty Deans
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </motion.div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            {/* <h3>
            Senior Advisor<br/>
            Faculty of Nursing and<br/>
            Faculty of Graduate Studies
            </h3> */}
            <Box
              sx={{
                width: "200px",
                height: "250px",
                my: 1.5
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/homepage/1731924901352_Picture4.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <Box>

            <h3 className="fac-de-pos">Mr. Akila Randika Jayamaha</h3>
            <p className="fac-de-pos1">Dean - Faculty of Nursing</p>
            <p className="fac-de-pos2">
            Dean - Faculty of Nursing                                                                
PhD (Reading)
Master of Nursing
Master of Epidemiology
BSc in Nursing, RN

                                                    

            </p>
            </Box>

            <Box sx={{
              mt: 2,
              '& p': {
                color: '#77807E',
              },
            }}>
            <p>Email:&nbsp;<a href="mailto:dean_fon@kiu.ac.lk" target='_blank'>dean_fon@kiu.ac.lk</a></p>
            <Button
                style={buttonStyles2}
              
                onClick={() => {
                const link = "https://scholar.google.com/citations?user=u60WqWYAAAAJ&hl=en "

                window.open(link, "_blank")}
                  
                }
              >
                View Publications
              </Button>
            {/* <p><a href="https://scholar.google.com/citations?user=ZY6EIWAAAAAJ&hl=en"  target='_blank'>Publications</a></p> */}
            </Box>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Career Summary</h3>
            <p className="fac-de-career">
            Akila R Jayamaha is an experienced and qualified nurse educator and nursing researcher affiliated with the Faculty of Nursing, KIU, Sri Lanka. He graduated with BSc Hons in Nursing in 2014 becoming the top of his batch and started his career as a nursing officer. With the enthusiasm of being a nursing researcher and nurse educator, he pursued two master’s degrees, MSc in Nursing (KIU) and MSc in Applied Epidemiology (University of Peradeniya) and currently reading for his Ph.D. at University of Leicester, UK. His research mainly focuses on nursing education, 24-hour physical behaviour, pulmonary rehabilitation, respiratory diseases, and substance use disorder. He has communicated his work in numerous national and international research arenas and published research articles in peer-reviewed journals. He has a h- index of 5 and his work has been cited more than 90 times. He currently serves as the editor of the IJKIU, editorial member of International Scientific Journal Critical Care Innovations. He was recognized as a one of the reputed scientists and ranked within the top 10 scientists in Medical and Health Sciences / Nursing and Midwifery subject area by Alper-Doger Scientific Index. He was awarded with the Nursing Now Challenge Award for Research in 2022, KIU Outstanding Researcher of the year 2021, KIU Best Scientist Award in 2023.


            </p>
            {/* <h3 className="fac-de-pub">Contact: +947699928636 </h3> */}
           
          </Grid>

        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            {/* <h3>
            Senior Advisor<br/>
            Faculty of Nursing and<br/>
            Faculty of Graduate Studies
            </h3> */}
            <Box
              sx={{
                width: "200px",
                height: "250px",
                my: 1.5
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/homepage/1731925119523_Picture5.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <Box>

            <h3 className="fac-de-pos">Ms. Nadeeka Ranadeva</h3>
            <p className="fac-de-pos1">Dean - The Faculty of Health Sciences</p>
            <p className="fac-de-pos2">
            MSc Molecular Pathology (FOM, UOC), 
Master in Med Stat (Reading, UOK),
BSc Sp. Human Biology (FMS, USJP)
            </p>
            </Box>

            <Box sx={{
              mt: 2,
              '& p': {
                color: '#77807E',
              },
            }}>
            <p>Email:&nbsp;<a href="mailto:dean_foh@kiu.ac.lk" target='_blank'>dean_foh@kiu.ac.lk</a></p>
            <Button
                style={buttonStyles2}
              
                onClick={() => {
                const link = "https://scholar.google.com/citations?user=uhZaI8sAAAAJ&hl=en"

                window.open(link, "_blank")}
                  
                }
              >
                View Publications
              </Button>
            {/* <p><a href="https://scholar.google.com/citations?user=ZY6EIWAAAAAJ&hl=en"  target='_blank'>Publications</a></p> */}
            </Box>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Career Summary</h3>
            <p className="fac-de-career">
            Beginning her career as a Probationary Lecturer in 2015, she has progressed to the role of Senior Lecturer. Over the years, she has held several key positions, including Head of the Department of Biomedical Sciences, Acting Dean of the Faculty of Health Sciences, Dean of the Faculty of Health Sciences, and Acting Deputy Vice Chancellor. Currently, she serves as the Dean of the Faculty of Health Sciences at KIU.
She specializes in Human Biology, with a focus on Biochemistry (Faculty of Medicine, University of Sri Jayewardenepura), and Molecular Pathology (Faculty of Medicine, University of Colombo). Her academic journey is marked by notable achievements, including a prestigious scholarship in 2023 for special training in Precision Medicine at the University of Hong Kong and the Bill and Melinda Gates Travel Award in 2019.
With over nine years of academic and research experience, she has contributed significantly to education and science. As the research team leader for the AHEAD HEI-SL CELL at KIU and deputy coordinator of a World Bank-funded Development-Oriented Research (DOR) project, she has made impactful contributions to drug addiction research, including the identification of genetic markers associated with addiction. Her research interests extend to pharmacogenetics, where she has developed an innovative protocol to detect CYP2D6 gene variants affecting cancer therapy.
In her leadership roles, she has spearheaded curriculum development, quality assurance, and strategic planning initiatives while fostering international collaborations. Her work demonstrates a steadfast commitment to advancing healthcare education, research, and innovation globally.



            </p>
            {/* <h3 className="fac-de-pub">Contact: +947699928636 </h3> */}
           
          </Grid>

        </Grid>
      </Container>

      <div className="fac-divider"></div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            {/* <h3>
            Senior Advisor<br/>
            Faculty of Nursing and<br/>
            Faculty of Graduate Studies
            </h3> */}
            <Box
              sx={{
                width: "200px",
                height: "250px",
                my: 1.5
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/homepage/1731926145111_Picture6.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <Box>

            <h3 className="fac-de-pos">Ms. Ranushi Peiris</h3>
            <p className="fac-de-pos1">Dean - Faculty of Behavioral Sciences</p>
            <p className="fac-de-pos2">
            M.Phil. in Clinical Psychology (UoC, Sri Lanka),
M.Sc. in Clinical Psychology (CU, India),
B.Sc. in Psychology (Hons) (UK).

            </p>
            </Box>

            <Box sx={{
              mt: 2,
              '& p': {
                color: '#77807E',
              },
            }}>
            <p>Email:&nbsp;<a href="mailto:dean_fbs@kiu.ac.lk" target='_blank'>dean_fbs@kiu.ac.lk</a></p>
            {/* <Button
                style={buttonStyles2}
              
                onClick={() => {
                const link = "https://scholar.google.com/citations?user=uhZaI8sAAAAJ&hl=en"

                window.open(link, "_blank")}
                  
                }
              >
                View Publications
              </Button> */}
            {/* <p><a href="https://scholar.google.com/citations?user=ZY6EIWAAAAAJ&hl=en"  target='_blank'>Publications</a></p> */}
            </Box>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Career Summary</h3>
            <p className="fac-de-career">
            Ms Ranushi Peiris, a licensed clinical psychologist practising at a state-owned Hospital in Sri Lanka, specialises in child and adolescent mental health. She has managed multidisciplinary support services for over 1,000 children with autism, addressing socio-economic challenges and implementing a holistic autism treatment model. In addition to her clinical work, Ms. Peiris has trained over 100 preschool teachers in Colombo, enhancing early education support for children with special needs.
Her academic background includes a BSc in Psychology from the United Kingdom, a Master’s in Clinical Psychology from Christ University in India, and an M.Phil. in Clinical Psychology from the University of Colombo. She also holds professional certifications in Behaviour Therapy and Eye Movement Desensitization and Reprocessing (EMDR). She has presented her work on special needs and learning disabilities at national and international psychology seminars.
Beyond clinical practice, Ms. Peiris has served as a visiting lecturer at several institutions in Sri Lanka, including the University of Kelaniya. Her strengths include autism assessment, psycho-pathology treatment planning, team management, and client relationship building. Recognised for her contributions, she won first place for a special needs school business model and has actively contributed to the psychology field through workshops, training, and seminars.



            </p>
            {/* <h3 className="fac-de-pub">Contact: +947699928636 </h3> */}
           
          </Grid>

        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            {/* <h3>
            Senior Advisor<br/>
            Faculty of Nursing and<br/>
            Faculty of Graduate Studies
            </h3> */}
            <Box
              sx={{
                width: "200px",
                height: "250px",
                my: 1.5
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/homepage/1731926350449_Picture7.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <Box>

            <h3 className="fac-de-pos">Dr. Anoma Edirimanna</h3>
            <p className="fac-de-pos1">Dean - Faculty of Management</p>
            <p className="fac-de-pos2">
            PhD in Business and Management 
(MSU, Malaysia)
MBA in Management of Technology (UoM)
BSc in Physical Science(USJP)
Passed Finalist (IBSL)

            </p>
            </Box>

            <Box sx={{
              mt: 2,
              '& p': {
                color: '#77807E',
              },
            }}>
            <p>Email:&nbsp;<a href="mailto:Dean_fom@kiu.ac.lk  " target='_blank'>Dean_fom@kiu.ac.lk</a></p>
            <Button
                style={buttonStyles2}
              
                onClick={() => {
                const link = "https://www.researchgate.net/profile/Edirimanna/research"

                window.open(link, "_blank")}
                  
                }
              >
                View Publications
              </Button>
            {/* <p><a href="https://scholar.google.com/citations?user=ZY6EIWAAAAAJ&hl=en"  target='_blank'>Publications</a></p> */}
            </Box>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Career Summary</h3>
            <p className="fac-de-career">
           
Dr. Anoma is a veteran academic professional with experience over 25 years in the higher education sector of Sri lanka. She holds a PhD in Business and Management, an MBA in Management of Technology and a BSc in Physical Sciences. Dr. Anoma began her career as a Computer Instructor at the University of Sri Jayewardenepura and then moved to the Australian College of Business and Technology (ACBT). Over two decades at ACBT, she served as a Senior Lecturer and Head of BTEC HND programs, where she was instrumental in establishing UK program partnerships. Later, she served at Chartered Institute of Personnel Management and University of Sri Jayewardenepura as a Visiting Lecturer. Her teaching specialties are Mathematics, Statistics, HR Analytics, and Quantitative Research, with her research focused on Enterprise Risk Management in higher education. Dr. Anoma is noted for her communication skills. Her commitment to educational advancement is evident in her comprehensive teaching, research, and leadership contributions.
            </p>
            {/* <h3 className="fac-de-pub">Contact: +947699928636 </h3> */}
           
          </Grid>

        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            {/* <h3>
            Senior Advisor<br/>
            Faculty of Nursing and<br/>
            Faculty of Graduate Studies
            </h3> */}
            <Box
              sx={{
                width: "200px",
                height: "250px",
                my: 1.5
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/homepage/1731926712816_Picture8.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <Box>

            <h3 className="fac-de-pos">Mr. Chanaka Galpaya</h3>
            <p className="fac-de-pos1">Dean - Faculty of Computer Science and Engineering</p>
            <p className="fac-de-pos2">
            M.Phil., BSc (S. Hons) in Physics
            </p>
            </Box>

            <Box sx={{
              mt: 2,
              '& p': {
                color: '#77807E',
              },
            }}>
            <p>Email:&nbsp;<a href="mailto:dean_foc@kiu.ac.lk" target='_blank'>dean_foc@kiu.ac.lk</a></p>
            <Button
                style={buttonStyles2}
              
                onClick={() => {
                const link = "https://www.researchgate.net/profile/Chanaka-Galpaya"

                window.open(link, "_blank")}
                  
                }
              >
                View Publications
              </Button>
            {/* <p><a href="https://scholar.google.com/citations?user=ZY6EIWAAAAAJ&hl=en"  target='_blank'>Publications</a></p> */}
            </Box>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Career Summary</h3>
            <p className="fac-de-career">
            Mr. Chanaka Galpaya is a dedicated academic and researcher specializing in nanotechnology, computational fluid dynamics, materials science, and applied physics. He is the current Dean of the Faculty of Computer Science and Engineering, KIU Campus, Sri Lanka. Mr. Chanaka's academic background includes an M.Phil. focused on the properties of engine oils with nano-additives, as well as a Bachelor of Science (Honours) in Physics from the University of Colombo.
With extensive experience in both research and teaching, he has contributed significantly to the development of new materials and nanofluid technologies in computational and experimental analysis, collaborating with industry partners and supervising graduate-level research projects. His research spans the synthesis and characterization of nanomaterials, including graphene oxide, TiO2, and fullerene-based nanofluids, CFD analysis, model thermodynamics cycle processes, and their applications in energy systems and materials engineering.
Mr. Chanaka is also an active contributor to international/national research conferences and has published extensively in peer-reviewed journals. His work aims to drive technological advancements in energy efficiency and materials performance, with particular focus on the automotive and energy sectors.
            </p>
            {/* <h3 className="fac-de-pub">Contact: +947699928636 </h3> */}
           
          </Grid>

        </Grid>
      </Container>

      <div className="fac-divider"></div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            {/* <h3>
            Senior Advisor<br/>
            Faculty of Nursing and<br/>
            Faculty of Graduate Studies
            </h3> */}
            <Box
              sx={{
                width: "200px",
                height: "250px",
                my: 1.5
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/homepage/1731926932138_Picture9.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <Box>

            <h3 className="fac-de-pos">Ms. Chamilya Perera</h3>
            <p className="fac-de-pos1">Dean, Faculty of Graduate Studies / Senior Lecturer</p>
            <p className="fac-de-pos2">
            PhD(reading)
MSc (Food and Nutrition), 
BSc (Hons) in Nursing (UOR), RN

            </p>
            </Box>

            <Box sx={{
              mt: 2,
              '& p': {
                color: '#77807E',
              },
            }}>
            <p>Email:&nbsp;<a href="mailto: dean_fgs@kiu.ac.lk" target='_blank'> dean_fgs@kiu.ac.lk</a></p>
            <Button
                style={buttonStyles2}
              
                onClick={() => {
                const link = "https://scholar.google.com/citations?User=9BvifSwAAAAJ&hl=en"

                window.open(link, "_blank")}
                  
                }
              >
                View Publications
              </Button>
            {/* <p><a href="https://scholar.google.com/citations?user=ZY6EIWAAAAAJ&hl=en"  target='_blank'>Publications</a></p> */}
            </Box>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Career Summary</h3>
            <p className="fac-de-career">
            Ms. Chamilya Perera is a distinguished academic and professional in the field of nursing and nutrition. A Bachelor of Science (Hons) Nursing graduate from the University of Ruhuna in 2016, she holds an MSc in Food and Nutrition from the University of Peradeniya and is currently pursuing her PhD at the University of Leicester, United Kingdom. As a registered nurse, nursing educator, and accomplished researcher, she has made significant contributions to academia and professional development. A senior academic at KIU, Ms. Perera has played a pivotal role in the education, research, and administrative development of over 5,000 students. Her research interests focus on nutrition and nursing, and her work has been widely recognized through publications presented at local and international forums. She is also a ranked researcher in the AD Scientific Index. Beyond academia, Ms. Perera is a sought-after motivational speaker, frequently conducting workshops for professionals and appearing on television programs to inspire broader audience.
            </p>
            {/* <h3 className="fac-de-pub">Contact: +947699928636 </h3> */}
           
          </Grid>

        </Grid>
      </Container>
      

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            {/* <h3>
            Senior Advisor<br/>
            Faculty of Nursing and<br/>
            Faculty of Graduate Studies
            </h3> */}
            <Box
              sx={{
                width: "200px",
                height: "250px",
                my: 1.5
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/homepage/1731927146818_Picture10.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <Box>

            <h3 className="fac-de-pos">Ms. Kanchanamala Geekiyanage</h3>
            <p className="fac-de-pos1">Dean- Faculty of Law</p>
            <p className="fac-de-pos2">
            Attorney-at-Law ,LLM, PhD(Reading)
            </p>
            </Box>

            <Box sx={{
              mt: 2,
              '& p': {
                color: '#77807E',
              },
            }}>
            <p>Email:&nbsp;<a href="dean_law@kiu.ac.lk" target='_blank'>dean_law@kiu.ac.lk</a></p>
            {/* <Button
                style={buttonStyles2}
              
                onClick={() => {
                const link = "https://www.researchgate.net/profile/Chanaka-Galpaya"

                window.open(link, "_blank")}
                  
                }
              >
                View Publications
              </Button> */}
            {/* <p><a href="https://scholar.google.com/citations?user=ZY6EIWAAAAAJ&hl=en"  target='_blank'>Publications</a></p> */}
            </Box>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">Career Summary</h3>
            <p className="fac-de-career">
            Mrs. Kanchanamala Geekiyanage is a dedicated legal scholar with a strong background in law and profound teaching experience, with a vision to promote academic excellence and innovative legal education.Her extensive academic qualifications include a Bachelor of Law (LL.B.) with a Second class (Upper division) and a Master of Law (LL.M.) from the University of Colombo in Company
She is an accomplished legal professional currently pursuing a PhD, recognized for her academic excellence as Best Overall Student at Visakha Vidyalaya and Island 2nd in the 1999 A/Ls. With numerous awards from the University of Colombo such as Ajita Parathalingam Memorial Prize and Gate Mudliyer Edmond Peiris for her academic excellence. Also 20 years of experience as an Attorney-at-Law specializing in law and conveyancing, she has also completed CIMA-UK and held notable positions at the National Development Bank and DFCC Bank. Her teaching career began in 2006/7 at the University of Colombo, followed by lecturing at KDU, APIIT, Horizon City Campus, and SLIIT. Currently, she is an Associate Lecturer at Curtin University, Australia, and has been a Guest Lecturer for various diplomas and postgraduate programs. Over two decades, she has contributed extensively to legal education across multiple institutions.
Kanchanamala has strong leadership and mentoring abilities, expertise in curriculum development and legal education, excellent communication and negotiation skills, proven success in enhancing student engagement and academic performance.
Outside the professional purview, she is also a Published Poet, a Freelance Journalist, a Recorded Lyricist, a TV presenter and a Public speaker.


            </p>
            {/* <h3 className="fac-de-pub">Contact: +947699928636 </h3> */}
           
          </Grid>

        </Grid>
      </Container>

      {/* <div className="fac-divider"></div> */}

      {/* <Container>
                <Grid container="container" spacing={2}>

                    <Grid item="item" xs={12} sm={12} md={4} lg={4} xl={4}>
                        <h3 className='fac-de-pub'>Publications</h3>
                        <ul className='fac-de-pub-list'>
                            <li>Publication 1</li>
                            <Button style={buttonStyles1} onClick={() => handleBtnClick('/Research')}>View More</Button>
                        </ul>
                    </Grid>

                    <Grid item="item" xs={12} sm={12} md={5} lg={5} xl={5}>
                        <h3 className='fac-de-pub'>Career Summary</h3>
                        <p className='fac-de-career'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Amet cursus sit amet dictum sit
                            amet. Amet mattis vulputate enim nulla aliquet porttitor lacus luctus. Mi quis
                            hendrerit dolor magna eget est lorem ipsum. Arcu cursus vitae congue mauris
                            rhoncus. Ornare lectus sit amet est placerat in
                        </p>
                        <h3 className='fac-de-pub'>Contact</h3>
                        <p className='fac-de-career'>
                            Email:
                            <br/>
                            Phone:
                        </p>
                    </Grid>

                    <Grid item="item" xs={12} sm={12} md={3} lg={3} xl={3}>

                        <img alt="KIU Uni" 
                            src='https://news.harvard.edu/wp-content/uploads/2015/10/101615_humanitarian_2811_605.jpg'
                            width='100%'
                            className='fac-img'/>
                        <h3 className='fac-de-pos'>Name</h3>
                        <h3 className='fac-de-pos'>Position</h3>
                        <h3 className='fac-de-pos'>Education</h3>

                    </Grid>
                </Grid>
            </Container> */}

      <div className="footer-div">
        <Footer />
      </div>
    </div>
  );
};

export default Faculties;
