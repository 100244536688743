import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "./Ugcrs.css";
import BAPDF from "../../../brochure/sample.pdf";
import BusinessA from "../../../assets/BusinessA.png";

const BA = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              ビジネス分析における経営学士（優等学位）
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="ug-crs-des1">
              KIU スリランカの意思決定科学学部へようこそ！
              ビジネス分析プログラムは、分析ライフサイクル全体を包括的に理解できるように作られています。ビジネス分析プログラムは、データ主導の時代に成功するために必要なツールと知識を学部生に提供するために設計されています。カリキュラムは、幅広いトピックをカバーするように慎重に作成されています。統計とデータ分析の基礎概念から高度な機械学習と予測モデリングまで、学部生は分析の全範囲にわたる分析スキルを身に付けます。
              <br />
              プログラム全体を通じて、学部生は実践的なプロジェクト、ケーススタディに参加し、Python、R、SQL、Tableau
              などのデータ視覚化ツールなどの業界標準ツールを習得する機会があります。さらに、カリキュラムにはインターンシップ期間が含まれており、学部生に実践的な業界経験を積ませます。
              <br />
              学業の最終学年では、学生は学業の旅の礎となる研究プロジェクトに着手します。
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={BusinessA}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "60%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des2">
              <p>
                レベル: SLQF 6
                <br />
                期間: 4年間
                <br />
                クレジットポイント: 120
                <br />
                入学時期: 3月と9月
                <br />
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des3">
              <h3>キャリアの機会</h3>
              <ol>
                <li>データウェアハウスアーキテクト </li>
                <li>ビジネスアナリスト </li>
                <li>ビジネス分析エンジニア</li>
                <li>ビジネス分析システム </li>
                <li>ビジネス管理者 </li>
              </ol>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>入学条件</h5>

              <ul>
                <li>
                  GCE
                  A/L試験で1回の受験で科学/商業/技術系に最低3回合格、またはロンドンA/L試験に3回合格
                </li>
                または
                <li>留学生にとって同等の資格。</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des5">
              <h5>プログラム構成</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>1年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>1学期</h5>
              <ul>
                <li>ビジネス統計学I</li>
                <li>ビジネス数学I</li>
                <li>プログラミングの基礎</li>
                <li>会計入門</li>
                <li>ミクロ経済学</li>
                <li>ビジネスコミュニケーションスキル I</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>2学期</h5>

              <ul>
                <li>ビジネス数学II</li>
                <li>ビジネス統計学 II</li>
                <li>マクロ経済学</li>
                <li>経営の原則</li>
                <li>データ構造とアルゴリズム</li>
                <li>ビジネスコミュニケーションスキル II</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>2年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>1学期</h5>
              <ul>
                <li>ビジネス数学III</li>
                <li>計量経済学</li>
                <li>財務管理</li>
                <li>データベース管理</li>
                <li>組織行動</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>2学期</h5>

              <ul>
                <li>オペレーションズリサーチI</li>
                <li>企業金融</li>
                <li>業務管理</li>
                <li>データの視覚化</li>
                <li>システム分析と設計</li>
                <li>技術および学術ライティング</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>3年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>1学期</h5>
              <ul>
                <li>オペレーションズリサーチ II</li>
                <li>機械学習 – I</li>
                <li>マーケティングの原則</li>
                <li>エンタープライズ リソース プランニング</li>
                <li>ビジネスプロセス管理</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>2学期</h5>

              <ul>
                <li>機械学習 II</li>
                <li>サプライチェーンマネジメント</li>
                <li>プロジェクト管理</li>
                <li>人的資源管理</li>
                <li>財務モデリング</li>
                <li>ビジネスアプリケーション開発</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>4年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>1学期</h5>
              <ul>
                <li>研究手法</li>
                <li>高度なデータベース管理</li>
                <li>投資とポートフォリオ管理</li>
                <li>戦略的経営</li>
                <li>ビジネスインターンシップ</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>2学期</h5>

              <ul>
                <li>ビッグデータ技術</li>
                <li>ビジネスプロセスの自動化</li>
                <li>事業法</li>
                <li>データのプライバシーと倫理</li>
                <li>研究プロジェクト</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>
                コース終了時までに、学習者は次のことができるようになります。{" "}
              </h5>

              <ul>
                <li>ビジネスおよび関連分野における知識と理解を実証します。</li>
                <li>
                  地域および世界環境における多様なビジネス状況で、主題固有の知識と専門スキルを適用します。{" "}
                </li>
                <li>
                  現代のテクノロジーと、その専門分野およびビジネス分野への応用に関する知識と理解を示します。{" "}
                </li>
                <li>
                  地域および世界のビジネス環境における新たなトレンドを特定し、ビジネス慣行の有効性を高めるためのイノベーションを導入します。
                </li>
                <li>
                  ビジネスおよび関連分野に関する議論や問題を分析し、批判的に評価します。{" "}
                </li>
                <li>
                  リサーチスキルを適用して、ビジネスに関連する実際的な問題に対する解決策を考案します。{" "}
                </li>
                <li>自立した生涯学習に取り組む必要性を認識する。 </li>
                <li>
                  適切なテクノロジーを使用して、専門的な状況やビジネスの状況において、口頭と書面の両方で効果的にコミュニケーションをとる。{" "}
                </li>
                <li>
                  文化的、言語的に多様な職場でリーダーシップと対人スキルを発揮します。{" "}
                </li>
                <li>
                  行動を通じて、職業倫理とビジネス慣行の責任に対する理解とコミットメントを示します。{" "}
                </li>
                <li>
                  社会的、市民的責任、人権、持続可能性に関する事項についての理解を示します。{" "}
                </li>
                当社のビジネス
                アナリティクスで変革的な学習の旅に乗り出しましょう。そこでは、データを実用的な洞察に変換し、今日のデータ中心のビジネス世界で成功を推進するために必要なスキルを習得できます。
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/34"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>適用する</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              パンフレットをダウンロード
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              近い
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default BA;
