import { Icon } from "@iconify/react/dist/iconify.js";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Head from "../components/Head";
import Header from "../components/Header";
import Footer from "../components/footer";
import "./MRC.css";

const MRC = () => {
  const handleDownloadASG = () => {
    const link = document.createElement("a");
    link.href =
      "https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721278524277_Abstract-submission-guideline.doc";
    link.click();
  };

  const handleDownloadADF = () => {
    const link = document.createElement("a");
    link.href =
      "https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721278663802_declaration-form-MRC-2024.docx";
    link.click();
  };

  const handleDownloadMRC22 = () => {
    const link = document.createElement("a");
    link.href =
      "https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721281311732_MRC-2022-1.pdf";
    link.click();
  };

  const handleDownloadMRC23 = () => {
    const link = document.createElement("a");
    link.href =
      "https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721281364121_MRC-2023-Proceeding-Book.pdf";
    link.click();
  };

  const handleRegister = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSelt84kjIIddtICTBM47tVUsZto8JI6fqTyrUKuPIc-P7ERBg/viewform?usp=sf_link"
    );
  };

  const viewPortal = () => {
    window.open("https://cmt3.research.microsoft.com/MRC2024");
  };
  return (
    <>
      <div className="mrc">
        <Header />

        <div className="head-div">
          <Head />
        </div>

        <Container
          style={{
            marginTop: "1%",
          }}
        >
          <Grid container="container" spacing={2}>
            <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                variant="h2"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: {
                    xs: "20px", // small devices
                    sm: "35px", // small-medium devices
                    md: "45px", // medium devices
                    lg: "45px", // large devices
                    xl: "52px", // extra large devices
                  },
                  marginTop: "3%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                MULTIDISCIPLINARY RESEARCH CONFERENCE – 2024 (MRC 2024)
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <Container>
          <Grid container="container" spacing={2}>
            <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className="news-des">
                “Bridging Knowledge, Innovating Solutions: Advancing
                Multidisciplinary Research”
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <div className="news-horizontal-divider"></div>

        <Container>
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              sx={{ ml: 5, minHeight: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LazyLoadImage
                  effect="blur"
                  src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721273143351_625-02-scaled.jpg"
                  alt="MRC"
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </Box>
            </Grid>

            <Grid
              item="item"
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              sx={{ ml: 5, minHeight: "100%" }}
            >
              <Typography className="mrc-des">
                KIU, a prestigious institution known for its dynamic research
                culture and outstanding educational excellence, is excited to
                unveil its much-awaited annual event, the Multidisciplinary
                Research Conference 2024 (MRC 2024).
                <br />
                MRC 2024, aims to connect and engage with the broader national
                and international research community, fostering an environment
                of collaboration and intellectual exchange. It will bring
                together distinguished scholars recognized nationally and
                internationally from numerous disciplines, providing them with a
                platform for disseminating their knowledge, creating networking
                opportunities, and sharing their perspectives in evidence-based
                practice.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <Typography className="mrc-des">
                  Date – 26th October 2024 (Virtual)
                </Typography>
                <Typography className="mrc-des">
                  Conference registration fee: Rs. 2500/-
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    p: 1,
                  }}
                >
                  <Button variant="outlined" onClick={handleDownloadASG}>
                    {" "}
                    <Icon
                      icon="basil:document-outline"
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    />
                    <Typography>Abstract submission guidelines</Typography>
                  </Button>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    p: 1,
                  }}
                >
                  <Button variant="outlined" onClick={handleDownloadADF}>
                    {" "}
                    <Icon
                      icon="basil:document-outline"
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    />
                    <Typography>Author declaration form</Typography>
                  </Button>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    p: 1,
                  }}
                >
                  <Button variant="outlined" onClick={handleDownloadMRC22}>
                    {" "}
                    <Icon
                      icon="basil:document-outline"
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    />
                    <Typography>MRC 2022 Proceeding Book</Typography>
                  </Button>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    p: 1,
                  }}
                >
                  <Button variant="outlined" onClick={handleDownloadMRC23}>
                    {" "}
                    <Icon
                      icon="basil:document-outline"
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    />
                    <Typography>MRC 2023 Proceeding Book</Typography>
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              sx={{ ml: 5 }}
            >
              <Box width="100%" sx={{ display: "flex", flexDirection: "row" }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleRegister}
                >
                  Register Here
                </Button>
              </Box>
            </Grid>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              sx={{ ml: 5 }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  float: "right",
                }}
              >
                <Typography variant="body1">SUBMIT YOUR ABSTRACT</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ borderRadius: 5 }}
                  onClick={viewPortal}
                >
                  CMT Portal
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>

        <div className="footer-div">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default MRC;
