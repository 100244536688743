import { Container, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import React, { useEffect, useState } from "react";
import "./News.css";

const News4 = ({ newsId }) => {
  const [newsData, setNewsData] = useState(null);

  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        // Make the API call using the newsId parameter
        const response = await fetch(
          `https://kiu.ac.lk/api/website/JP/getNewsById/${newsId}`
        );
        const data = await response.json();

        // Set the fetched data to the state
        setNewsData(data);
      } catch (error) {
        console.error("Error fetching news data:", error);
      }
    };

    // Call the fetchNewsData function when the component mounts or when newsId
    // changes
    fetchNewsData();
  }, [newsId]);

  if (!newsData) {
    // You might want to render a loading spinner or message while fetching data
    return <p>Loading...</p>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}.${month}.${day}`;
  };

  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="new-title">{newsData.title}</h3>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <p className="new-date">{formatDate(newsData.date)}</p>
          </Grid>
        </Grid>
      </Container>

      <Container
        style={{
          marginBottom: "5%",
        }}
      >
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <img
              alt="KIU Uni"
              src={newsData.titleImage}
              className="new-img"
            />
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              <ImageList variant="masonry" cols={3} gap={8}>
                {[
                  newsData.image1,
                  newsData.image2,
                  newsData.image3,
                  newsData.image4,
                  newsData.image5,
                ]
                  .filter((image) => image) // Filter out null or empty images
                  .map((image, index) => (
                    <ImageListItem key={index}>
                      <img
                        alt="KIU Uni"
                        src={image}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
              </ImageList>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <p className="new-descrip">{newsData.content1}</p>
            <p className="new-descrip">{newsData.content2}</p>
            <p className="new-descrip">{newsData.content3}</p>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default News4;
