// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popupF {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 6;

}

.popupF-inner {
    position: relative;
    padding: 2em;
    width: 100%;
    max-width: 100%;
    background: rgba(255,255,255,0.4);
    -webkit-backdrop-filter: blur(11px);
    backdrop-filter: blur(11px);
    border: 1px solid rgba(255,255,255,0.2);
    /* border-radius: 1.5rem; */
    overflow-y: auto;
    /* Add this property to enable vertical scrolling */
    max-height: 90vh;
    
    /* Set a maximum height, adjust as needed */
}

.popupF-inner .close-btn {
    position: absolute;
    top: 3%;
    right: 1%;
    font-size: 3rem;
}

.popupF-inner .close-btn:hover {
    cursor: pointer;
    color: red;
}
`, "",{"version":3,"sources":["webpack://./src/components/JP/PopupFacility.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;;AAEd;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,eAAe;IACf,iCAAiC;IACjC,mCAAmC;IACnC,2BAA2B;IAC3B,uCAAuC;IACvC,2BAA2B;IAC3B,gBAAgB;IAChB,mDAAmD;IACnD,gBAAgB;;IAEhB,2CAA2C;AAC/C;;AAEA;IACI,kBAAkB;IAClB,OAAO;IACP,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,UAAU;AACd","sourcesContent":[".popupF {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.7);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 6;\n\n}\n\n.popupF-inner {\n    position: relative;\n    padding: 2em;\n    width: 100%;\n    max-width: 100%;\n    background: rgba(255,255,255,0.4);\n    -webkit-backdrop-filter: blur(11px);\n    backdrop-filter: blur(11px);\n    border: 1px solid rgba(255,255,255,0.2);\n    /* border-radius: 1.5rem; */\n    overflow-y: auto;\n    /* Add this property to enable vertical scrolling */\n    max-height: 90vh;\n    \n    /* Set a maximum height, adjust as needed */\n}\n\n.popupF-inner .close-btn {\n    position: absolute;\n    top: 3%;\n    right: 1%;\n    font-size: 3rem;\n}\n\n.popupF-inner .close-btn:hover {\n    cursor: pointer;\n    color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
