import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Faculty.css";

const FOL = () => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #f21111", // Blue outline
    color: "#f21111", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const navigate = useNavigate();
  const handleBtnClick = (path) => {
    navigate(path);
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-title">法学部</h3>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <p className="fac-descrip">
              KIU
              法学部は、スリランカの雰囲気を持つ法律分野で世界クラスのプロフェッショナルになるための登竜門です。KIU
              で学位を取得する法学部卒業生は、高い評価を得ている 4
              年間の法学優等学位を取得するだけでなく、正義を支える永続的な基本原則を深く理解することになります。世界に飛び出すとき、彼らは鋭い知性、彼らを導く道徳的指針、そして複雑な国際法および国内法の環境を巧みに乗り切る能力など、数々の利点を携えて出かけます。
              <br />
              法学部の真の宝は、有名な模擬裁判です。学生はここで弁護スキルを磨き、本物の法的シナリオに取り組み、法廷に立つ自信を養うことができます。ここで議論の技術が頂点に達し、法廷で強力かつ実用的な法的議論を展開したり、紛争解決メカニズムの代替手段を通じて交渉、調停、仲裁の複雑さを身に付けたりするという困難な課題に学生を準備します。
              <br />
              学生にグローバルな視点を提供するという私たちの取り組みは、法学部の地位をさらに高めます。卒業生は、国際的な協力や交流に参加し、画期的な法的研究に従事するというユニークな機会を得て、今日の相互接続された世界でますます不可欠となっているグローバルな視点を養います。私たちの卒業生は単なる法律専門家ではありません。彼らはグローバルな舞台での正義の大使であり、法の支配の発展と社会の改善に貢献する準備ができています。私たちの学部が提供する特別な機会を備えた彼らは、困難な法的環境を切り抜けるだけでなく、それを再定義する準備ができています。これらの卒業生は、急速に変化する世界で正義を守る準備の整った世界クラスの法律専門家として成長します。私たちの法学部は、その旅の始まりのプラットフォームですが、その影響は世界規模で響き渡り、法と正義の世界に消えない痕跡を残します。
              <br />
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
            <h3 className="fac-dean-hed">学部長</h3>
            <p className="fac-descrip1">
              Wasantha Seneviratne 教授 <br />
              法学修士、弁護士
              <br />
              コロンボ大学
              <br />
              連絡先: +94 77 123 4567
              <br />
              メール: Dean_fol@kiu.ac.lk
            </p>
          </Grid>

          <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
            {/* <h3 className='fac-hod-hed'>Head of Department</h3>
                        <p className='fac-descrip1'>
                            Prof. Koliya Pulasinghe<br/>BSc Eng. (Hons) in Computer Systems & Engineering,
                                University of Moratuwa, Sri Lanka, 1996<br/>
                            Contact: +94 77 123 4567<br/>
                            Email: Dean_fol@kiu.ac.lk
                        </p> */}
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-depts-hed">学部</h3>
            <ul className="fac-depts-list">
              <li className="fac-depts-list-item">法学部</li>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-fas-hed">設備</h3>
            <p className="fac-descrip1">
              このプログラムは、地域および世界基準に準拠しており、法律専門職に就くための比類のない学歴を確実に提供します。
              この学位プログラムは、模擬裁判の実践、実際の法廷、その他多くのことに自信を持つための、法律分野における必要な知識、スキル、姿勢、トレーニングを学生に提供します。
              私たちは、学位プログラムの必須要件として、主要な法廷での産業研修を促進しており、貴重な業界および研究経験を積むことができます。
              学術界および研究機関の分野でキャリアを追求したい学生は、120単位の学術的経験をカバーする英語で実施されるLLB学位プログラムを通じて得られた知識を深めるために選択された分野での法的研究に従事することを要求する論文から大きな利益を得ます。これにより、法的な文章作成スキル、調査スキル、分析スキルがさらに向上し、世界中のどこでも有能に働く自信が高まります。
              <br />
              特に、法学士課程の模擬裁判で、洗練された法の世界に足を踏み入れてください。複雑なケースをナビゲートしながら説得力のある弁護の技術に浸り、模擬法廷環境でスキルを磨きます。練習セッションは、自信、雄弁さ、法的戦略に対する深い理解を植え付けるように細心の注意を払って作成されています。
              <br />
              法曹界の権威者としての潜在能力を解き放ち、模擬裁判の手続きのニュアンスを習得します。当プログラムは、理論と実践が出会うダイナミックなプラットフォームを提供し、講義室から法廷へとシームレスに移行できるようにします。
              <br />
              将来の法律家が説得力のある議論を明確に表現し、複雑な法的原則を分析し、プレッシャーの下で優雅にプレゼンテーションできるように支援しながら、卓越性の追求にご参加ください。模擬裁判の実践の変革力で法学学士号の経験を向上しましょう。法曹界の輝かしい未来への旅が始まります。{" "}
            </p>
          </Grid>
        </Grid>
      </Container>

      {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button style={buttonStyles1} onClick={() => handleBtnClick('/WeOffer')}>View Courses</Button>
                    </Grid>
                </Grid>
            </Container> */}
    </div>
  );
};

export default FOL;
