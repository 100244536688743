import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "./Ugcrs.css";
import BAPDF from "../../brochure/sample.pdf";
import Marketing from "../../assets/Marketing.png";

const MK = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Bachelor of Management Honours in Marketing
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="ug-crs-des1">
              Welcome to the Department of Marketing at KIU Sri Lanka! Our
              marketing degree program has been specifically designed to develop
              students' strategic thinking and provide them with the tools they
              need to succeed in the fast-paced marketing industry. With the
              help of our extensive curriculum, which covers each significant
              subject matter, students become competent and informed marketers.
              Interactive lectures encourage active student participation and
              provide a hands-on learning environment. <br />
              We take pleasure in using a variety of evaluation techniques to
              make sure that our graduates have a broad range of skills.
              Students can use theoretical knowledge in real-world circumstances
              by participating in practical workshops that ensure them acquire
              practical skills relevant to the marketing business within certain
              modules. <br />
              A capstone Research project is a requirement for students in their
              final year of study and serves as a vital component of their
              education. With the help of this project, they will be exposed to
              actual marketing problems, refining their analytical and
              problem-solving abilities and fostering the confidence necessary
              for a prosperous career in the dynamic sector of marketing. <br />
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={Marketing}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "60%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des2">
              <p>
                Level: SLQF 6
                <br />
                Duration: 4 years
                <br />
                Credit Points: 120
                <br />
                Intakes: March and September
                <br />
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des3">
              <h3>Career Opportunities</h3>
              <ol>
                <li>Marketing Specialist/Coordinator </li>
                <li>Digital Marketing Analyst/Manager </li>
                <li>Brand Manager </li>
                <li>Academic positions in universities </li>
                <li>Sales Representative/Sales Manager </li>
              </ol>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>Entry Requirements</h5>

              <ul>
                <li>Three Passes in GCE Advanced Level (A/L) in any Stream </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des5">
              <h5>Program Structure</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 1</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Management Principles and Practices</li>
                <li>Business & Environment</li>
                <li>Business Mathematics</li>
                <li>Micro Economics</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Elements of Sociology and Psychology</li>
                <li>Principles of Accounting</li>
                <li>Macro Economics</li>
                <li>Business Communication</li>
                <li>Business Statistics</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 2</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Management Information System</li>
                <li>Management Accounting</li>
                <li>Organizational Behavior</li>
                <li>Marketing Management</li>
                <li>Human Resources Management</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Organization Theory</li>
                <li>Financial Management</li>
                <li>Commercial and Industrial Law</li>
                <li>Taxation</li>
                <li>Business Ethics and Corporate Responsibilities</li>
                <li>Marketing Communication (Elective)</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 3</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Auditing</li>
                <li>Computer Based Business Statistics</li>
                <li>Personality Development</li>
                <li>Entrepreneurship and SME</li>
                <li>Organizational Change and Development</li>
                <li>Digital Marketing (Elective)</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Industrial Training</li>
                <li>Strategic Management</li>
                <li>Conflict Management</li>
                <li>Knowledge Management</li>
                <li>Brand Management (Elective)</li>
                <li>Customer Relationship Management (Elective)</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 4</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Research Methods and Academic Writing</li>
                <li>Culture and Management</li>
                <li>Service Marketing</li>
                <li>Supply Chain Management</li>
                <li>Advertising Management</li>
                <li>Product Planning & Pricing Strategy (Elective)</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Research Project</li>
                <li>Operation Management</li>
                <li>Consumer Behavior</li>
                <li>Consumer Protection Law (Elective)</li>
                <li>Strategic Marketing (Elective)</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>By the end of the course, learners will be able to: </h5>

              <ul>
                <li>
                  Understand principles, concepts and theories of marketing and
                  explain marketing and other related disciplines.{" "}
                </li>
                <li>
                  Build knowledge in foreseen marketing opportunities and
                  challenges in the dynamic business environment through formal
                  and informal learning, observations, and investigations.{" "}
                </li>
                <li>
                  Respond proactively to marketing challenges with creative and
                  innovative marketing solutions.{" "}
                </li>
                <li>
                  Develop skills to work as a team in any business context by
                  analyzing group dynamics, building empowered work teams,
                  creating a shared vision, and resolving conflicts.{" "}
                </li>
                <li>
                  Build adequate leadership and organizational skills that are
                  essential to become a professional in marketing.{" "}
                </li>
                <li>
                  Construct an understanding and commitment to uphold ethical
                  standards in marketing practice through behavior.{" "}
                </li>
                Join KIU to explore the world of Human Resource Management and
                shape your career.
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/7"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>Apply</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              Download Brochure
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default MK;
