import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "./Ugcrs.css";
import BMSimg from "../../../assets/BMS.jpg";
import BAPDF from "../../../brochure/sample.pdf";

const BS = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              生物医学科学の理学士（優等学位）
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="ug-crs-des1">
              KIU スリランカの生物医学科学科へようこそ！
              当校の学位プログラムは、学生の分析的思考を育み、科学研究に駆り立て、将来、生物医学科学者として医療分野に革命を起こすリーダーとなるよう、綿密に作成されています。
              <br />
              当校の包括的なカリキュラムは、すべての必須分野を網羅し、学生を生物医学科学の分野で熟練した知識豊富な科学者に変えます。講義はインタラクティブに行われ、学生の積極的な参加を促し、学習体験を向上させます。
              <br />
              当校は、卒業生の質を高めるために、試験で多様な評価方法を採用していることを誇りに思っています。特定のモジュールの実践的なセッションにより、医療分野に関連する実践的なスキルを習得できます。
              <br />
              学業の最終学年では、学生は研究プロジェクトに着手します。これは、学業の旅の要です。このプロジェクトでは、学生は系統的な実験手順に没頭し、分析スキルを磨き、研究者として成功するキャリアに必要な自信を植え付けます。
              <br />
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={BMSimg}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "70%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des2">
              <p>
                レベル: SLQF 6
                <br />
                期間: 4 年
                <br />
                単位数: 120
                <br />
                入学時期: 3 月と 9 月
                <br />
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des3">
              <h3>キャリア チャンス</h3>
              <ol>
                <li>研究アシスタント/ヘルスケア サイエンティスト</li>
                <li>大学の学術職</li>
                <li>臨床検査技師</li>
                <li>ラボ マネージャー/ラボ品質マネージャー</li>
                <li>微生物学者</li>
              </ol>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>入学要件</h5>
              <ul>
                <li>
                  バイオサイエンス分野の GCE アドバンスレベル (A/L) に 3 回合格
                </li>
                <li>留学生の場合は同等の資格 </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des5">
              <h5>プログラム構造</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>1年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>第 1 学期</h5>
              <ul>
                <li>解剖学</li>
                <li>生理学</li>
                <li>実験室の安全性</li>
                <li>基礎化学</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>セメスター 2</h5>

              <ul>
                <li>一般および体系的微生物学 1</li>
                <li>生化学</li>
                <li>臨床生化学 1</li>
                <li>計測機器</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>2年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>第 1 学期</h5>
              <ul>
                <li>免疫学</li>
                <li>一般病理学</li>
                <li>臨床生化学 2</li>
                <li>基礎血液学</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>第 2 学期</h5>

              <ul>
                <li>基礎薬理学</li>
                <li>一般および体系的微生物学 2</li>
                <li>全身病理学</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>3年生</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>第 1 学期</h5>
              <ul>
                <li>応用医学微生物学 / 感染症 1</li>
                <li>応用血液学</li>
                <li>疫学</li>
                <li>寄生虫学</li>
                <li>生物統計学</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>第 2 学期</h5>

              <ul>
                <li>全身薬理学</li>
                <li>応用生化学 1</li>
                <li>研究方法論</li>
                <li>公衆衛生微生物学</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>4年生</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>セメスター 1</h5>
              <ul>
                <li>上級薬理学</li>
                <li>実験室管理と品質管理</li>
                <li>応用生化学 2</li>
                <li>応用微生物学 / 感染症 2</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>第 2 学期</h5>

              <ul>
                <li>一般および応用分子生物学</li>
                <li>輸血医学</li>
                <li>研究プロジェクト</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>
                コース終了時までに、学習者は次のことができるようになります:{" "}
              </h5>

              <ul>
                <li>
                  分子、細胞、集団レベルで生命の基本構造と基本プロセスを理解する。
                </li>
                <li>
                  多様な生物医学的状況で問題解決と実験設計に科学的手法を効果的に適用する。
                </li>
                <li>
                  科学的調査における定量的および定性的データの両方を熟練して収集、整理、分析、解釈、および提示する。
                </li>
                <li>
                  生物医学科学のダイナミックな分野における現在の問題と課題を特定し、明確に表現する。
                </li>
                <li>
                  基本的な生物学実験技術とスキルを巧みに実証および適用する。
                </li>
                <li>
                  書面および口頭で効果的にコミュニケーションし、複雑な科学的概念を明確かつ簡潔に伝える能力を示す。
                </li>
                KIU
                スリランカで生物医学科学の世界を探索し、健康科学の未来を形作るために私たちと一緒に参加してください。
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>適用する</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              パンフレットをダウンロード
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              近い
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default BS;
