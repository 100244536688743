import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "../postgraduate/Pgcrs.css";
import CIPimg from "../../assets/CIP.jpg";
import BAPDF from "../../brochure/sample.pdf";

const CIP = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Certificate in IELTS Preparation
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="pg-crs-des1">
              Certificate in IELTS Preparation is designed to maximize success
              on test day by equipping students with the strategies they need to
              succeed and improve their performance on the IELTS test Further,
              they will be provided with a training platform to familiarize
              themselves with IELTS exam modules and standards. <br />
              The course addresses all four components – speaking, listening,
              reading, writing - ensuring a well-rounded approach to IELTS
              preparation. This IELTS preparation course is an invaluable
              resource for those seeking to perform exceptionally well on the
              test and excel in academic and professional communication.
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={CIPimg}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "80%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>
                WHY SHOULD YOU SELECT KIU FOR PURSUING THE CERTIFICATE IN IELTS
                PREPARATION:{" "}
              </h5>

              <ul>
                <li>
                  The course is designed to maximize success on test day by
                  equipping students with the strategies they need to succeed
                  and improve their academic performance on the IELTS test.
                  Further, they will be provided with a training platform to
                  familiarize themselves with IELTS exam modules and standards.
                </li>
                <li>
                  The course addresses all four components – speaking,
                  listening, reading, writing - ensuring a well-rounded approach
                  to IELTS preparation. This IELTS preparation course is an
                  invaluable resource for those seeking to perform exceptionally
                  well on the test and excel in academic and professional
                  communication.
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des2">
              <p>
                Level: Short Course
                <br />
                Duration: 3 Months, Total number of hours-54, 4 Mock Exams
                <br />
                Delivery Mode: On campus / Distance Mode
                <br />
                Delivery Medium: English
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des3">
              <h3>Entry Requirements</h3>
              <ul>
                <li>
                  For Nursing Students:
                  <ul>
                    <li>
                      Entry requirements for the Certificate in IELTS
                      Preparation are as follows,Government Nursing Officials
                      who have completed the Diploma in General Nursing offered
                      by Government Nurses’ Training Schools
                    </li>
                    <li>
                      Students with a minimum Ordinary Pass (‘S’) for the
                      English subject at the G.C.E Ordinary Level Examination.
                    </li>
                  </ul>
                </li>
                <li>
                  For General Students:
                  <ul>
                    <li>
                      Successful completion of GCE (O/L) Examination with
                      minimum “S” pass for English or equivalent qualification.
                    </li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/32"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>Apply</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              Download Brochure
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CIP;
