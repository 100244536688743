import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "../postgraduate/Pgcrs.css";
import JLPimg from "../../../assets/JLP.jpg";
import BAPDF from "../../../brochure/sample.pdf";

const JLP = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              日本語プログラム
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="pg-crs-des1">
              言語学部は、日本語プログラムを通じて、日本での就職機会に対する高まる需要に応えることを期待しています。これらのプログラムは、学生にN3レベルの専門知識と知識を提供し、スリランカの学生が日本でキャリアの夢を追求する素晴らしい機会を得られるように設計されています。
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={JLPimg}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "80%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des2">
              <p>
                レベル: 短期コース
                <br />
                期間: 初級レベル – 350 時間 / 上級レベル – 550 時間
                <br />
                提供方法: 対面(木曜日と金曜日) /
                オンライン(月曜日と火曜日と水曜日と金曜日)
                <br />
                利用可能なコース: 日本語プログラマー オンラインと対面 / JFT
                試験対策
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des3">
              <h3>入学要件</h3>
              <ul>
                <li>G.C.E A/L 試験に合格する必要があります</li>
                <li>年齢 17 歳以上</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <h5>カリキュラム</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <p>
                言語学部は、日本語プログラムを通じて、日本での就職機会に対する需要の高まりに応えることを期待しています。これらのプログラムは、学生にN3レベル以上の専門知識と知識を提供し、スリランカの学生が日本でキャリアの夢を追求する素晴らしい機会を得ることを目指しています。この日本語クラスは、基本的な日本語運用スキルを習得するために、言語の4つのスキル（聞く、読む、話す、書く）を向上させるように設計されています。カバーされる分野は次のとおりです。
              </p>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <ul>
                <li>日本語の読み書き。</li>
                <li>日本語の会話。</li>
                <li>日本語の文法。</li>
                <li>日本の歴史と文化。</li>
                <li>日本のライフスタイルと日本社会のルール。</li>
                <li>技能実習生の受け入れ準備。</li>
                <li>特定技能労働者の受け入れ準備。</li>
              </ul>

              <p>授業で使用する教授法:</p>

              <ul>
                <li>
                  <strong>コミュニケーション言語教授法 (CLT):</strong>
                  実際の状況に基づいて、言語スキルを使って現実世界でコミュニケーションする方法を学びます。
                </li>
                <li>
                  <strong>協同言語学習法 (CLL):</strong>
                  教室でのペアや小グループの学習者による協同活動を最大限に活用します
                  (ペアによる生徒中心の学習)。
                </li>
                <li>
                  <strong>直接法:</strong>
                  すべての指導は日本語で行われ、学習者は日本語で考え、話すことを強いられます
                  (オールイン日本語演習)。
                </li>
                <li>
                  <strong>文法翻訳:</strong>
                  英語から日本語への翻訳、およびその逆の翻訳を優先します。
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            {/* <a href="#" target="_blank" rel="noopener noreferrer"> */}
            <Button disabled style={buttonStyles1}>
              適用する
            </Button>
            {/* </a> */}
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              パンフレットをダウンロード
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              近い
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default JLP;
