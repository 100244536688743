import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "./Ugcrs.css";
import BAPDF from "../../../brochure/sample.pdf";
import Hr from "../../../assets/Hr.png";

const HR = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              人事管理学士（優等学位）
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="ug-crs-des1">
              KIU
              スリランカの人事学部へようこそ！当学部の学位プログラムは、学生の分析的思考を養うために綿密に作成されており、人材というダイナミックな分野で卓越するために必要な知識、スキル、能力を学生に身につけさせ、組織内の人的資本を管理するために必要なスキルを提供します。
              <br />
              当学部の包括的なカリキュラムは、重要な領域を徹底的に探求し、学生を従業員との良好な関係を育み、人材獲得を監督し、雇用法の厳格な遵守を確保できる熟練した専門家に育てます。
              インタラクティブな講義は、学生の積極的な参加を促し、人材管理の複雑さに効果的に備えるための学習体験を向上させます。
              <br />
              当学部は、卒業生の卓越性を高めることを目的とした試験中のさまざまな評価方法に反映される卓越性に取り組んでいます。特定のモジュールには、人材管理のダイナミックな分野に直接適用できる実践的なスキルを確実に習得できるように、業界での経験が組み込まれています。{" "}
              <br />
              学生の学業の最終学年では、学生は教育活動の礎となる重要な研究プロジェクトに取り組みます。このプロジェクトでは、体系的な手順に取り組み、分析スキルを磨き、豊かなキャリアに不可欠な自信を植え付けます。
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={Hr}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "60%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des2">
              <p>
                レベル: SLQF 6
                <br />
                期間: 4 年
                <br />
                単位数: 120
                <br />
                入学時期: 3 月と 9 月
                <br />
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des3">
              <h3>キャリア チャンス</h3>
              <ol>
                <li>人事マネージャー</li>
                <li>最高経営責任者</li>
                <li>講師</li>
                <li>人事コンサルタント</li>
                <li>従業員関係責任者</li>
                <li>人事アナリスト</li>
              </ol>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>入学要件</h5>

              <ul>
                <li>
                  GCE アドバンス レベル (A/L) の 3 つの合格 (任意のストリーム)
                </li>
                <li>留学生の場合は同等の資格</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des5">
              <h5>プログラム構造</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>1年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>第 1 学期</h5>
              <ul>
                <li>経営原則と実践</li>
                <li>ビジネスと環境</li>
                <li>ビジネス数学</li>
                <li>ミクロ経済学</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>第 2 学期</h5>

              <ul>
                <li>社会学と心理学の要素</li>
                <li>会計の原則</li>
                <li>マクロ経済学</li>
                <li>ビジネス コミュニケーション</li>
                <li>ビジネス統計</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>2年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>第 1 学期</h5>
              <ul>
                <li>経営情報システム</li>
                <li>管理会計</li>
                <li>組織行動</li>
                <li>マーケティング管理</li>
                <li>人的資源管理</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>第 2 学期</h5>

              <ul>
                <li>組織理論</li>
                <li>財務管理</li>
                <li>商法および産業法</li>
                <li>課税</li>
                <li>ビジネス倫理と企業責任</li>
                <li>仮想職場の管理 (選択科目)</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>3年生</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>第 1 学期</h5>
              <ul>
                <li>監査</li>
                <li>コンピュータ ベースのビジネス統計</li>
                <li>人格開発</li>
                <li>起業家精神と中小企業</li>
                <li>組織変更と開発</li>
                <li>人事情報システム (選択科目)</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>第 2 学期</h5>

              <ul>
                <li>産業研修</li>
                <li>戦略管理</li>
                <li>紛争管理</li>
                <li>知識管理</li>
                <li>労働心理学とカウンセリング (選択科目)</li>
                <li>人事管理における現代的課題 (選択科目)</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>4年生</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>第 1 学期</h5>
              <ul>
                <li>研究方法と学術論文執筆</li>
                <li>文化と管理</li>
                <li>パフォーマンス管理</li>
                <li>報酬管理</li>
                <li>トレーニングと開発</li>
                <li>国際人材管理 (選択科目)</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>第 2 学期</h5>

              <ul>
                <li>研究プロジェクト</li>
                <li>運営管理</li>
                <li>従業員関係</li>
                <li>HR アウトソーシング (選択科目)</li>
                <li>戦略的人材管理 (選択科目)</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>
                コース終了時までに、学習者は次のことができるようになります:{" "}
              </h5>

              <ul>
                <li>
                  個人、グループ、企業レベルでの人事管理の基本的な枠組みと手順を認識します。{" "}
                </li>
                <li>
                  多様な組織的状況で問題解決と戦略計画を行うために、戦略的人事管理の原則を巧みに活用します。{" "}
                </li>
                <li>
                  人事関連の調査と問題解決のシナリオで、定量的データと定性的なデータの両方を効率的に収集、整理、分析、解釈、および提示します。{" "}
                </li>
                <li>
                  人事管理のダイナミックな分野における最新の問題と課題を認識し、明確に表現します。{" "}
                </li>
                <li>
                  人材獲得、従業員関係、戦略的人事計画における熟練度を示し、必須の人事管理手法とスキルの適用に習熟していることを実証します。{" "}
                </li>
                <li>
                  複雑な人事概念を明確に伝える能力を示し、書面と口頭で巧みにコミュニケーションします。そして
                  簡潔に。{" "}
                </li>
                KIU に参加して、人事管理の世界を探索し、
                キャリアを形成しましょう。
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/6"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>適用する</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              パンフレットをダウンロード
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              近い
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default HR;
