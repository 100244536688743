import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "../postgraduate/Pgcrs.css";
import BAPDF from "../../brochure/sample.pdf";
import DFWimg from "../../assets/DFW.jpg";

const DFW = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Diploma In Fabrication And Welding
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="pg-crs-des1">
              Level 3 NVQ Diploma in Fabrication and Welding Engineering –
              Manual Welding is for learners who work or want to work as
              fabrication and welding engineers in the engineering sector. It
              allows learners to learn, develop, and practice the skills
              required for employment and/or career progression in the
              respective sector. The Course is designed in association with
              SEMTA, the Sector Skills Council for Science, Engineering and
              Manufacturing Technologies. This Course allows learners to
              progress into employment or to the following City & Guilds
              qualifications
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={DFWimg}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "80%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des2">
              <p>
                Level: Short Course Level 3 NVQ Extended Diploma in Fabrication
                and Welding Engineering.
                <br />
                Duration:
                <br />
                Delivery Mode:
                <br />
                Delivery Medium:
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des3">
              <h3>Entry Requirements</h3>
              <ul>
                <li>
                  <ul>
                    <li></li>
                    <li></li>
                  </ul>
                </li>
                <li></li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <h5>CURRICULUM</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <ul>
                <ol>
                  Mandatory
                  <ul>
                    <li>
                      Complying with statutory regulations and organizational
                      safety requirements
                    </li>
                    <li>
                      Using and interpreting engineering data and documentation
                    </li>
                    <li>Working efficiently and effectively in engineering</li>
                  </ul>
                </ol>
                <ol>
                  Optional
                  <ul>
                    <li>Welding Materials by the Manual Metal Arc Process</li>
                    <li>
                      Welding Materials by the Semi-Automatic MIG/MAG and Flux
                      Cored Arc Processes
                    </li>
                    <li>
                      Welding Materials by the Manual TIG and Plasma Arc Welding
                      Process
                    </li>
                    <li>
                      Welding Materials by the Manual Oxy/Fuel Gas Welding
                      Process
                    </li>
                    <li>
                      Welding Pipe/Tube using Multiple Manual Arc Welding
                      Processes
                    </li>
                    <li>
                      Welding Plate using Multiple Manual Arc Welding Processes
                    </li>
                  </ul>
                </ol>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            {/* <a href="#" target="_blank" rel="noopener noreferrer"> */}
            <Button disabled style={buttonStyles1}>
              Apply
            </Button>
            {/* </a> */}
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              Download Brochure
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default DFW;
