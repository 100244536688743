import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Faculty.css";

const FOHS = () => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #f21111", // Blue outline
    color: "#f21111", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const navigate = useNavigate();
  const handleBtnClick = (path) => {
    navigate(path);
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-title">健康科学部</h3>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <p className="fac-descrip">
              KIU
              の名高い健康科学学部に足を踏み入れてください。当学部は、厳格な学術プログラムと実践的なトレーニングを通じて、次世代の医療専門家の育成に取り組んでいます。当学部の中心には、鍼灸学科と生物医学科学の
              2
              つのダイナミックな学科があります。鍼灸学科では、学生は鍼灸の古代の芸術と科学を深く学び、何世紀にもわたって効果が実証されている技術を習得します。一方、生物医学科学学科では、学生は細胞プロセスから病気の病理まで、人体の複雑さを探求し、現代医学の最前線でのキャリアに備えます。最先端の設備と専門家の教員があらゆる段階で指導する健康科学学部の学生は、発見、革新、そして人類への奉仕の旅に乗り出します。医療の未来を形作るために、私たちと一緒に働きましょう。
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
            <h3 className="fac-dean-hed">学部長</h3>
            <p className="fac-descrip1">
              ナディーカ・ラナデヴァ夫人
              <br />
              BSc Sp. 人間生物学 (FMS、USJP)、医学統計学修士 (リーディング、
              UOK)、MSc 分子病理学 (FOM、UOC)、PhD (リーディング)
              <br />
              メール: dean_foh@kiu.ac.lk
            </p>
          </Grid>

          <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
            <h3 className="fac-hod-hed">健康科学部管理部長</h3>
            <p className="fac-descrip1">
              Ruwani Dedunu 氏
              <br />
              金融数学修士号 (UOM) (リーディング)、経営戦略 MBA (KIU)
              (リーディング) BSc統計学専攻、UOJ
              <br />
              メール: ruwani@kiu.ac.lk
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-depts-hed">学科</h3>
            <ul className="fac-depts-list">
              <li className="fac-depts-list-item">鍼灸医学科</li>
              <li className="fac-depts-list-item">生物医学科</li>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-fas-hed">施設</h3>
            <p className="fac-descrip1">
              生物医学科学科は、業界リーダーとの有意義なパートナーシップを築くことに尽力しています。これにより、生物医学科学の実際の応用に触れ、業界の専門家とのネットワーキングの機会、実地体験による雇用可能性の向上、分野の最先端にあるリソースやテクノロジーへのアクセスなど、この分野での学部生にとって研究機会と専門的進歩の両方への道が開かれます。
            </p>
            <p className="fac-descrip1">
              私たちは、ヘルスケア コミュニケーション
              ソリューションの先駆者である Medcom
              と提携できることを誇りに思っています。この戦略的提携により、医療コミュニケーションとテクノロジーの最新の進歩に触れる機会が提供され、学生の学習体験が向上します。{" "}
            </p>
            <p class="fac-descrip1">
              <strong>学生ラボ コンプレックス (収容人数: 150):</strong>
              <br />
              学生ラボ コンプレックスは、最大 150
              人の学生を同時に収容できる最先端の施設です。最新の設備とリソースを備えた実践的な学習環境を提供し、さまざまな健康科学分野での共同学習と体験学習を促進します。
            </p>

            <p class="fac-descrip1">
              <strong>研究室 (定員: 25):</strong>
              <br />
              この専門研究室は研究活動専用で、25
              人の学生に高度な設備を提供します。徹底的な調査と実験をサポートするように設計されており、学生は科学的探究を探求し、健康科学の知識を進歩させるために不可欠な重要な研究スキルを身に付けることができます。
            </p>

            <p class="fac-descrip1">
              <strong>分子生物学ユニット:</strong>
              <br />
              分子生物学ユニットは、遺伝子と分子の研究に焦点を当てた最先端の施設です。最先端の技術と機器を備えたこのユニットの学生は、健康関連現象の根底にある複雑なメカニズムを理解するために不可欠な分子研究、DNA
              分析、その他の高度な技術に取り組みます。{" "}
            </p>

            <p class="fac-descrip1">
              <strong>解剖モデルユニット:</strong>
              <br />
              このユニットは、包括的な解剖モデルのコレクションを備えた人体解剖学の研究に特化しています。学生はこれらのモデルを使用して人体の構造と機能の理解を深め、実践的で視覚的に没入感のある学習体験を提供します。
            </p>

            <p class="fac-descrip1">
              <strong>ECG ルーム:</strong>
              <br />
              ECG ルームは、心臓の活動を研究および監視するための心電図 (ECG)
              機器を備えた専門施設です。学生は、ECG
              テストの実施と解釈の実践的な経験を積み、健康科学の分野で心血管疾患を診断するために不可欠なスキルを磨きます。{" "}
            </p>

            <p class="fac-descrip1">
              <strong>顕微鏡ユニット:</strong>
              <br />
              顕微鏡ユニットは実験室設備の基本的な構成要素であり、学生は高品質の顕微鏡を使用して生物学的サンプルを詳細に検査できます。このユニットは、組織学、病理学、および顕微鏡分析を必要とするその他の分野の研究をサポートし、健康関連の主題の包括的な理解に貢献します。
            </p>

            <p class="fac-descrip1">
              <strong>ゼブラフィッシュユニット:</strong>
              <br />
              ゼブラフィッシュユニットは、ゼブラフィッシュをモデル生物として使用することに焦点を当てた、学部の設備に追加されたエキサイティングなユニットです。このユニットの学生は、ゼブラフィッシュのユニークな特性を活用して、発生生物学、遺伝学、および毒物学に関連する研究を行います。このユニットでは、健康科学研究で広く使用されているモデル生物を実際に体験できます。
            </p>

            <p class="fac-descrip1">
              <strong>鍼灸トレーニング センター:</strong>
              <br />
              KIU の鍼灸トレーニング
              センターは、学生が学位プログラムの必須要件である臨床および実地トレーニングを完了するために必要な施設を提供します。資格のある西洋およびアーユルヴェーダの医師が、鍼灸学科長の監督の下でこのトレーニングを実施します。
            </p>

            <p class="fac-descrip1">
              <strong>フレンドリーな学習環境:</strong>
              <br />
              近代的な講堂は、学生に没頭できる学習環境を提供し、更衣室と特別ゲスト用の
              VIP
              スペースも備えています。スマートボードを巧みに取り入れることで、教育体験が向上し、インタラクティブでダイナミックなセッションが促進されます。
            </p>

            <p class="fac-descrip1">
              要約すると、実験室施設は、理論的な知識と実践的な研究機会を組み合わせた多面的な学習体験を提供するように設計されています。学生は研究活動に積極的に参加し、健康科学のダイナミックな分野で有能な研究者として活躍するために必要なスキルと専門知識を習得します。
            </p>
          </Grid>
        </Grid>
      </Container>

      {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button style={buttonStyles1} onClick={() => handleBtnClick('/WeOffer')}>View Courses</Button>
                    </Grid>
                </Grid>
            </Container> */}
    </div>
  );
};

export default FOHS;
