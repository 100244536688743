import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "./Ugcrs.css";
import MISimg from "../../assets/MIS.jpg";
import BAPDF from "../../brochure/sample.pdf";

const MIS = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              BSc Honors in Management Information System 
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="ug-crs-des1">
              Bachelor of Science Honours in Management Information System
              degree enables undergraduates to perform in the arena as
              passionate professionals with knowledge in Management Information
              Systems, improve business and enhance the quality of life.
              Management Information System degree provides skills such as
              problem-solving, management skills, computer science and
              programming, security awareness etc. Furthermore, the students who
              pursue the MIS degree will gain excellent critical thinking and
              business abilities. This curriculum in the Management Information
              System degree program has been designed in accordance with the Sri
              Lankan and global university standards. KIU facilitates an
              industrial placement in leading companies which will expose the
              said students to valuable industry and research experience. This
              will ensure that the KIU graduates in the field of Management
              Information Systems will have the opportunity to be employed as
              Computer Systems Administrators, consultants, innovators, Search
              Engine Optimization Specialists, Database Administrators and many
              more.
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={MISimg}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "60%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des2">
              <p>
                Level: SLQF 6
                <br />
                Duration: Semester 8 (4 Years)
                <br />
                Credit Points: 126
                <br />
                Intakes: March and September 
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des3">
              <h3>Career Opportunities</h3>
              <ol>
                <li>Computer Systems Administrators</li>
                <li>Consultants</li>
                <li>Innovators</li>
                <li>Search Engine Optimization Specialists</li>
                <li>Database Administrators</li>
              </ol>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>Entry Requirements</h5>

              <ul>
                <li>
                At least three (3) simple passes (S) in Physical Sciences stream or Engineering Technology stream in one and the same sitting at the G.C.E (Advanced Level) Examination conducted by the Department of Examinations of Sri Lanka or equivalent qualification.
                </li>
                Or
                <li>
                At least three (3) simple passes (S) in any stream in one and the same sitting at the G.C.E (Advanced Level) Examination conducted by the Department of Examinations of Sri Lanka or equivalent qualification with a Credit pass (C) in Mathematics at the G.C.E (Ordinary Level) Examination conducted by the Department of Examination of Sri Lanka or equivalent qualification with a bridging programme approved by the Specified Authority.
                </li>
                Or
                <li>
                At least three (3) simple passes(S) in any stream in one and the same sitting with a simple pass (S) for Information & Communication Technology, in any attempt at the G.C.E. (Advanced Level) Examination conducted by the Department of Examinations of Sri Lanka or equivalent qualification and a Credit pass(C) in Mathematics at the G.C.E. (Ordinary Level) Examination conducted by the Department of Examinations of Sri Lanka or equivalent qualification.
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des5">
              <h5>Program Structure</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 1</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Introduction to Computer Systems</li>
                <li>Computer Architecture</li>
                <li>Fundamentals of Programming</li>
                <li>Academic Practices and Grooming</li>
                <li>Mathematics for Computing I</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Data Structures and Algorithms</li>
                <li>Object-Oriented Programming</li>
                <li>Web Technologies</li>
                <li>Data Communications</li>
                <li>Mathematics for Computing II</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 2</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Business Studies</li>
                <li>Database Systems</li>
                <li>Systems Analysis and Design</li>
                <li>Operating Systems</li>
                <li>Applied Statistics</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Foundations of Information Systems</li>
                <li>Theory of Computation</li>
                <li>Computer Security</li>
                <li>Human-Computer Interaction</li>
                <li>Enterprise Systems Architecture</li>
                <li>
                  Career Planning and Employability Skills Development – Seminar
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 3</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Research Methods for Computing</li>
                <li>IT Security and Risk Management</li>
                <li>IS Project Management</li>
                <li>Data and Information Management</li>
                <li>Systems Acquisition and Management</li>
                <li>IT Infrastructure</li>
                <li>Knowledge-based Systems</li>
                <li>Emerging Technologies</li>
                <li>IT Support for End Users</li>
                <li>Visual Programming</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Work Based Enterprise Placement</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 4</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Final Year Individual Project</li>
                <li>IT Professionalism and Practice</li>
                <li>Data Science</li>
                <li>Business Intelligence</li>
                <li>IT Audit and Controls</li>
                <li>High-Performance Computing</li>
                <li>Business Policy</li>
                <li>Business Analysis and Consultancy Skills</li>
                <li>E-Commerce</li>
                <li>Mobile Application Development</li>
                <li>Advanced Database Systems</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Database Server Management</li>
                <li>Entrepreneurship</li>
                <li>Information Systems for Strategic Management</li>
                <li>Cloud Computing</li>
                <li>Internet of Things</li>
                <li>Operational Research</li>
                <li>Ethical Hacking and Countermeasures</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>
                Graduates obtaining the Bachelor of Science Honours in
                Management Information Systems programme should be able to:
              </h5>

              <ul>
                <li>
                  show mastery of knowledge in management information systems
                  and skills of the professional standards necessary to practice
                  as a Chief Information Officer;
                </li>
                <li>
                  understand the fundamental concepts related to organizational
                  processes;
                </li>
                <li>
                  analyze existing processes based on interviewing, observation,
                  documentation analysis, and other similar methods;
                </li>
                <li>
                  understand how the very large amounts of data collected by
                  modern organizations can be used to review, redesign, and
                  improve processes;
                </li>
                <li>
                  identify and capture the essential findings from the large
                  amount of data produced by the analysis process;
                </li>
                <li>
                  research and apply industry reference models and best
                  practices in order to improve process designs;
                </li>
                <li>
                  use analysis results as a basis for designing revised
                  processes based on the graduates' strong understanding of both
                  organizations and information systems.
                </li>
                <li>
                  simulate proposed processes and revise them as necessary
                </li>
                <li>
                  understand the limitations of what can be achieved with
                  available technology, financial resources, and organizational
                  capabilities;{" "}
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/24"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>Apply</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              Download Brochure
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default MIS;
