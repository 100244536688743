import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { MdOutlineCloseFullscreen } from "react-icons/md";
import Head from "../../components/JP/Head";
import Header from "../../components/JP/Header";
import Footer from "../../components/JP/footer";
import N1 from "../../components/JP/news/News1";
import N2 from "../../components/JP/news/News2";
import N3 from "../../components/JP/news/News3";
import N4 from "../../components/JP/news/News4";
import N5 from "../../components/JP/news/News5";
import NTable from "../../components/JP/news/PastNewsTable";
import "./News.css";

const News = () => {
  const [open1, setOpen1] = useState(false);
  const [selectedNewsId1, setSelectedNewsId1] = useState(null);
  const showDrawer1 = (newsId) => {
    setSelectedNewsId1(newsId);
    setOpen1(true);
  };

  const [open2, setOpen2] = useState(false);
  const [selectedNewsId2, setSelectedNewsId2] = useState(null);
  const showDrawer2 = (newsId) => {
    setSelectedNewsId2(newsId);
    setOpen2(true);
  };

  const [open3, setOpen3] = useState(false);
  const [selectedNewsId3, setSelectedNewsId3] = useState(null);
  const showDrawer3 = (newsId) => {
    setSelectedNewsId3(newsId);
    setOpen3(true);
  };

  const [open4, setOpen4] = useState(false);
  const [selectedNewsId4, setSelectedNewsId4] = useState(null);
  const showDrawer4 = (newsId) => {
    setSelectedNewsId4(newsId);
    setOpen4(true);
  };

  const [open5, setOpen5] = useState(false);
  const [selectedNewsId5, setSelectedNewsId5] = useState(null);
  const showDrawer5 = (newsId) => {
    setSelectedNewsId5(newsId);
    setOpen5(true);
  };

  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    fetch("https://kiu.ac.lk/api/website/JP/findLast5News")
      .then((response) => response.json())
      .then((data) => setNewsData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const drawerWidth = window.innerWidth < 600 ? "70%" : "50%";

  return (
    <div className="news">
      <Header />

      <div className="head-div">
        <Head />
      </div>

      <Container
        style={{
          marginTop: "2%",
        }}
      >
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "45px",
                marginTop: "3%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              ニュース＆イベント
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="news-des">
              「ニュースとイベントのページへようこそ。最新ニュースや今後のイベント情報を
              すべてご覧いただける場所です。
              このページで最新情報を確認し、世界で起こっているすべての出来事について最新情報を入手してください。」
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="news-horizontal-divider"></div>

      <Container
        style={{
          marginBottom: "2%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              height: "auto",
              marginLeft: "6%",
            }}
          >
            {/* News 1 */}

            {newsData.length > 0 && (
              <Card
                sx={{
                  maxWidth: "100%",
                }}
              >
                <CardMedia
                  component="img"
                  alt="KIU UNI"
                  width="100%"
                  height="auto"
                  image={newsData[0].titleImage}
                />
                <CardContent>
                  <h4
                    style={{
                      fontFamily: "Kanit, sans-serif",
                      color: "#5E6264",
                      fontSize: "20px",
                    }}
                  >
                    {newsData[0].title}
                  </h4>

                  <p
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#7F8688",
                    }}
                  >
                    {newsData[0].content1}
                  </p>
                </CardContent>
                <CardActions>
                  <Button
                    size="large"
                    variant="outlined"
                    onClick={() => showDrawer1(newsData[0].id)}
                  >
                    続きを読む
                  </Button>
                </CardActions>
              </Card>
            )}

            {newsData.length > 1 && (
              <Grid
                container="container"
                spacing={2}
                style={{
                  marginTop: "3%",
                }}
              >
                <Grid
                  item="item"
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  style={{
                    height: "50vh",
                  }}
                >
                  <img
                    alt="KIU Uni"
                    src={newsData[1].titleImage}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Grid>

                <Grid
                  item="item"
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    height: "auto",
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      fontFamily: "Kanit, sans-serif",
                      color: "#5E6264",
                    }}
                  >
                    {newsData[1].title}
                  </Typography>

                  <Typography
                    variant="body1"
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#7F8688",
                    }}
                  >
                    {newsData[1].content1}
                  </Typography>

                  <Button
                    size="large"
                    variant="outlined"
                    onClick={() => showDrawer2(newsData[1].id)}
                    style={{
                      marginTop: "5%",
                    }}
                  >
                    続きを読む
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              height: "100%",
              marginLeft: "6%",
            }}
          >
            {newsData.length > 2 && (
              <Grid container="container" spacing={2}>
                <Grid
                  item="item"
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  style={{
                    height: "50vh",
                    marginTop: "2%",
                  }}
                >
                  <img
                    alt="KIU Uni"
                    src={newsData[2].titleImage}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Grid>

                <Grid
                  item="item"
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    height: "auto",
                    marginTop: "2%",
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      fontFamily: "Kanit, sans-serif",
                      color: "#5E6264",
                    }}
                  >
                    {newsData[2].title}
                  </Typography>

                  <Typography
                    variant="body1"
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#7F8688",
                    }}
                  >
                    {newsData[2].content1}
                  </Typography>

                  <Button
                    size="large"
                    variant="outlined"
                    onClick={() => showDrawer3(newsData[2].id)}
                    style={{
                      marginTop: "5%",
                    }}
                  >
                    続きを読む
                  </Button>
                </Grid>
              </Grid>
            )}

            {newsData.length > 3 && (
              <Grid
                container="container"
                spacing={2}
                style={{
                  marginTop: "3%",
                }}
              >
                <Grid
                  item="item"
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  style={{
                    height: "50vh",
                  }}
                >
                  <img
                    alt="KIU Uni"
                    src={newsData[3].titleImage}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Grid>

                <Grid
                  item="item"
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    height: "auto",
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      fontFamily: "Kanit, sans-serif",
                      color: "#5E6264",
                    }}
                  >
                    {newsData[3].title}
                  </Typography>

                  <Typography
                    variant="body1"
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#7F8688",
                    }}
                  >
                    {newsData[3].content1}
                  </Typography>

                  <Button
                    size="large"
                    variant="outlined"
                    onClick={() => showDrawer4(newsData[3].id)}
                    style={{
                      marginTop: "5%",
                    }}
                  >
                    続きを読む
                  </Button>
                </Grid>
              </Grid>
            )}

            {newsData.length > 4 && (
              <Grid
                container="container"
                spacing={2}
                style={{
                  marginTop: "2%",
                }}
              >
                <Grid
                  item="item"
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  style={{
                    height: "50vh",
                  }}
                >
                  <img
                    alt="KIU Uni"
                    src={newsData[4].titleImage}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Grid>

                <Grid
                  item="item"
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{
                    height: "auto",
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      fontFamily: "Kanit, sans-serif",
                      color: "#5E6264",
                    }}
                  >
                    {newsData[4].title}
                  </Typography>

                  <Typography
                    variant="body1"
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#7F8688",
                    }}
                  >
                    {newsData[4].content1}
                  </Typography>

                  <Button
                    size="large"
                    variant="outlined"
                    onClick={() => showDrawer5(newsData[4].id)}
                    style={{
                      marginTop: "5%",
                    }}
                  >
                    続きを読む
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>

      <Drawer
        open={open1}
        closable={false}
        title={
          <MdOutlineCloseFullscreen
            onClick={() => {
              setOpen1(false);
            }}
            className="close-new-draw"
          >
            {" "}
          </MdOutlineCloseFullscreen>
        }
        onClose={() => {
          setOpen1(false);
        }}
        width={drawerWidth}
        style={{
          background: "rgba(0, 0, 0, 0.5)",
          WebkitBackdropFilter: "blur(10px)",
          backdropFilter: "blur(10px)",
          border: "1px solid rgba(0, 0, 0, 0.25)",
        }}
      >
        <N1 newsId={selectedNewsId1} />
      </Drawer>

      <Drawer
        open={open2}
        closable={false}
        title={
          <MdOutlineCloseFullscreen
            onClick={() => {
              setOpen2(false);
            }}
            className="close-new-draw"
          >
            {" "}
          </MdOutlineCloseFullscreen>
        }
        onClose={() => {
          setOpen2(false);
        }}
        width={drawerWidth}
        style={{
          background: "rgba(0, 0, 0, 0.5)",
          WebkitBackdropFilter: "blur(10px)",
          backdropFilter: "blur(10px)",
          border: "1px solid rgba(0, 0, 0, 0.25)",
        }}
      >
        <N2 newsId={selectedNewsId2} />
      </Drawer>

      <Drawer
        open={open3}
        closable={false}
        title={
          <MdOutlineCloseFullscreen
            onClick={() => {
              setOpen3(false);
            }}
            className="close-new-draw"
          >
            {" "}
          </MdOutlineCloseFullscreen>
        }
        onClose={() => {
          setOpen3(false);
        }}
        width={drawerWidth}
        style={{
          background: "rgba(0, 0, 0, 0.5)",
          WebkitBackdropFilter: "blur(10px)",
          backdropFilter: "blur(10px)",
          border: "1px solid rgba(0, 0, 0, 0.25)",
        }}
      >
        <N3 newsId={selectedNewsId3} />
      </Drawer>

      <Drawer
        open={open4}
        closable={false}
        title={
          <MdOutlineCloseFullscreen
            onClick={() => {
              setOpen4(false);
            }}
            className="close-new-draw"
          >
            {" "}
          </MdOutlineCloseFullscreen>
        }
        onClose={() => {
          setOpen4(false);
        }}
        width={drawerWidth}
        style={{
          background: "rgba(0, 0, 0, 0.5)",
          WebkitBackdropFilter: "blur(10px)",
          backdropFilter: "blur(10px)",
          border: "1px solid rgba(0, 0, 0, 0.25)",
        }}
      >
        <N4 newsId={selectedNewsId4} />
      </Drawer>

      <Drawer
        open={open5}
        closable={false}
        title={
          <MdOutlineCloseFullscreen
            onClick={() => {
              setOpen5(false);
            }}
            className="close-new-draw"
          >
            {" "}
          </MdOutlineCloseFullscreen>
        }
        onClose={() => {
          setOpen5(false);
        }}
        width={drawerWidth}
        style={{
          background: "rgba(0, 0, 0, 0.5)",
          WebkitBackdropFilter: "blur(10px)",
          backdropFilter: "blur(10px)",
          border: "1px solid rgba(0, 0, 0, 0.25)",
        }}
      >
        <N5 newsId={selectedNewsId5} />
      </Drawer>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "45px",
                marginTop: "3%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              過去のニュース
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ mt: 6, ml: "6%" }}
          >
            <NTable />
          </Grid>
        </Grid>
      </Container>

      <div className="footer-div">
        <Footer />
      </div>
    </div>
  );
};

export default News;
