import { Container, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import bms from "../../assets/bms11.jpg";
import care from "../../assets/care1.JPG";
import com from "../../assets/com11.jpg";
import kyo from "../../assets/kyo2.JPG";
import Head from "../../components/JP/Head";
import Header from "../../components/JP/Header";
import Popup from "../../components/JP/PopupFacility";
import BioMedLab from "../../components/JP/facilities/BioMedLab";
import StudyArea from "../../components/JP/facilities/Caregiver";
import ComputerLab from "../../components/JP/facilities/ComputerLab";
import KyoCampus from "../../components/JP/facilities/KyoCampus";
import Footer from "../../components/JP/footer";
import "./Facilities.css";

const Facilities = () => {
  const [buttonPopup1, setButtonPopup1] = useState(false);
  const [buttonPopup2, setButtonPopup2] = useState(false);
  const [buttonPopup3, setButtonPopup3] = useState(false);
  const [buttonPopup4, setButtonPopup4] = useState(false);
  const [buttonPopup5, setButtonPopup5] = useState(false);
  const [buttonPopup6, setButtonPopup6] = useState(false);

  return (
    <div className="facility">
      <Header />

      <div className="head-div">
        <Head />
      </div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              大学施設の改革：充実への現代的なアプローチ
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Typography className="faci-des">
              「最先端の設備が教育体験を再定義する未来的な大学に足を踏み入れてください。スマートな教室ではテクノロジーとコラボレーションがシームレスに統合され、設備の整った図書館では豊富なデジタルリソースにアクセスできます。ハイテクフィットネスセンターでは健康とレクリエーションが盛んに行われ、研究室ではイノベーションが推進されています。活気に満ちたアートとパフォーマンスのスペースは創造性を刺激し、モダンな寮と多様なダイニングオプションは理想的なキャンパスライフを完成させます。教育の未来へようこそ。」
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={1}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card sx={{ minHeight: "100%" }}>
              <CardMedia
                component="img"
                width="100%"
                height="100%"
                image={care}
                alt="KIU UNI"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setButtonPopup5(true)}
              />
              <CardActions disableSpacing="disableSpacing">
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontFamily: "Kanit, sans-serif",
                    color: "#77807E",
                    padding: "0.5em",
                  }}
                >
                  介護士養成センター
                </Typography>
                <Popup trigger={buttonPopup5} setTrigger={setButtonPopup5}>
                  <StudyArea onClose={() => setButtonPopup5(false)} />
                </Popup>
              </CardActions>
            </Card>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card sx={{ minHeight: "100%" }}>
              <CardMedia
                component="img"
                width="100%"
                height="100%"
                image={com}
                alt="KIU UNI"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setButtonPopup3(true)}
              />
              <CardActions disableSpacing="disableSpacing">
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontFamily: "Kanit, sans-serif",
                    color: "#77807E",
                    padding: "0.5em",
                  }}
                >
                  コンピューター室
                </Typography>
                <Popup trigger={buttonPopup3} setTrigger={setButtonPopup3}>
                  <ComputerLab onClose={() => setButtonPopup3(false)} />
                </Popup>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Container
        style={{
          marginTop: "2%",
        }}
      >
        <Grid container="container" spacing={1}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card>
              <CardMedia
                component="img"
                width="100%"
                height="100%"
                image={bms}
                alt="KIU UNI"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setButtonPopup4(true)}
              />
              <CardActions disableSpacing="disableSpacing">
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontFamily: "Kanit, sans-serif",
                    color: "#77807E",
                    padding: "0.5em",
                  }}
                >
                  バイオメディカル研究所
                </Typography>
                <Popup trigger={buttonPopup4} setTrigger={setButtonPopup4}>
                  <BioMedLab onClose={() => setButtonPopup4(false)} />
                </Popup>
              </CardActions>
            </Card>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card>
              <CardMedia
                component="img"
                width="100%"
                height="100%"
                image={kyo}
                alt="KIU UNI"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setButtonPopup2(true)}
              />
              <CardActions disableSpacing="disableSpacing">
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontFamily: "Kanit, sans-serif",
                    color: "#77807E",
                    padding: "0.5em",
                  }}
                >
                  京キャンパス
                </Typography>
                <Popup trigger={buttonPopup2} setTrigger={setButtonPopup2}>
                  <KyoCampus onClose={() => setButtonPopup2(false)} />
                </Popup>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <div className="footer-div">
        <Footer />
      </div>
    </div>
  );
};

export default Facilities;
