// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eths {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-x: hidden;
}

.eths-des{
    padding: 0.5rem;
    text-align: justify;
    font-size: 35px;
    color: #77807E;
    font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/pages/Ethics.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,4BAA4B;IAC5B,sBAAsB;IACtB,2BAA2B;IAC3B,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,eAAe;IACf,cAAc;IACd,kBAAkB;AACtB","sourcesContent":[".eths {\n    position: relative;\n    width: 100%;\n    height: 100%;\n    background-color: whitesmoke;\n    background-size: cover;\n    background-position: center;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    overflow-x: hidden;\n}\n\n.eths-des{\n    padding: 0.5rem;\n    text-align: justify;\n    font-size: 35px;\n    color: #77807E;\n    font-style: italic;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
