import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Faculty.css";

const FOM = () => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #f21111", // Blue outline
    color: "#f21111", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const navigate = useNavigate();
  const handleBtnClick = (path) => {
    navigate(path);
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-title">経営学部</h3>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <p className="fac-descrip">
              KIU
              の経営学部は、経営学の卒業生が正しい姿勢、リーダーシップ、協調性を体現できるよう育成、支援、指導することに専念しています。KIU
              の経営学部の卒業生は鋭い認知能力を備え、知識、スキル、研究能力をもって明日のビジネスに貢献する責任を負います。
              <br />
              経営学部は、学生が著名で有能な教授、講師、メンター、ビジネス業界の専門家から学ぶ機会を提供することを目標に、教育環境を構築しました。これらの専門家は、学生が目標を達成できるよう支援することに尽力しています。
              <br />
              学部は、それぞれの専門分野で会計、マーケティング、人事、ビジネス分析の専門家となる優れた個性を持つ卒業生を育成することを目指しています。会計、マーケティング、人事、ビジネス分析の学部が提供する学位プログラムは、スリランカの高等教育省によって承認され、大学助成委員会によって認められています。
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-dean-hed">学部長</h3>
            <p className="fac-descrip1">
              アノマ・エディリマンナ博士
              <br />
              ビジネスおよびマネジメントの博士号（MSU、マレーシア）
              テクノロジーのマネジメントのMBA（UoM、スリランカ）
              物理科学の理学士（USJP、スリランカ） ファイナリストに合格（IBSL）
              <br />
              メール：dean_fom@kiu.ac.lk
            </p>
          </Grid>

          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-hod-hed">学科長</h3>
            <p className="fac-descrip1">
              会計学科 - Thakshila Dushyanthi 氏
              <br />
              財務学 MBA (リーディング) スリジャヤワルダナプラ大学 B.Sc.
              <br />
              財務管理学優等学位、スリランカ サバラガムワ大学
              <br />
              スリランカ ファイナリスト合格 (スリランカ銀行協会)
              <br />
              連絡先: 0774709089
              <br />
              メール: thakshila@kiu.ac.lk
            </p>

            <p className="fac-descrip1">
              人材学科 - Ishara Herath 氏
              <br />
              経済学 MBA、政策研究大学院大学 (GRIPS) 日本
              <br /> B.Sc.公共管理学（特別）スリランカ大学 ジャヤワルダナプラ校
              <br />
              連絡先: 0740798565
              <br />
              メール: iherath@kiu.ac.lk
            </p>

            <Grid container="container" spacing={2}>
              <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
                <p className="fac-descrip1">
                  意思決定科学部門名誉部長 - インドラジャナカ・マハカランダ博士
                  <br />
                  博士号（経営科学）、カンタベリー大学、
                  クライストチャーチ、ニュージーランド。
                </p>
              </Grid>
              <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
                <p className="fac-descrip1">
                  意思決定科学部門副部長 - Ms. Iruni Satharasinghe
                  <br />
                  リーディング ビジネス分析修士号 (UOM) <br />
                  理学士 人材管理 (Sp.) (USJP) <br />
                  連絡先: 076-9853013
                  <br />
                  電子メール: iruni@kiu.ac.lk
                </p>
              </Grid>
            </Grid>

            <p className="fac-descrip1">
              マーケティング学部 - Arundika Gunarathna 氏
              <br />
              MBA (リーディング) (USJ)、BSc (優等学位) マーケティング管理 (SUSL)
              <br />
              連絡先: 077 490 7665
              <br />
              メール: arundika@kiu.ac.lk
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-depts-hed">学部</h3>
            <ul className="fac-depts-list">
              <li className="fac-depts-list-item">人事部</li>
              <li className="fac-depts-list-item">マーケティング部</li>
              <li className="fac-depts-list-item">会計部</li>
              <li className="fac-depts-list-item">意思決定科学部</li>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-fas-hed">プログラムに含まれるもの</h3>
            <p className="fac-descrip1">
              <strong>現地訪問: </strong>
              <br />
              経営学部での学習は、教室の枠を超えています。
              学部では、企業オフィス、製造工場、その他の関連施設への現地訪問を定期的に企画し、学生に経営理論の実践に関する貴重な洞察を提供しています。これらの経験は、理論と実践のギャップを埋めるものです。
              <br />
              <br />
              <strong>ワークショップ シリーズ: </strong>
              <br />
              ワークショップ
              シリーズでは、リーダーシップ開発、技術力、人格の向上、職業上のエチケット、効果的なコミュニケーション、モチベーション戦略、キャリア開発の洞察、チーム
              ビルディング
              スキル、ビジネス分野における社会的能力のニュアンスなど、さまざまな重要なスキルをカバーしています。業界の専門家が主導するこれらのワークショップは、今日の競争の激しい環境で成功するために不可欠な多面的な能力を学生に身につけさせるようにカスタマイズされています。
              <br />
              <br />
              <strong>産業研究: </strong>
              <br />
              教員は、さまざまな管理分野にわたる画期的な産業研究に携わり、知識の限界を押し広げ、現実世界の課題に取り組んでいます。業界のリーダーと協力して、私たちの産業研究プロジェクトは、革新的なソリューションをもたらし、ベストプラクティスを伝え、管理理論の進化に貢献するように設計されています。この変革的な研究は、学生が管理の未来を形作ることに積極的に参加する環境を育みます。
              <br />
              <br />
              <strong>年次キャリアフェアプログラム: </strong>
              <br />
              教員は、学界と専門の世界の間のギャップを埋めるために、年次キャリアフェアプログラムを企画しています。これらのフェアは、学生が主要な雇用主、業界の専門家、および採用担当者とつながるためのユニークなプラットフォームを提供します。これらのプログラムは、経営のさまざまな分野に焦点を当てており、貴重なネットワーキングの機会を提供し、学生が潜在的なキャリアパスを模索し、業界のトレンドに関する洞察を得て、インターンシップや就職を確保できるようにします。
              <br />
              <br />
            </p>
          </Grid>
        </Grid>
      </Container>

      {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button style={buttonStyles1} onClick={() => handleBtnClick('/WeOffer')}>View Courses</Button>
                    </Grid>
                </Grid>
            </Container> */}
    </div>
  );
};

export default FOM;
