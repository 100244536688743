// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/just-sans);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Just Sans", sans-serif;
}

*::after,
*::before {
  box-sizing: border-box;
}

.body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.slick-prev:before,
.slick-next:before {
  color: black !important;
  font-size: 30px !important;
}

.slick-next:before {
  margin-left: 30px !important;
}

.slick-prev:before {
  margin-left: -50px !important;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;;EAEE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,oCAAoC;AACtC;;AAEA;;EAEE,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,kBAAkB;AACpB;;AAEA;;EAEE,uBAAuB;EACvB,0BAA0B;AAC5B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":["@import url(\"https://fonts.cdnfonts.com/css/just-sans\");\n\nhtml,\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: \"Just Sans\", sans-serif;\n}\n\n*::after,\n*::before {\n  box-sizing: border-box;\n}\n\n.body {\n  margin: 0;\n  padding: 0;\n  overflow-x: hidden;\n}\n\n.slick-prev:before,\n.slick-next:before {\n  color: black !important;\n  font-size: 30px !important;\n}\n\n.slick-next:before {\n  margin-left: 30px !important;\n}\n\n.slick-prev:before {\n  margin-left: -50px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
