import { Box, CardContent, Container, Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Card } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Icon from "../../@core/components/icon";
import quote from "../../assets/blockR.png";
import Head from "../../components/JP/Head";
import Header from "../../components/JP/Header";
import Footer from "../../components/JP/footer";
import "./Research.css";

const Research = () => {
  const cardStyle = {
    // borderRadius: "31px",
    background: "#fdf7fb",
    boxShadow: "22px 22px 20px #d7d2d5, -22px -22px 20px #ffffff",
    transition: "transform 0.3s",
    cursor: "pointer",
    height: "17rem",
  };

  const deanStyle = {
    fontFamily: "Kanit, sans-serif",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#FC4E4E",
  };

  const newsStyle = {
    // borderRadius: "50px",
    background: "linear-gradient(225deg, #f0f0f0, #cacaca)",
    boxShadow: "20px 20px 60px #bebebe, -20px -20px 60px #ffffff",
    marginTop: "4%",
    marginLeft: "6%",
  };

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}.${month}.${day}`;
  };

  const [news, setNews] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    fetch("https://kiu.ac.lk/api/website/JP/findLast4Research")
      .then((response) => response.json())
      .then((data) => {
        // Assuming the API response is an array of objects with "testamonial" and
        // "thumbnail" properties
        setNews(data || []);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    // Get the hash from the URL
    const hash = window.location.hash;

    // Check if the hash exists and scroll to the element with the corresponding ID
    if (hash) {
      const targetElement = document.getElementById(hash.substring(1));
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <div className="research">
      <Header />

      <div className="head-div">
        <Head />
      </div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              KIUでの研究
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid
          container="container"
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <Typography
              className="fac-des"
              sx={{
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              「KIU
              は研究をイノベーションの最前線に位置付け、差し迫った課題に取り組み、障壁を打ち破って新たな可能性を引き出すことに専心する研究者チームを擁しています。当大学の研究の卓越性は、当大学の若手研究者の潜在能力を体現しており、複数の分野にわたる共同作業を促進してコミュニティに貢献し、社会の課題に取り組んでいます。
              <br />
              当大学の研究活動、情熱的な研究者、そして当大学の研究の変革的影響について探求する発見の旅にご参加ください。」
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              研究テーマ
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid
          container="container"
          spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: "7%",
            paddingRight: "1.5%",
          }}
        >
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={3.5}
            xl={3.5}
            sx={{
              marginTop: "5%",
            }}
          >
            <Card
              style={cardStyle}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "25px",
                  marginTop: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                人間の健康
              </Typography>
              <Typography
                className="fac-des"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                明日のリーダーに力を与え、人類の健康と幸福の向上に貢献します。
              </Typography>
            </Card>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={3.5}
            xl={3.5}
            sx={{
              marginTop: "5%",
            }}
          >
            <Card
              style={cardStyle}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "25px",
                  marginTop: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                ビジネスと経済
              </Typography>
              <Typography
                className="fac-des"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                ビジネスと経済の分野で先見性と革新性を持つ人材を育成
              </Typography>
            </Card>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={3.5}
            xl={3.5}
            sx={{
              marginTop: "5%",
            }}
          >
            <Card
              style={cardStyle}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "25px",
                  marginTop: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                生物科学
              </Typography>
              <Typography
                className="fac-des"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                生物界の謎を解明する。
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid
          container="container"
          spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: "6%",
            paddingRight: "1.5%",
          }}
        >
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={3.5}
            xl={3.5}
            sx={{
              marginTop: "5%",
            }}
          >
            <Card
              style={cardStyle}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "25px",
                  marginTop: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                言語と文学
              </Typography>
              <Typography
                className="fac-des"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                ダイナミックな学術環境の中で言葉と文化の芸術性を解明します。
              </Typography>
            </Card>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={3.5}
            xl={3.5}
            sx={{
              marginTop: "5%",
            }}
          >
            <Card
              style={cardStyle}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "25px",
                  marginTop: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                心理学
              </Typography>
              <Typography
                className="fac-des"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                人間の心の科学を研究する。
              </Typography>
            </Card>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={3.5}
            xl={3.5}
            sx={{
              marginTop: "5%",
            }}
          >
            <Card
              style={cardStyle}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "25px",
                  marginTop: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                コンピュータサイエンスと情報科学
              </Typography>
              <Typography
                className="fac-des"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                デジタル革命を推進し、テクノロジーとイノベーションの未来を形作ります。
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              KIUで研究する理由
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid
          container="container"
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <Typography
              className="fac-des"
              sx={{
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              KIU
              の活気ある研究コミュニティは、コラボレーション、イノベーション、そして世界的な課題に対する創造的な解決策を促進しています。当校は、画期的な技術進歩に専念する評価の高い機関として際立つ、さまざまな研究の強みに優れています。
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginTop: "1%",
              marginLeft: "3%",
            }}
          >
            <Typography>
              <ul className="res-de-pub-list">
                <li>最先端の研究室</li>
                <p
                  style={{
                    paddingLeft: "4%",
                  }}
                >
                  設備の整った最新の研究室は、さまざまな研究分野で優れています。
                </p>
                <li>図書館とアーカイブへのアクセス</li>
                <p
                  style={{
                    paddingLeft: "4%",
                  }}
                >
                  充実した図書館と貴重なコレクションにより、当社の研究能力が向上します。
                </p>
                <li>フィールド研究リソース</li>
                <p
                  style={{
                    paddingLeft: "4%",
                  }}
                >
                  外部組織との強力な関係により、独自の研究サイトへのアクセスが可能になります。
                </p>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginTop: "1%",
              marginLeft: "1%",
            }}
          >
            <Typography className="fac-des">
              <ul className="res-de-pub-list">
                <li>技術移転およびインキュベーション センター</li>
                <p
                  style={{
                    paddingLeft: "4%",
                  }}
                >
                  業界およびビジネス関係のネットワークが、技術移転とイノベーションをサポートします。
                </p>
                <li>資金調達の機会</li>
                <p
                  style={{
                    paddingLeft: "4%",
                  }}
                >
                  機関や組織との提携により、研究助成金や資金調達の機会が提供されます。
                </p>
                <li>研究ワークショップ</li>
                <p
                  style={{
                    paddingLeft: "4%",
                  }}
                >
                  インタラクティブなワークショップにより、研究者間の知識とコラボレーションが促進されます。
                </p>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              弊社の調査パネルをご覧ください
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid
          container="container"
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <Typography
              className="fac-des"
              sx={{
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              私たちの画期的な研究の背後にいる優れた頭脳たちに会い、彼らの発見への情熱と、彼らが私たちの機関と世界に与えた大きな影響を定義する物語を発見してください。
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "200px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721387744867_Akila.jpg"
                alt="researcher"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Typography className="res-quote-text-s1" sx={{ fontWeight: 600 }}>
              アキラ・ランディカ・ジャヤマハ
            </Typography>
            <p className="res-quote-text-s2">最高成長責任者 / 大学院学部長</p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <div className="res-quote">
              <img
                alt="KIU Uni"
                src={quote}
                className="blockQ"
              ></img>
            </div>
            <div className="res-quote-text">
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                }}
              >
                医療分野の複雑さを調査し、豊かな国家の実現に向けた効果的なソリューションへの道を開きます。
              </Typography>
              <Typography className="res-foc-txt">
                研究の焦点: 豊かな国家のための医療分野の複雑さ。
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  textAlign: "end",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                博士号(読書) (レスター大学、英国)
                <br />
                疫学修士 (ペラデニヤ大学、スリランカ)
                <br />
                看護教育修士 (KIU、スリランカ)
                <br />
                看護学学士号 (優等学位) (ルフナ大学、スリランカ)
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "200px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721388184383_Caroline.jpg"
                alt="researcher"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Typography className="res-quote-text-s1" sx={{ fontWeight: 600 }}>
              キャロライン・アナスタシア・フェルナンド
            </Typography>
            <p className="res-quote-text-s2">
              テクノロジー移転マネージャー/講師
            </p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <div className="res-quote">
              <img
                alt="KIU Uni"
                src={quote}
                className="blockQ"
              ></img>
            </div>
            <div className="res-quote-text">
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                }}
              >
                私は、依存症から解放され、回復への道を切り開くために遺伝暗号を解読することを目指しています。"
              </Typography>
              <Typography className="res-foc-txt">
                研究の焦点: 依存症からの回復のために遺伝暗号を解読すること。
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  textAlign: "end",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                BSc. (Hons) 医療検査科学 (General Sir John Kotelawala Defence
                University、スリランカ)
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "200px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721388367427_Amal.jpg"
                alt="researcher"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Typography className="res-quote-text-s1" sx={{ fontWeight: 600 }}>
              アマル・スダラカ・サマラシンゲ
            </Typography>
            <p className="res-quote-text-s2">アシスタント講師</p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <div className="res-quote">
              <img
                alt="KIU Uni"
                src={quote}
                className="blockQ"
              ></img>
            </div>
            <div className="res-quote-text">
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                }}
              >
                不可能なことだらけの世界で、私は持続可能な食と農業のルーツを探り、革新と献身を通じて、より環境に優しく、より豊かな世界を育んでいます。」
              </Typography>
              <Typography className="res-foc-txt">
                研究の焦点: より環境に優しい世界のための持続可能な食糧と農業。
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  textAlign: "end",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                BSc. (優等学位) 食品科学技術 (ペラデニヤ大学、スリランカ)
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "200px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721388425900_Fabiola.jpg"
                alt="researcher"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Typography className="res-quote-text-s1" sx={{ fontWeight: 600 }}>
              アントネット・メリザ・ファビオラ・フェルナンド
            </Typography>
            <p className="res-quote-text-s2">研究アシスタント</p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <div className="res-quote">
              <img
                alt="KIU Uni"
                src={quote}
                className="blockQ"
              ></img>
            </div>
            <div className="res-quote-text">
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                }}
              >
                高度な技術的介入を調査することで、私の研究が遺伝的複雑さに対する私たちの理解にどのような影響を与え、どのような影響を与えるかについての洞察を得ようと努めています。」
              </Typography>
              <Typography className="res-foc-txt">
                研究の焦点: 遺伝子研究における高度な技術的介入。
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  textAlign: "end",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                BSc. (優等学位) バイオテクノロジー (ノーサンブリア大学、英国)
                <br />
                薬学上級ディプロマ (PATHE アカデミー、スロバキア)
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "200px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721388482425_Janitha.jpg"
                alt="researcher"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Typography className="res-quote-text-s1" sx={{ fontWeight: 600 }}>
              T. M. ジャニタ チャルニ
            </Typography>
            <p className="res-quote-text-s2">研究助手</p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <div className="res-quote">
              <img
                alt="KIU Uni"
                src={quote}
                className="blockQ"
              ></img>
            </div>
            <div className="res-quote-text">
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                }}
              >
                薬理学的実践の可能性を明らかにし、臨床神経心理学的洞察を調査する力を活用する」
              </Typography>
              <Typography className="res-foc-txt">
                研究の焦点: 薬理学的実践と臨床神経心理学的洞察。
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  textAlign: "end",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                BSc. (優等学位) 心理学 (KIU、スリランカ)
                <br />
                カウンセリング ディプロマ (ケラニヤ大学、スリランカ)
                <br />
                薬局管理および実務ディプロマ (アクィナス
                カレッジおよび高等研究、スリランカ)
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-horizontal-divider"></div>

      <Container id="researchSection">
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              学部長が主導する研究の成果をご覧ください
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container id="researchSection">
        <Grid
          container="container"
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginTop: "3%",
              marginLeft: "6%",
            }}
          >
            <Accordion
              id="researchSection"
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                id="controlled-panel-header-4"
                aria-controls="controlled-panel-content-4"
                expandIcon={
                  <Icon fontSize="1.25rem" icon="tabler:chevron-down" />
                }
              >
                <Typography sx={deanStyle}>
                  S. S. P. ワルナクラシュリヤ教授: 看護学部名誉学部長{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open("https://doi.org/10.1016/j.pain.2013.02.008")
                  }
                >
                  Coggon, D.、Ntani, G.、Palmer,K. T.、Felli, V. E.、Harari,
                  R.、Barrero, L. H.、Felknor, S. A.、Gimeno, D.、Cattrell, A.、
                  Serra, C.、Bonzini, M.、Solidaki, E.、Merisalu, E.、Habib, R.
                  R.、Sadeghian, F.、Kadir, M. M.、Warnakulasuriya, S. S. P.、
                  Matsudaira, K.、Nyantumbu, B.、Sim, M. R. & Gray, A. (2013)
                  「労働人口における筋骨格系の痛みの障害：それは仕事、人、それとも文化か？」、PAIN、154
                  (6)、pp. 856-863。
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open(
                      "https://doi.org/10.1371/annotation/3faf76e5-f73e-427f-9d60-8f94939b0f7e"
                    )
                  }
                >
                  Coggon, D.、Ntani, G.、Palmer, K. T.、Felli, V. E.、Harari, R.
                  et al. (2012)「CUPID (障害に対する文化的および心理社会的影響)
                  研究: データ収集の方法と研究サンプルの特徴」、PLOS ONE、7
                  (10)。
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open("https://doi.org/10.1093/occmed/kqs057")
                  }
                >
                  Warnakulasuriya, S. S. P.、Peiris-John, R. J.、Coggon, D.、
                  Ntani, G.、Sathiakumar, N. & Wickremasinghe, A. R. (2012)
                  「スリランカの4つの職業集団における筋骨格痛」、職業医学、62
                  (4)、pp.269–272。
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              id="researchSection"
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
              sx={{ mt: 1 }}
            >
              <AccordionSummary
                id="controlled-panel-header-6"
                aria-controls="controlled-panel-content-6"
                expandIcon={
                  <Icon fontSize="1.25rem" icon="tabler:chevron-down" />
                }
              >
                <Typography sx={deanStyle}>
                  アノマ・エディリマンナ博士：経営学部長
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // 必要に応じて色を変更します
                    },
                  }}
                  onClick={() =>
                    window.open("https://doi.org/10.24191/ajue.v18i1.17252")
                  }
                >
                  Perera, A. A. S.、Rahmat, A.K.、Khatibi, A. & Azam, S.M.F.
                  (2022).
                  スリランカの高等教育機関のパフォーマンスに対するエンタープライズリスク管理の影響を測定する指標の信頼性評価。アジア大学教育ジャーナル
                  (AJUE)。第 18 巻、第 1 号、2022 年 1 月。pp (300 – 321){" "}
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // 必要に応じて色を変更します
                    },
                  }}
                  onClick={() =>
                    window.open(
                      "http://hkjoss.com/index.php/journal/article/view/474/470"
                    )
                  }
                >
                  Perera, A. A. S.、Rahmat, A.K.、Khatibi, A. & Azam, S.M.F.
                  (2021)。スリランカの高等教育機関のパフォーマンスに対するエンタープライズ
                  リスク管理の影響。 Hong Kong Journal of Social Sciences
                  (HKJOSS)。 No 58 2021年秋冬号。pp (252 – 265)。
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // 必要に応じて色を変更します
                    },
                  }}
                  onClick={() =>
                    window.open(
                      " https://www.ijern.com/journal/2020/October- 2020/14.pdf "
                    )
                  }
                >
                  Perera, A. A. S.、Rahmat, A.K.、Khatibi, A. & Azam, S.M.F.
                  (2020). 文献レビュー:
                  高等教育へのエンタープライズリスク管理の導入。国際教育研究ジャーナル。8(10).
                  pp (155-172)
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // 必要に応じて色を変更します
                    },
                  }}
                  onClick={() =>
                    window.open(
                      "http://dx.doi.org/10.29322/IJSRP.9.07.2019.p9130"
                    )
                  }
                >
                  Perera, A. A. S. (2019). Enterprise Risk Management –
                  International Standards and Frameworks. International Journal
                  of Scientific and Research Publications. 9(7). pp (211-217)
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              id="researchSection"
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              sx={{ mt: 1 }}
            >
              <AccordionSummary
                id="controlled-panel-header-2"
                aria-controls="controlled-panel-content-2"
                expandIcon={
                  <Icon fontSize="1.25rem" icon="tabler:chevron-down" />
                }
              >
                <Typography sx={deanStyle}>
                  カンティ・ヘッティゴダ博士：行動科学学部名誉学部長{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open("https://doi.org/10.4038/kjms.v5i1.71")
                  }
                >
                  Weerasinghe, G. D. D. B.、Patabendige, P. N. M.、Thuduwage, S.
                  V. R.、Arumapperumachchi, P. M.、Gamage, C. K. W.、Hettigoda,
                  K.
                  (2023)「スリランカの国防大学の看護学生を対象としたシュッテ自己報告式感情知能検査（SSEIT）の異文化適応」、KDU
                  Journal of Multidisciplinary Studies、5（1）。
                </Typography>
                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open(
                      "http://192.248.104.6/bitstream/handle/345/2062/dss012.pdf?sequence=3&isAllowed=y"
                    )
                  }
                >
                  Hettigoda, K. & Hamersley, R.
                  (2019)「レジリエンスは軍事パフォーマンスと後継者を予測する：軍事レジリエンスインベントリ（RIM）の予測妥当性研究」。2019年第12回国際研究会議の議事録、KDU。
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              id="researchSection"
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={{ mt: 1 }}
            >
              <AccordionSummary
                id="controlled-panel-header-1"
                aria-controls="controlled-panel-content-1"
                expandIcon={
                  <Icon fontSize="1.25rem" icon="tabler:chevron-down" />
                }
              >
                <Typography sx={deanStyle}>
                  アキラ・ランディカ・ジャヤマハ（博士課程）：大学院研究科長{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open("https://doi.org/10.1007/s11136-022-03270-4")
                  }
                >
                  Jayamaha, A. R.、Herath, N. D. M.、Dharmarathna, N. D.、
                  Sandakumari, H. S.、Ranadeva, N. D. K.、Fernando, M. M.、
                  Samarakoon, N. A. W.、Amarabandu, P. N.、Senanayake, B.、
                  Darshana, T.、Renuka, N.、Samarasinghe, K. L. & Fernando, N.
                  (2022)「スリランカの居住型治療に登録された物質使用障害患者の健康関連の生活の質：後ろ向き横断研究」、生活の質研究。
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open("https://doi.org/10.1177/11782218221100823")
                  }
                >
                  Jayamaha, A. R.、Dharmarathna, N. D.、Herath, N. D.、Ranadeva,
                  N. D.、Fernando, M. M.、Samarasinghe, K. L.、Amarabandu, P.
                  N.、Senanayake, B.、Darshana, T.、Renuka, N.、Rajapakse, I.
                  H.、Gunasekara, C. P.、Meegoda, L. & Fernando, N.
                  (2022)「スリランカの特定のリハビリテーションセンターの居住型治療を受けている個人の薬物使用パターンと特徴：記述的横断的研究」、薬物乱用：研究と治療、16、pp.1-17。
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open("https://doi.org/10.1080/10509674.2022.2062519")
                  }
                >
                  Jayamaha, A. R.、Herath, H. M. N. D. M.、Dharmarathna, H. N.
                  N. D.、Ranadeva, N. D. K.、Amarabandu, P. N.、Senanayake, B.、
                  ... & Fernando, S. S. N.
                  (2022)「薬物使用障害のある投獄された麻薬犯罪者に対する更生介入としての治療コミュニティの実施：スリランカへの特例」、Journal
                  of Offender Rehabilitation、 61 (4)、pp. 188-206。
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              id="researchSection"
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
              sx={{ mt: 1 }}
            >
              <AccordionSummary
                id="controlled-panel-header-5"
                aria-controls="controlled-panel-content-5"
                expandIcon={
                  <Icon fontSize="1.25rem" icon="tabler:chevron-down" />
                }
              >
                <Typography sx={deanStyle}>
                  R. M. S. ラトナヤケ博士:
                  コンピュータサイエンスおよびエンジニアリング学部名誉学部長{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open("https://doi.org/10.1145/2503210.2503269")
                  }
                >
                  Jayasena, S.、Amarasinghe, S.、Abeyweera, A.、Amarasinghe,
                  G.、 De Silva, H.、Rathnayake, S.、Meng, X.、Liu, Y. (2013)
                  「機械学習を使用した偽共有の検出」。SC '13:
                  高性能コンピューティング、ネットワーキング、ストレージ、分析に関する国際会議の議事録。
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open("https://doi.org/10.1109/ICPP.2017.43")
                  }
                >
                  Rathnayake, S.、Loghin, D.、Teo, Y. M. (2017)「CELIA:
                  クラウド上の弾力性のあるアプリケーションのコストと時間のパフォーマンス」。第46回
                  国際並列処理会議 (ICPP)、pp. 342-351。
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open("https://doi.org/10.1145/3409390.3409409")
                  }
                >
                  Rathnayake, S.、Ramapantulu, L.、Teo, Y. M. (2020) 「クラウド
                  アプリケーションのコスト精度パフォーマンスの特徴付け」。第 49
                  回国際並列処理会議のワークショップ議事録。
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              id="researchSection"
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              sx={{ mt: 1 }}
            >
              <AccordionSummary
                id="controlled-panel-header-3"
                aria-controls="controlled-panel-content-3"
                expandIcon={
                  <Icon fontSize="1.25rem" icon="tabler:chevron-down" />
                }
              >
                <Typography sx={deanStyle}>
                  ナディーカ・ラナデヴァ氏：健康科学学部長
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open(
                      "https://www.researchgate.net/publication/376621943_Roles_of_NS1_Protein_in_Flavivirus_Pathogenesis"
                    )
                  }
                >
                  1. DR Perera、ND Ranadeva、K Sirisena、KJ Wijesinghe;
                  フラビウイルス病原性における NS1 タンパク質の役割、ACS 感染症
                  10 (1)、20-56
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open(
                      "https://www.researchgate.net/publication/365990771_Perceived_effectiveness_of_theraputic_community_model_as_a_rehabilitation_approach_for_the_individuals_with_substance_use_disorder_Sri_Lanka"
                    )
                  }
                >
                  2. Dharmarathna, N. D.、Jayamaha, A. R.、Ranadeva, N. D. K.、
                  Rajapakse, H.、Gunasekara, C.、Fernando, N.、および Meegoda,
                  L.
                  (2023)。スリランカの薬物使用障害患者に対するリハビリテーションアプローチとしての治療コミュニティモデルの有効性の認識。治療コミュニティ：治療コミュニティの国際ジャーナル。
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open(
                      "https://www.researchgate.net/publication/364342186_Health-related_quality_of_life_in_patients_with_substance_use_disorders_enrolled_to_the_residential_treatment_in_Sri_Lanka_a_retrospective_cross-sectional_study"
                    )
                  }
                >
                  3. Jayamaha AR、Herath NDM、Dharmarathna ND、Sandakumari HS、
                  Ranadeva NDK、Fernando MM、Samarakoon NAW、Amarabandu PN、
                  Senanayake B、Darshana T、Renuka N、Samarasinghe KL、Fernando
                  N。スリランカの居住型治療を受けている物質使用障害患者の健康関連の生活の質：後ろ向き横断研究。Qual
                  Life Res。 2023年2月;32(2):435-445。doi:
                  10.1007/s11136-022-03270-4。電子出版 2022年10月16日。PMID:
                  36245018。
                </Typography>

                <Typography
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    mb: 2,
                    "&:hover": {
                      color: "#CECECE", // Change the color as needed
                    },
                  }}
                  onClick={() =>
                    window.open(
                      "https://www.researchgate.net/publication/359294643_Design_and_implementation_of_a_novel_pharmacogenetic_assay_for_the_identification_of_the_CYP2D610_genetic_variant"
                    )
                  }
                >
                  4. Ranadeva NDK、Sirisena ND、Wetthasinghe TK、Noordeen N、
                  Dissanayake
                  VHW。CYP2D6*10遺伝子変異の同定のための新しい薬理遺伝学的アッセイの設計と実装。BMC
                  Res Notes。2022年3月16日;15(1):104。doi:
                  10.1186/s13104-022-05993-6。PMID: 35296326; PMCID:
                  PMC8925205。
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              研究ニュース
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container
        sx={{
          marginTop: "5%",
        }}
      >
        {news.map((item) => (
          <Card key={item.id} style={newsStyle}>
            <CardContent>
              <Grid container="container" spacing={2}>
                <Grid
                  item="item"
                  xs={12}
                  sm={12}
                  md={7}
                  lg={7}
                  xl={7}
                  sx={{
                    marginTop: "3%",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Kanit, sans-serif",
                      textAlign: "left",
                      fontSize: "25px",
                      fontWeight: "bold",
                    }}
                  >
                    {item.title}
                  </Typography>
                </Grid>

                <Grid
                  item="item"
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                  sx={{
                    marginTop: "5%",
                  }}
                >
                  {/* Add content for the second grid if needed */}
                </Grid>

                <Grid
                  item="item"
                  xs={12}
                  sm={12}
                  md={7}
                  lg={7}
                  xl={7}
                  sx={{
                    marginTop: "1%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      color: "#717171 ",
                      fontFamily: "Montserrat, sans-serif",
                      fontStyle: "italic",
                    }}
                  >
                    {item.content}
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "end",
                      mt: 3,
                      fontWeight: "900",
                    }}
                  >
                    {formatDate(item.date)}
                  </Typography>
                </Grid>

                <Grid
                  item="item"
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                  sx={{
                    marginTop: "3%",
                    marginLeft: "5%",
                  }}
                >
                  <Typography>
                    <img
                      alt="KIU Uni"
                      src={item.titleImage}
                      className="res-researcher-img"
                      style={{
                        width: "100%",
                        height: "70%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </Container>

      <div className="footer-div">
        <Footer />
      </div>
    </div>
  );
};

export default Research;
