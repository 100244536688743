import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "../postgraduate/Pgcrs.css";
import BAPDF from "../../../brochure/sample.pdf";
import DFWimg from "../../../assets/DFW.jpg";

const DFW = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              製作と溶接のディプロマ
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="pg-crs-des1">
              レベル 3 NVQ 製造および溶接工学ディプロマ -
              手動溶接は、エンジニアリング部門の製造および溶接エンジニアとして働いている、または働きたい学習者向けです。このコースでは、学習者は各部門での雇用やキャリアアップに必要なスキルを習得、開発、実践できます。このコースは、科学、エンジニアリング、製造技術のセクタースキル協議会である
              SEMTA と共同で設計されています。
              このコースでは、学習者は就職または次の City & Guilds
              資格に進むことができます。
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={DFWimg}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "80%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des2">
              <p>
                レベル: 製造および溶接工学の短期コース レベル 3 NVQ
                拡張ディプロマ。
                <br />
                期間:
                <br />
                配信モード:
                <br />
                配信媒体:
              </p>
            </Typography>
          </Grid>

          {/* <Grid
                        item="item"
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{
                            // marginLeft: '6%'
                        }}>
                        <Typography className='pg-crs-des3'>
                            <h3>Entry Requirements</h3>
                            <ul>
                                <li>
                                    <ul>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                </li>
                                <li></li>
                            </ul>
                        </Typography>
                    </Grid> */}
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <h5>カリキュラム</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <ul>
                <ol>
                  必須
                  <ul>
                    <li>法定規制および組織の安全要件の遵守</li>
                    <li>エンジニアリング データとドキュメントの使用と解釈</li>
                    <li>エンジニアリングにおける効率的かつ効果的な作業</li>
                  </ul>
                </ol>
                <ol>
                  オプション
                  <ul>
                    <li>手動金属アーク プロセスによる溶接材料</li>
                    <li>
                      半自動 MIG/MAG およびフラックス入りアーク
                      プロセスによる溶接材料
                    </li>
                    <li>
                      手動 TIG およびプラズマ アーク溶接プロセスによる溶接材料
                    </li>
                    <li>手動酸素/燃料ガス溶接プロセスによる溶接材料</li>
                    <li>
                      複数の手動アーク溶接プロセスを使用したパイプ/チューブの溶接
                    </li>
                    <li>
                      複数の手動アーク溶接プロセスを使用したプレートの溶接
                    </li>
                  </ul>
                </ol>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            {/* <a href="#" target="_blank" rel="noopener noreferrer"> */}
            <Button disabled style={buttonStyles1}>
              適用する
            </Button>
            {/* </a> */}
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              パンフレットをダウンロード
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              近い
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default DFW;
