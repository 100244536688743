import { Box, Button, Container, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import {
  PiArrowCircleRightBold,
  PiMagnifyingGlassDuotone,
} from "react-icons/pi";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import ScrollTrigger from "react-scroll-trigger";
// import bg from '../assets/bg.jpg';
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useLocation, useNavigate } from "react-router-dom";
import homeImg from "../../assets/HOMEIMG.jpg";
import logo from "../../assets/NEWLOG.png";
import Header from "../../components/JP/Header";
import KIULife from "../../components/JP/flippingCard/KIULife";
import TypeSlider from "../../components/JP/flippingCard/TypeSlider";
import Foot from "../../components/JP/footer";
import PartnerS from "../../components/JP/partnerS";
import "./Home.css";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

import { Icon } from "@iconify/react/dist/iconify.js";

import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";

import { BsInstagram, BsLinkedin } from "react-icons/bs";

import { useMediaQuery } from "@mui/material";

const LanguageSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23000000' stroke='%23000000' stroke-width='1' d='M20.472 11.997a4.865 4.865 0 0 0-4-2.204a5.592 5.592 0 0 0-.131-1.024a1 1 0 1 0-1.945.462a3.553 3.553 0 0 1 .082.601a13.577 13.577 0 0 0-3.257.822c.023-1.204.077-2.407.197-3.607l.038-.382A33.435 33.435 0 0 0 14.938 6l.12-.03a1 1 0 1 0-.48-1.94l-.122.03c-.922.23-1.856.404-2.794.55l.151-1.51a1 1 0 0 0-1.99-.2l-.196 1.96c-.934.083-1.87.14-2.809.14a1 1 0 0 0 0 2c.87 0 1.74-.046 2.607-.114a46.66 46.66 0 0 0-.203 4.698c-.134.073-.27.142-.403.22a16.407 16.407 0 0 0-1.949 1.31l-.022.018a13.74 13.74 0 0 0-2.649 2.7a3.004 3.004 0 0 0 2.947 4.72a9.74 9.74 0 0 0 2.837-1.014a.996.996 0 0 0 1.822-.703c-.025-.145-.036-.291-.058-.437a13.838 13.838 0 0 0 1.314-1.155a13.167 13.167 0 0 0 2.101-2.73c.023-.039.042-.078.065-.118c.118-.21.23-.422.332-.635c.053-.111.102-.222.151-.333a10.4 10.4 0 0 0 .329-.838c.032-.096.06-.191.09-.287c.05-.169.101-.337.141-.504l.005-.018A3.015 3.015 0 0 1 18.741 13c1.019 1.767-.963 4.977-4.417 7.154a1 1 0 1 0 1.067 1.692c4.499-2.836 6.683-7.069 5.08-9.849M6.796 18.583a1.005 1.005 0 0 1-.98-1.574a11.893 11.893 0 0 1 2.291-2.323l.027-.021a13.97 13.97 0 0 1 1.144-.793c.06 1.195.173 2.386.326 3.574a8.185 8.185 0 0 1-2.808 1.137M14.126 12a8.166 8.166 0 0 1-.316.78c-.056.12-.118.239-.18.358q-.145.278-.311.554c-.085.14-.172.279-.265.418a11.48 11.48 0 0 1-1.408 1.719c-.07.07-.143.133-.214.2q-.163-1.597-.211-3.202a12.513 12.513 0 0 1 2.94-.933z'/%3E%3C/svg%3E")`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#000000",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#F0F6F5",
    width: 32,
    height: 32,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23000000' stroke='%23000000' stroke-width='1' d='M14 10h2v.757a4.5 4.5 0 0 1 7 3.743V20h-2v-5.5c0-1.43-1.174-2.5-2.5-2.5S16 13.07 16 14.5V20h-2zm-2-6v2H4v5h8v2H4v5h8v2H2V4z'/%3E%3C/svg%3E")`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#000000",
    borderRadius: 20 / 2,
  },
}));

const CustomButton = styled(Button)({
  backgroundColor: "white",
  color: "black",
  borderRadius: "30px",
  padding: "10px 20px",
  textTransform: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontWeight: "bold",
  fontSize: "1.2rem",
  transition: "background-color 0.3s, color 0.3s",
  "& .icon-container .icon": {
    color: "white",
  },
  "&:hover": {
    backgroundColor: "red",
    color: "white",
    "& .icon-container": {
      backgroundColor: "white",
      "& .icon": {
        color: "black",
      },
    },
  },
});

const Home = () => {
  const [heading, setHeading] = useState("");
  const [subheading, setSubHeading] = useState("");
  const [background, setBackground] = useState("");
  const [type, setType] = useState("");

  const handleIconClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://kiu.ac.lk/api/website/JP/getLastHeading")
      .then((response) => response.json())
      .then((data) => {
        // Assuming the API response contains 'heading' and 'subHeading' fields
        setHeading(data.heading);
        setSubHeading(data.subheading);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://kiu.ac.lk/api/website/JP/getLastBackground")
      .then((response) => response.json())
      .then((data) => {
        // Assuming the API response contains 'heading' and 'subHeading' fields
        setBackground(data.background);
        setType(data.type);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    fetch("https://kiu.ac.lk/api/website/JP/findLast3News")
      .then((response) => response.json())
      .then((data) => setNewsData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const [counterOn, setCounterOn] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [shouldAnimate2, setShouldAnimate2] = useState(false);
  const [shouldAnimate3, setShouldAnimate3] = useState(false);
  const [shouldAnimate4, setShouldAnimate4] = useState(false);
  const [shouldAnimate5, setShouldAnimate5] = useState(false);
  const [shouldAnimate6, setShouldAnimate6] = useState(false);
  const [shouldAnimate7, setShouldAnimate7] = useState(false);
  const [shouldAnimate8, setShouldAnimate8] = useState(false);
  const [shouldAnimate9, setShouldAnimate9] = useState(false);
  const [shouldAnimate10, setShouldAnimate10] = useState(false);
  const [shouldAnimate11, setShouldAnimate11] = useState(false);

  const videoRef = React.createRef();

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const [ref1, inView1] = useInView({ threshold: 0.2 });
  const [ref2, inView2] = useInView({ threshold: 0.2 });
  const [ref3, inView3] = useInView({ threshold: 0.2 });
  const [ref4, inView4] = useInView({ threshold: 0.2 });
  const [ref5, inView5] = useInView({ threshold: 0.2 });
  const [ref6, inView6] = useInView({ threshold: 0.2 });
  const [ref7, inView7] = useInView({ threshold: 0.2 });
  const [ref8, inView8] = useInView({ threshold: 0.2 });
  const [ref9, inView9] = useInView({ threshold: 0.2 });
  // const [ref12, inView12] = useInView({   threshold: 0.2, }); const [ref13,
  // inView13] = useInView({   threshold: 0.2, });

  const [ref14, inView14] = useInView({ threshold: 0.2 });

  const [ref15, inView15] = useInView({ threshold: 0.2 });

  const [ref16, inView16] = useInView({ threshold: 0.2 });
  const [ref17, inView17] = useInView({ threshold: 0.2 });
  const [ref18, inView18] = useInView({ threshold: 0.2 });

  const [ref19, inView19] = useInView({ threshold: 0.2 });
  const [ref20, inView20] = useInView({ threshold: 0.2 });
  const [ref21, inView21] = useInView({ threshold: 0.2 });

  // const [ref22, inView22] = useInView({   threshold: 0.2, }); useEffect(() => {
  // Trigger animations sequentially based on scroll position   if (inView22) {
  // setTimeout(() => {       setCounterOn(true);     }, 500);  Adjust the delay
  // as needed   } }, [inView22]);

  useEffect(() => {
    if (inView2) {
      // Perform any other actions/animations here
    }
  }, [inView2]);

  useEffect(() => {
    if (inView3) {
      // Perform any other actions/animations here
    }
  }, [inView3]);

  useEffect(() => {
    if (inView4) {
      // Perform any other actions/animations here
    }
  }, [inView4]);

  useEffect(() => {
    if (inView5) {
      // Perform any other actions/animations here
    }
  }, [inView5]);

  useEffect(() => {
    if (inView6) {
      // Perform any other actions/animations here
    }
  }, [inView6]);

  useEffect(() => {
    if (inView7) {
      // Perform any other actions/animations here
    }
  }, [inView7]);
  useEffect(() => {
    if (inView8) {
      // Add a delay of 0.5 seconds (adjust as needed)
      setTimeout(() => {
        setShouldAnimate(true);
      }, 350); // 500 milliseconds (0.5 seconds) delay
    } else {
      // Reset shouldAnimate to false when not in view
      setShouldAnimate(false);
    }
  }, [inView8]);

  useEffect(() => {
    if (inView9) {
      // Add a delay of 0.5 seconds (adjust as needed)
      setTimeout(() => {
        setShouldAnimate5(true);
      }, 50); // 500 milliseconds (0.5 seconds) delay
    } else {
      // Reset shouldAnimate to false when not in view
      setShouldAnimate5(false);
    }
  }, [inView9]);

  useEffect(() => {
    if (inView17) {
      // Add a delay of 0.5 seconds (adjust as needed)
      setTimeout(() => {
        setShouldAnimate6(true);
      }, 650); // 500 milliseconds (0.5 seconds) delay
    } else {
      // Reset shouldAnimate to false when not in view
      setShouldAnimate6(false);
    }
  }, [inView17]);

  useEffect(() => {
    if (inView18) {
      // Add a delay of 0.5 seconds (adjust as needed)
      setTimeout(() => {
        setShouldAnimate7(true);
      }, 950); // 500 milliseconds (0.5 seconds) delay
    } else {
      // Reset shouldAnimate to false when not in view
      setShouldAnimate7(false);
    }
  }, [inView18]);

  useEffect(() => {
    if (inView21) {
      // Add a delay of 0.5 seconds (adjust as needed)
      setTimeout(() => {
        setShouldAnimate11(true);
      }, 350); // 500 milliseconds (0.5 seconds) delay
    } else {
      // Reset shouldAnimate to false when not in view
      setShouldAnimate11(false);
    }
  }, [inView21]);

  useEffect(() => {
    if (inView16) {
      // Add a delay of 0.5 seconds (adjust as needed)
      setTimeout(() => {
        setShouldAnimate9(true);
      }, 650); // 500 milliseconds (0.5 seconds) delay
    } else {
      // Reset shouldAnimate to false when not in view
      setShouldAnimate9(false);
    }
  }, [inView16]);

  useEffect(() => {
    if (inView14) {
      // Add a delay of 0.5 seconds (adjust as needed)
      setTimeout(() => {
        setShouldAnimate2(true);
      }, 50); // 500 milliseconds (0.5 seconds) delay
    } else {
      // Reset shouldAnimate to false when not in view
      setShouldAnimate2(false);
    }
  }, [inView14]);

  useEffect(() => {
    if (inView15) {
      // Add a delay of 0.5 seconds (adjust as needed)
      setTimeout(() => {
        setShouldAnimate3(true);
      }, 350); // 500 milliseconds (0.5 seconds) delay
    } else {
      // Reset shouldAnimate to false when not in view
      setShouldAnimate3(false);
    }
  }, [inView15]);

  useEffect(() => {
    if (inView16) {
      // Add a delay of 0.5 seconds (adjust as needed)
      setTimeout(() => {
        setShouldAnimate4(true);
      }, 950); // 500 milliseconds (0.5 seconds) delay
    } else {
      // Reset shouldAnimate to false when not in view
      setShouldAnimate4(false);
    }
  }, [inView16]);

  useEffect(() => {
    if (inView19) {
      // Add a delay of 0.5 seconds (adjust as needed)
      setTimeout(() => {
        setShouldAnimate8(true);
      }, 50); // 500 milliseconds (0.5 seconds) delay
    } else {
      // Reset shouldAnimate to false when not in view
      setShouldAnimate8(false);
    }
  }, [inView19]);

  useEffect(() => {
    if (inView20) {
      // Add a delay of 0.5 seconds (adjust as needed)
      setTimeout(() => {
        setShouldAnimate10(true);
      }, 150); // 500 milliseconds (0.5 seconds) delay
    } else {
      // Reset shouldAnimate to false when not in view
      setShouldAnimate10(false);
    }
  }, [inView20]);

  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const [language, setLanguage] = useState("english");
  useEffect(() => {
    setLanguage(location.pathname.includes("/jp") ? "japanese" : "english");
  }, [location.pathname]);

  const handleChange = (event) => {
    const newLanguage = event.target.checked ? "japanese" : "english";
    setLanguage(newLanguage);
    navigate(newLanguage === "japanese" ? "/jp" : "/");
  };

  const isLargeScreen = useMediaQuery("(min-width:1280px)");

  const isMobileScreen = useMediaQuery("(max-width:901px)");

  const handleSearch = () => {
    const keyword = searchInput.toLowerCase();

    if (
      keyword === "home" ||
      keyword === "partners" ||
      keyword === "contact" ||
      keyword === "pillers"
    ) {
      navigate("/");
    } else if (
      keyword === "about" ||
      keyword === "start" ||
      keyword === "past" ||
      keyword === "going" ||
      keyword === "present" ||
      keyword === "vision" ||
      keyword === "mission" ||
      keyword === "vc message" ||
      keyword === "testamonial" ||
      keyword === "student" ||
      keyword === "voice"
    ) {
      navigate("/About");
    } else if (
      keyword.toLowerCase() === "we offer" ||
      keyword.toLowerCase() === "undergraduate" ||
      keyword.toLowerCase() === "postgraduate" ||
      keyword.toLowerCase() === "short" ||
      keyword.toLowerCase() === "course" ||
      keyword.toLowerCase() === "short course" ||
      keyword.toLowerCase() === "business analytics" ||
      keyword.toLowerCase() === "artificial intelligence" ||
      keyword.toLowerCase() === "data science" ||
      keyword.toLowerCase() === "software engineering" ||
      keyword.toLowerCase() === "cyber security" ||
      keyword.toLowerCase() === "computer networks" ||
      keyword.toLowerCase() === "computer networks and cyber security" ||
      keyword.toLowerCase() === "management information system" ||
      keyword.toLowerCase() === "management" ||
      keyword.toLowerCase() === "information system" ||
      keyword.toLowerCase() === "accounting" ||
      keyword.toLowerCase() === "human resource" ||
      keyword.toLowerCase() === "marketing" ||
      keyword.toLowerCase() === "psychology" ||
      keyword.toLowerCase() === "science" ||
      keyword.toLowerCase() === "biomedical science" ||
      keyword.toLowerCase() === "acupuncture" ||
      keyword.toLowerCase() === "medical science in acupuncture" ||
      keyword.toLowerCase() === "kaatsu" ||
      keyword.toLowerCase() === "medical science in kaatsu" ||
      keyword.toLowerCase() === "medical science" ||
      keyword.toLowerCase() === "nursing" ||
      keyword.toLowerCase() === "master" ||
      keyword.toLowerCase() === "administration" ||
      keyword.toLowerCase() === "business" ||
      keyword.toLowerCase() === "education" ||
      keyword.toLowerCase() === "nursing education" ||
      keyword.toLowerCase() === "nursing management" ||
      keyword.toLowerCase() === "science in nursing" ||
      keyword.toLowerCase() === "philosophy in nursing" ||
      keyword.toLowerCase() === "slqf 9" ||
      keyword.toLowerCase() === "slqf 10" ||
      keyword.toLowerCase() === "slqf 11" ||
      keyword.toLowerCase() === "certificate" ||
      keyword.toLowerCase() === "ielts" ||
      keyword.toLowerCase() === "laboratory" ||
      keyword.toLowerCase() === "assistant" ||
      keyword.toLowerCase() === "analytics" ||
      keyword.toLowerCase() === "human" ||
      keyword.toLowerCase() === "resource" ||
      keyword.toLowerCase() === "hr" ||
      keyword.toLowerCase() === "financial" ||
      keyword.toLowerCase() === "digital" ||
      keyword.toLowerCase() === "marketing" ||
      keyword.toLowerCase() === "digital marketing" ||
      keyword.toLowerCase() === "caregiver" ||
      keyword.toLowerCase() === "acupuncture" ||
      keyword.toLowerCase() === "clinical" ||
      keyword.toLowerCase() === "fabrication" ||
      keyword.toLowerCase() === "welding"
    ) {
      navigate("/WeOffer");
    } else if (keyword === "faculties" || keyword === "dean") {
      navigate("/Faculties");
    } else if (
      keyword === "facilities" ||
      keyword === "sakura" ||
      keyword === "kyo" ||
      keyword === "computer" ||
      keyword === "lab" ||
      keyword === "computer lab" ||
      keyword === "biomedical lab" ||
      keyword === "training center" ||
      keyword === "moot" ||
      keyword === "court" ||
      keyword === "moot court"
    ) {
      navigate("/Facilities");
    } else if (keyword === "news" || keyword === "past news") {
      navigate("/News");
    } else if (
      keyword === "research" ||
      keyword === "theme" ||
      keyword === "research theme" ||
      keyword === "why" ||
      keyword === "why research" ||
      keyword === "panel" ||
      keyword === "researches" ||
      keyword === "dean research" ||
      keyword === "research news"
    ) {
      navigate("/Research");
    } else {
      alert("No such page exists");
    }
  };

  return (
    <div className="home">
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        exit={{
          opacity: 0,
        }}
        transition={{
          duration: 0.2,
          ease: "easeOut",
        }}
      >
        <Header />

        {isLargeScreen ? (
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                background: "#ffff",
                height: "95px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                ml: 12,
              }}
            >
              <Grid item>
                <img
                  alt="KIU Uni"
                  src={logo}
                  height="60px"
                  width="auto"
                />
              </Grid>
              <Grid item>
                <Grid container="container" spacing={2}>
                  <Grid item sx={{ my: 3 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="search"
                        className="home-search"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                      <PiMagnifyingGlassDuotone
                        style={{
                          color: "black",
                          fontSize: "30px",
                          marginTop: 10,
                          marginBottom: 10,
                          cursor: "pointer",
                        }}
                        onClick={handleSearch}
                      />
                      <FormControlLabel
                        sx={{ px: 1 }}
                        label={
                          <Typography style={{ color: "black" }}>
                            言語切り替え
                          </Typography>
                        }
                        control={
                          <LanguageSwitch
                            sx={{ m: 1 }}
                            checked={language === "japanese"}
                            onChange={handleChange}
                          />
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box
                      height="95px"
                      width="280px"
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <Box
                        width="auto"
                        height="60%"
                        sx={{
                          backgroundColor: "red",
                          display: "flex",
                          flexDirection: "column",
                          py: 1.75,
                        }}
                      >
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <Box
                            sx={{
                              px: 1,
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Icon
                              icon="entypo:old-phone"
                              color="white"
                              fontSize="1.25rem"
                            />
                            <Typography variant="body2" sx={{ color: "white" }}>
                              +94 112741878
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              px: 1,
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Icon
                              icon="ic:round-phone-iphone"
                              color="white"
                              fontSize="1.25rem"
                            />
                            <Typography variant="body2" sx={{ color: "white" }}>
                              +94 767334744
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            px: 1,
                            pt: 0.5,
                          }}
                        >
                          <Icon
                            icon="majesticons:mail-line"
                            color="white"
                            fontSize="1.25rem"
                          />
                          <Typography variant="body2" sx={{ color: "white" }}>
                            info@kiu.ac.lk
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        width="auto"
                        height="40%"
                        sx={{ backgroundColor: "black" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            px: 1,
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{ color: "white", my: 1 }}
                          >
                            ソーシャルメディア
                          </Typography>
                          <div className="sb_handle-Links_div">
                            <div className="socialmedia">
                              <a
                                href="https://www.facebook.com/kiusrilanka/"
                                style={{ color: "white" }}
                              >
                                <p>
                                  <FaFacebook
                                    className="footer-icon"
                                    style={{ fontSize: "1.2rem" }}
                                  />
                                </p>
                              </a>
                              <a
                                href="https://www.instagram.com/kiu_sri_lanka/"
                                style={{ color: "white" }}
                              >
                                <p>
                                  <BsInstagram
                                    className="footer-icon"
                                    style={{ fontSize: "1.2rem" }}
                                  />
                                </p>
                              </a>
                              <a
                                href="https://www.linkedin.com/school/kiuedu/"
                                style={{ color: "white" }}
                              >
                                <p>
                                  <BsLinkedin
                                    className="footer-icon"
                                    style={{ fontSize: "1.2rem" }}
                                  />
                                </p>
                              </a>
                              <a
                                href="https://www.youtube.com/@KIUSrilanka"
                                style={{ color: "white" }}
                              >
                                <p>
                                  <FaYoutube
                                    className="footer-icon"
                                    style={{ fontSize: "1.2rem" }}
                                  />
                                </p>
                              </a>
                            </div>
                          </div>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : isMobileScreen ? (
          <Grid container spacing={2} sx={{ backgroundColor: "#fff" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ pl: 3 }}>
                <img
                  alt="KIU Uni"
                  src={logo}
                  height="60px"
                  width="auto"
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mx: 10,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  type="search"
                  className="home-search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <PiMagnifyingGlassDuotone
                  style={{
                    color: "black",
                    fontSize: "30px",
                    marginTop: 10,
                    marginBottom: 10,
                    cursor: "pointer",
                  }}
                  onClick={handleSearch}
                />
                <FormControlLabel
                  sx={{ px: 1 }}
                  label={
                    <Typography style={{ color: "black" }}>
                      言語切り替え
                    </Typography>
                  }
                  control={
                    <LanguageSwitch
                      sx={{ m: 1 }}
                      checked={language === "japanese"}
                      onChange={handleChange}
                    />
                  }
                />
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                width="100%"
                sx={{
                  height: "95px",
                  backgroundColor: "#fff",
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="KIU Uni"
                    src={logo}
                    height="60px"
                    width="auto"
                  />
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="search"
                      className="home-search"
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                    <PiMagnifyingGlassDuotone
                      style={{
                        color: "black",
                        fontSize: "30px",
                        marginTop: 10,
                        marginBottom: 10,
                        cursor: "pointer",
                      }}
                      onClick={handleSearch}
                    />
                    <FormControlLabel
                      sx={{ px: 1 }}
                      label={
                        <Typography style={{ color: "black" }}>
                          言語切り替え
                        </Typography>
                      }
                      control={
                        <LanguageSwitch
                          sx={{ m: 1 }}
                          checked={language === "japanese"}
                          onChange={handleChange}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}

        <div
          style={{
            position: "relative",
          }}
        >
          <Grid container="container" spacing={2} sx={{ top: "5%" }}>
            {/* Column 1 (Extra small screens) */}
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                marginLeft: "5%",
                top: "5%",
              }}
            >
              {/* <video src={background} autoPlay loop muted/> */}
              {type === "image" && background && (
                <LazyLoadImage
                  effect="blur"
                  src={background}
                  alt="Background Img"
                  style={{
                    width: "100%",
                  }}
                />
              )}
              {type === "video" && background && (
                <video
                  ref={videoRef}
                  src={background}
                  autoPlay="autoPlay"
                  loop="loop"
                  muted="muted"
                  playsInline="playsInline"
                />
              )}
            </Grid>
          </Grid>

          {/* Overlay Grid */}
          <Grid
            container="container"
            spacing={2}
            style={{
              position: "absolute",
              top: "5%",
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            {/* Add your overlay grid elements here */}
            <Grid
              item="item"
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              sx={{ top: "5%" }}
            >
              {/* Your overlay content */}
              <div className="home-content">
                {/* <h1 className="home-fview">{heading}</h1> */}
                <p className="home-ovr">{subheading}</p>
              </div>
            </Grid>

            {/* Black Tint Overlay */}
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background:
                  "linear-gradient(180deg,rgba(14, 14, 14, 0)29.9%, #0e0e0e 100%)",
                height: "100%",
                marginLeft: "6%",
              }}
            ></div>
          </Grid>
        </div>
      </motion.div>

      {/* <div className="home-horizontal-divider"></div> */}

      <Box sx={{ background: "black", padding: 4 }}>
        <ScrollTrigger
          onEnter={() => setCounterOn(true)}
          onExit={() => setCounterOn(false)}
        >
          <Container
            style={{
              paddingLeft: 76,
            }}
          >
            <Grid container="container" spacing={2}>
              {/* Column 1 (Extra small screens) */}
              <Grid item="item" xs={12} sm={4} md={4} lg={4} xl={4}>
                <h1 className="home-stat">
                  {counterOn && (
                    <CountUp
                      start={new Date().getFullYear()}
                      end={2009}
                      duration={2}
                      delay={0}
                      formattingFn={(value) => value.toFixed(0)}
                    >
                      {({ countUpRef, start }) => (
                        <div>
                          {start === 2009 ? "Now it's " : "In "}
                          <span ref={countUpRef} />
                        </div>
                      )}
                    </CountUp>
                  )}
                </h1>
                <h3 className="home-stat-des">KIUが設立された年</h3>
              </Grid>

              {/* Column 2 (Small screens) */}
              <Grid item="item" xs={12} sm={4} md={4} lg={4} xl={4}>
                <h1 className="home-stat">
                  {counterOn && (
                    <CountUp
                      start={0}
                      end={11769}
                      duration={2}
                      delay={0}
                      formattingFn={(value) => value.toFixed(0)}
                    ></CountUp>
                  )}
                </h1>
                <h3 className="home-stat-des">現在の在学生</h3>
              </Grid>

              <Grid item="item" xs={12} sm={4} md={4} lg={4} xl={4}>
                <h1
                  className="home-stat"
                  onClick={() => navigate("/Faculties")}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {counterOn && (
                    <CountUp
                      start={0}
                      end={16}
                      duration={2}
                      delay={0}
                      formattingFn={(value) => value.toFixed(0)}
                    ></CountUp>
                  )}
                </h1>
                <h3 className="home-stat-des">学部数</h3>
              </Grid>
              <Grid item="item" xs={12} sm={4} md={4} lg={4} xl={4}>
                <h1 className="home-stat">
                  {counterOn && (
                    <CountUp
                      start={0}
                      end={213}
                      duration={2}
                      delay={0}
                      formattingFn={(value) => value.toFixed(0)}
                    ></CountUp>
                  )}
                  +
                </h1>
                <h3 className="home-stat-des">優秀なスタッフ</h3>
              </Grid>
              <Grid item="item" xs={12} sm={4} md={4} lg={4} xl={4}>
                <h1 className="home-stat">
                  約
                  {counterOn && (
                    <CountUp
                      start={0}
                      end={3000}
                      duration={2}
                      delay={0}
                      formattingFn={(value) => value.toFixed(0)}
                    ></CountUp>
                  )}
                  +
                </h1>
                <h3 className="home-stat-des">毎年卒業する学生</h3>
              </Grid>
              <Grid item="item" xs={12} sm={4} md={4} lg={4} xl={4}>
                <h1 className="home-stat">
                  {counterOn && (
                    <CountUp
                      start={0}
                      end={69}
                      duration={2}
                      delay={0}
                      formattingFn={(value) => value.toFixed(0)}
                    ></CountUp>
                  )}
                  +
                </h1>
                <h3 className="home-stat-des">受け入れ留学数</h3>
              </Grid>
            </Grid>
          </Container>
        </ScrollTrigger>
      </Box>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ position: "relative" }}
      >
        <img
          alt="KIU Uni"
          src={homeImg}
          width="100%"
          height="auto"
        />
        <Box
          width="80%"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            top: "50%",
            left: "10%",
            position: "absolute",
            color: "white",
            transform: "translateY(-50%)",
          }}
        >
          <Box width="40%">
            <h1 className="home-ins-jp">
              <span className="highlight-jp">KIU</span>
              <span className="sub-highlight-jp">
                の卓越性を活かし大きく大胆に発展する
              </span>
            </h1>
          </Box>

          <Box width="30%">
            <CustomButton sx={{ my: 3 }} onClick={() => navigate("/About")}>
              <p className="home-ins-b">当校について</p>
              <Box
                className="icon-container"
                width={30}
                height={30}
                sx={{
                  mx: 1,
                  background: "black",
                  borderRadius: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  transition: "background-color 0.3s",
                }}
              >
                <Icon
                  icon="iconamoon:arrow-right-2-bold"
                  className="icon"
                  style={{ transition: "color 0.3s" }}
                />
              </Box>
            </CustomButton>
          </Box>
        </Box>
      </Grid>

      {/* <div className="home-horizontal-divider"></div> */}

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
          <Grid
            item="item"
            xs={10}
            sm={10}
            md={10}
            lg={10}
            xl={10}
            sx={{
              my: 2,
              mx: "auto",
            }}
          >
            <motion.div
              ref={ref1}
              initial={{
                opacity: 0,
                y: 20,
              }}
              animate={
                inView1
                  ? {
                      opacity: 1,
                      y: 0,
                    }
                  : {
                      opacity: 0,
                      y: 20,
                    }
              }
              transition={{
                duration: 0.7,
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "35px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                大学の最先端の研究分野を公開
              </Typography>
            </motion.div>
          </Grid>
          <Grid item="item" xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
        </Grid>
      </Container>

      <Grid container="container" spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 2,
          }}
        >
          <Box className="type-slider-container">
            <TypeSlider />
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 5,
            background: "black",
            pt: "0 !important",
          }}
        >
          <Box
            width="100%"
            height="100%"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" }, // Flex direction column for small screens, row for medium and larger
              justifyContent: "space-between",
              alignItems: { xs: "center", md: "center" }, // Center items on small screens
              ml: { xs: 0, md: 15 }, // Adjust margin for small screens
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "auto" }, // Full width for small screens
                mb: { xs: 2, md: 0 }, // Add margin bottom for small screens
                display: "flex",
                justifyContent: "center",
                alignItems: "center", // Center content for small screens
                textAlign: "center", // Center text for small screens
              }}
            >
              <motion.div
                ref={ref9}
                initial={{ opacity: 0, x: -50 }}
                animate={
                  shouldAnimate5 ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }
                }
                transition={{ duration: 1.1 }}
              >
                <Typography variant="h5" className="home-stat1" sx={{ px: 2 }}>
                  14以上の短期プログラム
                </Typography>
              </motion.div>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "auto" },
                mb: { xs: 2, md: 0 },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <motion.div
                ref={ref21}
                initial={{ opacity: 0, x: -50 }}
                animate={
                  shouldAnimate11
                    ? { opacity: 1, x: 0 }
                    : { opacity: 0, x: -50 }
                }
                transition={{ duration: 1.1 }}
              >
                <Typography variant="h5" className="home-stat1" sx={{ px: 2 }}>
                  ５つ以上の大学院プログラム
                </Typography>
              </motion.div>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "auto" },
                mb: { xs: 2, md: 0 },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <motion.div
                ref={ref17}
                initial={{ opacity: 0, x: -50 }}
                animate={
                  shouldAnimate6 ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }
                }
                transition={{ duration: 1.1 }}
              >
                <Typography variant="h5" className="home-stat1" sx={{ px: 2 }}>
                  14以上の短期プログラム
                </Typography>
              </motion.div>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "auto" },
                height: "100%",
                background: "red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <motion.div
                ref={ref18}
                initial={{ opacity: 0, x: -50 }}
                animate={
                  shouldAnimate7 ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }
                }
                transition={{ duration: 1.1 }}
              >
                <div className="about-link-container1">
                  <Link to="/WeOffer">
                    <PiArrowCircleRightBold className="about-arrow1" />
                  </Link>
                  <Link to="/WeOffer" className="home-about-link1">
                    学位プログラム を見る
                  </Link>
                </div>
              </motion.div>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container="container" spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 5,
            mx: 5,
            pt: "0 !important",
          }}
        >
          <Box width="100%" sx={{ ml: 9 }}>
            <KIULife />
          </Box>
        </Grid>
      </Grid>

      {/* <motion.div
        ref={ref6}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView6
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={6} md={6} lg={6} xl={6}>
            <Typography
              variant="h5"
              style={{
                fontSize: "40px",
                fontFamily: "Kanit, sans-serif",
                marginBottom: "30px",
                marginLeft: "26%",
                fontWeight: "700",
              }}
            >
              News & Events
            </Typography>
          </Grid>
        </Grid>

        <Container
          maxWidth="lg"
          sx={{
            paddingX: (theme) => theme.spacing(1),
            "@media (min-width: 1px) and (max-width: 599px)": {
              paddingLeft: (theme) => theme.spacing(8),
            },
            "@media (min-width: 600px) and (max-width: 960px)": {
              paddingLeft: (theme) => theme.spacing(9),
            },
            "@media (min-width: 961px) and (max-width: 1280px)": {
              paddingLeft: (theme) => theme.spacing(9),
            },
            "@media (min-width: 1281px) and (max-width: 1920px)": {
              paddingLeft: (theme) => theme.spacing(7),
            },
          }}
        >
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="news-link-container">
                <Link to="/News">
                  <PiArrowCircleRightBold className="news-arrow" />
                </Link>
                <Link to="/News" className="home-news-link">
                  View News
                </Link>
              </div>
            </Grid>

            <Grid item="item" xs={12} sm={6} md={3} lg={3} xl={3}>
              <motion.div
                ref={ref14}
                initial={{
                  opacity: 0,
                  x: 100,
                }}
                animate={
                  shouldAnimate2
                    ? {
                        opacity: 1,
                        x: 0,
                      }
                    : {
                        opacity: 0,
                        x: 100,
                      }
                }
                transition={{
                  duration: 1.1,
                }}
              >
                {newsData.length > 0 && (
                  <Card
                    style={{
                      height: "380px",
                    }}
                  >
                    <CardMedia
                      component="img"
                      width="100%"
                      height="210px"
                      style={{
                        objectFit: "fill",
                      }}
                      image={newsData[0].titleImage}
                      alt="KIU UNI"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom="gutterBottom"
                        variant="h5"
                        component="div"
                        style={{
                          fontFamily: "Kanit, sans-serif",
                        }}
                      >
                        {newsData[0].title}
                      </Typography>
                      
                    </CardContent>
                  </Card>
                )}
              </motion.div>
            </Grid>

            
            <Grid item="item" xs={12} sm={6} md={3} lg={3} xl={3}>
              <motion.div
                ref={ref15}
                initial={{
                  opacity: 0,
                  x: 100,
                }}
                animate={
                  shouldAnimate3
                    ? {
                        opacity: 1,
                        x: 0,
                      }
                    : {
                        opacity: 0,
                        x: 100,
                      }
                }
                transition={{
                  duration: 1.1,
                }}
              >
                {newsData.length > 1 && (
                  <Card
                    style={{
                      height: "380px",
                    }}
                  >
                    <CardMedia
                      component="img"
                      width="100%"
                      height="210px"
                      style={{
                        objectFit: "fill",
                      }}
                      image={newsData[1].titleImage}
                      alt="KIU UNI"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom="gutterBottom"
                        variant="h5"
                        component="div"
                        style={{
                          fontFamily: "Kanit, sans-serif",
                        }}
                      >
                        {newsData[1].title}
                      </Typography>
                      
                    </CardContent>
                    
                  </Card>
                )}
              </motion.div>
            </Grid>

            
            <Grid item="item" xs={12} sm={6} md={3} lg={3} xl={3}>
              <motion.div
                ref={ref16}
                initial={{
                  opacity: 0,
                  x: 100,
                }}
                animate={
                  shouldAnimate9
                    ? {
                        opacity: 1,
                        x: 0,
                      }
                    : {
                        opacity: 0,
                        x: 100,
                      }
                }
                transition={{
                  duration: 1.1,
                }}
              >
                {newsData.length > 2 && (
                  <Card
                    style={{
                      height: "380px",
                    }}
                  >
                    <CardMedia
                      component="img"
                      width="100%"
                      height="210px"
                      image={newsData[2].titleImage}
                      style={{
                        objectFit: "fill",
                      }}
                      alt="KIU UNI"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom="gutterBottom"
                        variant="h5"
                        component="div"
                        style={{
                          fontFamily: "Kanit, sans-serif",
                        }}
                      >
                        {newsData[2].title}
                      </Typography>
                      
                    </CardContent>
                    
                  </Card>
                )}
              </motion.div>
            </Grid>

            
          </Grid>
        </Container>
      </motion.div> */}

      <motion.div
        ref={ref7}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView7
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        <Container
          style={{
            marginTop: "7%",
          }}
        >
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PartnerS></PartnerS>
            </Grid>
          </Grid>
        </Container>
      </motion.div>

      <div className="footer-div">
        <Foot></Foot>
      </div>
    </div>
  );
};

export default Home;
