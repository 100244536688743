import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "./Ugcrs.css";
import CSimg from "../../assets/CS.jpg";
import BAPDF from "../../brochure/sample.pdf";

const CN = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              BSc Honors in Computer Networks & Cyber Security
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="ug-crs-des1">
              Bachelor Of Science Honours in Computer Network and Cyber Security
              enables the undergraduates to perform in the arena as passionate
              professionals with the knowledge in Computer Networks and Cyber
              Security. The Computer Networks and Cyber Security degree provides
              skills of managing networks and maintaining its security. In
              addition to that, Computer Networks and Cyber Security involves
              operational procedures and technologies for designing,
              implementing, administrating, securing, and troubleshooting
              corporate networks while applying cybersecurity principles
              operationally. This curriculum in Computer Networks and Cyber
              Security degree program has been designed in accordance with the
              Sri Lankan and global university standards. Our degree program is
              conducted in English medium and provides valuable industry and
              research experience. <br />
              In addition, KIU undergraduates can take part in industrial
              placements in leading companies which provide valuable industry
              and research experience. This will ensure that the KIU Computer
              Networks and Cyber Security graduates will have the opportunity to
              be employed as Chief Information Security Officers, Forensic
              Computer Analysts, Information Security Analysts, Penetration
              Testers, Security Architects and many more.
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={CSimg}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "60%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des2">
              <p>
                Level: SLQF 6 
                <br />
                Duration: Semester 8 (4 Years)
                <br />
                Credit Points: 126
                <br />
                Intakes: March and September
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des3">
              <h3>Career Opportunities</h3>
              <ol>
                <li>Chief Information Security Officers</li>
                <li>Forensic Computer Analysts</li>
                <li>Information Security Analysts</li>
                <li>Penetration Testers</li>
                <li>Security Architects</li>
              </ol>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>Entry Requirements</h5>

              <ul>
                <li>
                At least three (3) simple passes (S) in Physical Sciences stream or Engineering Technology stream in one and the same sitting at the G.C.E (Advanced Level) Examination conducted by the Department of Examinations of Sri Lanka or equivalent qualification.
                </li>
                Or
                <li>
                At least three (3) simple passes (S) in any stream in one and the same sitting at the G.C.E (Advanced Level) Examination conducted by the Department of Examinations of Sri Lanka or equivalent qualification with a Credit pass (C) in Mathematics at the G.C.E (Ordinary Level) Examination conducted by the Department of Examination of Sri Lanka or equivalent qualification with a bridging programme approved by the Specified Authority.
                </li>
                Or
                <li>
                At least three (3) simple passes(S) in any stream in one and the same sitting with a simple pass (S) for Information & Communication Technology, in any attempt at the G.C.E. (Advanced Level) Examination conducted by the Department of Examinations of Sri Lanka or equivalent qualification and a Credit pass(C) in Mathematics at the G.C.E. (Ordinary Level) Examination conducted by the Department of Examinations of Sri Lanka or equivalent qualification.
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des5">
              <h5>Program Structure</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 1</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Introduction to Computer Systems</li>
                <li>Computer Architecture</li>
                <li>Fundamentals of Programming</li>
                <li>Academic Practices and Grooming</li>
                <li>Mathematics for Computing I</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Data Structures and Algorithms</li>
                <li>Object-Oriented Programming</li>
                <li>Web Technologies</li>
                <li>Data Communications</li>
                <li>Mathematics for Computing II</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 2</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Computer Networks</li>
                <li>Database Systems</li>
                <li>Systems Analysis and Design</li>
                <li>Network Operating System</li>
                <li>Applied Statistics</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Cryptography</li>
                <li>Component Security</li>
                <li>Mobiles and Wireless Networks</li>
                <li>Networking Infrastructure and Protocols</li>
                <li>Principles of Cyber Security</li>
                <li>
                  Career Planning and Employability Skills Development – Seminar
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 3</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Research Methods for Computing</li>
                <li>Secure Software Development</li>
                <li>Cyber Crime and Legal Environment</li>
                <li>Network Security</li>
                <li>Web Security</li>
                <li>Network Management</li>
                <li>Network Management</li>
                <li>Knowledge-based Systems</li>
                <li>Emerging Technologies</li>
                <li>IT Infrastructure</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Work Based Enterprise Placement</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 4</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Final Year Individual Project</li>
                <li>IT Professionalism and Practice</li>
                <li>Advanced Computer Networks</li>
                <li>System Security</li>
                <li>Cyber Security Analytic</li>
                <li>Data Science</li>
                <li>High-Performance Computing</li>
                <li>Database Server Management</li>
                <li>Advanced Web Technology</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Ethical Hacking and Countermeasures</li>
                <li>Digital Forensic</li>
                <li>System and Network Administration</li>
                <li>Cloud Computing and Virtualization</li>
                <li>Internet of Things</li>
                <li>Operational Research</li>
                <li>Advanced Web Technology</li>
                <li>Entrepreneurship</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>
                Graduates obtaining the Bachelor of Science Honours in Computer
                Networks and Cyber Security programme should be able to:
              </h5>

              <ul>
                <li>
                  understand the deep concepts of Computer Networks and Cyber
                  Security;
                </li>
                <li>
                  explain how organizational context is influenced by the impact
                  of the deployment of Computer Networks and Cyber Security;
                </li>
                <li>
                  understand social, legal, ethical, cultural, and professional
                  responsibilities in the discipline of computing;
                </li>
                <li>
                  manage complexity in Computer Networks and Cyber environments
                  by applying best practices of the security concepts and using
                  appropriate network methodologies;
                </li>
                <li>gain project experience and industrial practice;</li>
                <li>
                  illustrate the use of Computer Networks and Cyber Security
                  technologies to solve problems as an IT professional;
                </li>
                <li>
                  develop lines of argument and evaluate possible approaches,
                  tools, techniques, platforms, and solutions based on knowledge
                  of Computer Network and Cyber Security principles;
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/25"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>Apply</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              Download Brochure
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CN;
