import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Box } from "@mui/material";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

function MarketingSlider() {
  const [sliderImages, setSliderImages] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    fetch("https://kiu.ac.lk/api/website/findLast5Slider")
      .then((response) => response.json())
      .then((data) => setSliderImages(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const settings = {
    className: "center",
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    nextArrow: false,
    prevArrow: false,
    responsive: [
      {
        breakpoint: 1024, // large screens
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768, // tablet
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480, // mobile
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const getSlideHeight = () => {
    const screenWidth = window.innerWidth;
  
    if (screenWidth >= 1920) {
      return "50vh";  // For very large screens
    } else if (screenWidth >= 1280) {
      return "43vh";  // For large screens
    } else if (screenWidth >= 1024) {
      return "21vh";  // For medium screens
    } else if (screenWidth >= 780) {
      return "18vh";  // For tablets
    } else if (screenWidth >= 640) {
      return "15vh";  // For small tablets
    } else {
      return "11vh";  // For mobile phones
    }
  };
  
  const slideStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: getSlideHeight(),
    width: "100vw",
  };
  

  const boxMX = 1;

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {sliderImages.map((image) => (
          <div key={image.id}>
            <Box
              style={slideStyle}
              sx={{
                mx: boxMX,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                pr: 4,
              }}
            >
              
              <Box
                height="100%"
                width="100%"
                sx={{
                  backgroundImage: `url(${image.slider})`,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></Box>
            </Box>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default MarketingSlider;