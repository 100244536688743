import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Faculty.css";

const FON = () => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #f21111", // Blue outline
    color: "#f21111", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const navigate = useNavigate();
  const handleBtnClick = (path) => {
    navigate(path);
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-title">看護学部</h3>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <p className="fac-descrip">
              KIU
              看護学部は、教育、研究、臨床実践の卓越性を通じて医療の未来を形作ることに専心しています。看護教育のリーダーとして、私たちは、他の医療専門家と協力して看護ケアを提供し、国内および国際社会の新たなニーズにうまく対応できる、有能で思いやりがあり、情け深く、思慮深い看護卒業生を育成することに尽力しています。
              <br />
              KIU
              看護学部は、看護教育学科、臨床看護学科、研究開発学科、ケア推進学科で構成されています。私たちは、ヘルスケア分野の進化するニーズを満たすために、多種多様な革新的なモジュールを備えた看護学の
              BSc (Hons) 学部課程を提供しています。それに加えて、KIU
              看護学部は、看護学部生がさまざまなケア環境で実践者、擁護者、教育者、および専門的リーダーになる準備ができるような、インタラクティブな学術環境を育んでいます。
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-dean-hed">名誉学部長</h3>
            <p className="fac-descrip1">
              Prof. S.S.P. Warnakulasuriya
              <br />
              PhD (USJP)、MNSc (アデレード、オーストラリア)、PGDCP
              (コロンボ)、BScN (Hons.)
              (OUSL)、RN、RMN、教育および監督のディプロマ
              <br />
              接触: +947699928636
              <br />
              Eメール: dean_fon@kiu.ac.lk / ashara96@yahoo.com
            </p>
          </Grid>

          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-hod-hed">学科長</h3>
            <p className="fac-descrip1">
              介護学科　ー　森本　幸一郎
              <br />
              <br />
              看護教育学科 - Ms. K.H.A.Y.Kariyawasam (akindra@kiu.ac.lk)
              <br />
              <br />
              臨床看護学科 - Ms. H.H.H.S.Sandakumari (shashikala@kiu.ac.lk)
              <br />
              <br />
              研究開発学科 - Ms. N.S.A.S.N. Senerath (samanthi@kiu.ac.lk)
              <br />
              <br />
              ケア推進学科 - Ms. A.C.H. Perera (chamilya@kiu.ac.lk)
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-depts-hed">学部</h3>
            <ul className="fac-depts-list">
              <li className="fac-depts-list-item">看護教育学部</li>
              <li className="fac-depts-list-item">臨床看護学部</li>
              <li className="fac-depts-list-item">研究開発学部</li>
              <li className="fac-depts-list-item">ケア推進学部</li>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-fas-hed">施設</h3>
            <p className="fac-descrip1">
              KIU
              看護学部は、活気に満ちた学術環境を育み、学習体験を向上させるさまざまな施設を提供することに誇りを持っています。
            </p>
            <p className="fac-descrip1">
              <ul>
                <li>
                  <strong>介護者ラボ:</strong>
                  <ul>
                    最先端のリソースを備えた介護者ラボは、管理された環境で基本的な介護スキルを練習するためのシミュレーション環境を提供します
                  </ul>
                </li>
                <li>
                  <strong>電子図書館と図書館ブログ:</strong>
                  <ul>
                    当校の電子図書館と図書館ブログは、学術リソースの広範なコレクションを提供しています。これらは、学生と教員がシームレスにアクセスして情報を共有するためのプラットフォームを提供します。{" "}
                  </ul>
                </li>
                <li>
                  <strong>研究センター:</strong>
                  <ul>
                    研究センターは、学部生が地域や国際フォーラムで研究を発表できるようサポートします。
                  </ul>
                </li>
                <li>
                  <strong>キングス病院での臨床研修:</strong>
                  <ul>
                    介護者プログラムでは、学生は臨床スキルを養い、患者との交流を促進し、医療チームとの連携を促進する実践的な経験を積むことができます。この経験により、医療提供の複雑さに対する理解が深まります。
                  </ul>
                </li>
              </ul>
            </p>
          </Grid>
        </Grid>
      </Container>

      {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button style={buttonStyles1} onClick={() => handleBtnClick('/WeOffer')}>View Courses</Button>
                    </Grid>
                </Grid>
            </Container> */}
    </div>
  );
};

export default FON;
