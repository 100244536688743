import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "./Ugcrs.css";
import BMSimg from "../../assets/BMS.jpg";
import BAPDF from "../../brochure/sample.pdf";

const BS = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Bachelor of Science Honours in Biomedical Science
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="ug-crs-des1">
              Welcome to the Department of Biomedical Science at KIU Sri Lanka!
              Our degree program is meticulously crafted to foster students'
              analytical thinking and propel them into scientific research,
              making them future leaders in revolutionizing the health sector as
              Biomedical Scientists. <br />
              Our comprehensive curriculum ensures coverage of all essential
              areas, transforming students into skilled and knowledgeable
              scientists in the field of Biomedical Science. Lectures are
              conducted interactively to actively engage students and enhance
              their learning experience. <br />
              We take pride in employing diverse assessment methods in
              examinations to elevate the quality of our graduates. Practical
              sessions in specific modules guarantee the acquisition of
              practical skills relevant to the medical field. <br />
              In the final year of academic studies, students embark on a
              research project, a cornerstone of their academic journey. This
              project immerses them in methodical laboratory procedures,
              refining analytical skills, and instilling the self-confidence
              needed for a successful career as a researcher. <br />
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={BMSimg}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "70%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des2">
              <p>
                Level: SLQF 6
                <br />
                Duration: 4 years
                <br />
                Credit Points: 120
                <br />
                Intakes: March and September
                <br />
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des3">
              <h3>Career Opportunities</h3>
              <ol>
                <li>Research Assistant/ Healthcare Scientist </li>
                <li>Academic positions in universities </li>
                <li>Medical Laboratory Technologist </li>
                <li>Laboratory Manager/ Laboratory Quality Manager </li>
                <li>Microbiologist </li>
              </ol>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>Entry Requirements</h5>
              <ul>
                <li>
                Three Passes in GCE Advanced Level (A/L) in Biological Science Stream 
                </li>
                <li>An equivalent qualification for international students </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des5">
              <h5>Program Structure</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 1</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Anatomy</li>
                <li>Physiology</li>
                <li>Laboratory Safety</li>
                <li>Basic Chemistry</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>General and Systematic Microbiology 1</li>
                <li>Biochemistry</li>
                <li>Clinical Biochemistry 1</li>
                <li>Instrumentation</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 2</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Immunology</li>
                <li>General Pathology</li>
                <li>Clinical Biochemistry 2</li>
                <li>Basic Hematology</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Basic Pharmacology</li>
                <li>General and Systematic Microbiology 2</li>
                <li>Systemic Pathology</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 3</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Applied Medical Microbiology / Infectious Diseases 1</li>
                <li>Applied Hematology</li>
                <li>Epidemiology</li>
                <li>Parasitology</li>
                <li>Biostatistics</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Systemic Pharmacology</li>
                <li>Applied Biochemistry 1</li>
                <li>Research Methodology</li>
                <li>Public Health Microbiology</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 4</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Advanced Pharmacology</li>
                <li>Laboratory Management & Quality Control</li>
                <li>Applied Biochemistry 2</li>
                <li>Applied Microbiology / Infectious Disease 2</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>General and Applied Molecular Biology</li>
                <li>Transfusion Medicine</li>
                <li>Research Project</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>By the end of the course, learners will be able to: </h5>

              <ul>
                <li>
                  Understand the basic structures and fundamental processes of
                  life at molecular, cellular, and population levels.
                </li>
                <li>
                  Effectively apply the scientific method for problem-solving
                  and experimental design in diverse biomedical contexts.
                </li>
                <li>
                  Collect, organize, analyze, interpret, and present both
                  quantitative and qualitative data in scientific investigations
                  with proficiency.
                </li>
                <li>
                  Identify and articulate current issues and challenges within
                  the dynamic field of biomedical science.
                </li>
                <li>
                  Demonstrate and apply fundamental biological laboratory
                  techniques and skills adeptly.
                </li>
                <li>
                  Communicate effectively through written and oral means,
                  showing the ability to convey complex scientific concepts
                  clearly and concisely.
                </li>
                Explore the world of Biomedical Science at KIU Sri Lanka and
                join us in shaping the future of health science.
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>Apply</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              Download Brochure
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default BS;
