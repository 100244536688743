import { Icon } from "@iconify/react/dist/iconify.js";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Head from "../components/Head";
import Header from "../components/Header";
import Footer from "../components/footer";
import "./Ethics.css";

const Ethics = () => {
  const handleDownloadIns = () => {
    const link = document.createElement("a");
    link.href =
      "https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721301860094_INSTRUCTIONS-TO-APPLICANTS.pdf";
    link.click();
  };
  const handleDownloadApp = () => {
    const link = document.createElement("a");
    link.href =
      "https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721301888093_Application-for-Ethical-Review-new12.pdf";
    link.click();
  };
  return (
    <>
      <div className="mrc">
        <Header />

        <div className="head-div">
          <Head />
        </div>

        <Container
          style={{
            marginTop: "1%",
          }}
        >
          <Grid container="container" spacing={2}>
            <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                variant="h2"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: {
                    xs: "20px", // small devices
                    sm: "35px", // small-medium devices
                    md: "45px", // medium devices
                    lg: "45px", // large devices
                    xl: "52px", // extra large devices
                  },
                  marginTop: "3%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Ethics Review Committee
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <div className="news-horizontal-divider"></div>

        <Container>
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              sx={{ ml: 5, minHeight: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LazyLoadImage
                  effect="blur"
                  src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721298195846_ethics-review-commite.jpg"
                  alt="MRC"
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </Box>
            </Grid>

            <Grid
              item="item"
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              sx={{ ml: 5, minHeight: "100%" }}
            >
              <Typography className="eths-des">
                In the year 2017 Ethics Review Committee of the KIU was
                established to help the researchers in promoting and adhering to
                ethical principles in research, including obtaining ethical
                clearance for the research projects.
                <br />
                Ethics Review Committee provides ethical review with accordance
                and adheres to the guidelines of the Forum of Ethics Review
                Committees in Sri Lanka (FERCSL) and other relevant national and
                international legislations and guidelines.
                <br />
                The committee has been formulated in accordance with the by
                –laws of KIU and functions under the supervision of the Vice
                Chancellor who takes decisions in accordance with the advice and
                recommendations of the Senate. Ethics Review Committee members
                are appointed by the Vice Chancellor upon recommendations of the
                Senate and at present the committee is consisting of an expert
                external and internal academic professional representing
                different sectors. The committee has developed standard
                operating procedures (SOPs) in accordance with the guidelines of
                FERCSL.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <Typography className="eths-des">
                  ERC comprises of the following;
                  <ul>
                    <li>Chairperson</li>
                    <li>Secretary</li>
                    <li>Members</li>
                  </ul>
                </Typography>
              </Box>
            </Grid>

            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ ml: 5 }}
            >
              <Typography className="ug-crs-des4">
                <h5>Terms and conditions for submission of application:</h5>
                <ul>
                  <li>Download the application</li>
                  <li>
                    Applications must be submitted in the format as determined
                    by the ERC.
                  </li>
                  <li>
                    All project proposals must be submitted to the secretary of
                    ERC on or before the last Wednesday of each month for review
                    at the next scheduled meeting.
                  </li>
                  <li>
                    Immediately after the submission of the hard copy, an e-copy
                    of the proposal should be submitted to the secretary of ERC
                    through email “erc@kiu.ac.lk”
                  </li>
                  <li>
                    A fee will be levied for applications submitted for review
                    by the ERC. Fees levied as processing charges for ethical
                    approval for Individual / group research proposals.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ ml: 5 }}
            >
              <Typography className="ug-crs-des4">
                <h5>Type Fee</h5>
                <ul>
                  <li>Self – funded Rs 5000/= (per proposal)</li>
                </ul>
              </Typography>
            </Grid>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ ml: 5 }}
            >
              <Typography className="ug-crs-des4">
                <h5>Proposals submitted by KIU internal staff Free</h5>
                <ul>
                  <li>
                    The applicant shall pay the stipulated fee to the KIU
                    accounts department and shall submit the application along
                    with the payment receipt for review by the ERC.
                  </li>
                </ul>
              </Typography>
            </Grid>

            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ ml: 5 }}
            >
              <Box
                width="100%"
                height="auto"
                sx={{ border: "2px solid black", p: 2 }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", color: "red" }}
                >
                  More Information
                </Typography>
                <Typography className="eths-des">
                  Ethics Review Committee, KIU
                </Typography>
                <Typography className="eths-des">
                  Email: erc@kiu.ac.lk
                </Typography>
                <Typography className="eths-des">
                  Tel: +94 (0)11 2741 878 Ext.
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ mr: 2 }}
                  onClick={handleDownloadIns}
                >
                  INSTRUCTIONS TO APPLICANTS
                </Button>
                <Button
                  variant="outlined"
                  sx={{ mr: 2 }}
                  onClick={handleDownloadApp}
                >
                  Download Application Form
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>

        <div className="footer-div">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Ethics;
