import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "./Ugcrs.css";
import BAPDF from "../../brochure/sample.pdf";
import SEimg from "../../assets/SE.jpg";

const SE = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              BSc Honors in Software Engineering  
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="ug-crs-des1">
              Today, every industry has a major demand for automated manual
              systems to minimize customer errors. Most industries will hire
              software engineers internally or outsource their requirements to
              automate. <br />
              This degree focuses on the software development life cycle but
              goes beyond programming to assess and meet customer needs and to
              design and test software. This is a crucial area when considering
              automating systems. <br />
              Developing software solutions often involves assembling extensive
              amounts of code into working applications, as well as updating and
              fixing problems in the existing software. With excellent
              communication skills, you will become the managerial link between
              a business and its software. <br />
              In addition, KIU undergraduates also can take part in industrial
              placements in leading companies which provide valuable industry
              and research experience. This will ensure that the KIU Software
              Engineering graduates will have the opportunity to be employed as
              Software Engineers, Applications Developers, Back End Engineers,
              Database Administrators, DevOps Engineers, Quality Assurance
              Engineers, Business Analysts, Mobile Developers, and many more.
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={SEimg}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "60%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des2">
              <p>
                Level: SLQF 6 
                <br />
                Duration: Semester 8 (4 Years)
                <br />
                Credit Points: 126
                <br />
                Intakes: March and September
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des3">
              <h3>Career Opportunities</h3>
              <ol>
                <li>Software Engineers</li>
                <li>Applications Developers</li>
                <li>Back End Engineers</li>
                <li>Database Administrators</li>
                <li>DevOps Engineers</li>
                <li>Quality Assurance Engineers</li>
                <li>Business Analysts</li>
                <li>Mobile Developers</li>
              </ol>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>Entry Requirements</h5>

              <ul>
                <li>
                At least three (3) simple passes (S) in Physical Sciences stream or Engineering Technology stream in one and the same sitting at the G.C.E (Advanced Level) Examination conducted by the Department of Examinations of Sri Lanka or equivalent qualification.
                </li>
                Or
                <li>
                At least three (3) simple passes (S) in any stream in one and the same sitting at the G.C.E (Advanced Level) Examination conducted by the Department of Examinations of Sri Lanka or equivalent qualification with a Credit pass (C) in Mathematics at the G.C.E (Ordinary Level) Examination conducted by the Department of Examination of Sri Lanka or equivalent qualification with a bridging programme approved by the Specified Authority.
                </li>
                Or
                <li>
                At least three (3) simple passes(S) in any stream in one and the same sitting with a simple pass (S) for Information & Communication Technology, in any attempt at the G.C.E. (Advanced Level) Examination conducted by the Department of Examinations of Sri Lanka or equivalent qualification and a Credit pass(C) in Mathematics at the G.C.E. (Ordinary Level) Examination conducted by the Department of Examinations of Sri Lanka or equivalent qualification.
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des5">
              <h5>Program Structure</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 1</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Introduction to Computer Systems</li>
                <li>Computer Architecture</li>
                <li>Fundamentals of Programming</li>
                <li>Academic Practices and Grooming</li>
                <li>Mathematics for Computing I</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Data Structures and Algorithms</li>
                <li>Object-Oriented Programming</li>
                <li>Web Technologies</li>
                <li>Data Communications</li>
                <li>Mathematics for Computing II</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 2</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Introduction to Software Engineering</li>
                <li>Database Systems</li>
                <li>Systems Analysis and Design</li>
                <li>Operating Systems </li>
                <li>Applied Statistics</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Software Requirements Engineering</li>
                <li>Theory of Computation</li>
                <li>Computer Security</li>
                <li>Human-Computer Interaction</li>
                <li>Software Architecture</li>
                <li>
                  Career Planning and Employability Skills Development – Seminar
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 3</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Research Methods for Computing</li>
                <li>Software Engineering Group Project</li>
                <li>Software Project Management</li>
                <li>Software Engineering Practices</li>
                <li>Enterprise Architectures</li>
                <li>Software Quality Assurance</li>
                <li>Knowledge-based Systems</li>
                <li>Emerging Technologies</li>
                <li>IT Support for End Users</li>
                <li>Visual Programming</li>
                <li>Computer Graphics and Multimedia</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Work Based Enterprise Placement</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 4</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>Final Year Individual Project</li>
                <li>IT Professionalism and Practice</li>
                <li>Formal Methods in Software Development</li>
                <li>Mobile Application Development</li>
                <li>Advanced Database Systems</li>
                <li>Data Science</li>
                <li>High-Performance Computing</li>
                <li>Database Server Management</li>
                <li>Advanced Web Technology</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>Cloud Computing</li>
                <li>Systems Programming</li>
                <li>Game Development and Programming</li>
                <li>Internet of Things</li>
                <li>Operational Research</li>
                <li>Embedded Systems Design</li>
                <li>Artificial Intelligence</li>
                <li>Entrepreneurship</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>
                Graduates obtaining the Bachelor of Science Honours in Software
                Engineering programme should be able to:{" "}
              </h5>

              <ul>
                <li>
                  show mastery of knowledge in software engineering and skills
                  of the professional standards necessary to practice as a
                  software engineer.
                </li>
                <li>
                  understand the application fundamentals including information
                  management and intelligent applications.
                </li>
                <li>
                  demonstrate an understanding of multiple programming
                  languages, paradigms and technologies.
                </li>
                <li>
                  apply appropriate theories, models, and techniques that
                  provide a basis for problem identification and analysis,
                  software design, development, implementation, verification,
                  and documentation.
                </li>
                <li>
                  reconcile conflicting project objectives, and find acceptable
                  compromises within the limitations of cost, time, knowledge,
                  existing systems, and organizations.
                </li>
                <li>
                  work both individually and as part of a team to develop and
                  deliver quality software artifacts.
                </li>
                develop confidence to use latest concepts to design and develop
                software solutions for an organization/individual 
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/23"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>Apply</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              Download Brochure
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default SE;
