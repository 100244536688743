import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Faculty.css";

const FOHS = () => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #f21111", // Blue outline
    color: "#f21111", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const navigate = useNavigate();
  const handleBtnClick = (path) => {
    navigate(path);
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-title">Faculty Of Health Sciences</h3>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <p className="fac-descrip">
              Step into the esteemed Faculty of Health Sciences at KIU. Our
              faculty is dedicated to nurturing the next generation of
              healthcare professionals through rigorous academic programs and
              hands-on training. At the heart of our faculty, there are two
              dynamic departments: Acupuncture and Biomedical Sciences. In the
              Department of Acupuncture, students delve into the ancient art and
              science of acupuncture, mastering techniques that have been proven
              effective for centuries. Meanwhile, in the Department of
              Biomedical Sciences, students explore the intricacies of the human
              body, from cellular processes to disease pathology, preparing them
              for careers at the forefront of modern medicine. With
              state-of-the-art facilities and expert faculty guiding them every
              step of the way, students at the Faculty of Health Sciences embark
              on a journey of discovery, innovation, and service to humanity.
              Join us in shaping the future of healthcare.
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          {/* <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
            <h3 className="fac-dean-hed">Dean</h3>
            <p className="fac-descrip1">
              Mrs.Nadeeka Ranadeva
              <br />
              BSc Sp. Human Biology (FMS, USJP), Master in Med Stat (Reading,
              UOK), MSc Molecular Pathology (FOM, UOC), PhD (Reading)
              <br />
              Email: dean_foh@kiu.ac.lk
            </p>
          </Grid> */}

          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-hod-hed">Head of Academic administration</h3>
            <p className="fac-descrip1">
              Ms. Ruwani Dedunu 
              <br />
              MSc in Financial Mathematics (UOM) (Reading),
              <br />
              MBA in Management Strategy (KIU) (Reading),
              <br />
              BSc Hons in Statistics, UOJ
              <br />
              Email: ruwani@kiu.ac.lk
            </p>
          </Grid>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-hod-hed">
              Head of Department of Biomedical Science
            </h3>
            <p className="fac-descrip1">
              Ms. Roshani Maduwanthi De Silva
              <br />
              BSc Honours in Biomedical Science,
              <br />
              MSc in Public Health (Reading)
              <br />
              Email: roshani@kiu.ac.lk
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-depts-hed">Departments</h3>
            <ul className="fac-depts-list">
              <li className="fac-depts-list-item">
                Department of Medical Science in Acupuncture 
              </li>
              <li className="fac-depts-list-item">
                Department of Biomedical Science 
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-fas-hed">Facilities</h3>
            <p className="fac-descrip1">
              The Department of Biomedical Science is committed to cultivating
              meaningful partnerships with industry leaders, which enables
              pathways to both research opportunities and professional
              advancement for our undergraduates within the field with exposure
              to real-world applications of biomedical science, networking
              opportunities with industry professionals, enhanced employability
              through hands-on experience and access to resources and
              technologies at the forefront of the field.
            </p>
            <p className="fac-descrip1">
              We are proud to partner with Medcom, a trailblazer in healthcare
              communication solutions. This strategic alliance enhances our
              students' learning experience by providing exposure to the latest
              advancements in medical communication and technology.
            </p>
            <p class="fac-descrip1">
              <strong>Student Lab Complex (Capacity: 150):</strong>
              <br />
              The Student Lab Complex is a cutting-edge facility designed to
              accommodate up to 150 students simultaneously. It provides a
              hands-on learning environment equipped with modern equipment and
              resources, fostering collaborative and experiential learning in
              various health science disciplines.
            </p>

            <p class="fac-descrip1">
              <strong>Research Laboratory (Capacity: 25):</strong>
              <br />
              This specialized laboratory is dedicated to research activities,
              offering advanced facilities for 25 students. It is designed to
              support in-depth exploration and experimentation, allowing
              students to delve into scientific inquiry and develop critical
              research skills essential for advancing knowledge in health
              science.
            </p>

            <p class="fac-descrip1">
              <strong>Molecular Biology Unit:</strong>
              <br />
              The Molecular Biology Unit is a state-of-the-art facility focused
              on genetic and molecular research. Equipped with cutting-edge
              technology and instruments, students in this unit engage in
              molecular studies, DNA analysis, and other advanced techniques
              essential for understanding the intricate mechanisms underlying
              health-related phenomena.
            </p>

            <p class="fac-descrip1">
              <strong>Anatomy Model Unit:</strong>
              <br />
              This unit is dedicated to the study of human anatomy, featuring a
              comprehensive collection of anatomical models. Students utilize
              these models to enhance their understanding of the structure and
              function of the human body, providing a hands-on and visually
              immersive learning experience.
            </p>

            <p class="fac-descrip1">
              <strong>ECG Room:</strong>
              <br />
              The ECG room is a specialized facility equipped with
              electrocardiogram (ECG) machines for studying and monitoring heart
              activity. Students gain practical experience in conducting and
              interpreting ECG tests, honing skills crucial for diagnosing
              cardiovascular conditions in the field of health science.
            </p>

            <p class="fac-descrip1">
              <strong>Microscope Unit:</strong>
              <br />
              The Microscope Unit is a fundamental component of the laboratory
              facilities, providing students with access to high-quality
              microscopes for detailed examination of biological samples. This
              unit supports studies in histology, pathology, and other
              disciplines that require microscopic analysis, contributing to a
              comprehensive understanding of health-related subjects.
            </p>

            <p class="fac-descrip1">
              <strong>Zebrafish Unit:</strong>
              <br />
              The Zebrafish Unit is an exciting addition to the department's
              facilities, focusing on the use of zebrafish as a model organism.
              Students in this unit conduct research related to developmental
              biology, genetics, and toxicology, leveraging the unique
              attributes of zebrafish. This unit provides hands-on experience
              with a model organism widely used in health science research.
            </p>

            <p class="fac-descrip1">
              <strong>Acupuncture Training Centre:</strong>
              <br />
              The Acupuncture Training Centre of KIU provides the necessary
              facilities for the students to complete clinical and practical
              training which is a compulsory requirement in the degree
              programme. Qualified Western and Ayurvedic physicians conduct this
              training under the supervision of the Head of the Department of
              Acupuncture.
            </p>

            <p class="fac-descrip1">
              <strong>Friendly Learning Environment:</strong>
              <br />
              Modern lecture halls provide an immersive learning environment for
              students, complemented by a dressing room and VIP space for
              special guests. A thoughtful inclusion of smartboards enhances the
              teaching experience, facilitating interactive and dynamic
              sessions.
            </p>

            <p class="fac-descrip1">
              In summary, the laboratory facilities are designed to provide a
              multifaceted learning experience, combining theoretical knowledge
              with hands-on research opportunities. Students actively
              participate in research activities, acquiring the skills and
              expertise necessary to excel as competent researchers in the
              dynamic field of health science.
            </p>
          </Grid>
        </Grid>
      </Container>

      {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button style={buttonStyles1} onClick={() => handleBtnClick('/WeOffer')}>View Courses</Button>
                    </Grid>
                </Grid>
            </Container> */}
    </div>
  );
};

export default FOHS;
