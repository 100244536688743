import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "./Ugcrs.css";
import BAPDF from "../../../brochure/sample.pdf";
import Accounting from "../../../assets/Accounting.png";

const AC = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              会計学経営学士（優等学位）
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="ug-crs-des1">
              KIU スリランカの会計学部へようこそ！
              <br />
              KIU
              の会計管理学士（優等学位）は、会計と財務の分野でやりがいのあるキャリアを目指す人向けに作られています。KIU
              が授与するこの包括的な 4
              年間のプログラムは、学生をグローバル金融セクターの会計専門家として成功するための準備を整えます。他のスリランカの大学のカリキュラムと整合し、経営教育の国際基準に準拠したこの学位は、会計、監査、税務、財務分析、運転資本管理、投資分析とポートフォリオ管理などを網羅した総合的な教育を提供します。
              <br />
              大手企業での実習は、貴重な業界体験と研究の機会を提供します。英語で提供されるこのプログラムは、120
              単位の学術コースワークで構成されており、国内外で会計士や財務専門家を目指す人にとって確固たる基盤となります。
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={Accounting}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "60%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des2">
              <p>
                レベル: SLQF 6
                <br />
                期間: 4 年
                <br />
                単位数: 120
                <br />
                入学時期: 3 月と 9 月
                <br />
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des3">
              <h3>キャリア チャンス</h3>
              <ol>
                <li>会計アシスタント</li>
                <li>会計士</li>
                <li>会計マネージャー</li>
                <li>予算アナリスト</li>
                <li>公認内部監査人</li>
                <li>法廷会計士</li>
                <li>政府会計士</li>
                <li>最高財務責任者 - CFO</li>
              </ol>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>入学要件</h5>
              <ul>
                <li>
                  G.C.E. 上級レベル試験に 3 回合格し、G.C.E (O/L)
                  試験で数学の成績が最低でも「C」以上であること。
                </li>
                <li>留学生の場合は同等の資格。</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des5">
              <h5>プログラム構造</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>1年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>第 1 学期</h5>
              <ul>
                <li>経営原則と実践</li>
                <li>ビジネスと環境</li>
                <li>ビジネス数学</li>
                <li>ミクロ経済学</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>第 2 学期</h5>

              <ul>
                <li>社会学と心理学の要素</li>
                <li>会計の原則</li>
                <li>マクロ経済学</li>
                <li>ビジネス コミュニケーション</li>
                <li>ビジネス統計</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>2年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>第 1 学期</h5>
              <ul>
                <li>経営情報システム</li>
                <li>管理会計</li>
                <li>組織行動</li>
                <li>マーケティング管理</li>
                <li>人的資源管理</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>第 2 学期</h5>

              <ul>
                <li>組織理論</li>
                <li>財務管理</li>
                <li>商法および産業法</li>
                <li>課税</li>
                <li>ビジネス倫理と企業責任</li>
                <li>会計情報システム (選択科目)</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>3年生</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>第 1 学期</h5>
              <ul>
                <li>監査</li>
                <li>コンピュータ ベースのビジネス統計</li>
                <li>人格開発</li>
                <li>起業家精神と中小企業</li>
                <li>組織変更と開発</li>
                <li>上級財務会計と報告 (選択科目)</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>第 2 学期</h5>

              <ul>
                <li>産業研修</li>
                <li>戦略管理</li>
                <li>紛争管理</li>
                <li>知識管理</li>
                <li>会計および財務モデリング (選択科目)</li>
                <li>企業統治、リスク、倫理 (選択科目)</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>4年生</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>第 1 学期</h5>
              <ul>
                <li>研究方法と学術論文執筆</li>
                <li>文化と経営</li>
                <li>運転資本管理</li>
                <li>投資分析とポートフォリオ管理</li>
                <li>財務報告と規制</li>
                <li>ビジネス評価理論と応用 (選択科目)</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>第 2 学期</h5>

              <ul>
                <li>研究プロジェクト</li>
                <li>運用管理</li>
                <li>コンピュータ ベースの会計</li>
                <li>法廷会計 (選択科目)</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>
                コース終了時までに、学習者は次のことができるようになります:{" "}
              </h5>
              <ul>
                <li>
                  会計分野および関連分野の原則、概念、理論を理解していることを実証する{" "}
                </li>
                <li>
                  会計特有の知識と専門スキルを、ダイナミックなビジネス環境におけるさまざまなビジネス状況に適用する
                </li>
                <li>
                  技術の進歩と、その専門的およびビジネス環境への適用に関する理解を実証する
                </li>
                <li>
                  会計に関連する実際的な問題に対する解決策を考案するために調査スキルを適用する。
                </li>
                <li>
                  適切なテクノロジーを使用して、専門的な環境で効果的にコミュニケーションするためのコミュニケーションスキルを構築する。
                </li>
                <li>
                  グループダイナミクスを分析し、権限を与えられた作業チームを構築し、共通のビジョンを作成し、対立を解決することで、あらゆるビジネス環境でチームとして働くスキルを開発する。{" "}
                </li>
                <li>
                  文化的および言語的に多様な職場で適切なリーダーシップと対人スキルを構築します
                </li>
                <li>
                  ローカルおよびグローバルなビジネス環境における新たなトレンドを特定し、会計業務の効率と有効性を高めるための革新を生み出し、会計分野に関連する問題を批判的に評価します
                </li>
                <li>
                  行動を通じて、会計業務の職業倫理と責任に対する理解とコミットメントを示します
                </li>
                <li>
                  社会的および市民的責任、人権、持続可能性に関連する問題に対する理解を示します。
                </li>
                <li>
                  会計分野の専門家になるための自主的かつ生涯にわたる学習に必要な主要な能力を開発します。
                </li>
                KIU
                スリランカで会計の世界を探索し、未来を形作るために私たちと一緒に参加してください。
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>適用する</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              パンフレットをダウンロード
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              近い
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AC;
