import { Container, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import bms from "../assets/bms11.jpg";
import care from "../assets/care1.JPG";
import com from "../assets/com11.jpg";
import kyo from "../assets/kyo2.JPG";
import Head from "../components/Head";
import Header from "../components/Header";
import Popup from "../components/PopupFacility";
import BioMedLab from "../components/facilities/BioMedLab";
import StudyArea from "../components/facilities/Caregiver";
import ComputerLab from "../components/facilities/ComputerLab";
import KyoCampus from "../components/facilities/KyoCampus";
import Footer from "../components/footer";
import "./Facilities.css";

const Facilities = () => {
  const [buttonPopup1, setButtonPopup1] = useState(false);
  const [buttonPopup2, setButtonPopup2] = useState(false);
  const [buttonPopup3, setButtonPopup3] = useState(false);
  const [buttonPopup4, setButtonPopup4] = useState(false);
  const [buttonPopup5, setButtonPopup5] = useState(false);
  const [buttonPopup6, setButtonPopup6] = useState(false);

  return (
    <div className="facility">
      <Header />

      <div className="head-div">
        <Head />
      </div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Revolutionizing University Facilities: A Contemporary Approach to
              Enrichment
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Typography className="faci-des">
              "Step into our futuristic university, where cutting-edge
              facilities redefine the educational experience. Our smart
              classrooms seamlessly integrate technology and collaboration,
              while our fully equipped library grants access to a wealth of
              digital resources. Wellness and recreation thrive with a high-tech
              fitness center, and our research laboratories drive innovation.
              Vibrant art and performance spaces inspire creativity, while our
              modern dormitories and diverse dining options complete the ideal
              campus life. Welcome to the future of education."
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={1}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card sx={{ minHeight: "100%" }}>
              <CardMedia
                component="img"
                width="100%"
                height="100%"
                image={care}
                alt="KIU UNI"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setButtonPopup5(true)}
              />
              <CardActions disableSpacing="disableSpacing">
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontFamily: "Kanit, sans-serif",
                    color: "#77807E",
                    padding: "0.5em",
                  }}
                >
                  Caregiver Training Center
                </Typography>
                <Popup trigger={buttonPopup5} setTrigger={setButtonPopup5}>
                  <StudyArea onClose={() => setButtonPopup5(false)} />
                </Popup>
              </CardActions>
            </Card>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card sx={{ minHeight: "100%" }}>
              <CardMedia
                component="img"
                width="100%"
                height="100%"
                image={com}
                alt="KIU UNI"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setButtonPopup3(true)}
              />
              <CardActions disableSpacing="disableSpacing">
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontFamily: "Kanit, sans-serif",
                    color: "#77807E",
                    padding: "0.5em",
                  }}
                >
                  Computer Lab
                </Typography>
                <Popup trigger={buttonPopup3} setTrigger={setButtonPopup3}>
                  <ComputerLab onClose={() => setButtonPopup3(false)} />
                </Popup>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Container
        style={{
          marginTop: "2%",
        }}
      >
        <Grid container="container" spacing={1}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card>
              <CardMedia
                component="img"
                width="100%"
                height="100%"
                image={bms}
                alt="KIU UNI"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setButtonPopup4(true)}
              />
              <CardActions disableSpacing="disableSpacing">
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontFamily: "Kanit, sans-serif",
                    color: "#77807E",
                    padding: "0.5em",
                  }}
                >
                  Biomedical Labotary
                </Typography>
                <Popup trigger={buttonPopup4} setTrigger={setButtonPopup4}>
                  <BioMedLab onClose={() => setButtonPopup4(false)} />
                </Popup>
              </CardActions>
            </Card>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card>
              <CardMedia
                component="img"
                width="100%"
                height="100%"
                image={kyo}
                alt="KIU UNI"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setButtonPopup2(true)}
              />
              <CardActions disableSpacing="disableSpacing">
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontFamily: "Kanit, sans-serif",
                    color: "#77807E",
                    padding: "0.5em",
                  }}
                >
                  Kyo Campus
                </Typography>
                <Popup trigger={buttonPopup2} setTrigger={setButtonPopup2}>
                  <KyoCampus onClose={() => setButtonPopup2(false)} />
                </Popup>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <div className="footer-div">
        <Footer />
      </div>
    </div>
  );
};

export default Facilities;
