import React from "react";
import Head from "../components/Head";
import Header from "../components/Header";
import Footer from "../components/footer";
import { motion, useAnimation, useInView } from "framer-motion";
import { Helmet } from "react-helmet";
import { useRef, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  Tab,
  Button,
  Tabs,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const LecturePanel = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="lecturePanel">
      <Header />

      <div className="head-div">
        <Head />
      </div>

      <div ref={ref}>
        <motion.div
          variants={{
            hidden: {
              opacity: 0,
              y: 75,
            },
            visible: {
              opacity: 1,
              y: 0,
            },
          }}
          initial="hidden"
          animate={mainControls}
          transition={{
            duration: 0.5,
            delay: 0.25,
          }}
        >
          <Container>
            <Grid container="container" spacing={2}>
              <Grid
                item="item"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  marginLeft: "6%",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontFamily: "Kanit, sans-serif",
                    fontSize: "35px",
                    marginTop: "5%",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Introducing Our Diverse Lecture Panel: Exploring Contemporary
                  Perspectives in Higher Education
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </motion.div>

        <Container sx={{ mt: 5 }}>
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Box
                sx={{
                  bgcolor: "background.paper",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab
                    label="Faculty Of Nursing"
                    sx={{ fontSize: "16px", fontWeight: "700" }}
                  />
                  <Tab
                    label="Faculty Of Health Sciences"
                    sx={{ fontSize: "16px", fontWeight: "700" }}
                  />
                  <Tab
                    label="Faculty Of Management"
                    sx={{ fontSize: "16px", fontWeight: "700" }}
                  />
                  <Tab
                    label="Faculty Of Education & Languages"
                    sx={{ fontSize: "16px", fontWeight: "700" }}
                  />
                  <Tab
                    label="Faculty Of Computer Science & Engineering"
                    sx={{ fontSize: "16px", fontWeight: "700" }}
                  />
                  <Tab
                    label="Faculty Of Graduate Studies"
                    sx={{ fontSize: "16px", fontWeight: "700" }}
                  />
                  <Tab
                    label="Faculty Of Behavioural Sciences"
                    sx={{ fontSize: "16px", fontWeight: "700" }}
                  />
                  <Tab
                    label="KIU School Of Agriculture And Livestock Management"
                    sx={{ fontSize: "16px", fontWeight: "700" }}
                  />
                  <Tab
                    label="Faculty of Law"
                    sx={{ fontSize: "16px", fontWeight: "700" }}
                  />
                </Tabs>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className="footer-div">
        <Footer />
      </div>
    </div>
  );
};

export default LecturePanel;
