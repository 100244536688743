import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "../postgraduate/Pgcrs.css";
import BAPDF from "../../../brochure/sample.pdf";
import CHRAimg from "../../../assets/CHRA.jpg";

const CHRA = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              人事分析の認定コース
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="pg-crs-des1">
              人事分析の認定コースは、進化し続ける人事分析業界で成功するために必要な基礎的な能力と理解を学習者に提供するために慎重に作成されています。人事管理の分野で分析を理解して使用するために、このカリキュラムは徹底的かつ実践的なアプローチを採用しています。
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={CHRAimg}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "80%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des2">
              <p>
                レベル: NVQ レベル 03
                <br />
                期間: 03 か月
                <br />
                クレジット ポイント: 10
                <br />
                受講開始時期: 4 月と 9 月
                <br />
                配信媒体: 英語媒体
                <br />
                配信モード: Microsoft Teams 経由のオンライン
                <br />
                連絡先番号: 074-0798565/ 071-9207078
                <br />
                メール: madhura@kiu.ac.lk /iherath@kiu.ac.lk
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des3">
              <h3>入学要件</h3>
              <ul>
                <li>G.C.E A/L 資格を修了していること。</li>
                または
                <li>数学、英語で「C」合格の G.C.E O/L を修了していること。</li>
                または
                <li>特定の分野で 1 年以上の専門経験があること。</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>主なハイライト: </h5>

              <ul>
                <li>
                  実際の HR
                  分析シナリオのコンテキスト内で、実践的なハンズオン演習に取り組みます。このアプローチにより、参加者は理論的な知識と実際のアプリケーションの間のギャップを埋めることができます。
                </li>
                <li>
                  カリキュラムは、重要なトピックをカバーするように慎重に作成されており、参加者は業界の要件を満たすために今日の
                  HR 分析環境で求められるスキルを習得できます。{" "}
                </li>
                <li>
                  HR
                  分析の複雑さについて参加者を指導することに専念する、経験豊富で資格のあるインストラクターから学びます。
                  インストラクターは、参加者がこの専門分野で成功するために必要なサポートを提供することに尽力しています。{" "}
                </li>
                <li>
                  {" "}
                  週末のクラスでは、柔軟なスケジュールを提供しています。{" "}
                </li>
                <li>
                  このコースを修了すると、さまざまな業界の HR
                  分析のさまざまなエントリーレベルのポジションへの道が開かれます。このコースは、急速に進化する人材管理の分野でのさらなる学術研究やキャリアアップのための強固な基盤となります。{" "}
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/43"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>適用する</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              パンフレットをダウンロード
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              近い
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CHRA;
