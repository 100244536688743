import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Faculty.css";

const FOGS = () => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #f21111", // Blue outline
    color: "#f21111", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const navigate = useNavigate();
  const handleBtnClick = (path) => {
    navigate(path);
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-title">大学院研究科</h3>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <p className="fac-descrip">
              KIU 大学院研究科 (FGS) は、2018 年以来、大学助成委員会 (UGC)
              が認可した包括的な大学院学位プログラムの提供に取り組んでいます。厳格な学術基準で承認されたこれらのプログラムは、研究主導型、コースワーク中心型、またはハイブリッド型など、さまざまな教育方法に対応するように調整されています。この学部は、名門大学や民間企業から集まった著名な学者や業界の専門家で構成されており、その集合的な専門知識が学術環境を豊かにしています。学部内の学術および管理業務の監督は、学部長の管轄下にあり、学部長は、その使命を推進することに専念する団結したチームを率いています。さらに、学部は、看護研究委員会や経営研究委員会などの個別の研究委員会で構成されており、それぞれが関連する大学院カリキュラムの指導を担当しています。
            </p>

            <p className="fac-descrip">
              <strong>ビジョン</strong>
              <br />
              大学院生が国内外で学術的、職業的に成功し、生涯学習の文化を育めるようにします。
            </p>

            <p className="fac-descrip">
              <strong>ミッション</strong>
              <br />
              私たちのミッションは、大学院プログラムの多様性を確保し、分野を超えて知的、職業的成果を高めることです。
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-dean-hed">学部長</h3>
            <p className="fac-descrip1">
              Akila Randika Jayamaha
              <br />
              PhD (Reading) - レスター大学、英国、MSc 看護学 (KIU)、MSc 応用疫学
              (ペラデニヤ大学)/BSc 看護学 (ルフナ大学)
              <br />
              連絡先: 0777614918 / 0759359834
              <br />
              メール: akila@kiu.ac.lk
            </p>
          </Grid>

          {/* <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
                        <h3 className='fac-hod-hed'>Head of Department</h3>
                        <p className='fac-descrip1'>
                            Prof. Koliya Pulasinghe<br/>BSc Eng. (Hons) in Computer Systems & Engineering,
                                University of Moratuwa, Sri Lanka, 1996<br/>
                            Contact: +94 77 123 4567<br/>
                            Email: abc@gmail.com
                        </p>
                    </Grid> */}
        </Grid>
      </Container>

      {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <h3 className='fac-depts-hed'>Departments</h3>
                        <ul className='fac-depts-list'>
                            <li className='fac-depts-list-item'>Department of Computer Science</li>
                        </ul>
                    </Grid>
                </Grid>
            </Container> */}

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-fas-hed">施設</h3>
            <p className="fac-descrip1">
              KIU
              大学院学部は、国際基準に従って学術的卓越性を維持し、最先端の研究施設、専門家による指導、活気ある学術コミュニティへの比類のないアクセスを提供し、変革的な学習体験を促進し、学生が選択した分野で知識の限界を押し広げられるようにします。
            </p>

            <p className="fac-descrip1">
              <strong>図書館とアーカイブ</strong>
              <br />
              大学院の研究には、広範な図書館とアーカイブへのアクセスが不可欠です。学部は、物理形式とデジタル形式の両方で膨大な学​​術資料のコレクションへのアクセスを提供し、学生が研究や調査に必要なリソースを得られるようにします。KIU
              は、物理プラットフォームとデジタル
              プラットフォームの両方を通じて、学術研究記事と書籍の広範なリポジトリへのアクセスを容易にします。{" "}
            </p>

            <p className="fac-descrip1">
              <strong>学習スペース、セミナー室、プレゼンテーション設備</strong>
              <br />
              大学院生が学業に集中するには、優れた学術環境が不可欠です。学部では、個人学習室、グループ学習室、共同スペースなど、学術研究のための設備が整ったさまざまな環境を提供しています。さらに、セミナー、ワークショップ、学術プレゼンテーション用に、学部では、小グループのディスカッションと大規模な学術イベントの両方に対応できるように設計された、設備の整ったセミナー室とプレゼンテーション設備を提供しています。
            </p>

            <p className="fac-descrip1">
              <strong>コンピューター ラボと IT サービス</strong>
              <br />
              今日のデジタル時代では、テクノロジーへのアクセスが最も重要です。学部では、最新のハードウェアとソフトウェアを備えたコンピューター
              ラボを維持し、学生と教員のテクノロジー ニーズを支援する強力な IT
              サポート
              サービスも提供しています。それにもかかわらず、学生は最新の IT
              設備を備えた物理的なプラットフォームとオンライン
              プラットフォームの両方を通じて学習体験に参加する機会が与えられています。今日のデジタル時代では、テクノロジーへのアクセスが最も重要です。学部は、最新のハードウェアとソフトウェアを備えたコンピューター
              ラボを維持し、学生と学部のテクノロジー ニーズを支援する強力な IT
              サポート
              サービスも提供しています。それにもかかわらず、学生は最新の IT
              設備を備えた物理的なプラットフォームとオンライン
              プラットフォームの両方を通じて学習体験に参加する機会が与えられています。
            </p>

            <p className="fac-descrip1">
              <strong>キャリア開発サービス</strong>
              <br />
              学部は、大学院生が学術界を超えて成功するキャリアを積むための準備の重要性を理解しています。そのため、ワークショップ、セミナー、1
              対 1
              のカウンセリングなどのキャリア開発サービスを提供し、学生がキャリア
              オプションを探索し、必須スキルを身に付け、就職市場を乗り切るのを支援しています。
            </p>
          </Grid>
        </Grid>
      </Container>

      {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button style={buttonStyles1} onClick={() => handleBtnClick('/WeOffer')}>View Courses</Button>
                    </Grid>
                </Grid>
            </Container> */}
    </div>
  );
};

export default FOGS;
