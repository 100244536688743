import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Faculty.css";

const KSLM = () => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #f21111", // Blue outline
    color: "#f21111", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const navigate = useNavigate();
  const handleBtnClick = (path) => {
    navigate(path);
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-title">KIU 農業畜産管理学部</h3>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <p className="fac-descrip">
              KIU の農業および家畜管理トレーニング
              スクールの「農業および家畜管理のディプロマ」プログラムは、農業の概念と現代の家畜管理技術に関する強固な基礎を提供します。この専門資格は、地域および世界の両方で、農業および家畜管理の実践に関する重要な洞察力を身に付けます。農場運営の監督、農家の手法改善の支援、アグリビジネス管理への従事、農業研究への貢献、または畜産の専門化を目指すかどうかにかかわらず、このディプロマはスリランカおよび国際規模での農業および家畜管理のやりがいのあるキャリアへの扉を開きます。スキルを磨き、この活気ある業界でダイナミックな旅に乗り出すために、私たちと一緒に参加してください。
            </p>
          </Grid>
        </Grid>
      </Container>

      {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
                        <h3 className='fac-dean-hed'>Dean</h3>
                        <p className='fac-descrip1'>
                            Prof. Koliya Pulasinghe<br/>BSc Eng. (Hons) in Computer Systems & Engineering,
                                University of Moratuwa, Sri Lanka, 1996<br/>
                            Contact: +94 77 123 4567<br/>
                            Email: abc@gmail.com
                        </p>
                    </Grid>

                    <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
                        <h3 className='fac-hod-hed'>Head of Department</h3>
                        <p className='fac-descrip1'>
                            Prof. Koliya Pulasinghe<br/>BSc Eng. (Hons) in Computer Systems & Engineering,
                                University of Moratuwa, Sri Lanka, 1996<br/>
                            Contact: +94 77 123 4567<br/>
                            Email: abc@gmail.com
                        </p>
                    </Grid>
                </Grid>
            </Container> */}

      {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <h3 className='fac-depts-hed'>Departments</h3>
                        <ul className='fac-depts-list'>
                            <li className='fac-depts-list-item'>Department Of Research And Development</li>
                            <li className='fac-depts-list-item'>Department Of Nursing Education</li>
                            <li className='fac-depts-list-item'>Department Of Clinical Nursing</li>
                            <li className='fac-depts-list-item'>Department Of Basic Sciences</li>
                            <li className='fac-depts-list-item'>Department Of Care Advancement</li>
                        </ul>
                    </Grid>
                </Grid>
            </Container> */}

      {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <h3 className='fac-fas-hed'>Facilities</h3>
                        <p className='fac-descrip1'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Amet cursus sit amet dictum sit
                            amet. Amet mattis vulputate enim nulla aliquet porttitor lacus luctus. Mi quis
                            hendrerit dolor magna eget est lorem ipsum. Arcu cursus vitae congue mauris
                            rhoncus. Ornare lectus sit amet est placerat in egestas erat imperdiet. Aliquam
                            malesuada bibendum arcu vitae elementum curabitur vitae. Blandit cursus risus at
                            ultrices mi tempus. Etiam sit amet nisl purus in. Mollis aliquam ut porttitor
                            leo a. Est pellentesque elit ullamcorper dignissim cras tincidunt lobortis
                            feugiat vivamus. Posuere sollicitudin aliquam ultrices sagittis orci a
                            scelerisque purus. Turpis massa tincidunt dui ut. Sem nulla pharetra diam sit
                            amet. Risus sed vulputate odio ut enim blandit.
                        </p>
                    </Grid>
                </Grid>
            </Container> */}

      {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button style={buttonStyles1} onClick={() => handleBtnClick('/WeOffer')}>View Courses</Button>
                    </Grid>
                </Grid>
            </Container> */}
    </div>
  );
};

export default KSLM;
