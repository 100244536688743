import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "./Ugcrs.css";
import BAPDF from "../../../brochure/sample.pdf";
import DSimg from "../../../assets/EngFlip1.jpg";

const DS = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              データサイエンス学士（優等学位）
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="ug-crs-des1">
              データ サイエンスは科学の新興分野として認識されており、ビッグ
              データとデータ駆動型テクノロジーで構成されています。データ
              サイエンスの分野は、研究と産業の領域に変革をもたらします。データ
              サイエンスの専門分野は、データ
              サイエンスの原理とそれを現実の問題に適用する方法を学生に教えることを目的としています。カリキュラムでは、データ分析、統計手法、数学、およびコンピューター
              サイエンスの基礎にも重点を置いています。 <br />
              この学位プログラムを履修する学生は、調査のあらゆる段階でデータとやり取りする方法を学び、チーム環境で作業できるようになります。学生が習得する主な能力は、計算および統計的思考、数学的基礎、モデル構築と評価、アルゴリズムとソフトウェアの基礎、データキュレーション、知識移転、コミュニケーションと責任です。{" "}
              <br />
              データ サイエンスの卒業生には、国内外でさまざまなキャリア
              チャンスがあり、学位を取得すると、一般的な職から専門的な職まで、データ
              サイエンスの分野で働く資格が得られます。可能性のあるキャリア
              チャンスは、データ サイエンティスト、データ アナリスト、データ
              サイエンス コンサルタント、ビッグ データ
              エンジニア、機械学習エンジニアです。この研究分野の知識の深さと幅広さは、雇用主にとってますます魅力的であり、データ
              サイエンティストの専門家としてだけでなく、IT
              業界のより幅広い分野でのキャリアにもつながります。 <br />
              さらに、KIUの学部生は、業界および研究に関する貴重な経験を提供する一流企業での実習に参加する機会があります。
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={DSimg}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "50%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des2">
              <p>
                レベル: SLQF 6
                <br />
                期間: 第8学期 (4年間)
                <br />
                クレジットポイント: 129
                <br />
                入学時期: 3月と9月
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des3">
              <h3>キャリアの機会</h3>
              <ol>
                <li>データサイエンティスト</li>
                <li>データアナリスト</li>
                <li>データサイエンスコンサルタント</li>
                <li>ビッグデータエンジニア</li>
                <li>機械学習エンジニア</li>
              </ol>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>入学条件</h5>

              <ul>
                <li>
                  スリランカ試験局が実施するG.C.E（上級レベル）試験で、物理科学分野または工学技術分野で少なくとも3回の単純合格（S）または同等の資格。
                </li>
                または
                <li>
                  スリランカ試験局が実施する G.C.E（上級レベル）試験で、1
                  回の受験でいずれかの分野で少なくとも 3
                  つの単純合格（S）を取得するか、またはスリランカ試験局が実施する
                  G.C.E（普通レベル）試験で数学のクレジット合格（C）を取得し、同等の資格を取得するか、または指定機関が承認したブリッジング
                  プログラムで同等の資格を取得します。
                </li>
                または
                <li>
                  スリランカ試験局が実施する G.C.E. (上級レベル)
                  試験の受験で、情報通信技術の単純合格(S) を 1
                  回の受験で少なくとも 3
                  回取得するか、同等の資格を取得し、スリランカ試験局が実施する
                  G.C.E. (普通レベル) 試験で数学の単位合格(C)
                  を取得するか、同等の資格を取得していること。{" "}
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des5">
              <h5>プログラム構成</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>1年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>1学期</h5>
              <ul>
                <li>コンピュータシステム入門</li>
                <li>コンピュータアーキテクチャ</li>
                <li>プログラミングの基礎</li>
                <li>学術的実践と身だしなみ</li>
                <li>離散数学とグラフ理論</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>2学期</h5>

              <ul>
                <li>データ構造とアルゴリズム</li>
                <li>オブジェクト指向プログラミング</li>
                <li>データベースシステム</li>
                <li>データ通信</li>
                <li>線形代数とベクトル解析</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>2年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>1学期</h5>
              <ul>
                <li>データサイエンス入門</li>
                <li>データのプライバシーとセキュリティ</li>
                <li>システム分析と設計</li>
                <li>ソフトウェアエンジニアリング入門</li>
                <li>微積分</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>2学期</h5>

              <ul>
                <li>人工知能</li>
                <li>オペレーティングシステムとシステム管理</li>
                <li>データサイエンスのための統計</li>
                <li>データの取得と管理</li>
                <li>暗号と情報理論</li>
                <li>データの視覚化</li>
                <li>キャリアプランニングと就業スキル開発 – セミナー</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>3年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>1学期</h5>
              <ul>
                <li>コンピューティングの研究方法</li>
                <li>ITの専門性と実践</li>
                <li>データマイニングとデータウェアハウス</li>
                <li>機械学習入門</li>
                <li>データサイエンスグループプロジェクト</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>2学期</h5>

              <ul>
                <li>ビジネス分析</li>
                <li>ビジネス・インテリジェンス</li>
                <li>バイオインフォマティクス</li>
                <li>職場ベースの企業配置</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>4年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>1学期</h5>
              <ul>
                <li>最終学年個人プロジェクト</li>
                <li>ビッグデータシステム</li>
                <li>分散システム</li>
                <li>ブロックチェーン技術入門</li>
                <li>データサイエンスプロジェクト管理</li>
                <li>モノのインターネット</li>
                <li>ウェブテクノロジー</li>
                <li>知識ベースシステム</li>
                <li>データベース内部</li>
                <li>自然言語処理入門</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>2学期</h5>

              <ul>
                <li>高度な機械学習</li>
                <li>データモデリングとシミュレーション</li>
                <li>ビッグデータ分析</li>
                <li>高度な統計</li>
                <li>情報検索</li>
                <li>ナチュラルコンピューティング</li>
                <li>確率モデル</li>
                <li>起業家精神</li>
                <li>コンパイラ理論</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>
                データサイエンス専攻の理学士優等学位を取得した卒業生は、以下のことができるようになります。
              </h5>

              <ul>
                <li>
                  コンピューティングの専門分野に適したデータサイエンスとコンピューティングの基礎知識、数学、科学、データサイエンスのドメイン知識を、定義された問題と要件からのコンピューティングモデルの抽象化と概念化に適用します。
                </li>
                <li>
                  限界を理解した上で、データサイエンス関連の実用アプリケーションに適切な技術、リソース、コンピューティングツールを適用する。
                </li>
                <li>
                  複雑なデータサイエンスとコンピューティングの問題に対する創造的なソリューションを設計し、評価し、公衆衛生と安全、文化、社会、環境への配慮を適切に考慮しながら、特定のニーズを満たすシステム、コンポーネント、またはプロセスを設計および評価します。
                </li>
                <li>
                  データサイエンスおよびコンピューティングコミュニティおよび社会全体と効果的にコミュニケーションをとる能力（効果的なレポートや設計文書を理解して作成する能力、効果的なプレゼンテーションを行う能力、明確な指示を与えたり受け取ったりする能力など）
                </li>
                <li>
                  人々や組織とのネットワークを構築し、社会の中で円滑かつ効果的に機能する。
                </li>
                <li>
                  個人として、また多様なチームや多分野にわたる環境においてメンバーまたはリーダーとして効果的に機能する。
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/29"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>適用する</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              パンフレットをダウンロード
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              近い
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default DS;
