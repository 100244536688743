import * as React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Box, useMediaQuery, useTheme } from "@mui/material";

import LIFEKIU from "../../../assets/LIFEKIU.webp";
import Life1 from "../../../assets/Life1.jpg";
import Life2 from "../../../assets/Life2.jpg";
import Life3 from "../../../assets/Life3.jpg";
import Life4 from "../../../assets/Life4.jpg";
import Life5 from "../../../assets/Life5.jpg";
import Life6 from "../../../assets/Life6.jpg";
import Life7 from "../../../assets/Life7.jpg";
import Life8 from "../../../assets/Life8.jpg";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const itemData = [
  {
    img: LIFEKIU,
    rows: 2,
    cols: 2,
  },
  {
    img: Life1,
    rows: 2,
    cols: 4,
  },
  {
    img: Life2,
    rows: 1.5,
    cols: 2,
  },
  {
    img: Life3,
    rows: 1.5,
    cols: 2,
  },
  {
    img: Life4,
    rows: 1.5,
    cols: 2,
  },
  {
    img: Life5,
    rows: 1.5,
    cols: 3,
  },
  {
    img: Life6,
    rows: 1.5,
    cols: 3,
  },
  {
    img: Life7,
    rows: 2.5,
    cols: 3,
  },
  {
    img: Life8,
    rows: 2.5,
    cols: 3,
  },
];

const adjustItemSize = (item, isSmall, isMedium, isLarge, isXLarge) => {
  if (isSmall) {
    return { ...item, rows: item.rows * 0.4, cols: item.cols * 0.1 };
  } else if (isMedium) {
    return { ...item, rows: item.rows * 0.75, cols: item.cols * 0.75 };
  } else if (isLarge) {
    return { ...item, rows: item.rows * 1, cols: item.cols * 1 };
  } else if (isXLarge) {
    return { ...item, rows: item.rows * 1, cols: item.cols * 1 };
  }
  return item;
};

const MotionImageListItem = ({ item, index }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.1, // Percentage of the element that needs to be visible to trigger the animation
  });

  return (
    <ImageListItem
      ref={ref}
      key={item.img}
      cols={item.cols || 1}
      rows={item.rows || 1}
      component={motion.div}
      initial={{ opacity: 0, scale: 0.5, y: 50 }}
      animate={inView ? { opacity: 1, scale: 1, y: 0 } : {}}
      transition={{ duration: 0.5, delay: index * 0.2, ease: "easeOut" }}
    >
      <img
        alt="KIU Uni"
        {...srcset(item.img, 150, item.rows, item.cols)}
        loading="lazy"
      />
    </ImageListItem>
  );
};

export default function QuiltedImageList() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLarge = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXLarge = useMediaQuery(theme.breakpoints.up("lg"));

  let cols = 6;
  if (isSmall) {
    cols = 2;
  } else if (isMedium) {
    cols = 3;
  } else if (isLarge) {
    cols = 4;
  } else if (isXLarge) {
    cols = 6;
  }

  return (
    <ImageList variant="quilted" cols={cols} rowHeight={200}>
      {itemData.map((item, index) => {
        const adjustedItem = adjustItemSize(
          item,
          isSmall,
          isMedium,
          isLarge,
          isXLarge
        );
        return (
          <MotionImageListItem
            key={adjustedItem.img}
            item={adjustedItem}
            index={index}
          />
        );
      })}
    </ImageList>
  );
}
