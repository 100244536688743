import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Faculty.css";

const FOCSE = () => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #f21111", // Blue outline
    color: "#f21111", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const navigate = useNavigate();
  const handleBtnClick = (path) => {
    navigate(path);
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-title">
              コンピュータサイエンス＆エンジニアリング学部
            </h3>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <p className="fac-descrip">
              今日、コンピュータ サイエンスは世界の未来となっています。KIU
              は、既存のシステムを超えるスキルと知識を提供し、IT
              業界でのプロフェッショナルなキャリアに備えることを目指しています。
              <br />
              学生には、コンピュータ
              サイエンスの主要分野の理論的知識を習得するための完璧な環境が提供されます。また、コンピュータ
              プログラミングとさまざまなソフトウェアの実践的な能力を習得して、キャリアの機会に応用できるスキルを身に付けることができます。さらに、学生は著名な教授や学者から指導を受けます。
              <br />
              これにより、コンピュータ
              サイエンスとエンジニアリングの分野で成功した卒業生が輩出され、明日の発明家や起業家となるでしょう。
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-dean-hed">学部長</h3>
            <p className="fac-descrip1">
              R. M. S. Rathnayake 博士 (名誉学部長)
              <br />
              コンピュータ サイエンス博士号 (シンガポール国立大学) BSc
              工学優等学位 (モラトゥワ)
              <br />
              メール: dean_foc@kiu.ac.lk
            </p>
          </Grid>

          <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
            <h3 className="fac-hod-hed">管理部長</h3>
            <p className="fac-descrip1">
              Shashika Ruwan Bandara 氏
              <br />
              BSc (Hons) サイバー セキュリティ管理
              <br />
              連絡先: 076-0467731
              <br />
              メール: shashika@kiu.ac.lk
            </p>
          </Grid>

          <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
            <h3 className="fac-hod-hed">学術部門長: コンピュータサイエンス</h3>
            <p className="fac-descrip1">
              Nilusha Perera 氏
              <br />
              IT 修士、IT 学士 (特別優等学位)
              <br />
              連絡先: 072-4110843
              <br />
              メール: nilusha@kiu.ac.lk
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-depts-hed">学部</h3>
            <ul className="fac-depts-list">
              <li className="fac-depts-list-item">
                コンピュータサイエンス学部
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-fas-hed">施設</h3>
            <p className="fac-descrip1">
              さくらキャンパスの最先端の IT ネットワーク
              ラボ施設は、ネットワークとサイバーセキュリティの分野向けに特別に設計されており、学生の学問と業界の間のギャップを埋めます。学生は、ネットワーク管理、倫理的ハッキング、侵入テスト、クラウド
              コンピューティングの実践的な経験を積むことができ、ネットワークとサイバーセキュリティに関連する業界でのキャリアを積むのに役立ちます。ラボには、ベンダー固有の相互接続デバイス、サーバー、膨大な数のオペレーティング
              システム、および旧バージョンから最新バージョンまでのソフトウェアが装備されています。この包括的なセットアップは、サイバーセキュリティ、ネットワーク、クラウド
              ラボ
              セッション、テスト環境、仮想マシン管理機能をサポートします。さらに、ラボには講義用のスマートボード、科目に関連する資料を豊富に集めたデジタルライブラリ、圧着工具、キーストーン、さまざまな種類のケーブル、パンチダウンツール、ケーブルテスターなどを含む膨大な
              IT ネットワークツールのコレクションが備わっています。{" "}
            </p>
          </Grid>
        </Grid>
      </Container>

      {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button style={buttonStyles1} onClick={() => handleBtnClick('/WeOffer')}>View Courses</Button>
                    </Grid>
                </Grid>
            </Container> */}
    </div>
  );
};

export default FOCSE;
