import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CardHeader, Container, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useInView } from "react-intersection-observer";
import postgrad from "../../assets/postgrad.jpg";
import shortC from "../../assets/shortC.jpg";
import ug from "../../assets/ug.png";
import Head from "../../components/JP/Head";
import Header from "../../components/JP/Header";
import Popup from "../../components/JP/Popup";
import Footer from "../../components/JP/footer";
import Mba from "../../components/JP/postgraduate/Mba";
import Mnd from "../../components/JP/postgraduate/Mnd";
import Mnm from "../../components/JP/postgraduate/Mnm";
import Mpn from "../../components/JP/postgraduate/Mpn";
import Msn from "../../components/JP/postgraduate/Msn";
import CC from "../../components/JP/shortcource/CC";
import CCA from "../../components/JP/shortcource/CCA";
import CDM from "../../components/JP/shortcource/CDM";
import CELA from "../../components/JP/shortcource/CELA";
import CELE from "../../components/JP/shortcource/CELE";
import CELI from "../../components/JP/shortcource/CELI";
import CFMA from "../../components/JP/shortcource/CFMA";
import CHRA from "../../components/JP/shortcource/CHRA";
import CIP from "../../components/JP/shortcource/CIP";
import CLA from "../../components/JP/shortcource/CLA";
import DALM from "../../components/JP/shortcource/DALM";
import DFW from "../../components/JP/shortcource/DFW";
import DIA from "../../components/JP/shortcource/DIA";
import JLP from "../../components/JP/shortcource/JLP";
import AC from "../../components/JP/undergraduate/AC";
import BA from "../../components/JP/undergraduate/BA";
import BS from "../../components/JP/undergraduate/BS";
import CN from "../../components/JP/undergraduate/CN";
import DS from "../../components/JP/undergraduate/DS";
import HR from "../../components/JP/undergraduate/HR";
import LLB from "../../components/JP/undergraduate/LLB";
import MIS from "../../components/JP/undergraduate/MIS";
import MK from "../../components/JP/undergraduate/MK";
import MSA from "../../components/JP/undergraduate/MSA";
import NUR from "../../components/JP/undergraduate/NUR";
import PSY from "../../components/JP/undergraduate/PSY";
import SE from "../../components/JP/undergraduate/SE";
import "./WeOffer.css";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  // marginLeft: 'auto',
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const WeOffer = () => {
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [buttonPopup1, setButtonPopup1] = useState(false);
  const [buttonPopup2, setButtonPopup2] = useState(false);
  const [buttonPopup3, setButtonPopup3] = useState(false);
  const [buttonPopup4, setButtonPopup4] = useState(false);
  const [buttonPopup5, setButtonPopup5] = useState(false);
  const [buttonPopup6, setButtonPopup6] = useState(false);
  const [buttonPopup7, setButtonPopup7] = useState(false);
  const [buttonPopup8, setButtonPopup8] = useState(false);
  const [buttonPopup9, setButtonPopup9] = useState(false);
  const [buttonPopup10, setButtonPopup10] = useState(false);
  const [buttonPopup11, setButtonPopup11] = useState(false);
  const [buttonPopup12, setButtonPopup12] = useState(false);
  const [buttonPopup13, setButtonPopup13] = useState(false);
  const [buttonPopup14, setButtonPopup14] = useState(false);
  const [buttonPopup15, setButtonPopup15] = useState(false);
  const [buttonPopup16, setButtonPopup16] = useState(false);
  const [buttonPopup17, setButtonPopup17] = useState(false);
  const [buttonPopup18, setButtonPopup18] = useState(false);
  const [buttonPopup19, setButtonPopup19] = useState(false);
  const [buttonPopup20, setButtonPopup20] = useState(false);
  const [buttonPopup21, setButtonPopup21] = useState(false);
  const [buttonPopup22, setButtonPopup22] = useState(false);
  const [buttonPopup23, setButtonPopup23] = useState(false);
  const [buttonPopup24, setButtonPopup24] = useState(false);
  const [buttonPopup25, setButtonPopup25] = useState(false);
  const [buttonPopup26, setButtonPopup26] = useState(false);
  const [buttonPopup27, setButtonPopup27] = useState(false);
  const [buttonPopup28, setButtonPopup28] = useState(false);
  const [buttonPopup29, setButtonPopup29] = useState(false);
  const [buttonPopup30, setButtonPopup30] = useState(false);
  const [buttonPopup31, setButtonPopup31] = useState(false);
  const [buttonPopup32, setButtonPopup32] = useState(false);
  const [buttonPopup33, setButtonPopup33] = useState(false);
  const [buttonPopup34, setButtonPopup34] = useState(false);

  const handleExpandClick1 = () => {
    setExpanded1(!expanded1);
  };
  const handleExpandClick2 = () => {
    setExpanded2(!expanded2);
  };
  const handleExpandClick3 = () => {
    setExpanded3(!expanded3);
  };
  // const buttonStyles = {     backgroundColor: 'transparent',     border: '2px
  // solid #00AAFD',  Blue outline     color: '#00AAFD',  Blue text color   };

  const [ref1, inView1] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView1) {
    }
  }, [inView1]);

  const [ref2, inView2] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView2) {
    }
  }, [inView2]);

  const [ref3, inView3] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView3) {
    }
  }, [inView3]);

  const [ref4, inView4] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView4) {
    }
  }, [inView4]);

  const [ref5, inView5] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView5) {
    }
  }, [inView5]);

  const [ref6, inView6] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView6) {
    }
  }, [inView6]);

  const [ref7, inView7] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView7) {
    }
  }, [inView7]);

  const [ref8, inView8] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView8) {
    }
  }, [inView8]);

  const [ref9, inView9] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView9) {
    }
  }, [inView9]);

  return (
    <div className="offer">
      <Header />

      <div className="head-div">
        <Head />
      </div>

      <motion.div
        ref={ref1}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView1
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.5,
        }}
      >
        <Container>
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "35px",
                  marginTop: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                多様な学習パスウェイ: 大学のコースの多様性をナビゲートする
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </motion.div>

      <motion.div
        ref={ref2}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView2
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.5,
        }}
      >
        <Container>
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Typography className="offer-des">
                「KIU
                では、多様な学部プログラム、大学院プログラム、短期コースを提供しています。地元や海外を志望しているかどうかにかかわらず、目標に合った道を自由に選択できます。成功への旅はここから始まります。私たちと一緒に可能性を探りましょう！」
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </motion.div>

      <motion.div
        ref={ref3}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView3
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.5,
        }}
      >
        <div className="offer-horizontal-divider"></div>
      </motion.div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={1.5} lg={1.5} xl={1.5}></Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={{
              marginLeft: "6%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card>
              <CardHeader
                title={
                  <Typography
                    sx={{
                      fontFamily: "Kanit, sans-serif",
                      fontSize: "35px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    学部プログラム
                  </Typography>
                }
              />
              <CardMedia
                component="img"
                width="100%"
                height="100%"
                image={ug}
                alt="KIU UNI"
                sx={{ padding: "20px" }}
              />
              <CardContent>
                <Typography className="offer-des-ug">
                  学部課程とは、中等教育修了後の高等教育の初期段階を指します。通常、学部課程は
                  3 ～ 4
                  年間の学習期間で、学生は特定の分野または学問分野で学士号を取得します。学部課程は、学生に基礎教育を提供し、幅広い科目を紹介しながら、選択した専攻分野を専門的に学ぶことができるように設計されています。この教育段階は、多様な学術的および課外活動の経験が特徴で、学生が批判的思考、問題解決能力、および選択した分野に対するより深い理解を身に付けるのに役立ちます。これは、卒業後のさらなる学術的追求または就職への足がかりとなります。
                </Typography>
              </CardContent>
              <CardActions
                disableSpacing="disableSpacing"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontFamily: "Libre Baskerville, serif",
                    color: "red",
                    fontSize: "25px",
                    cursor: "pointer",
                  }}
                  onClick={handleExpandClick1}
                >
                  すべてのプログラムを表示する
                </Typography>
                <ExpandMore
                  size="5rem"
                  expand={expanded1}
                  onClick={handleExpandClick1}
                  aria-expanded={expanded1}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </CardActions>
              <Collapse
                in={expanded1}
                timeout="auto"
                unmountOnExit="unmountOnExit"
              >
                <CardContent>
                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup1(true)}
                  >
                    ビジネス分析における経営学士（優等学位）
                  </Typography>
                  <Popup trigger={buttonPopup1} setTrigger={setButtonPopup1}>
                    <BA onClose={() => setButtonPopup1(false)} />
                  </Popup>

                  {/* <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup2(true)}
                  >
                    Bachelor of Science Honours in Artificial Intelligence
                  </Typography> */}
                  {/* <Popup trigger={buttonPopup2} setTrigger={setButtonPopup2}>
                                            <AI onClose={() => setButtonPopup2(false)}/>
                                        </Popup> */}

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup3(true)}
                  >
                    データサイエンスの理学士（優等学位）
                  </Typography>
                  <Popup trigger={buttonPopup3} setTrigger={setButtonPopup3}>
                    <DS onClose={() => setButtonPopup3(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup4(true)}
                  >
                    ソフトウェア工学の理学士（優等学位）
                  </Typography>
                  <Popup trigger={buttonPopup4} setTrigger={setButtonPopup4}>
                    <SE onClose={() => setButtonPopup4(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup5(true)}
                  >
                    コンピュータネットワークとサイバーセキュリティの理学士（優等学位）
                  </Typography>
                  <Popup trigger={buttonPopup5} setTrigger={setButtonPopup5}>
                    <CN onClose={() => setButtonPopup5(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup6(true)}
                  >
                    経営情報システム学士（優等学位）
                  </Typography>
                  <Popup trigger={buttonPopup6} setTrigger={setButtonPopup6}>
                    <MIS onClose={() => setButtonPopup6(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup7(true)}
                  >
                    会計学経営学士（優等学位）
                  </Typography>
                  <Popup trigger={buttonPopup7} setTrigger={setButtonPopup7}>
                    <AC onClose={() => setButtonPopup7(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup8(true)}
                  >
                    人事管理学士（優等学位）
                  </Typography>
                  <Popup trigger={buttonPopup8} setTrigger={setButtonPopup8}>
                    <HR onClose={() => setButtonPopup8(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup9(true)}
                  >
                    マーケティング経営学士（優等学位）
                  </Typography>
                  <Popup trigger={buttonPopup9} setTrigger={setButtonPopup9}>
                    <MK onClose={() => setButtonPopup9(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup10(true)}
                  >
                    心理学の理学士（優等学位）
                  </Typography>
                  <Popup trigger={buttonPopup10} setTrigger={setButtonPopup10}>
                    <PSY onClose={() => setButtonPopup10(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup11(true)}
                  >
                    生物医学科学の理学士（優等学位）
                  </Typography>
                  <Popup trigger={buttonPopup11} setTrigger={setButtonPopup11}>
                    <BS onClose={() => setButtonPopup11(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup12(true)}
                  >
                    医学科学の理学士（優等学位） 鍼灸学
                  </Typography>
                  <Popup trigger={buttonPopup12} setTrigger={setButtonPopup12}>
                    <MSA onClose={() => setButtonPopup12(false)} />
                  </Popup>

                  {/* <Typography
                                            paragraph="paragraph"
                                            className='ug-crs'
                                            onClick={() => setButtonPopup13(true)}>
                                            Bachelor of Science Honours Medical Science in KAATSU
                                        </Typography>
                                        <Popup trigger={buttonPopup13} setTrigger={setButtonPopup13}>
                                            <MSK onClose={() => setButtonPopup13(false)}/>
                                        </Popup> */}

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup14(true)}
                  >
                    看護学の理学士（優等学位）（登録看護師向け）
                  </Typography>
                  <Popup trigger={buttonPopup14} setTrigger={setButtonPopup14}>
                    <NUR onClose={() => setButtonPopup14(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup28(true)}
                  >
                    法学士（優等学位）
                  </Typography>
                  <Popup trigger={buttonPopup28} setTrigger={setButtonPopup28}>
                    <LLB onClose={() => setButtonPopup28(false)} />
                  </Popup>
                </CardContent>
              </Collapse>
            </Card>
          </Grid>
          <Grid item="item" xs={12} sm={12} md={1.5} lg={1.5} xl={1.5}></Grid>
        </Grid>
      </Container>
      {/* </motion.div> */}

      <motion.div
        ref={ref6}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView6
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.5,
        }}
      >
        <div className="offer-horizontal-divider"></div>
      </motion.div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={1.5} lg={1.5} xl={1.5}></Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card>
              <CardHeader
                title={
                  <Typography
                    sx={{
                      fontFamily: "Kanit, sans-serif",
                      fontSize: "35px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    大学院プログラム
                  </Typography>
                }
              />
              <CardMedia
                component="img"
                width="100%"
                height="100%"
                image={postgrad}
                alt="KIU UNI"
                sx={{ padding: "20px" }}
              />
              <CardContent>
                <Typography className="offer-des-ug">
                  KIU
                  の大学院教育は、専門知識、最先端の研究、変革的な経験の世界を解き放ち、境界を越えます。修士課程と
                  MPhil
                  を含む当校の大学院プログラムは、あなたの学問的および職業的な旅を向上させるために細心の注意を払って設計されています。
                  <br />
                  KIU
                  の大学院プログラムのアクセス性と柔軟性は、他とは一線を画しています。オンライン学習の利便性とパートタイム構造により、大学院生は教育を既存の取り組みとシームレスに統合することができます。{" "}
                  <br />
                  KIU
                  の大学院学位は、新しいキャリアの展望への鍵となり、収入の可能性を高めます。KIU
                  大学の大学院教育の世界を探索してください。ここは、知識に限界がなく、成功が具体的な現実になる場所です。
                </Typography>
              </CardContent>
              <CardActions
                disableSpacing="disableSpacing"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontFamily: "Libre Baskerville, serif",
                    color: "red",
                    fontSize: "25px",
                    cursor: "pointer",
                  }}
                  onClick={handleExpandClick2}
                >
                  すべてのプログラムを表示する
                </Typography>
                <ExpandMore
                  size="5rem"
                  expand={expanded2}
                  onClick={handleExpandClick2}
                  aria-expanded={expanded2}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </CardActions>
              <Collapse
                in={expanded2}
                timeout="auto"
                unmountOnExit="unmountOnExit"
              >
                <CardContent>
                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup15(true)}
                  >
                    経営学修士（SLQF 10）
                  </Typography>
                  <Popup trigger={buttonPopup15} setTrigger={setButtonPopup15}>
                    <Mba onClose={() => setButtonPopup15(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup16(true)}
                  >
                    看護教育修士（SLQF 9）
                  </Typography>
                  <Popup trigger={buttonPopup16} setTrigger={setButtonPopup16}>
                    <Mnd onClose={() => setButtonPopup16(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup17(true)}
                  >
                    看護管理学修士（SLQF 9）
                  </Typography>
                  <Popup trigger={buttonPopup17} setTrigger={setButtonPopup17}>
                    <Mnm onClose={() => setButtonPopup17(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup18(true)}
                  >
                    看護学修士（SLQF 10）
                  </Typography>
                  <Popup trigger={buttonPopup18} setTrigger={setButtonPopup18}>
                    <Msn onClose={() => setButtonPopup18(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup27(true)}
                  >
                    看護学修士（SLQF 11）
                  </Typography>
                  <Popup trigger={buttonPopup27} setTrigger={setButtonPopup27}>
                    <Mpn onClose={() => setButtonPopup27(false)} />
                  </Popup>
                </CardContent>
              </Collapse>
            </Card>
          </Grid>
          <Grid item="item" xs={12} sm={12} md={1.5} lg={1.5} xl={1.5}></Grid>
        </Grid>
      </Container>
      {/* </motion.div> */}

      <motion.div
        ref={ref8}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView8
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.5,
        }}
      >
        <div className="offer-horizontal-divider"></div>
      </motion.div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={1.5} lg={1.5} xl={1.5}></Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card>
              <CardHeader
                title={
                  <Typography
                    sx={{
                      fontFamily: "Kanit, sans-serif",
                      fontSize: "35px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    短期プログラム
                  </Typography>
                }
              />
              <CardMedia
                component="img"
                width="100%"
                height="100%"
                image={shortC}
                alt="KIU UNI"
                sx={{ padding: "20px" }}
              />
              <CardContent>
                <Typography className="offer-des-ug">
                  現代の学習者向けに設計された KIU
                  のダイナミックな短期コースで、無限の可能性の世界を発見してください。慎重に作成された当校のプログラムは、需要の高いスキルを習得し、多様な興味を探求し、知識を高めるための柔軟性を提供します。
                  <br />
                  KIU
                  では、教育だけでなく、サポート体制の整った学習環境も重視しています。コース期間を超えて、実践的な洞察と貴重なネットワーキングの機会を提供する専門の講師の力を借りることができます。キャリアアップ、個人の成長、好奇心の満足など、どのような目的であっても、KIU
                  の短期コースは、選択した分野で常に最新の情報を入手し、継続的に成長するための現代的で便利な方法です。
                  <br />
                  KIU
                  の短期コースで知識とスキルを習得する旅に出ましょう。学習と柔軟性が融合します。
                </Typography>
              </CardContent>
              <CardActions
                disableSpacing="disableSpacing"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontFamily: "Libre Baskerville, serif",
                    color: "red",
                    fontSize: "25px",
                    cursor: "pointer",
                  }}
                  onClick={handleExpandClick3}
                >
                  すべてのプログラムを表示する
                </Typography>
                <ExpandMore
                  size="5rem"
                  expand={expanded3}
                  onClick={handleExpandClick3}
                  aria-expanded={expanded3}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </CardActions>
              <Collapse
                in={expanded3}
                timeout="auto"
                unmountOnExit="unmountOnExit"
              >
                <CardContent>
                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup19(true)}
                  >
                    IELTS準備証明書
                  </Typography>
                  <Popup trigger={buttonPopup19} setTrigger={setButtonPopup19}>
                    <CIP onClose={() => setButtonPopup19(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup20(true)}
                  >
                    研究室アシスタント認定コース
                  </Typography>
                  <Popup trigger={buttonPopup20} setTrigger={setButtonPopup20}>
                    <CLA onClose={() => setButtonPopup20(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup21(true)}
                  >
                    人事分析の認定コース
                  </Typography>
                  <Popup trigger={buttonPopup21} setTrigger={setButtonPopup21}>
                    <CHRA onClose={() => setButtonPopup21(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup22(true)}
                  >
                    財務会計と管理会計の認定コース
                  </Typography>
                  <Popup trigger={buttonPopup22} setTrigger={setButtonPopup22}>
                    <CFMA onClose={() => setButtonPopup22(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup23(true)}
                  >
                    デジタルマーケティング認定コース
                  </Typography>
                  <Popup trigger={buttonPopup23} setTrigger={setButtonPopup23}>
                    <CDM onClose={() => setButtonPopup23(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup24(true)}
                  >
                    日本向け介護認定コース
                  </Typography>
                  <Popup trigger={buttonPopup24} setTrigger={setButtonPopup24}>
                    <CC onClose={() => setButtonPopup24(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup25(true)}
                  >
                    鍼灸臨床能力証明書
                  </Typography>
                  <Popup trigger={buttonPopup25} setTrigger={setButtonPopup25}>
                    <CCA onClose={() => setButtonPopup25(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup26(true)}
                  >
                    製作と溶接のディプロマ
                  </Typography>
                  <Popup trigger={buttonPopup26} setTrigger={setButtonPopup26}>
                    <DFW onClose={() => setButtonPopup26(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup29(true)}
                  >
                    鍼治療のディプロマ
                  </Typography>
                  <Popup trigger={buttonPopup29} setTrigger={setButtonPopup29}>
                    <DIA onClose={() => setButtonPopup29(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup30(true)}
                  >
                    日本語プログラム
                  </Typography>
                  <Popup trigger={buttonPopup30} setTrigger={setButtonPopup30}>
                    <JLP onClose={() => setButtonPopup30(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup31(true)}
                  >
                    英語資格コース（初級レベル）
                  </Typography>
                  <Popup trigger={buttonPopup31} setTrigger={setButtonPopup31}>
                    <CELE onClose={() => setButtonPopup31(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup32(true)}
                  >
                    英語資格コース（中級レベル）
                  </Typography>
                  <Popup trigger={buttonPopup32} setTrigger={setButtonPopup32}>
                    <CELI onClose={() => setButtonPopup32(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup33(true)}
                  >
                    英語認定コース（上級レベル）
                  </Typography>
                  <Popup trigger={buttonPopup33} setTrigger={setButtonPopup33}>
                    <CELA onClose={() => setButtonPopup33(false)} />
                  </Popup>

                  <Typography
                    paragraph="paragraph"
                    className="ug-crs"
                    onClick={() => setButtonPopup34(true)}
                  >
                    農業および畜産管理のディプロマ(DALM)
                  </Typography>
                  <Popup trigger={buttonPopup34} setTrigger={setButtonPopup34}>
                    <DALM onClose={() => setButtonPopup34(false)} />
                  </Popup>
                </CardContent>
              </Collapse>
            </Card>
          </Grid>
          <Grid item="item" xs={12} sm={12} md={1.5} lg={1.5} xl={1.5}></Grid>
        </Grid>
      </Container>
      {/* </motion.div> */}

      <div className="footer-div">
        <Footer />
      </div>
    </div>
  );
};

export default WeOffer;
