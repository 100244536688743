import { Button, Container, Grid, Paper } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Head.css";
import Logo from "../assets/NEWLOG.png";
import { PiMagnifyingGlassDuotone } from "react-icons/pi";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const LanguageSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23000000' stroke='%23000000' stroke-width='1' d='M20.472 11.997a4.865 4.865 0 0 0-4-2.204a5.592 5.592 0 0 0-.131-1.024a1 1 0 1 0-1.945.462a3.553 3.553 0 0 1 .082.601a13.577 13.577 0 0 0-3.257.822c.023-1.204.077-2.407.197-3.607l.038-.382A33.435 33.435 0 0 0 14.938 6l.12-.03a1 1 0 1 0-.48-1.94l-.122.03c-.922.23-1.856.404-2.794.55l.151-1.51a1 1 0 0 0-1.99-.2l-.196 1.96c-.934.083-1.87.14-2.809.14a1 1 0 0 0 0 2c.87 0 1.74-.046 2.607-.114a46.66 46.66 0 0 0-.203 4.698c-.134.073-.27.142-.403.22a16.407 16.407 0 0 0-1.949 1.31l-.022.018a13.74 13.74 0 0 0-2.649 2.7a3.004 3.004 0 0 0 2.947 4.72a9.74 9.74 0 0 0 2.837-1.014a.996.996 0 0 0 1.822-.703c-.025-.145-.036-.291-.058-.437a13.838 13.838 0 0 0 1.314-1.155a13.167 13.167 0 0 0 2.101-2.73c.023-.039.042-.078.065-.118c.118-.21.23-.422.332-.635c.053-.111.102-.222.151-.333a10.4 10.4 0 0 0 .329-.838c.032-.096.06-.191.09-.287c.05-.169.101-.337.141-.504l.005-.018A3.015 3.015 0 0 1 18.741 13c1.019 1.767-.963 4.977-4.417 7.154a1 1 0 1 0 1.067 1.692c4.499-2.836 6.683-7.069 5.08-9.849M6.796 18.583a1.005 1.005 0 0 1-.98-1.574a11.893 11.893 0 0 1 2.291-2.323l.027-.021a13.97 13.97 0 0 1 1.144-.793c.06 1.195.173 2.386.326 3.574a8.185 8.185 0 0 1-2.808 1.137M14.126 12a8.166 8.166 0 0 1-.316.78c-.056.12-.118.239-.18.358q-.145.278-.311.554c-.085.14-.172.279-.265.418a11.48 11.48 0 0 1-1.408 1.719c-.07.07-.143.133-.214.2q-.163-1.597-.211-3.202a12.513 12.513 0 0 1 2.94-.933z'/%3E%3C/svg%3E")`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#ffffff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#ffffff",
    width: 32,
    height: 32,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23000000' stroke='%23000000' stroke-width='1' d='M14 10h2v.757a4.5 4.5 0 0 1 7 3.743V20h-2v-5.5c0-1.43-1.174-2.5-2.5-2.5S16 13.07 16 14.5V20h-2zm-2-6v2H4v5h8v2H4v5h8v2H2V4z'/%3E%3C/svg%3E")`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#ffffff",
    borderRadius: 20 / 2,
  },
}));

const Head = () => {
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const [language, setLanguage] = useState("english");
  useEffect(() => {
    setLanguage(location.pathname.includes("/jp") ? "japanese" : "english");
  }, [location.pathname]);

  const handleChange = (event) => {
    const newLanguage = event.target.checked ? "japanese" : "english";
    setLanguage(newLanguage);
    navigate(newLanguage === "japanese" ? "/jp" : "/");
  };

  const handleSearch = () => {
    const keyword = searchInput.toLowerCase();

    if (
      keyword === "home" ||
      keyword === "partners" ||
      keyword === "contact" ||
      keyword === "pillers"
    ) {
      navigate("/");
    } else if (
      keyword === "about" ||
      keyword === "start" ||
      keyword === "past" ||
      keyword === "going" ||
      keyword === "present" ||
      keyword === "vision" ||
      keyword === "mission" ||
      keyword === "vc message" ||
      keyword === "testamonial" ||
      keyword === "student" ||
      keyword === "voice"
    ) {
      navigate("/About");
    } else if (
      keyword.toLowerCase() === "we offer" ||
      keyword.toLowerCase() === "undergraduate" ||
      keyword.toLowerCase() === "postgraduate" ||
      keyword.toLowerCase() === "short" ||
      keyword.toLowerCase() === "course" ||
      keyword.toLowerCase() === "short course" ||
      keyword.toLowerCase() === "business analytics" ||
      keyword.toLowerCase() === "artificial intelligence" ||
      keyword.toLowerCase() === "data science" ||
      keyword.toLowerCase() === "software engineering" ||
      keyword.toLowerCase() === "cyber security" ||
      keyword.toLowerCase() === "computer networks" ||
      keyword.toLowerCase() === "computer networks and cyber security" ||
      keyword.toLowerCase() === "management information system" ||
      keyword.toLowerCase() === "management" ||
      keyword.toLowerCase() === "information system" ||
      keyword.toLowerCase() === "accounting" ||
      keyword.toLowerCase() === "human resource" ||
      keyword.toLowerCase() === "marketing" ||
      keyword.toLowerCase() === "psychology" ||
      keyword.toLowerCase() === "science" ||
      keyword.toLowerCase() === "biomedical science" ||
      keyword.toLowerCase() === "acupuncture" ||
      keyword.toLowerCase() === "medical science in acupuncture" ||
      keyword.toLowerCase() === "kaatsu" ||
      keyword.toLowerCase() === "medical science in kaatsu" ||
      keyword.toLowerCase() === "medical science" ||
      keyword.toLowerCase() === "nursing" ||
      keyword.toLowerCase() === "master" ||
      keyword.toLowerCase() === "administration" ||
      keyword.toLowerCase() === "business" ||
      keyword.toLowerCase() === "education" ||
      keyword.toLowerCase() === "nursing education" ||
      keyword.toLowerCase() === "nursing management" ||
      keyword.toLowerCase() === "science in nursing" ||
      keyword.toLowerCase() === "philosophy in nursing" ||
      keyword.toLowerCase() === "slqf 9" ||
      keyword.toLowerCase() === "slqf 10" ||
      keyword.toLowerCase() === "slqf 11" ||
      keyword.toLowerCase() === "certificate" ||
      keyword.toLowerCase() === "ielts" ||
      keyword.toLowerCase() === "laboratory" ||
      keyword.toLowerCase() === "assistant" ||
      keyword.toLowerCase() === "analytics" ||
      keyword.toLowerCase() === "human" ||
      keyword.toLowerCase() === "resource" ||
      keyword.toLowerCase() === "hr" ||
      keyword.toLowerCase() === "financial" ||
      keyword.toLowerCase() === "digital" ||
      keyword.toLowerCase() === "marketing" ||
      keyword.toLowerCase() === "digital marketing" ||
      keyword.toLowerCase() === "caregiver" ||
      keyword.toLowerCase() === "acupuncture" ||
      keyword.toLowerCase() === "clinical" ||
      keyword.toLowerCase() === "fabrication" ||
      keyword.toLowerCase() === "welding"
    ) {
      navigate("/WeOffer");
    } else if (keyword === "faculties" || keyword === "dean") {
      navigate("/Faculties");
    } else if (
      keyword === "facilities" ||
      keyword === "sakura" ||
      keyword === "kyo" ||
      keyword === "computer" ||
      keyword === "lab" ||
      keyword === "computer lab" ||
      keyword === "biomedical lab" ||
      keyword === "training center" ||
      keyword === "moot" ||
      keyword === "court" ||
      keyword === "moot court"
    ) {
      navigate("/Facilities");
    } else if (keyword === "news" || keyword === "past news") {
      navigate("/News");
    } else if (
      keyword === "research" ||
      keyword === "theme" ||
      keyword === "research theme" ||
      keyword === "why" ||
      keyword === "why research" ||
      keyword === "panel" ||
      keyword === "researches" ||
      keyword === "dean research" ||
      keyword === "research news"
    ) {
      navigate("/Research");
    } else {
      alert("No such page exists");
    }
  };

  const goHome = () => {
    navigate("/");
  };

  return (
    <div>
      <div className="head-comp">
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box width="100%">
              <Button onClick={goHome}>
                <img
                  alt="KIU Uni"
                  src={Logo}
                  height="40px"
                  width="auto"
                />
              </Button>
            </Box>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1 className="head-comp-text">
              "Your Journey, Our Excellence: KIU University."
            </h1>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="head-search-grp">
              <input
                type="search"
                className="head-search"
                placeholder="Search.."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <PiMagnifyingGlassDuotone
                className="head-magnify"
                onClick={handleSearch}
              />
            </div>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={1}
            lg={1}
            xl={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <LanguageSwitch
                    sx={{ m: 1 }}
                    checked={language === "japanese"}
                    onChange={handleChange}
                  />
                }
                label={
                  <Typography style={{ color: "white" }}>
                    Language switch
                  </Typography>
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Head;
