import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "../postgraduate/Pgcrs.css";
import CIPimg from "../../../assets/CIP.jpg";
import BAPDF from "../../../brochure/sample.pdf";

const CIP = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              IELTS準備証明書
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="pg-crs-des1">
              IELTS 準備証明書は、IELTS
              テストで成功し、パフォーマンスを向上させるために必要な戦略を学生に提供することで、テスト当日の成功を最大限に高めるように設計されています。さらに、IELTS
              試験モジュールと基準に慣れるためのトレーニング
              プラットフォームが提供されます。
              <br />
              このコースでは、スピーキング、リスニング、リーディング、ライティングの
              4 つの要素すべてを取り上げ、IELTS
              準備への包括的なアプローチを保証します。この IELTS
              準備コースは、テストで非常に良い成績を収め、学術的および専門的なコミュニケーションで優れた成績を収めたいと考えている人にとって貴重なリソースです。
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={CIPimg}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "80%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>IELTS 準備の証明書取得のために KIU を選ぶべき理由: </h5>

              <ul>
                <li>
                  このコースは、IELTS
                  テストで成功し、学業成績を向上させるために必要な戦略を学生に提供することで、テスト当日の成功を最大限に高めるように設計されています。
                  さらに、IELTS 試験のモジュールと基準に慣れるためのトレーニング
                  プラットフォームが提供されます。
                </li>
                <li>
                  このコースでは、スピーキング、リスニング、リーディング、ライティングの
                  4 つの要素すべてを取り上げ、IELTS
                  準備への包括的なアプローチを保証します。 この IELTS
                  準備コースは、テストで非常に良い成績を収め、学術的および専門的なコミュニケーションで優れた成績を収めたいと考えている人にとって貴重なリソースです。
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des2">
              <p>
                レベル: 短期コース
                <br />
                期間: 3 か月、総時間数 54 時間、模擬試験 4 回
                <br />
                提供モード: キャンパス内 / 遠隔モード
                <br />
                提供媒体: 英語
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des3">
              <h3>入学要件</h3>
              <ul>
                <li>
                  看護学生の場合:
                  <ul>
                    <li>
                      IELTS準備証明書の入学要件は次のとおりです。
                      政府看護職員で、政府看護師養成学校が提供する一般看護のディプロマを修了していること
                    </li>
                    <li>
                      G.C.E通常レベル試験で英語科目の最低通常合格(「S」)を取得した学生。
                    </li>
                  </ul>
                </li>
                <li>
                  一般学生の場合:
                  <ul>
                    <li>
                      GCE(O/L)試験に合格し、英語で最低「S」合格または同等の資格を取得していること。
                    </li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/32"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>適用する</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              パンフレットをダウンロード
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              近い
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CIP;
