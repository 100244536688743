import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import Icon from "../../@core/components/icon";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { Grid } from "antd";

const CustomCloseButton = styled(IconButton)(({ theme }) => ({
  top: 0,
  right: 0,
  color: "grey.500",
  position: "absolute",
  boxShadow: theme.shadows[2],
  transform: "translate(10px, -10px)",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: `${theme.palette.background.paper} !important`,
  transition: "transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out",
  "&:hover": {
    transform: "translate(7px, -5px)",
  },
}));

const PastNewsTable = () => {
  const [data, setData] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 7,
  });
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [titleImage, setTitleImage] = useState("");
  const [content1, setContent1] = useState("");
  const [content2, setContent2] = useState("");
  const [content3, setContent3] = useState("");
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");
  const [image5, setImage5] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch("https://kiu.ac.lk/api/website/notLast5")
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Error fetching data", error));
  };

  const columns = [
    {
      flex: 0.435,
      minWidth: 440,
      field: "newstitle",
      headerName: "News Title",
      headerAlign: "center",
      renderCell: (params) => {
        const { row } = params;

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                overflow: "auto",
                width: "600px",
                scrollbarWidth: "thin", // For Firefox
                "&::-webkit-scrollbar": {
                  height: "10px", // Adjust as needed
                },
                "&::-webkit-scrollbar-track": {
                  background: "#e0e0e0", // Background color of the scrollbar track
                  borderRadius: "25px", // Rounded corners on the scrollbar thumb
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#ababab", // Color of the scrollbar thumb
                  borderRadius: "25px", // Rounded corners on the scrollbar thumb
                },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: "text.primary",
                  fontWeight: 600,
                }}
              >
                {row.title}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      flex: 0.2,
      type: "date",
      minWidth: 150,
      headerName: "Date",
      field: "date",
      valueGetter: (params) => new Date(params.value),
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{
            color: "text.primary",
          }}
        >
          {new Date(params.row.date).toLocaleDateString()}
        </Typography>
      ),
    },
    {
      flex: 0.185,
      minWidth: 140,
      field: "actions",
      headerName: "View",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              minWidth: "100%",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={(e) => {
                setTitle(params.row.title);
                setDate(new Date(params.row.date).toLocaleDateString());
                setTitleImage(params.row.titleImage);
                setContent1(params.row.content1);
                setContent2(params.row.content2);
                setContent3(params.row.content3);
                setImage1(params.row.image1);
                setImage2(params.row.image2);
                setImage3(params.row.image3);
                setImage4(params.row.image4);
                setImage5(params.row.image5);
                handleClickOpen();
              }}
            >
              <Icon icon="ant-design:read-outlined" />
            </Button>
          </Box>
        );
      },
    },
  ];

  const contentStyle = {
    fontSize: "18px",
    color: "#b4afaf",
    fontFamily: "Montserrat, sans-serif",
    textAlign: "left",
    marginTop: "5%",
  };

  return (
    <>
      <Card>
        <DataGrid
          autoHeight="autoHeight"
          columns={columns}
          pageSizeOptions={[7, 10, 25, 50]}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          rows={data}
        />
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        sx={{
          "& .MuiDialog-paper": {
            overflow: "visible",
          },
        }}
      >
        <DialogContent
          dividers="dividers"
          sx={{
            p: (theme) => `${theme.spacing(4)} !important`,
            maxHeight: 600,
          }}
        >
          <CustomCloseButton aria-label="close" onClick={handleClose}>
            <Icon icon="tabler:x" fontSize="1.25rem" />
          </CustomCloseButton>
          <Typography
            sx={{
              mb: 4,
              fontWeight: 600,
              textAlign: "center",
              fontSize: "1.5rem",
              fontFamily: "Kanit, sans-serif",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              mb: 4,
            }}
          >
            {date}
          </Typography>
          <img
            alt="KIU Uni"
            src={titleImage}
            style={{ width: "100%", height: "auto", marginBottom: "5%" }}
          />
          <Box
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <ImageList variant="masonry" cols={3} gap={8}>
              {[
                { image: image1 },
                { image: image2 },
                { image: image3 },
                { image: image4 },
                { image: image5 },
              ]
                .filter((item) => item.image) // Filter out null or empty images
                .map((item, index) => (
                  <ImageListItem key={index}>
                    <img
                      alt="KIU Uni"
                      src={item.image}
                      
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
            </ImageList>
          </Box>
          <Typography sx={contentStyle}>{content1}</Typography>
          <Typography sx={contentStyle}>{content2}</Typography>
          <Typography sx={contentStyle}>{content3}</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PastNewsTable;
