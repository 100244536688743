import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import "./ComputerLab.css";
// import ReactPlayer from 'react-player'; import kyo from '../assets/Kyo.mp4'
import ReactPlayer from "react-player";

const ComputerLab = () => {
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginBottom: "2%",
                textAlign: "center",
                fontWeight: "bold",
                color: "#353538",
              }}
            >
              Computer Lab
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container className="about-st">
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <ReactPlayer
              url="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1720000358224_Computer%20Lab.mp4"
              controls={true}
              playing={true}
              Autoplay
              loop
              muted
              width="100%"
              height="100%"
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload", // Disable download button
                  },
                },
              }}
            ></ReactPlayer>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ComputerLab;
