import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "../postgraduate/Pgcrs.css";
import BAPDF from "../../../brochure/sample.pdf";
import CLAimg from "../../../assets/CLA.jpg";

const CLA = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              研究室アシスタント認定コース
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="pg-crs-des1">
              「研究室アシスタント認定コース」の短期コースは、研究室環境で活躍するために必要な基本的なスキルと知識を身に付けることを目的としています。このコースでは、研究室アシスタントに対する実践的かつ包括的なアプローチを提供します。
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={CLAimg}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "80%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des2">
              <p>
                レベル: NVQ レベル 03
                <br />
                期間: 6 か月コース
                <br />
                クレジット ポイント: KIU および TVEC
                からコース修了証明書が発行されます。
                <br />
                受講開始時期: 毎年 4 月と 9 月の 2 回受講開始となります。
                <br />
                提供方法: Microsoft Teams 経由のオンライン + 実地実習
                <br />
                提供媒体: 英語媒体
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des3">
              <h3>入学要件</h3>
              <ul>
                <li>GCE O/L を修了していること</li>
                <li>
                  国際通常レベル、科学で最低「C」合格、通常レベルの英語で最低「S」合格。
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>主なハイライト: </h5>

              <ul>
                <li>
                  TVEC 承認:
                  当社のラボアシスタント短期コースは、高等教育および職業教育委員会
                  (TVEC)
                  によって承認されており、修了時に公認および認定された資格を取得できます。
                </li>
                <li>
                  実践経験:
                  実際のラボ環境で実践的な実践経験を積むことで、理論的な知識を現実のシナリオに適用できます。
                </li>
                <li>
                  業界関連のカリキュラム:
                  当社のカリキュラムは、重要なトピックを網羅するように慎重に作成されており、今日のラボで求められるスキルを習得できます。基本的なテクニックから安全プロトコルまで、すべてを網羅しています。
                </li>
                <li>
                  資格のあるインストラクター:
                  ラボでの作業の複雑さを指導することに熱意のある、経験豊富で資格のあるインストラクターから学びます。当社のインストラクターは、あなたの成功に全力を尽くし、必要なサポートを提供します。{" "}
                </li>
                <li>
                  柔軟なスケジュール:
                  週末の柔軟なスケジュールにより、既存のルーチンを中断することなくコースを受講できます。
                </li>
                <li>
                  キャリア チャンス: ラボ
                  アシスタントの短期コースを修了すると、さまざまな業界のラボでさまざまな初級レベルの職に就くことができます。このコースは、さらなる学術研究やキャリアアップのための強固な基盤となります。
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/37"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>適用する</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              パンフレットをダウンロード
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              近い
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CLA;
