import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "../postgraduate/Pgcrs.css";
import BAPDF from "../../brochure/sample.pdf";
import CLAimg from "../../assets/CLA.jpg";

const CLA = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Certificate Course for Laboratory Assistant
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="pg-crs-des1">
              Our Short Course for “Certificate course in Laboratory Assistant”
              is designed to equip you with the fundamental skills and knowledge
              needed to thrive in a laboratory setting. This course offers a
              practical and comprehensive approach to laboratory assistance.
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={CLAimg}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "80%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des2">
              <p>
                Level: NVQ Level 03
                <br />
                Duration: 06 months course
                <br />
                Credit Points: A certificate upon successful completion of the
                course from KIU and TVEC.
                <br />
                Intakes: There will be two intakes per year – April and
                September.
                <br />
                Delivery Mode: Online via Microsoft Teams + Physical Practical
                <br />
                Delivery Medium: English medium
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des3">
              <h3>Entry Requirements</h3>
              <ul>
                <li>Successful completion of GCE O/L</li>
                <li>
                  International Ordinary Levels with a minimum of a 'C' pass in
                  science and a minimum of an 'S' pass in English at the
                  Ordinary Level.
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>Key Highlights: </h5>

              <ul>
                <li>
                  TVEC Approved: Our Short Course for Laboratory Assistant is
                  approved by the Tertiary and Vocational Education Commission
                  (TVEC), ensuring that you receive a recognized and accredited
                  qualification upon completion.
                </li>
                <li>
                  Hands-On Experience: Gain practical, hands-on experience in a
                  real laboratory environment, allowing you to apply theoretical
                  knowledge to real-world scenarios.
                </li>
                <li>
                  Industry-Relevant Curriculum: Our curriculum is carefully
                  crafted to cover essential topics, ensuring that you acquire
                  the skills demanded by today's laboratories. From basic
                  techniques to safety protocols, we've got you covered.
                </li>
                <li>
                  Qualified Instructors: Learn from experienced and qualified
                  instructors who are passionate about guiding you through the
                  intricacies of laboratory work. Our instructors are committed
                  to your success and will provide the support you need.
                </li>
                <li>
                  Flexible Schedule: Our flexible schedule during weekends
                  allows you to pursue the course without disrupting your
                  existing routine.
                </li>
                <li>
                  Career Opportunities: Completing the Short Course for
                  Laboratory Assistant opens doors to various entry-level
                  positions in laboratories across industries. This course
                  serves as a solid foundation for further academic pursuits or
                  career advancements.
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/37"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>Apply</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              Download Brochure
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CLA;
