import { Box, Container, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Button, Drawer } from "antd";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { MdOutlineCloseFullscreen } from "react-icons/md";
import { useInView } from "react-intersection-observer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useNavigate } from "react-router-dom";
import behavio from "../../assets/behavio.jpg";
import computer from "../../assets/computer.jpg";
import education from "../../assets/education.jpg";
import graduate from "../../assets/graduate.jpg";
import health from "../../assets/health.jpg";
import law from "../../assets/law.jpg";
import livestock from "../../assets/livestock.jpg";
import management from "../../assets/management.jpg";
import nursing from "../../assets/nursing.jpeg";
import Head from "../../components/JP/Head";
import Header from "../../components/JP/Header";
import FOBS from "../../components/JP/faculties/FOBS";
import FOCSE from "../../components/JP/faculties/FOCSE";
import FOEL from "../../components/JP/faculties/FOEL";
import FOGS from "../../components/JP/faculties/FOGS";
import FOHS from "../../components/JP/faculties/FOHS";
import FOL from "../../components/JP/faculties/FOL";
import FOM from "../../components/JP/faculties/FOM";
import FON from "../../components/JP/faculties/FON";
import KSLM from "../../components/JP/faculties/KSLM";
import Footer from "../../components/JP/footer";
import "./Faculties.css";

const Faculties = () => {
  const [open1, setOpen1] = useState(false);
  const showDrawer1 = () => {
    setOpen1(true);
  };

  const [open2, setOpen2] = useState(false);
  const showDrawer2 = () => {
    setOpen2(true);
  };

  const [open3, setOpen3] = useState(false);
  const showDrawer3 = () => {
    setOpen3(true);
  };

  const [open4, setOpen4] = useState(false);
  const showDrawer4 = () => {
    setOpen4(true);
  };

  const [open5, setOpen5] = useState(false);
  const showDrawer5 = () => {
    setOpen5(true);
  };

  const [open6, setOpen6] = useState(false);
  const showDrawer6 = () => {
    setOpen6(true);
  };

  const [open7, setOpen7] = useState(false);
  const showDrawer7 = () => {
    setOpen7(true);
  };

  const [open8, setOpen8] = useState(false);
  const showDrawer8 = () => {
    setOpen8(true);
  };

  const [open9, setOpen9] = useState(false);
  const showDrawer9 = () => {
    setOpen9(true);
  };

  const [ref1, inView1] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView1) {
    }
  }, [inView1]);

  const [ref2, inView2] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView2) {
    }
  }, [inView2]);

  const [ref3, inView3] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView3) {
    }
  }, [inView3]);

  const [ref4, inView4] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView4) {
    }
  }, [inView4]);

  const [ref5, inView5] = useInView({ threshold: 0.2 });
  const [ref6, inView6] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView5) {
    }
  }, [inView5]);

  useEffect(() => {
    if (inView6) {
    }
  }, [inView6]);

  const buttonStyles1 = {
    marginTop: "2em",
    backgroundColor: "transparent",
    border: "2px solid #002FFF", // Blue outline
    color: "#002FFF", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const navigate = useNavigate();
  const handleBtnClick = (path) => {
    navigate(path);
  };

  const drawerWidth = window.innerWidth < 600 ? "70%" : "50%";

  return (
    <div className="faculty">
      <Header />

      <div className="head-div">
        <Head />
      </div>

      <motion.div
        ref={ref1}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView1
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        <Container>
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "35px",
                  marginTop: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                当大学の多分野にわたる驚異を探る:
                明日のリーダーを育成する学部を紹介
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </motion.div>

      <motion.div
        ref={ref2}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView2
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        <Container>
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Typography className="fac-des">
                「より明るい未来への入り口であるKIU大学を体験してください。当大学には8つの完全に機能する学部があり、それぞれに複数の学科があります。熟練したスタッフの献身的なチームとともに、私たちはあなたが自分の可能性を最大限に発揮できるように支援します。KIUでは、急速に変化する世界の課題に取り組むための準備を整える、学際的な学習を信じています。私たちと一緒にあなたの有望な未来を形作りましょう。」
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </motion.div>

      <motion.div
        ref={ref3}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView3
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        <div className="fac-horizontal-divider"></div>
      </motion.div>

      <motion.div
        ref={ref4}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView4
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        <Container>
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "35px",
                  marginBottom: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                学部
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </motion.div>

      <Container>
        <Grid container="container" spacing={1}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer1}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={nursing}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">看護学部</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open1}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen1(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen1(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FON />
          </Drawer>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer2}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={health}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">健康科学部</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open2}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen2(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen2(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FOHS />
          </Drawer>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={1}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer3}
              style={{
                cursor: "pointer",
                minHeight: "100%",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={management}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">経営学部</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open3}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen3(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen3(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FOM />
          </Drawer>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer4}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={education}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">教育学部・言語学部</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open4}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen4(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen4(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FOEL />
          </Drawer>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={1}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer5}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={computer}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">
                  コンピュータサイエンス＆エンジニアリング学部
                </h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open5}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen5(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen5(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FOCSE />
          </Drawer>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer6}
              style={{
                cursor: "pointer",
                minHeight: "100%",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={graduate}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">大学院研究科</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open6}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen6(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen6(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FOGS />
          </Drawer>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={1}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer7}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={behavio}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">行動科学学部</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open7}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen7(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen7(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FOBS />
          </Drawer>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer8}
              style={{
                cursor: "pointer",
                minHeight: "100%",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={livestock}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">KIU 農畜産学部</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open8}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen8(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen8(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <KSLM />
          </Drawer>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              marginLeft: "6%",
            }}
          >
            <Card
              onClick={showDrawer9}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="image-container">
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  image={law}
                  alt="KIU UNI"
                  className="hover-effect-image"
                />
              </div>
              <CardContent>
                <h2 className="fac-hed">法学部</h2>
              </CardContent>
            </Card>
          </Grid>

          <Drawer
            open={open9}
            closable={false}
            title={
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setOpen9(false);
                }}
                className="close-fac-draw"
              >
                {" "}
              </MdOutlineCloseFullscreen>
            }
            onClose={() => {
              setOpen9(false);
            }}
            width={drawerWidth}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              WebkitBackdropFilter: "blur(10px)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
          >
            <FOL />
          </Drawer>
        </Grid>
      </Container>

      <motion.div
        ref={ref5}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView5
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        <div className="fac-horizontal-divider"></div>
      </motion.div>

      <motion.div
        ref={ref6}
        initial={{
          opacity: 0,
          y: 20,
        }}
        animate={
          inView6
            ? {
                opacity: 1,
                y: 0,
              }
            : {
                opacity: 0,
                y: 20,
              }
        }
        transition={{
          duration: 0.7,
        }}
      >
        <Container>
          <Grid container="container" spacing={2}>
            <Grid
              item="item"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                marginLeft: "6%",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Kanit, sans-serif",
                  fontSize: "35px",
                  marginBottom: "5%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                学部長
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </motion.div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "250px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721387193498_ssp.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <h3 className="fac-de-pos">S.S.P. Warnakulasuriya 教授</h3>
            <p className="fac-de-pos1">看護学部名誉学部長</p>
            <p className="fac-de-pos2">
              PhD (USJP)、MNSc (アデレード、オーストラリア)、PGDCP
              (コロンボ)、BScN (Hons.) (OUSL)、RN、RMN、教育および監督のディップ
            </p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">経歴の概要</h3>
            <p className="fac-de-career">
              彼は 1987
              年に看護職に就きました。彼はスリランカのオープン大学で看護学の理学士
              オーストラリアのアデレード大学で看護学の理学修士、スリジャヤワルダナプラ大学で博士号を取得しています。コロンボ大学に入学する前は、2003
              年から 2005 年までセイシェル共和国の国立保健社会科学研究所 (NIHSS)
              に勤務していました。彼はスリジャヤワルダナプラ大学の医学部で 12
              年間、保健省で 17 年間勤務しました。{" "}
            </p>
            {/* <h3 className="fac-de-pub">連絡先: +947699928636 </h3> */}
            <p className="fac-de-career">メール: dean_fon@kiu.ac.lk</p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">出版物</h3>
            <ul className="fac-de-pub-list">
              <li>
                Warnakulasuriya SSP、Peiris-John RJ、Sathiakumar N、
                Wickremasinghe AR スリランカ国際疫学協会第 10
                回東南アジア地域科学会議: 2010 年 5 月、スリランカ、コロンボ。
              </li>
              <Button
                style={buttonStyles1}
                onClick={() => handleBtnClick("/jp/Research#researchSection")}
              >
                もっと見る
              </Button>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "250px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721387525025_MORI.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <h3 className="fac-de-pos">森本 幸一郎 介護学科長</h3>
            <p className="fac-de-pos1">
              NPO 法人介護支援ネットワーク理事長 社会福祉法人 長寿の森 理事
            </p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">介護学科開設によせて</h3>
            <p className="fac-de-career">
              我が介護学科ではスリランカ国内で初級看護師資格を
              持つ若者に世界で活躍出来る人材として日本的な気遣いや
              気配りを中心に、利用者に最適な介護サービスが出来る
              知識と技術を実地指導と講義を通して学びます。
              現在、外国から日本の介護現場に入職しても、即戦力と
              して活躍することが難しい場面が多々あります。《日本語は
              1年~2年で習得するには限界があります。》私達はその
              壁を乗り越えなくてはいけません。そのためにKIU 大学看
              護学部介護学科では、環境と設備を整え、『日本の福祉機
              材や資材を導入』、必要最低限の日本語教育に重点をおき、
              日本国内の特養で長く外国人介護職員の指導経験のある
              日本人スタッフと共に、介護担当者としての絶対必要事項
              を理解し習得する事と、あくまでも組織の一員で有るとい
              うことの自覚を身に付ける授業を行っています。
            </p>
          </Grid>

          {/* <Grid
            item="item"
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">出版物</h3>
            <ul className="fac-de-pub-list">
              <li>
                Warnakulasuriya SSP、Peiris-John RJ、Sathiakumar N、
                Wickremasinghe AR スリランカ国際疫学協会第 10
                回東南アジア地域科学会議: 2010 年 5 月、スリランカ、コロンボ。
              </li>
              <Button
                style={buttonStyles1}
                onClick={() => handleBtnClick("/jp/Research#researchSection")}
              >
                もっと見る
              </Button>
            </ul>
          </Grid> */}
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "250px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721387262663_profAnoma.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <h3 className="fac-de-pos">アノマ・エディリマンナ博士</h3>
            <p className="fac-de-pos1">経営学部長</p>
            <p className="fac-de-pos2">
              ビジネスおよび経営学博士号 (MSU、マレーシア)
              <br />
              テクノロジー管理学 MBA (UoM、スリランカ)
              <br />
              物理科学学士 (USJP、スリランカ)
              <br />
              ファイナリスト合格 (IBSL)
            </p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">経歴概要</h3>
            <p className="fac-de-career">
              アノマ・エディリマンナ博士は、高等教育および学術管理の分野で 25
              年以上の経験を持つベテラン学者で、現在は経営学部の学部長を務めています。博士の幅広い学歴には、スリジャヤワルダナプラ大学の物理科学の理学士号、モラトゥワ大学の技術管理の
              MBA、マレーシアの経営科学大学のビジネスおよび管理の博士号などがあり、スリランカ銀行協会のファイナリストに合格しています。アノマ博士は、1997
              年にスリジャヤワルダナプラ大学のコンピューター
              インストラクターとしてキャリアを開始し、その後 1998
              年にオーストラリア経営技術大学 (ACBT) の講師に就任しました。ACBT
              での 20
              年以上にわたり、博士は上級講師、研究監督者、試験官、学生アドバイザー、スポーツ担当、および若手講師のメンターとして多大な貢献をしました。彼女は、ピアソンおよびミドルセックス大学と提携して
              ACBT の英国支社を設立する上で極めて重要な役割を果たし、5 年間 BTEC
              HND プログラムの責任者を務めました。2022
              年、アノマ博士は、英国人事管理協会、スリジャヤワルダナプラ大学、ノーサンブリア大学と提携した
              BMS、シドニー工科大学と提携した UTS
              Insearch、オーストラリアのエディスコーワン大学と提携した ECU/ECC
              など、いくつかの権威ある機関で役職に就きました。彼女の教育の専門知識は、ビジネス数学、統計、ビジネス分析、定量的研究方法論、および
              HR
              分析にわたります。高等教育におけるエンタープライズリスク管理に関する研究は、多数の出版物や国際会議での発表につながっています。エディリマンナ博士は、コミュニケーション能力と対人スキル、高度な研究能力、および
              MS Excel、SPSS、Smart PLS、AMOS
              などのデータ分析ツールの熟練度で知られています。彼女の教育、専門能力開発、学術的卓越性への献身は、彼女の広範な教育、研究、管理への貢献に反映されています。
            </p>
            {/* <h3 className="fac-de-pub">連絡先: +94 72 990 7630 </h3> */}
            <p className="fac-de-career">メール: dean_fom@kiu.ac.lk</p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">出版物</h3>
            <ul className="fac-de-pub-list">
              <li>
                Perera, A. A. S.、Rahmat, A.K.、Khatibi, A.、Azam, S.M.F.
                (2022)。スリランカの高等教育機関の業績に対する企業リスク管理の影響を測定する指標の信頼性評価。アジア大学教育ジャーナル
                (AJUE)。第 18 巻、第 1 号、2022 年 1 月。pp (300 – 321)
              </li>
              <Button
                style={buttonStyles1}
                onClick={() => handleBtnClick("/jp/Research#researchSection")}
              >
                もっと見る
              </Button>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "250px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721387313287_kanthi.png"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <h3 className="fac-de-pos">Dr. Kanthi Hettigoda</h3>
            <p className="fac-de-pos1">行動科学学部長</p>
            <p className="fac-de-pos2">
              博士号 (心理学) - 英国 MPhil 臨床心理学 FGS UOC MSc. 組織管理 -
              PGIA UOP B. A (Hons.) 心理学 文学部 UOP
            </p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">キャリアサマリー</h3>
            <p className="fac-de-career">
              カンティ・ヘッティゴダ博士は、著名な公認臨床心理学者であり、現在は行動科学学部の名誉学部長を務めています。豊富な専門的経歴を持つ彼女は、2003
              年にスリランカ財団研究所 (SLFI) の講師としてキャリアを開始し、2013
              年まで心理学の分野に大きく貢献しました。SLFI
              での在職期間の後、カンティ・ヘッティゴダ博士は、2013 年から 2022
              年まで、スリランカ海軍の少佐というやりがいのある役職に就き、リーダーシップスキルと献身的な姿勢を発揮しました。2022
              年、カンティ・ヘッティゴダ博士は再び学界に移り、名門ペラデニヤ大学の上級講師に就任しました。行動科学の分野を発展させたいという彼女の熱意は、教育者およびリーダーとしての継続的な貢献に表れています。名誉学部長として、彼女は心理学および行動科学の分野で豊富な経験、専門知識、そして次世代の専門家を育てる情熱を持っています。カンティ・ヘッティゴダ博士のキャリアの歩みは、学問の卓越性、兵役、そして心理学の理解と幸福の発展への確固たる取り組みの見事な融合を反映しています。{" "}
            </p>
            <h3 className="fac-de-pub">連絡先</h3>
            <p className="fac-de-career">メール: dean_fbs@kiu.ac.lk</p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">出版物</h3>
            <ul className="fac-de-pub-list">
              <li>
                Jayasekera, M. M. P. T.、De Silva, N. L.、Edirisinghe, E. M. D.
                T.、Samarawickrama, T.、Sirimanna, S. W. D. R. C.、Hettigoda,
                K、 … & Wijesinghe, R. A. N. K.
                (2023)。スリランカの三次医療センターにおけるポストCOVID症候群に関する前向きコホート研究。
                Scientific Reports、13(1)、1–9
              </li>
              {/* <li></li>
                            <li></li>
                            <li></li>
                            <li></li> */}
              <Button
                style={buttonStyles1}
                onClick={() => handleBtnClick("/jp/Research#researchSection")}
              >
                もっと見る
              </Button>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "250px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721387609681_profWas.jpeg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <h3 className="fac-de-pos">ワサンタ・セネビラトネ教授</h3>
            <p className="fac-de-pos1">法学部名誉学部長</p>
            <p className="fac-de-pos2">コロンボ大学法学修士、弁護士</p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">経歴概要</h3>
            <p className="fac-de-career">
              Wasantha Seneviratne
              教授は現在、スリランカのコロンボ大学法学部の公法および国際法の主任教授を務めています。また、コロンボ大学付属の研究機関である人権研究センターの所長も務めています。国際法、人権法、人道法の教育、研究、出版で約
              28
              年の経験を持つ同教授は、スリランカのさまざまな法学教育機関で学部レベルと大学院レベルの両方で、国際法、国際人道法、人権法に関する専門知識を伝えています。Seneviratne
              教授は、多数の本の章や国内外の雑誌の研究論文を執筆、編集、出版しています。彼女の研究分野には、国際公法の理論、人道的介入、保護責任の概念、紛争後の状況における人道法の継続的な重要性などが含まれます。現在、彼女はコロンボ大学が発行する査読付きジャーナル「スリランカ国際法ジャーナル」の編集長を務めています。学問の世界を超えて、彼女はコミュニティ、特に軍隊と警察のメンバーに知識を積極的に広めています。彼女の貢献は、新憲法制定に関する専門委員会のメンバー、チームリーダー、子供の権利に関する国家監視委員会のメンバー、国家児童保護当局の法改正委員会のメンバーなどの役割にまで及びます。彼女はまた、警察改革に関する元国家運営委員会のメンバーを務めました。特に、彼女は最近ブリル国際出版社によって発売されたアジア国際公法百科事典スリランカ版の州版編集者です。{" "}
            </p>
            <h3 className="fac-de-pub">お問い合わせ</h3>
            <p className="fac-de-career">メール: Dean_fol@kiu.ac.lk</p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">出版物</h3>
            <ul className="fac-de-pub-list">
              <li>
                Wasantha Seneviratne
                他著、「スリランカとその他の管轄区域間の二国間労働協定:
                重要な法的分析」、ISSN: 2706-0268、移民研究開発センター、2023 年
              </li>
              <Button
                style={buttonStyles1}
                onClick={() => handleBtnClick("/jp/Research#researchSection")}
              >
                もっと見る
              </Button>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={1}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "250px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721387744867_Akila.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <h3 className="fac-de-pos">Akila Randika Jayamaha</h3>
            <p className="fac-de-pos1">大学院学部長</p>
            <p className="fac-de-pos2">
              看護学学士（優等学位）（ルフナ）、応用疫学修士
              （ペラデニヤ）看護学修士（KIU）、博士号（4年生、レディング）
              （レスター大学、英国）
            </p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">キャリアサマリー</h3>
            <p className="fac-de-career">
              彼の学問の旅は、2017 年に KIU
              看護学部の試用講師として入社したときに始まりました。長年にわたり、彼は看護学部内でさまざまな役割を担い、看護学部の副学部長、看護学部の学部長代理、看護学部の学部長代理を務めました。2019
              年から 2022 年にかけて、彼は KIU
              の研究ユニットを率いる栄誉に恵まれました。現在は、研究開発部門の最高成長責任者を務めています。2022
              年には、大学院学部長に任命される栄誉に浴しました。{" "}
            </p>
            <h3 className="fac-de-pub">連絡先: 0777614918 </h3>
            <p className="fac-de-career">メール: akila@kiu.ac.lk</p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">出版物</h3>
            <ul className="fac-de-pub-list">
              <li>
                Kalingamudali, Y. T.、Jalini, P.、Jayamaha, A. R.、Jayaratne, D.
                L.
                (2023)。侵襲的医療処置前の消毒剤の使用に関する知識、態度、実践：スリランカの医療専門家による証拠。SAGE
                Open Medicine、 11、1-9。
              </li>
              <Button
                style={buttonStyles1}
                onClick={() => handleBtnClick("/jp/Research#researchSection")}
              >
                詳細を表示
              </Button>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "250px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721387907324_sunimal.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <h3 className="fac-de-pos">
              R. M. S. Rathnayake 博士 (名誉学部長)
            </h3>
            <p className="fac-de-pos1">
              コンピュータサイエンスおよびエンジニアリング学部の名誉学部長
            </p>
            <p className="fac-de-pos2">
              PhD (シンガポール国立大学) BSc エンジニアリング優等学位
              (モラトゥワ)
            </p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">経歴概要</h3>
            <p className="fac-de-career">
              R. M. S. Rathnayake 博士は、コンピュータ
              システムの教育と研究に関心を持つ教育者であり研究者です。特に、クラウド
              コンピューティング、ネットワーク、高性能コンピューティング、セキュリティ、信頼できるコンピューティングに重点を置いています。大学レベルでの教育と研究で
              10
              年以上の経験があり、複数の政府機関や企業機関のコンサルタントとして幅広い経験を持っています。
              <br />
              モラトゥワ大学のコンピュータ サイエンス
              エンジニアリング学部の上級講師としてフルタイムで勤務するほか、モラトゥワ大学の
              IT サービス担当ディレクター、および LK ドメイン
              レジストリのディレクター/役員も務めています。 <br />
              ラトナヤケ博士は、SC、ICPP
              などの有名な国際会議で論文を発表しており、スリランカ技術者協会
              (IESL) の準会員、電気電子技術者協会 (IEEE)
              およびコンピュータ機械協会 (ACM) の正会員です。
            </p>
            <p className="fac-de-career">メール: dean_foc@kiu.ac.lk</p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">出版物</h3>
            <ul className="fac-de-pub-list">
              <li>
                クラウド
                アプリケーションのコスト精度パフォーマンスの特性評価。第 49
                回国際並列処理会議 (ICPP) の議事録:
                ワークショップ、エドモントン、カナダ、2020 年、ACM。
              </li>
              <Button
                style={buttonStyles1}
                onClick={() => handleBtnClick("/jp/Research#researchSection")}
              >
                もっと見る
              </Button>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            sx={{
              ml: "6%",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "250px",
              }}
            >
              <LazyLoadImage
                src="https://kiu-website.s3.ap-southeast-1.amazonaws.com/Resource/1721389873012_Picture2.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="KIU UNI"
              />
            </Box>
            <h3 className="fac-de-pos">ナディーカ・ラナデヴァ氏</h3>
            <p className="fac-de-pos1">健康科学学部長</p>
            <p className="fac-de-pos2">
              理学士（人間生物学）（FMS、USJP）、医学統計学修士（リーディング、UOK）、分子病理学修士（FOM、UOC）、博士号（リーディング）
            </p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">経歴概要</h3>
            <p className="fac-de-career">
              2015
              年に試用講師としてキャリアをスタートし、健康科学部の上級講師に昇進し、現在は健康科学部の学部長という名誉ある地位に就いています。彼女は、人間生物学
              (スリジャヤワルダナプラ大学医学部) と分子病理学
              (コロンボ大学医学部) を専門としています。 2023
              年には、香港大学から精密医療の特別研修のための名誉ある奨学金を授与されました。
              2019 年には、ビル & メリンダ ゲイツ旅行賞を受賞しました。また、KIU
              の生物医学科学部の部長と品質保証センターのディレクターを務めています。彼女の研究対象は、精密医療、がん遺伝学、依存症遺伝学、プロテオミクスです。
            </p>
            {/* <h3 className="fac-de-pub">連絡先: +94718355314</h3> */}
            <p className="fac-de-career">メール: dean_foh@kiu.ac.lk</p>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            sx={{
              ml: "6%",
            }}
          >
            <h3 className="fac-de-pub">出版物</h3>
            <ul className="fac-de-pub-list">
              <li>
                1. DR Perera、ND Ranadeva、K Sirisena、KJ Wijesinghe;
                フラビウイルス病原性におけるNS1タンパク質の役割、ACS感染症10（1）、20-56
              </li>
              <Button
                style={buttonStyles1}
                onClick={() => handleBtnClick("/jp/Research#researchSection")}
              >
                もっと見る
              </Button>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <div className="fac-divider"></div>

      {/* <div className="fac-divider"></div> */}

      {/* <Container>
                <Grid container="container" spacing={2}>

                    <Grid item="item" xs={12} sm={12} md={4} lg={4} xl={4}>
                        <h3 className='fac-de-pub'>Publications</h3>
                        <ul className='fac-de-pub-list'>
                            <li>Publication 1</li>
                            <Button style={buttonStyles1} onClick={() => handleBtnClick('/Research')}>View More</Button>
                        </ul>
                    </Grid>

                    <Grid item="item" xs={12} sm={12} md={5} lg={5} xl={5}>
                        <h3 className='fac-de-pub'>Career Summery</h3>
                        <p className='fac-de-career'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Amet cursus sit amet dictum sit
                            amet. Amet mattis vulputate enim nulla aliquet porttitor lacus luctus. Mi quis
                            hendrerit dolor magna eget est lorem ipsum. Arcu cursus vitae congue mauris
                            rhoncus. Ornare lectus sit amet est placerat in
                        </p>
                        <h3 className='fac-de-pub'>Contact</h3>
                        <p className='fac-de-career'>
                            Email:
                            <br/>
                            Phone:
                        </p>
                    </Grid>

                    <Grid item="item" xs={12} sm={12} md={3} lg={3} xl={3}>

                        <img alt="KIU Uni" 
                            src='https://news.harvard.edu/wp-content/uploads/2015/10/101615_humanitarian_2811_605.jpg'
                            width='100%'
                            className='fac-img'/>
                        <h3 className='fac-de-pos'>Name</h3>
                        <h3 className='fac-de-pos'>Position</h3>
                        <h3 className='fac-de-pos'>Education</h3>

                    </Grid>
                </Grid>
            </Container> */}

      <div className="footer-div">
        <Footer />
      </div>
    </div>
  );
};

export default Faculties;
