import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Faculty.css";

const FOCSE = () => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #f21111", // Blue outline
    color: "#f21111", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const navigate = useNavigate();
  const handleBtnClick = (path) => {
    navigate(path);
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-title">
              Faculty Of Computer Science & Engineering
            </h3>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <p className="fac-descrip">
              Today as Computer Science has become the future of the world, KIU
              is geared to deliver skills and knowledge to go beyond the
              existing system and intends to prepare you for a professional
              career in the IT industry. <br />
              Students will be provided with the perfect environment to gain
              theoretical knowledge in the major areas of Computer Science, and
              they can acquire practical competence in computer programming and
              varied software to develop transferable skills for their career
              opportunities. Further, the students will be taught by eminent
              professors and academics. <br />
              This would result in producing successful graduates in Computer
              Science and Engineering fields thus leading them to be the
              inventors and entrepreneurs of tomorrow.
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
            <h3 className="fac-dean-hed">Dean</h3>
            <p className="fac-descrip1">
              Mr. Chanaka Galpaya
              <br />
              M.Phil., BSc (S. Hons) in Physics
              <br />
              Email: dean_foc@kiu.ac.lk
            </p>
          </Grid>

          <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
          {/* <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
            <h3 className="fac-hod-hed">Head of Administration</h3>
            <p className="fac-descrip1">
              Mr.Shashika Ruwan Bandara
              <br />
              BSc (Hons) Cyber Security Managemen
              <br />
              Email: shashika@kiu.ac.lk
            </p>
          </Grid> */}

          <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
            <h3 className="fac-hod-hed">
              Head of Department
            </h3>
            <p className="fac-descrip1">
              Ms.Nilusha Perera
              <br />
              MSc in IT, BSc (Spe Hons) in IT
              <br />
              Email: nilusha@kiu.ac.lk
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-depts-hed">Departments</h3>
            <ul className="fac-depts-list">
              <li className="fac-depts-list-item">
                Department of Computer Science
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-fas-hed">Facilities</h3>
            <p className="fac-descrip1">
              The state-of-the-art IT network laboratory facility at Sakura
              Campus is specifically designed for Networking and Cybersecurity
              fields to bridge the gap between students' academics and the
              industry. Students gain hands-on experience in network management,
              ethical hacking, penetration testing, and cloud computing,
              supporting them in acquiring a career in the industry relevant to
              networking and cybersecurity. The lab is equipped with
              vendor-specific interconnecting devices, servers, a vast
              collection of operating systems, and software ranging from
              outdated to the latest versions. This comprehensive setup supports
              cybersecurity, network, and cloud lab sessions, test environments,
              and virtual machine management facilities. Additionally, the lab
              features smartboards for lectures, a digital library with an
              extensive collection of materials related to the subjects, and a
              huge collection of IT network tools, including crimping tools,
              keystones, different types of cables, punch-down tools, cable
              testers, and more.
            </p>
          </Grid>
        </Grid>
      </Container>

      {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button style={buttonStyles1} onClick={() => handleBtnClick('/WeOffer')}>View Courses</Button>
                    </Grid>
                </Grid>
            </Container> */}
    </div>
  );
};

export default FOCSE;
