// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.facility {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-x: hidden;
}

.faci-des {
    padding: 1em;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    font-size: 25px;
    color: #77807E;
    font-style: italic;
}

.faci-hed {
    padding: 0.2em;
    font-family: 'Kanit', sans-serif;
    text-align: center;
    font-size: 22px;
    color: #77807E;
    font-weight: bolder;
}`, "",{"version":3,"sources":["webpack://./src/pages/JP/Facilities.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,4BAA4B;IAC5B,sBAAsB;IACtB,2BAA2B;IAC3B,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,qCAAqC;IACrC,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,gCAAgC;IAChC,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,mBAAmB;AACvB","sourcesContent":[".facility {\n    position: relative;\n    width: 100%;\n    height: 100%;\n    background-color: whitesmoke;\n    background-size: cover;\n    background-position: center;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    overflow-x: hidden;\n}\n\n.faci-des {\n    padding: 1em;\n    font-family: 'Montserrat', sans-serif;\n    text-align: center;\n    font-size: 25px;\n    color: #77807E;\n    font-style: italic;\n}\n\n.faci-hed {\n    padding: 0.2em;\n    font-family: 'Kanit', sans-serif;\n    text-align: center;\n    font-size: 22px;\n    color: #77807E;\n    font-weight: bolder;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
