import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "./Ugcrs.css";
import BAPDF from "../../../brochure/sample.pdf";
import Marketing from "../../../assets/Marketing.png";

const MK = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              マーケティング経営学士（優等学位）
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="ug-crs-des1">
              KIU
              スリランカのマーケティング学部へようこそ！当校のマーケティング学位プログラムは、学生の戦略的思考を養い、変化の激しいマーケティング業界で成功するために必要なツールを提供するように特別に設計されています。各重要科目を網羅する当校の広範なカリキュラムにより、学生は有能で知識豊富なマーケティング担当者になります。インタラクティブな講義は学生の積極的な参加を促し、実践的な学習環境を提供します。
              <br />
              当校は、卒業生が幅広いスキルを身に付けられるように、さまざまな評価手法を使用しています。学生は、特定のモジュール内でマーケティングビジネスに関連する実践的なスキルを習得できる実践的なワークショップに参加することで、理論的な知識を現実の状況で活用できます。
              <br />
              キャップストーン研究プロジェクトは、最終学年の学生の必須科目であり、教育の重要な要素となります。このプロジェクトにより、学生は実際のマーケティング問題に触れ、分析能力と問題解決能力を磨き、ダイナミックなマーケティング分野でのキャリアを成功させるために必要な自信を育むことができます。
              <br />
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={Marketing}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "60%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des2">
              <p>
                レベル: SLQF 6
                <br />
                期間: 4 年
                <br />
                単位数: 120
                <br />
                入学時期: 3 月と 9 月
                <br />
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des3">
              <h3>キャリア チャンス</h3>
              <ol>
                <li>マーケティング スペシャリスト/コーディネーター</li>
                <li>デジタル マーケティング アナリスト/マネージャー</li>
                <li>ブランド マネージャー</li>
                <li>大学の学術職</li>
                <li>営業担当者/営業マネージャー</li>
              </ol>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>入学要件</h5>

              <ul>
                <li>
                  GCE アドバンス レベル (A/L) の 3 つの合格
                  (いずれかのストリーム){" "}
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des5">
              <h5>プログラム構造</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>1年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>第 1 学期</h5>
              <ul>
                <li>経営原則と実践</li>
                <li>ビジネスと環境</li>
                <li>ビジネス数学</li>
                <li>ミクロ経済学</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>第 2 学期</h5>

              <ul>
                <li>社会学と心理学の要素</li>
                <li>会計の原則</li>
                <li>マクロ経済学</li>
                <li>ビジネス コミュニケーション</li>
                <li>ビジネス統計</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>2年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>第 1 学期</h5>
              <ul>
                <li>経営情報システム</li>
                <li>管理会計</li>
                <li>組織行動</li>
                <li>マーケティング管理</li>
                <li>人的資源管理</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>第 2 学期</h5>

              <ul>
                <li>組織理論</li>
                <li>財務管理</li>
                <li>商法および産業法</li>
                <li>課税</li>
                <li>ビジネス倫理と企業責任</li>
                <li>マーケティング コミュニケーション (選択科目)</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>3年生</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>第 1 学期</h5>
              <ul>
                <li>監査</li>
                <li>コンピュータ ベースのビジネス統計</li>
                <li>人格開発</li>
                <li>起業家精神と中小企業</li>
                <li>組織変更と開発</li>
                <li>デジタル マーケティング (選択科目)</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>第 2 学期</h5>

              <ul>
                <li>産業研修</li>
                <li>戦略管理</li>
                <li>紛争管理</li>
                <li>知識管理</li>
                <li>ブランド管理 (選択科目)</li>
                <li>顧客関係管理 (選択科目)</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>4年生</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>第 1 学期</h5>
              <ul>
                <li>研究方法と学術論文執筆</li>
                <li>文化と管理</li>
                <li>サービス マーケティング</li>
                <li>サプライ チェーン管理</li>
                <li>広告管理</li>
                <li>製品計画と価格戦略 (選択科目)</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>第 2 学期</h5>

              <ul>
                <li>研究プロジェクト</li>
                <li>オペレーション管理</li>
                <li>消費者行動</li>
                <li>消費者保護法 (選択科目)</li>
                <li>戦略的マーケティング (選択科目)</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>
                コース終了時までに、学習者は次のことができるようになります:{" "}
              </h5>

              <ul>
                <li>
                  マーケティングの原則、概念、理論を理解し、マーケティングやその他の関連分野を説明します。{" "}
                </li>
                <li>
                  公式および非公式の学習、観察、調査を通じて、ダイナミックなビジネス環境における予測されるマーケティングの機会と課題に関する知識を構築します。{" "}
                </li>
                <li>
                  創造的で革新的なマーケティングソリューションでマーケティングの課題に積極的に対応します。{" "}
                </li>
                <li>
                  グループダイナミクスを分析し、権限を与えられた作業チームを構築し、共通のビジョンを作成し、対立を解決することで、あらゆるビジネスコンテキストでチームとして作業するスキルを開発します。{" "}
                </li>
                <li>
                  マーケティングの専門家になるために不可欠な適切なリーダーシップと組織スキルを構築します。{" "}
                </li>
                <li>
                  行動を通じて、マーケティングの実践における倫理基準を維持するための理解とコミットメントを構築します。{" "}
                </li>
                KIU
                に参加して、人事管理とマーケティングの世界を探索しましょう。あなたのキャリアを形作ります。
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/7"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>適用する</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              パンフレットをダウンロード
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              近い
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default MK;
