// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-x: hidden;
}

.news-des {
    padding: 1em;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    font-size: 35px;
    color: #77807E;
    font-style: italic;
}

.news-horizontal-divider {
    margin-top: 5%;
    margin-bottom: 5%;
    border: 0;
    border-top: 2px solid black;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.close-new-draw{
    font-size: 35px;
    cursor: pointer;
    color: white;
    transition: color 0.3s ease;
}

.close-new-draw:hover{
    color: red;
}
`, "",{"version":3,"sources":["webpack://./src/pages/News.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,4BAA4B;IAC5B,sBAAsB;IACtB,2BAA2B;IAC3B,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,qCAAqC;IACrC,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,SAAS;IACT,2BAA2B;IAC3B,UAAU;IACV,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,eAAe;IACf,YAAY;IACZ,2BAA2B;AAC/B;;AAEA;IACI,UAAU;AACd","sourcesContent":[".news {\n    position: relative;\n    width: 100%;\n    height: 100%;\n    background-color: whitesmoke;\n    background-size: cover;\n    background-position: center;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    overflow-x: hidden;\n}\n\n.news-des {\n    padding: 1em;\n    font-family: 'Montserrat', sans-serif;\n    text-align: center;\n    font-size: 35px;\n    color: #77807E;\n    font-style: italic;\n}\n\n.news-horizontal-divider {\n    margin-top: 5%;\n    margin-bottom: 5%;\n    border: 0;\n    border-top: 2px solid black;\n    width: 80%;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n.close-new-draw{\n    font-size: 35px;\n    cursor: pointer;\n    color: white;\n    transition: color 0.3s ease;\n}\n\n.close-new-draw:hover{\n    color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
