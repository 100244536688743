import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Faculty.css";

const FOM = () => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #f21111", // Blue outline
    color: "#f21111", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const navigate = useNavigate();
  const handleBtnClick = (path) => {
    navigate(path);
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-title">Faculty Of Management</h3>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <p className="fac-descrip">
              The Faculty of Management at KIU is dedicated to cultivating,
              empowering, and guiding management graduates to embody the right
              attitude, leadership, and cooperativeness. A KIU management
              graduate is equipped with sharp cognitive abilities and takes
              responsibility in serving tomorrow’s businesses with knowledge,
              skills, and research abilities. <br />
              Our Faculty of Management has structured its educational setting
              with the goal of providing students with the opportunity to learn
              from distinguished and skillful professors, lecturers, mentors,
              and business industry experts. These professionals are committed
              to helping students to achieve their goals. <br />
              The faculty aims to create graduates with outstanding
              personalities who will become accounting, marketing, human
              resource, and business analytics professionals in their respective
              fields of specialization. The degree programmes offered by our
              faculty in Accounting, Marketing, Human Resources, and Business
              Analytics are approved by Ministry of Higher Education and
              recognized by University Grant Commission in Sri Lanka.
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-dean-hed">Dean</h3>
            <p className="fac-descrip1">
              Dr. Anoma Edirimanna
              <br />
              PhD in Business and Management (MSU, Malaysia) MBA in Management
              of Technology (UoM, Sri Lanka) BSc in Physical Science (USJP, Sri
              Lanka) Passed Finalist (IBSL)
              <br />
              Email: dean_fom@kiu.ac.lk
            </p>
          </Grid>

          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-hod-hed">Heads of Departments</h3>
            <p className="fac-descrip1">
              Department of Accounting - Ms. Thakshila Dushyanthi
              <br />
              MBA in Finance (Reading)University of Sri Jayewardenepura B.Sc.
              <br />
              Honours in Financial Management, Sabaragamuwa University of Sri
              <br />
              Lanka Passed Finalist (Institute of Bankers of Sri Lanka)
              <br />
              Email: thakshila@kiu.ac.lk
            </p>

            <p className="fac-descrip1">
              Department of Human Resource - Ms. Ishara Herath
              <br />
              MBA in Economics National Graduate Institute for Policy Studies
              (GRIPS) Japan
              <br /> B.Sc. in Public Management (Special) University of Sri
              Jayewardenepura
              <br />
              Email: iherath@kiu.ac.lk
            </p>

            <Grid container="container" spacing={2}>
              <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                <p className="fac-descrip1">
                  Assistant Head of the Department of Decision Science - Ms.
                  Iruni Satharasinghe
                  <br />
                  Reading Master of Business Analytics (UOM) <br />
                  BSc. Human Resource Management (Sp.) (USJP) <br />
                  Email: iruni@kiu.ac.lk
                </p>
              </Grid>
            </Grid>

            <p className="fac-descrip1">
              Department of Marketing - Ms. Arundika Gunarathna
              <br />
              MBA (Reading) (USJ), BSc (Hons) Marketing Management (SUSL)
              <br />
              Email: arundika@kiu.ac.lk
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-depts-hed">Departments</h3>
            <ul className="fac-depts-list">
              <li className="fac-depts-list-item">
                Department of Human Resource
              </li>
              <li className="fac-depts-list-item">Department of Marketing</li>
              <li className="fac-depts-list-item">Department of Accounting</li>
              <li className="fac-depts-list-item">
                Department of Decision Science
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-fas-hed">Program Also Includes</h3>
            <p className="fac-descrip1">
              <strong>Field Visits: </strong>
              <br />
              Learning at the Faculty of Management goes beyond the classroom.
              The faculty organizes regular Field Visits to corporate offices,
              manufacturing plants, and other relevant sites, offering students
              valuable insights into practical applications of management
              theories. These experiences bridge the gap between theory and
              practice.
              <br />
              <br />
              <strong>Workshop Series: </strong>
              <br />
              Our Workshop Series covers a spectrum of crucial skills, including
              leadership development, technical proficiency, personality
              enhancement, professional etiquette, effective communication,
              motivation strategies, career development insights, team-building
              skills, and the nuances of social competence in the business
              domain. Led by industry experts, these workshops are tailored to
              equip students with the multifaceted abilities essential for
              success in today's competitive landscape
              <br />
              <br />
              <strong>Industrial Research: </strong>
              <br />
              Faculty engages in groundbreaking Industrial Research across
              various management disciplines, pushing the boundaries of
              knowledge and addressing real-world challenges. Collaborating with
              industry leaders, our industrial research projects are designed to
              bring about innovative solutions, inform best practices, and
              contribute to the evolution of management theory. This
              transformative research fosters an environment where students
              actively participate in shaping the future of management.
              <br />
              <br />
              <strong>Annual Career Fair Programmes: </strong>
              <br />
              Faculty organizes annual Career Fair Programmes to bridge the gap
              between academia and the professional world. These fairs provide a
              unique platform for students to connect with leading employers,
              industry experts, and recruiters. With a focus on diverse fields
              within management, these programs offer invaluable networking
              opportunities, allowing students to explore potential career
              paths, gain insights into industry trends, and secure internships
              or employment.
              <br />
              <br />
            </p>
          </Grid>
        </Grid>
      </Container>

      {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button style={buttonStyles1} onClick={() => handleBtnClick('/WeOffer')}>View Courses</Button>
                    </Grid>
                </Grid>
            </Container> */}
    </div>
  );
};

export default FOM;
