import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "./Pgcrs.css";
import BAPDF from "../../../brochure/MBA Brochure.pdf";
import Flow from "../../../assets/flow.png";
import structure from "../../../assets/structure.png";
import MBA from "../../../assets/MBA.JPG";

const Mba = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              経営学修士（SLQF 10）
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="pg-crs-des1">
              経営学修士（SLQF
              10）は、ビジネスと経営のさまざまな側面に対する深い理解を個人に提供するために綿密に作成された大学院レベルの学位プログラムです。MBAは、最も人気があり、世界的に尊敬されている大学院の学位の1つです。
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={MBA}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "80%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des2">
              <h3>MBA 戦略で期待できること</h3>
              <p>
                MBA
                戦略の卒業生は、ビジネスの成長の測定可能性、説明責任、および拡大戦略を習得します。市場調査の実践的なスキル、プラットフォーム間での戦略の実行に対する自信、ソーシャル
                メディア
                ツールの熟練度を身に付けます。マーケティング活動を効果的に計画、実装、管理、および測定する準備を整えます。
              </p>
              <p>
                <strong>連絡先情報</strong> - Imeshika Dilrukshi 氏 <br />
                imeshika@kiu.ac.lk <br />
                0716297258{" "}
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des3">
              <h3>KIU の MBA の特徴</h3>
              <ul>
                <li>毎週のインタラクティブ セッション</li>
                <li>2 年間</li>
                <li>60 単位</li>
                <li>5 つの実際のプロジェクトを独自に実施</li>
                <li>配信方法は英語です</li>
                <li>アクティブ ラーニング方式</li>
                <li>オンライン学習</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des4">
              <h5>入学要件</h5>
              <ul>
                <li>
                  認定大学/機関で取得したあらゆる分野の学士号と、資格取得後 2
                  年間の関連業務経験。
                </li>
                <li>
                  認定大学/機関で取得した 1
                  年以上またはそれ以上の分野の大学院卒業証書と、少なくとも 2
                  年間の関連業務経験。
                </li>

                <li>
                  以下のいずれかの専門資格と、資格取得後 2
                  年間以上の関連業務経験。スリランカ マーケティング協会 (SLIM)
                  が授与するマーケティング ディプロマ
                </li>

                <li>人事管理協会が授与する人事管理の専門資格。</li>

                <li>英語能力は必須です。</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des4">
              <h5>経営学修士への道</h5>

              <img
                alt="KIU Uni"
                src={Flow}
                style={{
                  width: "100%",
                }}
              />
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des4">
              <img
                alt="KIU Uni"
                src={structure}
                style={{
                  width: "100%",
                }}
              />
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des5">
              <h5>プログラム構造</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <h5>基礎コース</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <ul>
                <li>
                  FND 1001 - 経営の原則
                  <ul>
                    <li>必須</li>
                    <li>20 時間</li>
                  </ul>
                </li>
                <li>
                  FND 1002 - 経済学入門
                  <ul>
                    <li>必須</li>
                    <li>20 時間</li>
                  </ul>
                </li>
                <li>
                  FND 1003 - 会計と財務の基礎
                  <ul>
                    <li>必須</li>
                    <li>20 時間</li>
                  </ul>
                </li>
                <li>
                  FND 1004 - ビジネス数学と統計
                  <ul>
                    <li>必須</li>
                    <li>20 時間</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <h5>
                MBA (経営戦略) プログラム構造 (SLQF レベル 10) [プロジェクト
                レポート付き]
              </h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <h5>1年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <h5>セメスター 1</h5>
              <ul>
                <li>
                  MBA1201 - 経営プロセスと実践
                  <ul>
                    <li>必須</li>
                    <li>2 単位</li>
                    <li>30 時間</li>
                  </ul>
                </li>
                <li>
                  MBA1202 - 経営判断のための会計
                  <ul>
                    <li>必須</li>
                    <li>2 単位</li>
                    <li>30 時間</li>
                  </ul>
                </li>
                <li>
                  MBA1203 - 経営判断のための経済学
                  <ul>
                    <li>必須</li>
                    <li>2 単位</li>
                    <li>30 時間</li>
                  </ul>
                </li>
                <li>
                  MBA1204 - ビジネス統計
                  <ul>
                    <li>必須</li>
                    <li>2 単位</li>
                    <li>30時間</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des8">
              <h5>セメスター 2</h5>

              <ul>
                <li>
                  MBA1305 - ビジネス ファイナンス*
                  <ul>
                    <li>必須</li>
                    <li>3 単位</li>
                    <li>45 時間</li>
                  </ul>
                </li>
                <li>
                  MBA1306 - マーケティング管理
                  <ul>
                    <li>必須</li>
                    <li>3 単位</li>
                    <li>45 時間</li>
                  </ul>
                </li>
                <li>
                  MBA1307 - 人材管理
                  <ul>
                    <li>必須</li>
                    <li>3 単位</li>
                    <li>45 時間</li>
                  </ul>
                </li>
                <li>
                  MBA1308 - テクノロジーおよびオペレーション管理*
                  <ul>
                    <li>必須</li>
                    <li>3 単位</li>
                    <li>45 時間</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <h5>セメスター 3</h5>
              <ul>
                <li>
                  MBA1209 - アカデミック ライティング
                  <ul>
                    <li>必須</li>
                    <li>2 単位</li>
                    <li>30 時間</li>
                  </ul>
                </li>
                <li>
                  MBA1210 - 産業心理学
                  <ul>
                    <li>必須</li>
                    <li>2 単位</li>
                    <li>30 時間</li>
                  </ul>
                </li>
                <li>
                  MBA1211 - 組織行動
                  <ul>
                    <li>必須</li>
                    <li>2 単位</li>
                    <li>30 時間</li>
                  </ul>
                </li>
                <li>
                  MBA1212 - ビジネス インテリジェンスのための情報管理*
                  <ul>
                    <li>必須</li>
                    <li>2 単位</li>
                    <li>30時間</li>
                  </ul>
                </li>
                <li>
                  MBA1213 - 国際フィールド訪問とワークショップ
                  <ul>
                    <li>必須</li>
                    <li>2 単位</li>
                    <li>30 時間</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <h5>2年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <h5>セメスター 1</h5>
              <ul>
                <li>
                  MBA2301 - 戦略管理*
                  <ul>
                    <li>必須</li>
                    <li>3 単位</li>
                    <li>45 時間</li>
                  </ul>
                </li>
                <li>
                  MBA2302 - グリーン管理と倫理*
                  <ul>
                    <li>必須</li>
                    <li>3 単位</li>
                    <li>45 時間</li>
                  </ul>
                </li>
                <li>
                  MBA2203 - ビジネス法とコンプライアンス
                  <ul>
                    <li>必須</li>
                    <li>2 単位</li>
                    <li>30 時間</li>
                  </ul>
                </li>
                <li>
                  MBA2204 - グローバル ビジネス戦略*
                  <ul>
                    <li>必須</li>
                    <li>2 単位</li>
                    <li>30 時間</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des8">
              <h5>セメスター 2</h5>

              <ul>
                <li>
                  MBA2305 - 現代のビジネス課題*
                  <ul>
                    <li>必須</li>
                    <li>3 単位</li>
                    <li>45 時間</li>
                  </ul>
                </li>
                <li>
                  MBA2306 - サプライ チェーンとロジスティクス戦略*
                  <ul>
                    <li>必須</li>
                    <li>3 単位</li>
                    <li>45 時間</li>
                  </ul>
                </li>
                <li>
                  MBA2307 - ベンチャー創出戦略*
                  <ul>
                    <li>必須</li>
                    <li>3 単位</li>
                    <li>45 時間</li>
                  </ul>
                </li>
                <li>
                  MBA2308 - プロジェクトとイベント管理
                  <ul>
                    <li>必須</li>
                    <li>3クレジット</li>
                    <li>45 時間</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <h5>セメスター 3</h5>
              <ul>
                <li>
                  MBA2809 - 経営プロジェクト レポート*
                  <ul>
                    <li>8 単位</li>
                    <li>800 時間</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <h5>MBA プログラム構造 (SLQF レベル 10 合格用) [論文]</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <h5>1年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <h5>セメスター 1</h5>
              <ul>
                <li>
                  MBA1201 - 経営プロセスと実践
                  <ul>
                    <li>必須</li>
                    <li>2 単位</li>
                    <li>30 時間</li>
                  </ul>
                </li>
                <li>
                  MBA1202 - 経営判断のための会計
                  <ul>
                    <li>必須</li>
                    <li>2 単位</li>
                    <li>30 時間</li>
                  </ul>
                </li>
                <li>
                  MBA1203 - 経営判断のための経済学
                  <ul>
                    <li>必須</li>
                    <li>2 単位</li>
                    <li>30 時間</li>
                  </ul>
                </li>
                <li>
                  MBA1204 - ビジネス統計
                  <ul>
                    <li>必須</li>
                    <li>2 単位</li>
                    <li>30時間</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des8">
              <h5>セメスター 2</h5>

              <ul>
                <li>
                  MBA1305 - ビジネス ファイナンス*
                  <ul>
                    <li>必須</li>
                    <li>3 単位</li>
                    <li>45 時間</li>
                  </ul>
                </li>
                <li>
                  MBA1306 - マーケティング管理
                  <ul>
                    <li>必須</li>
                    <li>3 単位</li>
                    <li>45 時間</li>
                  </ul>
                </li>
                <li>
                  MBA1307 - 人材管理
                  <ul>
                    <li>必須</li>
                    <li>3 単位</li>
                    <li>45 時間</li>
                  </ul>
                </li>
                <li>
                  MBA1308 - テクノロジーおよびオペレーション管理*
                  <ul>
                    <li>必須</li>
                    <li>3 単位</li>
                    <li>45 時間</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <h5>セメスター 3</h5>
              <ul>
                <li>
                  MBA1209 - アカデミック ライティング
                  <ul>
                    <li>必須</li>
                    <li>2 単位</li>
                    <li>30 時間</li>
                  </ul>
                </li>
                <li>
                  MBA1210 - 産業心理学
                  <ul>
                    <li>必須</li>
                    <li>2 単位</li>
                    <li>30 時間</li>
                  </ul>
                </li>
                <li>
                  MBA1211 - 組織行動
                  <ul>
                    <li>必須</li>
                    <li>2 単位</li>
                    <li>30 時間</li>
                  </ul>
                </li>
                <li>
                  MBA1212 - ビジネス インテリジェンスのための情報管理*
                  <ul>
                    <li>必須</li>
                    <li>2 単位</li>
                    <li>30時間</li>
                  </ul>
                </li>
                <li>
                  MBA1213 - 国際フィールド訪問とワークショップ
                  <ul>
                    <li>必須</li>
                    <li>2 単位</li>
                    <li>30 時間</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <h5>2年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <h5>セメスター 1</h5>
              <ul>
                <li>
                  MBA2301 - 戦略管理*
                  <ul>
                    <li>必須</li>
                    <li>3 単位</li>
                    <li>45 時間</li>
                  </ul>
                </li>
                <li>
                  MBA2302 - グリーン管理と倫理*
                  <ul>
                    <li>必須</li>
                    <li>3 単位</li>
                    <li>45 時間</li>
                  </ul>
                </li>
                <li>
                  MBA2203 - ビジネス法とコンプライアンス
                  <ul>
                    <li>必須</li>
                    <li>2 単位</li>
                    <li>30 時間</li>
                  </ul>
                </li>
                <li>
                  MBA2204 - グローバル ビジネス戦略*
                  <ul>
                    <li>必須</li>
                    <li>2 単位</li>
                    <li>30 時間</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des8">
              <h5>セメスター 2</h5>

              <ul>
                <li>
                  MBA2310 - 研究方法論
                  <ul>
                    <li>必修</li>
                    <li>3 単位</li>
                    <li>45 時間</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <h5>セメスター 3</h5>
              <ul>
                <li>
                  MBA21711 - 論文*
                  <ul>
                    <li>17 単位</li>
                    <li>1700 時間</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://forms.office.com/Pages/ResponsePage.aspx?id=K4FZ8Bpt40u83KOC56hNc8lTY9vfkp1HisXQmlz-ce1UMTI1NVJLSllHOFdDQTlBN1NDQ0RQQkdSTC4u"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>適用する</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled="disabled"
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              パンフレットをダウンロード
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              近い
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Mba;
