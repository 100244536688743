import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "./Ugcrs.css";
import ACUimg from "../../assets/ACU.JPG";
import BAPDF from "../../brochure/sample.pdf";

const MSA = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Bachelor of Science Honours in Medical Science in Acupuncture
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="ug-crs-des1">
              Experience the artistry of acupuncture through our specialized
              degree program which is meticulously crafted to shape adept
              healthcare professionals. Drawing from the wealth of Traditional
              Chinese Medicine, the curriculum sharpens expertise in the precise
              application of acupuncture techniques. <br />
              The program covers core subjects such as Anatomy and Biochemistry,
              alongside in-depth exploration of specialized fields like pain
              management. <br />
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={ACUimg}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "60%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des2">
              <p>
                Level: SLQF 6
                <br />
                Duration: 4 years
                <br />
                Credit Points: 120
                <br />
                Intakes: March and September
                <br />
                Contact: 0773628663 / acupuncture@kiu.ac.lk
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des3">
              <h3>Career Opportunities</h3>
              <ol>
                <li>
                  Work as Professional Acupuncturists at
                  <ul>
                    <li>Holistic health centers </li>
                    <li>Private acupuncture clinics </li>
                    <li>Rehabilitation centers </li>
                  </ul>
                </li>
                <li>Researchers</li>
                <li>Academic positions</li>
                <li>Cruise ship acupuncture therapists</li>
                <li>Acupuncturists in international related settings</li>
                <li>Post graduate education opportunities</li>
              </ol>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>Entry Requirements</h5>

              <ul>
                <li>
                Three passes in Advanced Level (A/L) in Biological Science stream
                </li>
                <li>
                An equivalent qualification for international students.
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des5">
              <h5>Program Structure</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={16}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 1</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <ul>
                <li>Human Anatomy</li>
                <li>Human Physiology</li>
                <li>Biochemistry</li>
                <li>Psychology and Introduction to Sociology</li>
                <li>Ethics and Legal Issues</li>
                <li>Interpersonal Communication</li>
                <li>First Aid and CPR</li>
                <li>Epidemiology</li>
                <li>Nutrition and Dietetics</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 2</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <ul>
                <li>Microbiology and Parasitology</li>
                <li>Pathology</li>
                <li>Public Health</li>
                <li>Pharmacotherapeutics</li>
                <li>Biostatistics</li>
                <li>Basic Medical, Psychiatric, and Pediatric Conditions</li>
                <li>Gynecology and Obstetrics</li>
                <li>Basic Surgical, Eye, and ENT Conditions</li>
                <li>Radiology and Diagnostic Imaging</li>
                <li>Philosophy and Theories of Acupuncture</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 3</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <ul>
                <li>Research Methodology</li>
                <li>Acupuncture-Meridians, Aetiology, and Pathogenesis</li>
                <li>Acupuncture-Diagnostics and Techniques</li>
                <li>Clinical Observation</li>
                <li>Massage Therapy</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>Year 4</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <ul>
                <li>Acupuncture Therapeutics I</li>
                <li>Acupuncture Therapeutics II</li>
                <li>Clinical Practice</li>
                <li>Dissertation</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>By the end of the course, learners will be able to: </h5>

              <ul>
                <li>
                  Describe normal human structures and functions relevant to
                  Acupuncture.
                </li>
                <li>
                  Diagnose diseases according to Acupuncture principles and
                  interpret clinical laboratory findings.
                </li>
                <li>
                  Provide nutritional, dietary, and preventive medical advice.
                </li>
                <li>
                  Apply Traditional Chinese Medicine (TCM) and western medical
                  terminologies effectively.
                </li>
                <li>
                  Communicate with patients, health professionals, regulatory
                  bodies, instrument suppliers, and the public.
                </li>
                <li>Practice within regulatory and ethical frameworks.</li>
                <li>
                  Identify key business issues and utilize professional
                  resources appropriately.
                </li>
                <li>
                  Understand and acquire new knowledge from clinical research.
                </li>
                <li>
                  Critically review research publications relevant to
                  Acupuncture.
                </li>
                <li>
                  Review and monitor patient health, modifying treatment
                  accordingly.
                </li>
                Our graduates emerge with esteemed credentials, poised to excel
                as medical acupuncturists in private practices, hospitals, and
                international healthcare environments.
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/3"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>Apply</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              Download Brochure
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default MSA;
