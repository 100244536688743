import React from "react";
import "./footer.css";
import { FaFacebook } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { BsLinkedin } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="footer">
      <div className="sb_footer section_padding">
        <div className="sb_footer-Links">
          <div className="sb_footer-Links_div">
            <h4>KIUを探索する</h4>
            <Link to="/News" className="footer-link">
              <p>ニュース＆イベント</p>
            </Link>
            {/* <Link to='#' className='footer-link'><p>Student Support</p></Link>
                    <Link to='#' className='footer-link'><p>Career Guidaance</p></Link> */}
            <Link to="/Facilities" className="footer-link">
              <p>KIU介護士研修センター</p>
            </Link>
            <Link to="/Research" className="footer-link">
              <p>研究センター</p>
            </Link>
          </div>

          <div className="sb_footer-Links_div">
            <h4>ポータル</h4>
            <Link to="https://erpv2std.kiu.lk" className="footer-link">
              <p>学生ポータル</p>
            </Link>
            <Link to="https://undlms.kiu.ac.lk" className="footer-link">
              <p>KIU 学部 LMS</p>
            </Link>
            <Link to="https://portal.office.com" className="footer-link">
              <p>Office 365 ログイン</p>
            </Link>
            <Link to="http://library.kiu.lk/" className="footer-link">
              <p>図書館ポータル</p>
            </Link>
          </div>

          <div className="sb_footer-Links_div">
            <h4>コミットメントの</h4>
            <Link to="/About" className="footer-link">
              <p>私たちのビジョン</p>
            </Link>
            <Link to="/About" className="footer-link">
              <p>我々の使命</p>
            </Link>
            <Link to="/About" className="footer-link">
              <p>KIUの目的</p>
            </Link>
            <Link to="/Faculties" className="footer-link">
              <p>私たちのチーム</p>
            </Link>
            <Link to="/About" className="footer-link">
              <p>学生の声</p>
            </Link>
          </div>

          <div className="sb_footer-Links_div">
            <h4>フォローしてください</h4>
            <div className="socialmedia">
              <Link
                to="https://www.facebook.com/kiusrilanka/"
                style={{ color: "white" }}
              >
                <p>
                  <FaFacebook className="footer-icon" />
                </p>
              </Link>
              <Link
                to="https://www.youtube.com/@KIUSrilanka"
                style={{ color: "white" }}
              >
                <p>
                  <FaYoutube className="footer-icon" />
                </p>
              </Link>
              <Link
                to="https://www.linkedin.com/school/kiuedu/"
                style={{ color: "white" }}
              >
                <p>
                  <BsLinkedin className="footer-icon" />
                </p>
              </Link>
              <Link
                to="https://www.instagram.com/kiu_sri_lanka/"
                style={{ color: "white" }}
              >
                <p>
                  <BsInstagram className="footer-icon" />
                </p>
              </Link>
            </div>
            <div className="foot-address">
              249/1, Malabe Road,
              <br />
              Thalangama North,
              <br />
              Koswatta, <br />
              Battaramulla
              <br />
              Colombo Sri Lanka
              <br />
              +94 (0)11 2741 878
              <br />
              +94 (0)76 7334744
              <br />
              info@kiu.ac.lk
            </div>
          </div>
        </div>

        <hr></hr>
        <div className="sb_footer-below">
          <div className="sb_footer-copyright">
            <p>
              @{new Date().getFullYear()} KIU ソフトウェア部門。無断転載禁止。
            </p>
          </div>

          <div className="sb_footer-below-Links">
            <Link to="#" className="footer-link">
              <p>利用規約</p>
            </Link>
            <Link to="#" className="footer-link">
              <p>プライバシー</p>
            </Link>
            <Link to="#" className="footer-link">
              <p>安全</p>
            </Link>
            <Link to="#" className="footer-link">
              <p>クッキー宣言</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
