import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "../postgraduate/Pgcrs.css";
import BAPDF from "../../../brochure/sample.pdf";
import CFMAimg from "../../../assets/CFMA.jpg";

const CFMA = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              財務会計と管理会計の認定コース
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="pg-crs-des1">
              「会計で卓越するための理想的な道！」
              <br />
              財務および管理会計の証明書は、財務と会計の両方のスキルとキャリアアップを必要とする人々に強力な基盤を提供することを目的としています。プログラムを正常に完了すると、参加者は財務および管理会計に関連する基本理論を説明できるようになります。
              <br />
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={CFMAimg}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "80%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des2">
              <p>
                期間: 3 か月コース
                <br />
                受講開始時期: 毎年 3 月と 9 月の 2 回受講開始予定
                <br />
                配信媒体: 英語 (シンハラ語もサポート)
                <br />
                配信モード: Microsoft Teams 経由
                <br />
                お問い合わせ先: 077 470 9089 / 075 458 9659
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des3">
              <h3>入学要件</h3>
              <ul>
                <li>G.C.E. A/L 試験/ G.C.E O/L 試験に合格した方</li>
                または
                <li>会計分野でのキャリア チャンスを求めている方</li>
                または
                <li>同様の職務で 6 か月以上の実務経験がある候補者</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/39"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>適用する</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              パンフレットをダウンロード
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              近い
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CFMA;
