// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-title{
    font-size: 30px;
    font-weight: bold;
    color: white;
    font-family: 'Kanit', sans-serif;
    text-align: center;
    margin-bottom: 5%;
}

.new-date{
    font-size: 15px;
    color: white;
    font-family: 'Kanit', sans-serif;
    text-align: left;
    margin-bottom: 5%;
}

.new-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.new-descrip{
    font-size: 18px;
    color: #b4afaf;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    margin-bottom: 5%;
}`, "",{"version":3,"sources":["webpack://./src/components/news/News.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,gCAAgC;IAChC,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,gCAAgC;IAChC,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,qCAAqC;IACrC,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".new-title{\n    font-size: 30px;\n    font-weight: bold;\n    color: white;\n    font-family: 'Kanit', sans-serif;\n    text-align: center;\n    margin-bottom: 5%;\n}\n\n.new-date{\n    font-size: 15px;\n    color: white;\n    font-family: 'Kanit', sans-serif;\n    text-align: left;\n    margin-bottom: 5%;\n}\n\n.new-img{\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n.new-descrip{\n    font-size: 18px;\n    color: #b4afaf;\n    font-family: 'Montserrat', sans-serif;\n    text-align: left;\n    margin-bottom: 5%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
