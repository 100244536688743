import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "./Ugcrs.css";
import BAPDF from "../../../brochure/sample.pdf";
import nurse from "../../../assets/nursing.jpeg";

const NUR = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              看護学士（優等学位）
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="ug-crs-des1">
              KIU
              は、高等教育省の認可を受け、大学助成委員会の承認を受けた看護学の理学士号を提供しています。看護学の学位プログラムには、常勤講師と非常勤講師の両方を含む、非常に優秀で経験豊富な講師陣が揃っています。KIU
              は、図書館、臨床実験室、科学実験室、カフェテリア、体育館、レクリエーション施設など、数多くの学生用施設を提供しています。すべての講義室には、効果的な教育と学習のための最新の技術と設備が備わっています。さらに、KIU
              には授乳中の母親のニーズに対応する特別な施設もあります。KIU
              に入学して、最高のものの違いを体験してください。KIU
              に入学して、その素晴らしさを感じてください。
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={nurse}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "80%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>看護学の理学士号取得にKIUを選ぶ理由</h5>
              <ul>
                <li>
                  KIUは、高等教育省の認定を受け、大学助成委員会に認められた看護学の理学士号を提供しています。
                </li>
                <li>
                  看護学の学位プログラムには、常勤講師と非常勤講師の両方を含む、非常に有能で経験豊富な講師陣が揃っています。
                </li>
                <li>
                  KIUは、図書館、臨床実験室、科学実験室、カフェテリア、体育館、レクリエーション施設など、数多くの学生用施設を提供しています。
                </li>
                <li>
                  すべての講義室には、効果的な教育と学習のための洗練された最新技術と設備が備わっています。
                  さらに、KIUには授乳中の母親のニーズに対応する特別な施設があります。
                </li>
              </ul>
              KIUに入学して、最高の違いを体験してください。KIUに入学して、その卓越性を実感してください。
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des2">
              <p>
                レベル: SLQF 6
                <br />
                期間: 4 年間 (最初の 2 年間は免除)
                <br />
                単位数: 128
                <br />
                入学者数: バッチ 40
                <br />
                配信媒体: 英語
                <br />
                配信モード: 講義は週 2 日 (月 6 日) 実施されます
                <br />
                連絡先番号: 0770033905
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des3">
              <h3>キャリア チャンス</h3>
              <ol>
                <li>高等教育のチャンス (学士、修士、MPhil、博士)</li>
                <ul>
                  <li>看護学の講師 (大学および看護トレーニング スクール)</li>
                </ul>
                <li>教師</li>
              </ol>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>入学要件</h5>

              <ul>
                <li>
                  スリランカ看護評議会またはスリランカ医療評議会の登録看護師
                </li>
                <li>留学生の場合は同等の資格</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des5">
              <h5>プログラム構造</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>1年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>第 1 学期</h5>
              <ul>
                <li>応用人体解剖学と生理学 I</li>
                <li>薬物治療学 I</li>
                <li>看護学の基礎 I</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>第 2 学期</h5>

              <ul>
                <li>成人看護学 I</li>
                <li>成人看護学実習 I</li>
                <li>コミュニティ ヘルス ナーシング I</li>
                <li>コミュニティ ヘルス ナーシング実習 I</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>2年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>第 1 学期</h5>
              <ul>
                <li>病棟管理と専門能力開発</li>
                <li>栄養学と食事療法</li>
                <li>看護学の基礎 II</li>
                <li>成人看護学 II</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>第 2 学期</h5>

              <ul>
                <li>成人看護実習 II</li>
                <li>コミュニティ ヘルス ナーシング II</li>
                <li>コミュニティ ヘルス ナーシング実習 II</li>
                <li>看護倫理、歴史、傾向</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>3年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>第 1 学期</h5>
              <ul>
                <li>応用人体解剖学と生理学 (II)</li>
                <li>応用生化学</li>
                <li>ソフト スキル開発 (I)</li>
                <li>看護研究</li>
                <li>微生物学と寄生虫学</li>
                <li>看護におけるコミュニケーション</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>第 2 学期</h5>

              <ul>
                <li>病理学</li>
                <li>生物統計学</li>
                <li>薬物治療学 (II)</li>
                <li>母性、新生児、婦人科看護</li>
                <li>小児看護</li>
                <li>精神科およびメンタルヘルス看護</li>
                <li>健康の心理社会的側面</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>４年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>第 1 学期</h5>
              <ul>
                <li>成人向けの教育と学習</li>
                <li>集中治療看護</li>
                <li>プライマリ ヘルスケア</li>
                <li>看護情報学</li>
                <li>看護研究プロジェクト</li>
                <li>ソフト スキル開発 (II)</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>第 2 学期</h5>

              <ul>
                <li>看護リーダーシップと管理</li>
                <li>看護の理論的基礎</li>
                <li>看護研究プロジェクト</li>
                <li>高齢者の看護ケア</li>
                <li>看護の傾向と課題</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>適用する</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              パンフレットをダウンロード
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              近い
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default NUR;
