import React from 'react'
import './Popup.css'
import { IoClose } from "react-icons/io5";
import {Container, Grid} from '@mui/material';

const Popup = (props) => {
    return (props.trigger)
        ? (
            <div className='popup'>

                <Container>
                    <Grid container="container" spacing={2}>
                        <Grid
                            item="item"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={{
                                marginLeft: '11%'
                            }}
                            className='popup-inner'>
                            <IoClose className='close-btn' onClick={() => props.setTrigger(false)}/> {props.children}
                        </Grid>
                    </Grid>
                </Container>
            </div>
        )
        : "";
}

export default Popup