import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "../postgraduate/Pgcrs.css";
import BAPDF from "../../../brochure/sample.pdf";
import CELIimg from "../../../assets/CELI.jpg";

const CELI = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              英語資格コース（中級レベル）
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="pg-crs-des1">
              英語プログラムは学部生の能力開発のために策定されました。英語能力はキャリアの流動性にとって極めて重要です。したがって、雇用可能性を高めるために必要な能力を学生に身につけさせるため、学生の英語能力を向上させるための「初級」、「中級」、「上級」の認定プログラムが実施されました。
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={CELIimg}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "80%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des2">
              <p>
                レベル: 短期コース
                <br />
                期間: 48 時間
                <br />
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des3">
              <h3>入学要件</h3>
              <ul>
                <li>予備英語プログラムまたは初級プログラムに合格した学生</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <h5>カリキュラム</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <p>日常的な英語の使用</p>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <h2>ライティングスキル</h2>

              <h3>文構造の修正</h3>
              <ul>
                <li>単文</li>
                <li>複文</li>
                <li>複文</li>
              </ul>

              <h3>段落ライティング入門</h3>
              <ul>
                <li>効果的な段落の構造</li>
                <li>段落の種類</li>
                <ul>
                  <li>説明的</li>
                  <li>物語</li>
                  <li>議論的</li>
                  <li>説得的</li>
                </ul>
              </ul>

              <h3>エッセイライティング</h3>
              <ul>
                <li>論文ステートメント</li>
                <li>効果的なエッセイの構成</li>
                <li>エッセイの種類</li>
                <ul>
                  <li>説明的</li>
                  <li>物語</li>
                  <li>議論</li>
                  <li>説明</li>
                </ul>
              </ul>

              <h3>プロセス ライティング</h3>
              <ul>
                <li>受動態</li>
                <li>シーケンス マーカーの使用</li>
                <li>多様なプロセスとシステムの説明</li>
              </ul>

              <h2>リスニング スキル</h2>
              <ul>
                <li>複雑なリスニング テキストの紹介</li>
                <li>語彙力向上のためのリスニング</li>
                <li>英語の発音の多様性</li>
              </ul>

              <h2>アカデミック リーディング スキル</h2>
              <ul>
                <li>アカデミック リーディング スキルの紹介</li>
                <li>さまざまなリーディング テクニックと戦略の理解</li>
              </ul>

              <h2>プロフェッショナル スピーキング スキル</h2>
              <ul>
                <li>式典でのスピーチ</li>
                <li>即興スピーチ</li>
                <li>ペア討論</li>
                <li>
                  マーケティングおよびプロモーション
                  キャンペーンのプレゼンテーション スキル
                </li>
                <li>ビジネス ストーリーを語るプレゼンテーション スキル</li>
                <li>
                  科学的な革新的なアイデアや発明を紹介するプレゼンテーション
                  スキル
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            {/* <a href="#" target="_blank" rel="noopener noreferrer"> */}
            <Button disabled style={buttonStyles1}>
              適用する
            </Button>
            {/* </a> */}
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              パンフレットをダウンロード
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              近い
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CELI;
