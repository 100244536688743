import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Faculty.css";

const FOEL = () => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #f21111", // Blue outline
    color: "#f21111", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const navigate = useNavigate();
  const handleBtnClick = (path) => {
    navigate(path);
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-title">教育学部・言語学部</h3>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <p className="fac-descrip">
              教育分野での機会の増加に伴い、教育と言語は需要の高い研究分野となっています。
              教育・言語学部は、学生が言語能力を伸ばす機会と刺激的な学習環境を提供することに努めています。学部では、教育職に就き、関連分野で専門資格を高めたいと考えている人を対象とした教育コースを提供しています。
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          {/* <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
                        <h3 className='fac-dean-hed'>Dean</h3>
                        <p className='fac-descrip1'>
                            Prof. Koliya Pulasinghe<br/>BSc Eng. (Hons) in Computer Systems & Engineering,
                                University of Moratuwa, Sri Lanka, 1996<br/>
                            Contact: +94 77 123 4567<br/>
                            Email: abc@gmail.com
                        </p>
                    </Grid> */}

          <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
            <h3 className="fac-hod-hed">学科長</h3>
            <p className="fac-descrip1">
              D.Nimesha Amarasooriya
              <br />
              PhD( 読解 ) MA ( 言語学 )
              <br />
              連絡先: 0772106110
              <br />
              メール: nimesha@kiu.ac.lk
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-depts-hed">学部</h3>
            <ul className="fac-depts-list">
              <li className="fac-depts-list-item">言語学部</li>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-fas-hed">学部が提供するもの</h3>
            <p className="fac-descrip1">
              <strong>英語プログラム: </strong>
              <br />
              英語プログラムは、学部生の育成を目的として策定されています。英語能力は、キャリアの流動性にとって最も重要です。したがって、学生の雇用可能性を高めるために必要な能力を身につけさせるため、学生の英語能力を向上させる「初級」、「中級」、「上級」の認定プログラムが実施されています。
              <br />
              <br />
              <strong>日本語プログラム: </strong>
              <br />
              言語学部は、日本語プログラムを通じて、日本での就職機会に対する需要の高まりに応えることを期待しています。これらのプログラムは、学生にN3レベルの専門知識と知識を提供し、スリランカの学生が日本でキャリアの夢を追求する素晴らしい機会を得られるように設計されています。
              <br />
              <br />
            </p>
          </Grid>
        </Grid>
      </Container>

      {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button style={buttonStyles1} onClick={() => handleBtnClick('/WeOffer')}>View Courses</Button>
                    </Grid>
                </Grid>
            </Container> */}
    </div>
  );
};

export default FOEL;
