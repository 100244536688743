import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "./Pgcrs.css";
import BAPDF from "../../brochure/MBA Brochure.pdf";
import Flow from "../../assets/flow.png";
import structure from "../../assets/structure.png";
import MBA from "../../assets/MBA.JPG";

const Mba = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Master of Business Administration (SLQF 10)
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="pg-crs-des1">
              A Master of Business Administration (SLQF 10) is a graduate-level
              degree program meticulously crafted to provide individuals with a
              profound understanding of diverse facets of business and
              management. MBAs are among the most sought-after and globally
              respected graduate degrees.
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={MBA}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "80%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des2">
              <h3>What to expect in MBA Strategy?</h3>
              <p>
                As an MBA Strategy graduate, you'll master business growth
                measurability, accountability, and expansion strategies. Gain
                practical skills for market research, confidence in executing
                strategies across platforms, and proficiency in social media
                tools. Be ready to plan, implement, manage, and measure your
                marketing efforts effectively.
              </p>
              <p>
                <strong>Contact Information</strong> - Ms. Imeshika Dilrukshi{" "}
                <br />
                imeshika@kiu.ac.lk <br />
                0716297258{" "}
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des3">
              <h3>Features of the MBA at the KIU</h3>
              <ul>
                <li>Weekly interactive sessions</li>
                <li>2 years</li>
                <li>60 credits</li>
                <li>5 real projects implemented in your own</li>
                <li>Delivery method is by English medium</li>
                <li>Active learning method</li>
                <li>Online learning</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des4">
              <h5>Entry Requirements</h5>
              <ul>
                <li>
                  A bachelor’s degree in any subject area from a recognized
                  university/institution with two years of relevant
                  post-qualification experience.
                </li>
                <li>
                  A postgraduate diploma of no less than one year or a higher
                  degree in any other field from a recognized
                  university/institution, coupled with at least two years of
                  relevant experience.
                </li>

                <li>
                  One of the following professional qualifications, along with a
                  minimum of two years of relevant post qualification
                  experience. Diploma in Marketing awarded by the Sri Lanka
                  Institute of Marketing (SLIM)
                </li>

                <li>
                  Professional qualification in Human Resource Management from
                  the Institute of Personal Management.
                </li>

                <li>
                  Proficiency in the English language is a mandatory
                  requirement.
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des4">
              <h5>Path to Master Of Business Administration</h5>

              <img
                alt="KIU Uni"
                src={Flow}
                style={{
                  width: "100%",
                }}
              />
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des4">
              <img
                alt="KIU Uni"
                src={structure}
                style={{
                  width: "100%",
                }}
              />
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des5">
              <h5>Program Structure</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <h5>Foundation Course</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <ul>
                <li>
                  FND 1001 - Principles of Management
                  <ul>
                    <li>Compulsory</li>
                    <li>20 Hours</li>
                  </ul>
                </li>
                <li>
                  FND 1002 - Introduction to Economics
                  <ul>
                    <li>Compulsory</li>
                    <li>20 Hours</li>
                  </ul>
                </li>
                <li>
                  FND 1003 - Fundamentals of Accounting & Finance
                  <ul>
                    <li>Compulsory</li>
                    <li>20 Hours</li>
                  </ul>
                </li>
                <li>
                  FND 1004 - Business Mathematics & Statistics
                  <ul>
                    <li>Compulsory</li>
                    <li>20 Hours</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <h5>
                MBA (Management Strategy) Programme Structure (SLQF Level 10)
                [with Project Report]
              </h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <h5>Year 1</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>
                  MBA1201 - Management Process and Practice
                  <ul>
                    <li>Compulsory</li>
                    <li>2 credits</li>
                    <li>30 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA1202 - Accounting for Management Decisions
                  <ul>
                    <li>Compulsory</li>
                    <li>2 credits</li>
                    <li>30 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA1203 - Economics for Management Decisions
                  <ul>
                    <li>Compulsory</li>
                    <li>2 credits</li>
                    <li>30 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA1204 - Business Statistics
                  <ul>
                    <li>Compulsory</li>
                    <li>2 credits</li>
                    <li>30 Hours</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>
                  MBA1305 - Business Finance*
                  <ul>
                    <li>Compulsory</li>
                    <li>3 credits</li>
                    <li>45 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA1306 - Marketing Management
                  <ul>
                    <li>Compulsory</li>
                    <li>3 credits</li>
                    <li>45 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA1307 - Human Resources Management
                  <ul>
                    <li>Compulsory</li>
                    <li>3 credits</li>
                    <li>45 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA1308 - Technology & Operations Management*
                  <ul>
                    <li>Compulsory</li>
                    <li>3 credits</li>
                    <li>45 Hours</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <h5>Semester 3</h5>
              <ul>
                <li>
                  MBA1209 - Academic Writing
                  <ul>
                    <li>Compulsory</li>
                    <li>2 credits</li>
                    <li>30 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA1210 - Industrial Psychology
                  <ul>
                    <li>Compulsory</li>
                    <li>2 credits</li>
                    <li>30 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA1211 - Organizational Behavior
                  <ul>
                    <li>Compulsory</li>
                    <li>2 credits</li>
                    <li>30 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA1212 - Information Management for Business Intelligence*
                  <ul>
                    <li>Compulsory</li>
                    <li>2 credits</li>
                    <li>30 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA1213 - International Field Visit & Workshop
                  <ul>
                    <li>Compulsory</li>
                    <li>2 credits</li>
                    <li>30 Hours</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <h5>Year 2</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>
                  MBA2301 - Strategic Management*
                  <ul>
                    <li>Compulsory</li>
                    <li>3 credits</li>
                    <li>45 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA2302 - Green Management and Ethics*
                  <ul>
                    <li>Compulsory</li>
                    <li>3 credits</li>
                    <li>45 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA2203 - Business Law & Compliance
                  <ul>
                    <li>Compulsory</li>
                    <li>2 credits</li>
                    <li>30 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA2204 - Global Business Strategy*
                  <ul>
                    <li>Compulsory</li>
                    <li>2 credits</li>
                    <li>30 Hours</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>
                  MBA2305 - Contemporary Business Challenges*
                  <ul>
                    <li>Compulsory</li>
                    <li>3 credits</li>
                    <li>45 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA2306 - Supply Chain and Logistics Strategy*
                  <ul>
                    <li>Compulsory</li>
                    <li>3 credits</li>
                    <li>45 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA2307 - Venture Creation Strategy*
                  <ul>
                    <li>Compulsory</li>
                    <li>3 credits</li>
                    <li>45 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA2308 - Projects & Events Management
                  <ul>
                    <li>Compulsory</li>
                    <li>3 credits</li>
                    <li>45 Hours</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <h5>Semester 3</h5>
              <ul>
                <li>
                  MBA2809 - Management Project Report*
                  <ul>
                    <li>8 credits</li>
                    <li>800 Hours</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <h5>
                MBA Programme Structure (to pass the SLQF Level 10) [Thesis]
              </h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <h5>Year 1</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>
                  MBA1201 - Management Process and Practice
                  <ul>
                    <li>Compulsory</li>
                    <li>2 credits</li>
                    <li>30 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA1202 - Accounting for Management Decisions
                  <ul>
                    <li>Compulsory</li>
                    <li>2 credits</li>
                    <li>30 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA1203 - Economics for Management Decisions
                  <ul>
                    <li>Compulsory</li>
                    <li>2 credits</li>
                    <li>30 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA1204 - Business Statistics
                  <ul>
                    <li>Compulsory</li>
                    <li>2 credits</li>
                    <li>30 Hours</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>
                  MBA1305 - Business Finance*
                  <ul>
                    <li>Compulsory</li>
                    <li>3 credits</li>
                    <li>45 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA1306 - Marketing Management
                  <ul>
                    <li>Compulsory</li>
                    <li>3 credits</li>
                    <li>45 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA1307 - Human Resources Management
                  <ul>
                    <li>Compulsory</li>
                    <li>3 credits</li>
                    <li>45 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA1308 - Technology & Operations Management*
                  <ul>
                    <li>Compulsory</li>
                    <li>3 credits</li>
                    <li>45 Hours</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <h5>Semester 3</h5>
              <ul>
                <li>
                  MBA1209 - Academic Writing
                  <ul>
                    <li>Compulsory</li>
                    <li>2 credits</li>
                    <li>30 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA1210 - Industrial Psychology
                  <ul>
                    <li>Compulsory</li>
                    <li>2 credits</li>
                    <li>30 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA1211 - Organizational Behavior
                  <ul>
                    <li>Compulsory</li>
                    <li>2 credits</li>
                    <li>30 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA1212 - Information Management for Business Intelligence*
                  <ul>
                    <li>Compulsory</li>
                    <li>2 credits</li>
                    <li>30 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA1213 - International Field Visit & Workshop
                  <ul>
                    <li>Compulsory</li>
                    <li>2 credits</li>
                    <li>30 Hours</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <h5>Year 2</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <h5>Semester 1</h5>
              <ul>
                <li>
                  MBA2301 - Strategic Management*
                  <ul>
                    <li>Compulsory</li>
                    <li>3 credits</li>
                    <li>45 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA2302 - Green Management and Ethics*
                  <ul>
                    <li>Compulsory</li>
                    <li>3 credits</li>
                    <li>45 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA2203 - Business Law & Compliance
                  <ul>
                    <li>Compulsory</li>
                    <li>2 credits</li>
                    <li>30 Hours</li>
                  </ul>
                </li>
                <li>
                  MBA2204 - Global Business Strategy*
                  <ul>
                    <li>Compulsory</li>
                    <li>2 credits</li>
                    <li>30 Hours</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des8">
              <h5>Semester 2</h5>

              <ul>
                <li>
                  MBA2310 - Research Methodology
                  <ul>
                    <li>Compulsory</li>
                    <li>3 credits</li>
                    <li>45 Hours</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <h5>Semester 3</h5>
              <ul>
                <li>
                  MBA21711 - Thesis*
                  <ul>
                    <li>17 credits</li>
                    <li>1700 Hours</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://forms.office.com/Pages/ResponsePage.aspx?id=K4FZ8Bpt40u83KOC56hNc8lTY9vfkp1HisXQmlz-ce1UMTI1NVJLSllHOFdDQTlBN1NDQ0RQQkdSTC4u"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>Apply</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled="disabled"
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              Download Brochure
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Mba;
