import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "./Ugcrs.css";
import CSimg from "../../../assets/CS.jpg";
import BAPDF from "../../../brochure/sample.pdf";

const CN = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              コンピュータネットワークとサイバーセキュリティのBSc優等学位
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="ug-crs-des1">
              コンピュータ ネットワークとサイバー
              セキュリティの理学士優等学位は、学部生がコンピュータ
              ネットワークとサイバー
              セキュリティの知識を持つ情熱的な専門家として活躍することを可能にします。コンピュータ
              ネットワークとサイバー
              セキュリティの学位は、ネットワークを管理し、そのセキュリティを維持するスキルを養います。さらに、コンピュータ
              ネットワークとサイバー セキュリティには、サイバー
              セキュリティの原則を運用に適用しながら、企業ネットワークの設計、実装、管理、保護、トラブルシューティングを行うための運用手順とテクノロジーが含まれます。コンピュータ
              ネットワークとサイバー
              セキュリティの学位プログラムのカリキュラムは、スリランカと世界の大学の基準に従って設計されています。当学位プログラムは英語で実施され、貴重な業界および研究経験を提供します。{" "}
              <br />
              さらに、KIU
              の学部生は、業界および研究に関する貴重な経験を提供する大手企業での実習に参加できます。これにより、KIU
              のコンピュータ ネットワークおよびサイバー
              セキュリティの卒業生は、最高情報セキュリティ責任者、フォレンジック
              コンピュータ アナリスト、情報セキュリティ
              アナリスト、侵入テスト担当者、セキュリティ
              アーキテクトなど、さまざまな職種に就く機会が得られます。
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={CSimg}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "60%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des2">
              <p>
                レベル: SLQF 6 
                <br />
                期間: 第8学期 (4年間)
                <br />
                クレジットポイント: 126
                <br />
                入学時期: 3月と9月
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des3">
              <h3>キャリアの機会</h3>
              <ol>
                <li>最高情報セキュリティ責任者</li>
                <li>法医学コンピュータアナリスト</li>
                <li>情報セキュリティアナリスト</li>
                <li>侵入テスター</li>
                <li>セキュリティアーキテクト</li>
              </ol>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>入学条件</h5>

              <ul>
                <li>
                  スリランカ試験局が実施するG.C.E（上級レベル）試験で、物理科学分野または工学技術分野で少なくとも3回の単純合格（S）または同等の資格。
                </li>
                または
                <li>
                  スリランカ試験局が実施する G.C.E（上級レベル）試験で、1
                  回の受験でいずれかの分野で少なくとも 3
                  つの単純合格（S）を取得するか、またはスリランカ試験局が実施する
                  G.C.E（普通レベル）試験で数学のクレジット合格（C）を取得し、同等の資格を取得するか、または指定機関が承認したブリッジング
                  プログラムで同等の資格を取得します。
                </li>
                または
                <li>
                  スリランカ試験局が実施する G.C.E. (上級レベル)
                  試験のいずれの試験でも、情報通信技術の簡易合格 (S) を 1
                  回の受験で取得するか、同等の資格を持ち、スリランカ試験局が実施する
                  G.C.E. (普通レベル) 試験の数学の単位合格 (C)
                  を取得するか、同等の資格を持つ、いずれかの分野で 1
                  回の受験で少なくとも 3 つの簡易合格 (S) を取得していること。{" "}
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des5">
              <h5>プログラム構成</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>1年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>1学期</h5>
              <ul>
                <li>コンピュータシステム入門</li>
                <li>コンピュータアーキテクチャ</li>
                <li>プログラミングの基礎</li>
                <li>学術的実践と身だしなみ</li>
                <li>コンピューティングのための数学 I</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>2学期</h5>

              <ul>
                <li>データ構造とアルゴリズム</li>
                <li>オブジェクト指向プログラミング</li>
                <li>ウェブテクノロジー</li>
                <li>データ通信</li>
                <li>コンピューティングのための数学 II</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>2年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>1学期</h5>
              <ul>
                <li>コンピューターネットワーク</li>
                <li>データベースシステム</li>
                <li>システム分析と設計</li>
                <li>ネットワーク オペレーティング システム</li>
                <li>応用統計学</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>2学期</h5>

              <ul>
                <li>暗号化</li>
                <li>コンポーネントのセキュリティ</li>
                <li>モバイルとワイヤレスネットワーク</li>
                <li>ネットワークインフラストラクチャとプロトコル</li>
                <li>サイバーセキュリティの原則</li>
                <li>キャリアプランニングと就業スキル開発 – セミナー</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>3年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>1学期</h5>
              <ul>
                <li>コンピューティングの研究方法</li>
                <li>安全なソフトウェア開発</li>
                <li>サイバー犯罪と法的環境</li>
                <li>ネットワークセキュリティー</li>
                <li>ウェブセキュリティ</li>
                <li>ネットワーク管理</li>
                <li>知識ベースシステム</li>
                <li>新技術</li>
                <li>ITインフラストラクチャ</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>2学期</h5>

              <ul>
                <li>職場ベースの企業配置</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>4年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>1学期</h5>
              <ul>
                <li>最終学年個人プロジェクト</li>
                <li>ITの専門性と実践</li>
                <li>高度なコンピュータネットワーク</li>
                <li>システムセキュリティ</li>
                <li>サイバーセキュリティ分析</li>
                <li>データサイエンス</li>
                <li>高性能コンピューティング</li>
                <li>データベースサーバー管理</li>
                <li>高度なウェブテクノロジー</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>2学期</h5>

              <ul>
                <li>倫理的なハッキングとその対策</li>
                <li>デジタルフォレンジック</li>
                <li>システムおよびネットワーク管理</li>
                <li>クラウドコンピューティングと仮想化</li>
                <li>モノのインターネット</li>
                <li>オペレーショナルリサーチ</li>
                <li>高度なウェブテクノロジー</li>
                <li>起業家精神</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>
                コンピュータネットワークとサイバーセキュリティの理学士優等学位を取得した卒業生は、以下のことができるようになります。
              </h5>

              <ul>
                <li>
                  コンピュータネットワークとサイバーセキュリティの深い概念を理解する。
                </li>
                <li>
                  コンピュータネットワークとサイバーセキュリティの導入の影響によって組織の状況がどのように影響を受けるかを説明します。
                </li>
                <li>
                  コンピューティング分野における社会的、法的、倫理的、文化的、専門的な責任を理解する。
                </li>
                <li>
                  セキュリティ概念のベストプラクティスを適用し、適切なネットワーク手法を使用して、コンピュータネットワークとサイバー環境の複雑さを管理します。
                </li>
                <li>プロジェクト経験と産業実務を積む。</li>
                <li>
                  IT プロフェッショナルとして問題を解決するためにコンピュータ
                  ネットワークとサイバー
                  セキュリティ技術を使用する方法を説明します。
                </li>
                <li>
                  コンピュータネットワークとサイバーセキュリティの原則に関する知識に基づいて、議論を展開し、可能なアプローチ、ツール、テクニック、プラットフォーム、ソリューションを評価する。
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/25"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>適用する</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              パンフレットをダウンロード
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              近い
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CN;
