import { Icon } from '@iconify/react';
import React, { useState, useRef,useEffect } from 'react';
import { IoMdClose, IoMdMenu } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';

const Header = () => {
    const [active, setActive] = useState(false);
    const navigate = useNavigate();
    const headerRef = useRef(null);

    const activateNav = () => {
        setActive(!active);
    };

    const handleIconClick = (path) => {
        navigate(path);
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if(headerRef.current && !headerRef.current.contains(event.target)){
                setActive(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    },[]);

    return (
        <div
            ref={headerRef}
            className={active
                ? 'header'
                : 'header-mobile'}>
            <div className='menu-icon' onClick={activateNav}>
                {
                    !active
                        ? <IoMdMenu className='menu'/>
                        : <IoMdClose className='menu'/>
                }
            </div>
            <nav>
                <ul
                    className={active
                        ? 'ul-item'
                        : 'ul-item oicon'}>
                    <li>
                        <Icon icon="solar:home-broken" className='icon' onClick={() => handleIconClick('/')}/>
                        <Link to='/' className='transition1'>
                            Home
                        </Link>
                    </li>
                    <li>
                        <Icon icon="mdi:about-circle-outline" className='icon' onClick={() => handleIconClick('/About')}/>
                        <Link to='/About' className='transition2'>About</Link>
                    </li>

                    <li>
                        <Icon icon="charm:graduate-cap" className='icon' onClick={() => handleIconClick('/WeOffer')}/>
                        <Link to='/WeOffer' className='transition3'>We Offer</Link>
                    </li>

                    <li>
                        <Icon icon="ci:building-02" className='icon' onClick={() => handleIconClick('/Faculties')}/>
                        <Link to='/Faculties' className='transition4'>Faculties</Link>
                    </li>

                    <li>
                        <Icon icon="iconoir:brain-research" className='icon' onClick={() => handleIconClick('/Research')}/>
                        <Link to='/Research' className='transition5'>Research</Link>
                    </li>

                    <li>
                        <Icon icon="entypo:lab-flask" className='icon' onClick={() => handleIconClick('/Facilities')}/>
                        <Link to='/Facilities' className='transition6'>Facilities</Link>
                    </li>

                    {/* <li>
                        <Icon icon="octicon:people-16" className='icon' onClick={() => handleIconClick('/LecturePanel')}/>
                        <Link to='/LecturePanel' className='transition7'>Lecture Panel</Link>
                    </li> */}

                    <li>
                        <Icon icon="emojione-monotone:newspaper" className='icon' onClick={() => handleIconClick('/News')}/>
                        <Link to='/News' className='transition7'>News & Events</Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Header;
