// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.head-comp{
    background-color: #2d2d32;
    padding: 1rem 1rem 1rem 1rem;
}

.head-comp-text{
    font-family: 'Kanit', sans-serif;
    font-size: 30px;
    color: white;
    padding: 1.5rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.head-comp-logo{
    width: 120px;
    height: 120px;
}

.head-search-grp {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 2;
    margin-top: 5%;
    margin-left: 3%;
  }
  
  .head-search {
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 20px;
    font-size: 16px;
    flex-grow: 1;
    background-color: transparent;
    color: white;
    margin-right: 10px;
  }
  
  .head-magnify {
    font-size: 30px;
    color: white;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/components/Head.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,4BAA4B;AAChC;;AAEA;IACI,gCAAgC;IAChC,eAAe;IACf,YAAY;IACZ,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,YAAY;IACZ,6BAA6B;IAC7B,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,YAAY;IACZ,eAAe;EACjB","sourcesContent":[".head-comp{\n    background-color: #2d2d32;\n    padding: 1rem 1rem 1rem 1rem;\n}\n\n.head-comp-text{\n    font-family: 'Kanit', sans-serif;\n    font-size: 30px;\n    color: white;\n    padding: 1.5rem 0.5rem;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.head-comp-logo{\n    width: 120px;\n    height: 120px;\n}\n\n.head-search-grp {\n    position: relative;\n    display: flex;\n    align-items: center;\n    z-index: 2;\n    margin-top: 5%;\n    margin-left: 3%;\n  }\n  \n  .head-search {\n    padding: 10px;\n    border: 2px solid #ccc;\n    border-radius: 20px;\n    font-size: 16px;\n    flex-grow: 1;\n    background-color: transparent;\n    color: white;\n    margin-right: 10px;\n  }\n  \n  .head-magnify {\n    font-size: 30px;\n    color: white;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
