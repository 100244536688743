import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Faculty.css";

const FOBS = () => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #f21111", // Blue outline
    color: "#f21111", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const navigate = useNavigate();
  const handleBtnClick = (path) => {
    navigate(path);
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-title">Faculty Of Behavioural Sciences</h3>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <p className="fac-descrip">
              The Faculty of Behavioural Sciences offers the BSc (Hons) in
              Psychology program approved by the Ministry of Higher Education
              and recognized by the University Grants Commission in Sri Lanka.
              It is a dynamic and interdisciplinary academic faculty within our
              university that focuses on the study of the human mind and
              behaviour and its underlying factors. The faculty is committed to
              advancing knowledge, fostering critical thinking, and preparing
              our students for meaningful careers in various fields related to
              human behaviour. <br />
              <br />
              Our faculty members, comprising internal and external lecturers,
              are experts in their respective disciplines, actively engaged in
              research, and often contributing to the academic community through
              publications and presentations <br />
              Students have the opportunity to engage in hands-on research,
              fieldwork, and practical experiences, allowing them to apply
              theoretical knowledge in real-world settings. The program offered
              by the Faculty of Behavioural Sciences prepares students for a
              wide range of career paths, including counselling, research,
              social work, human resources, and more.
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-dean-hed">Dean</h3>
            <p className="fac-descrip1">
              Ms. Ranushi Peiris
              <br />
              M.Phil. in Clinical Psychology (UoC, Sri Lanka),
              <br />
              M.Sc. in Clinical Psychology (CU, India),
              <br />
              B.Sc. in Psychology (Hons) (UK).
              <br />
              Email: dean_fbs@kiu.ac.lk
            </p>
          </Grid>

          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-hod-hed">Acting Head of the Department</h3>
            <p className="fac-descrip1">
              Dinusha Sandaruwan <br />
              PgDip (Counselling and Psychosocial Support)(Reading),
              <br />
              B.Sc. (Hons) Psychology
              <br />
              Email: sandaruwan@kiu.ac.lk
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-depts-hed">Departments</h3>
            <ul className="fac-depts-list">
              <li className="fac-depts-list-item">Department Of Psychology</li>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            {/* <h3 className='fac-fas-hed'>Facilities</h3>
                        <p className='fac-descrip1'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Amet cursus sit amet dictum sit
                            amet. Amet mattis vulputate enim nulla aliquet porttitor lacus luctus. Mi quis
                            hendrerit dolor magna eget est lorem ipsum. Arcu cursus vitae congue mauris
                            rhoncus. Ornare lectus sit amet est placerat in egestas erat imperdiet. Aliquam
                            malesuada bibendum arcu vitae elementum curabitur vitae. Blandit cursus risus at
                            ultrices mi tempus. Etiam sit amet nisl purus in. Mollis aliquam ut porttitor
                            leo a. Est pellentesque elit ullamcorper dignissim cras tincidunt lobortis
                            feugiat vivamus. Posuere sollicitudin aliquam ultrices sagittis orci a
                            scelerisque purus. Turpis massa tincidunt dui ut. Sem nulla pharetra diam sit
                            amet. Risus sed vulputate odio ut enim blandit.
                        </p> */}
          </Grid>
        </Grid>
      </Container>

      {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button style={buttonStyles1} onClick={() => handleBtnClick('/WeOffer')}>View Courses</Button>
                    </Grid>
                </Grid>
            </Container> */}
    </div>
  );
};

export default FOBS;
