import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Typography } from "@mui/material";

import Computing from "../../../assets/TSComputing.jpg";
import Management from "../../../assets/TSManagement.jpg";
import HealthScience from "../../../assets/TSHealth.jpg";
import Psychology from "../../../assets/TSPsycology.jpg";
import Nursing from "../../../assets/TSNursing.jpg";
import Law from "../../../assets/TSLaw.jpg";

function TypeSlider() {
  const settings = {
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 4,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024, // large screens
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // tablet
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480, // mobile
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const slideStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "320px",
  };

  const boxMX = 1;

  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div>
          <Box
            style={slideStyle}
            sx={{
              mx: boxMX,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              height="85%"
              width="100%"
              sx={{
                backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%), url(${Computing})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: "white", textAlign: "center", py: 1, px: 1 }}
              >
                「バイトごとに未来をコーディング:
                イノベーションと想像力が出会うコンピューティング!」
              </Typography>
            </Box>
            <Box
              height="15%"
              width="100%"
              sx={{
                background: "#761772",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "1.5rem",
                fontWeight: "700",
              }}
            >
              IT 分野
            </Box>
          </Box>
        </div>
        <div>
          <Box
            style={slideStyle}
            sx={{
              mx: boxMX,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              height="85%"
              width="100%"
              sx={{
                backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%),url(${Nursing})`,
                backgroundSize: "cover",
                backgroundPosition: "center 30%",
                backgroundRepeat: "no-repeat",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: "white", textAlign: "center", py: 1, px: 1 }}
              >
                「思いやりを育み、ケアを創る：心と癒しがひとつになる看護！」
              </Typography>
            </Box>
            <Box
              height="15%"
              width="100%"
              sx={{
                background: "#4775BA",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "1.5rem",
                fontWeight: "700",
              }}
            >
              看護
            </Box>
          </Box>
        </div>
        <div>
          <Box
            style={slideStyle}
            sx={{
              mx: boxMX,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              height="85%"
              width="100%"
              sx={{
                backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%),url(${Management})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: "white", textAlign: "center", py: 1, px: 1 }}
              >
                「リーダーシップの潜在能力を解き放つ：マネジメント、戦略と成功の芸術！」
              </Typography>
            </Box>
            <Box
              height="15%"
              width="100%"
              sx={{
                background: "#652C90",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "1.5rem",
                fontWeight: "700",
              }}
            >
              経営経済学
            </Box>
          </Box>
        </div>
        <div>
          <Box
            style={slideStyle}
            sx={{
              mx: boxMX,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              height="85%"
              width="100%"
              sx={{
                backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%),url(${Law})`,
                backgroundSize: "cover",
                backgroundPosition: "center 30%",
                backgroundRepeat: "no-repeat",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: "white", textAlign: "center", py: 1, px: 1 }}
              >
                「法律：正義が声を上げ、より良い世界を主張する場所！」
              </Typography>
            </Box>
            <Box
              height="15%"
              width="100%"
              sx={{
                background: "#a61c31",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "1.5rem",
                fontWeight: "700",
              }}
            >
              法学
            </Box>
          </Box>
        </div>
        <div>
          <Box
            style={slideStyle}
            sx={{
              mx: boxMX,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              height="85%"
              width="100%"
              sx={{
                backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%),url(${HealthScience})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: "white", textAlign: "center", py: 1, px: 1 }}
              >
                「健康科学：ひとつひとつの発見で健康への道を照らします！」
              </Typography>
            </Box>
            <Box
              height="15%"
              width="100%"
              sx={{
                background: "#20B89A",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "1.5rem",
                fontWeight: "700",
              }}
            >
              健康科学
            </Box>
          </Box>
        </div>
        <div>
          <Box
            style={slideStyle}
            sx={{
              mx: boxMX,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              height="85%"
              width="100%"
              sx={{
                backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%),url(${Psychology})`,
                backgroundSize: "cover",
                backgroundPosition: "center 20%",
                backgroundRepeat: "no-repeat",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: "white", textAlign: "center", py: 1, px: 1 }}
              >
                「心理学：心を解き明かし、人生を変える -
                人間の行動の科学を探る！」
              </Typography>
            </Box>
            <Box
              height="15%"
              width="100%"
              sx={{
                background: "#b92360",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "1.5rem",
                fontWeight: "700",
              }}
            >
              心理学
            </Box>
          </Box>
        </div>
      </Slider>
    </div>
  );
}

export default TypeSlider;
