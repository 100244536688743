import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Faculty.css";

const FOBS = () => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #f21111", // Blue outline
    color: "#f21111", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const navigate = useNavigate();
  const handleBtnClick = (path) => {
    navigate(path);
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-title">行動科学学部</h3>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <p className="fac-descrip">
              行動科学学部は、スリランカの高等教育省の認可を受け、大学助成委員会の認定を受けた心理学のBSc（優等学位）プログラムを提供しています。
              これは、人間の心と行動、およびその根底にある要因の研究に焦点を当てた、当大学内のダイナミックで学際的な学術学部です。学部は、知識の進歩、批判的思考の育成、そして人間の行動に関連するさまざまな分野での有意義なキャリアに向けて学生を準備することに尽力しています。
              <br />
              <br />
              内部講師と外部講師で構成される当学部の教員は、それぞれの分野の専門家であり、積極的に研究に従事し、出版物やプレゼンテーションを通じて学術コミュニティに貢献しています。
              <br />
              学生は、実践的な研究、フィールドワーク、実践的な経験に参加する機会があり、理論的な知識を現実世界の環境に応用することができます。行動科学学部が提供するプログラムは、カウンセリング、研究、ソーシャルワーク、人事など、幅広いキャリアパスに向けて学生を準備します。{" "}
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-dean-hed">名誉学部長 行動科学部 </h3>

            <p className="fac-descrip1">
              カンティ・ヘティゴダ博士
              <br />
              博士号（ハル大学、英国）
              <br />
              MPhil（臨床心理学、ハル大学）
              <br />
              MSc（組織管理、ハル大学）、
              <br />
              心理学学士（優等学位）
              <br />
              連絡先：0777 268507
              <br />
              メール：dean_fbs@kiu.ac.lk
            </p>
          </Grid>

          <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
            <h3 className="fac-hod-hed">学科長</h3>
            <p className="fac-descrip1">
              L.M. Dilantha Deva Adithiya 氏 (MBPsS) <br />
              MPhil (臨床心理学、UoC) (読書) <br />
              MSc (臨床および健康心理学、UWL、英国) <br />
              心理学 BSc (優等学位)
              <br />
              連絡先: 077 2694539
              <br />
              メール: adithiya@kiu.ac.lk
            </p>
          </Grid>

          <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
            <h3 className="fac-hod-hed">学科長代理</h3>
            <p className="fac-descrip1">
              Amathullah Salman 氏 <br />
              MPhil/PhD (心理学、UOC) (読書) <br />
              MSc (臨床および健康心理学、UWL、英国) <br />
              心理学 BSc (優等学位) <br />
              BA (UoP)
              <br />
              連絡先: 076 3712338
              <br />
              メール: amathullah@kiu.ac.lk
            </p>
          </Grid>

          <Grid item="item" xs={12} sm={12} md={6} lg={6} xl={6}>
            <h3 className="fac-hod-hed">学科長補佐</h3>
            <p className="fac-descrip1">
              Nandika Vidurangi 氏 <br />
              MEd (Horizo​​n Campus) (読解) <br />
              心理学 BSc (優等学位) <br />
              HND (ビジネス管理、SQA)
              <br />
              連絡先: 076 7883774
              <br />
              メール: nandika@kiu.ac.lk
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-depts-hed">学部</h3>
            <ul className="fac-depts-list">
              <li className="fac-depts-list-item">心理学部</li>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            {/* <h3 className='fac-fas-hed'>Facilities</h3>
                        <p className='fac-descrip1'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Amet cursus sit amet dictum sit
                            amet. Amet mattis vulputate enim nulla aliquet porttitor lacus luctus. Mi quis
                            hendrerit dolor magna eget est lorem ipsum. Arcu cursus vitae congue mauris
                            rhoncus. Ornare lectus sit amet est placerat in egestas erat imperdiet. Aliquam
                            malesuada bibendum arcu vitae elementum curabitur vitae. Blandit cursus risus at
                            ultrices mi tempus. Etiam sit amet nisl purus in. Mollis aliquam ut porttitor
                            leo a. Est pellentesque elit ullamcorper dignissim cras tincidunt lobortis
                            feugiat vivamus. Posuere sollicitudin aliquam ultrices sagittis orci a
                            scelerisque purus. Turpis massa tincidunt dui ut. Sem nulla pharetra diam sit
                            amet. Risus sed vulputate odio ut enim blandit.
                        </p> */}
          </Grid>
        </Grid>
      </Container>

      {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button style={buttonStyles1} onClick={() => handleBtnClick('/WeOffer')}>View Courses</Button>
                    </Grid>
                </Grid>
            </Container> */}
    </div>
  );
};

export default FOBS;
