import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Computing from "../../assets/TSComputing.jpg";
import Management from "../../assets/TSManagement.jpg";
import HealthScience from "../../assets/TSHealth.jpg";
import Psychology from "../../assets/TSPsycology.jpg";
import Nursing from "../../assets/TSNursing.jpg";
import Law from "../../assets/TSLaw.jpg";

function TypeSlider() {
  const navigate = useNavigate();
  const settings = {
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 4,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024, // large screens
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // tablet
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480, // mobile
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const slideStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "320px",
  };

  const boxMX = 1;

  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div>
          <Box
            style={slideStyle}
            sx={{
              mx: boxMX,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              height="85%"
              width="100%"
              sx={{
                backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%), url(${Computing})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: "white", textAlign: "center", py: 1, px: 1 }}
              >
                "Coding the Future, Byte by Byte: Computing, Where Innovation
                Meets Imagination!"
              </Typography>
            </Box>
            <Box
              height="15%"
              width="100%"
              sx={{
                background: "#761772",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "1.5rem",
                fontWeight: "700",
                cursor: "pointer",
              }}
              onClick={() => navigate("/WeOffer")}
            >
              Computing
            </Box>
          </Box>
        </div>
        <div>
          <Box
            style={slideStyle}
            sx={{
              mx: boxMX,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              height="85%"
              width="100%"
              sx={{
                backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%),url(${Nursing})`,
                backgroundSize: "cover",
                backgroundPosition: "center 30%",
                backgroundRepeat: "no-repeat",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: "white", textAlign: "center", py: 1, px: 1 }}
              >
                "Nurturing Compassion, Crafting Care: Nursing, Where Hearts and
                Healing Unite!"
              </Typography>
            </Box>
            <Box
              height="15%"
              width="100%"
              sx={{
                background: "#4775BA",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "1.5rem",
                fontWeight: "700",
                cursor: "pointer",
              }}
              onClick={() => navigate("/WeOffer")}
            >
              Nursing
            </Box>
          </Box>
        </div>
        <div>
          <Box
            style={slideStyle}
            sx={{
              mx: boxMX,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              height="85%"
              width="100%"
              sx={{
                backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%),url(${Management})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: "white", textAlign: "center", py: 1, px: 1 }}
              >
                "Unlocking Leadership Potential: Management, the Art of Strategy
                and Success!"
              </Typography>
            </Box>
            <Box
              height="15%"
              width="100%"
              sx={{
                background: "#652C90",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "1.5rem",
                fontWeight: "700",
                cursor: "pointer",
              }}
              onClick={() => navigate("/WeOffer")}
            >
              Management
            </Box>
          </Box>
        </div>
        <div>
          <Box
            style={slideStyle}
            sx={{
              mx: boxMX,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              height="85%"
              width="100%"
              sx={{
                backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%),url(${Law})`,
                backgroundSize: "cover",
                backgroundPosition: "center 30%",
                backgroundRepeat: "no-repeat",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: "white", textAlign: "center", py: 1, px: 1 }}
              >
                "Law: Where Justice Finds Its Voice, Advocating for a Better
                World!"
              </Typography>
            </Box>
            <Box
              height="15%"
              width="100%"
              sx={{
                background: "#a61c31",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "1.5rem",
                fontWeight: "700",
                cursor: "pointer",
              }}
              onClick={() => navigate("/WeOffer")}
            >
              Law
            </Box>
          </Box>
        </div>
        <div>
          <Box
            style={slideStyle}
            sx={{
              mx: boxMX,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              height="85%"
              width="100%"
              sx={{
                backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%),url(${HealthScience})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: "white", textAlign: "center", py: 1, px: 1 }}
              >
                "Health Science: Illuminating the Path to Wellness, One
                Discovery at a Time!"
              </Typography>
            </Box>
            <Box
              height="15%"
              width="100%"
              sx={{
                background: "#20B89A",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "1.5rem",
                fontWeight: "700",
                cursor: "pointer",
              }}
              onClick={() => navigate("/WeOffer")}
            >
              Health Science
            </Box>
          </Box>
        </div>
        <div>
          <Box
            style={slideStyle}
            sx={{
              mx: boxMX,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              height="85%"
              width="100%"
              sx={{
                backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%),url(${Psychology})`,
                backgroundSize: "cover",
                backgroundPosition: "center 20%",
                backgroundRepeat: "no-repeat",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: "white", textAlign: "center", py: 1, px: 1 }}
              >
                "Psychology: Unraveling Minds, Transforming Lives – Explore the
                Science of Human Behavior!"
              </Typography>
            </Box>
            <Box
              height="15%"
              width="100%"
              sx={{
                background: "#b92360",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "1.5rem",
                fontWeight: "700",
                cursor: "pointer",
              }}
              onClick={() => navigate("/WeOffer")}
            >
              Psychology
            </Box>
          </Box>
        </div>
      </Slider>
    </div>
  );
}

export default TypeSlider;
