// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bio-des{
    font-size: 18px;
    color: white;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    margin-bottom: 5%;
}`, "",{"version":3,"sources":["webpack://./src/components/facilities/BioMedLab.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,qCAAqC;IACrC,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".bio-des{\n    font-size: 18px;\n    color: white;\n    font-family: 'Montserrat', sans-serif;\n    text-align: center;\n    margin-bottom: 5%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
