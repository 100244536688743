import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "../postgraduate/Pgcrs.css";
import BAPDF from "../../brochure/sample.pdf";
import CELIimg from "../../assets/CELI.jpg";

const CELI = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Certificate Course in English Language (Intermediate Level)
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="pg-crs-des1">
              The English language programmes have been formulated for the
              development of undergraduates. The competency of English is of
              paramount importance to career mobility. Hence, to equip them with
              the competency that is required to enhance their employability,
              ‘Elementary’, ‘Intermediate’ and ‘Advanced’ certificate programmes
              have been conducted to improve the English language competency of
              the students.
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={CELIimg}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "80%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des2">
              <p>
                Level: Short Course
                <br />
                Duration: 48 Hours
                <br />
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des3">
              <h3>Entry Requirements</h3>
              <ul>
                <li>
                  Students who passed the preparatory English programme or the
                  elementary programme
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <h5>CURRICULUM</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <p>Regular Daily English Usage</p>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <h2>Writing Skills</h2>

              <h3>Revision of Sentence Structures</h3>
              <ul>
                <li>Simple sentences</li>
                <li>Compound sentences</li>
                <li>Complex sentences</li>
              </ul>

              <h3>Introduction to Paragraph Writing</h3>
              <ul>
                <li>Structure of an effective paragraph</li>
                <li>Types of Paragraphs</li>
                <ul>
                  <li>Descriptive</li>
                  <li>Narrative</li>
                  <li>Argumentative</li>
                  <li>Persuasive</li>
                </ul>
              </ul>

              <h3>Essays Writing</h3>
              <ul>
                <li>Thesis statement</li>
                <li>Organization of an Effective Essay</li>
                <li>Types of Essays</li>
                <ul>
                  <li>Expository</li>
                  <li>Narrative</li>
                  <li>Argumentative</li>
                  <li>Descriptive</li>
                </ul>
              </ul>

              <h3>Process Writing</h3>
              <ul>
                <li>Passive structure</li>
                <li>Usage of Sequence Markers</li>
                <li>Describing diverse processes and systems</li>
              </ul>

              <h2>Listening Skills</h2>
              <ul>
                <li>Introduction to complex listening texts</li>
                <li>Listening for vocabulary development</li>
                <li>Varieties of English pronunciation</li>
              </ul>

              <h2>Academic Reading Skills</h2>
              <ul>
                <li>Introduction to academic reading skills</li>
                <li>
                  Understanding different techniques and strategies of reading
                </li>
              </ul>

              <h2>Professional Speaking Skills</h2>
              <ul>
                <li>Ceremonial speeches</li>
                <li>Impromptu speeches</li>
                <li>Debates involving pairs</li>
                <li>
                  Presentation skills for marketing and promotional campaigns
                </li>
                <li>Presentation skills for narrating business stories</li>
                <li>
                  Presentation skills for introducing scientific innovative
                  ideas and inventions
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            {/* <a href="#" target="_blank" rel="noopener noreferrer"> */}
            <Button disabled style={buttonStyles1}>
              Apply
            </Button>
            {/* </a> */}
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              Download Brochure
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CELI;
