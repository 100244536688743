import { Icon } from "@iconify/react";
import React, { useState, useRef, useEffect } from "react";
import { IoMdClose, IoMdMenu } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import "./Header.css";

const Header = () => {
  const [active, setActive] = useState(false);
  const navigate = useNavigate();
  const headerRef = useRef(null);

  const activateNav = () => {
    setActive(!active);
  };

  const handleIconClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (headerRef.current && !headerRef.current.contains(event.target)) {
        setActive(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={headerRef} className={active ? "header" : "header-mobile"}>
      <div className="menu-icon" onClick={activateNav}>
        {!active ? (
          <IoMdMenu className="menu" />
        ) : (
          <IoMdClose className="menu" />
        )}
      </div>
      <nav>
        <ul className={active ? "ul-item" : "ul-item oicon"}>
          <li>
            <Icon
              icon="solar:home-broken"
              className="icon"
              onClick={() => handleIconClick("/jp/")}
            />
            <Link to="/jp/" className="transition1">
              家
            </Link>
          </li>
          <li>
            <Icon
              icon="mdi:about-circle-outline"
              className="icon"
              onClick={() => handleIconClick("/jp/About")}
            />
            <Link to="/jp/About" className="transition2">
              について
            </Link>
          </li>

          <li>
            <Icon
              icon="charm:graduate-cap"
              className="icon"
              onClick={() => handleIconClick("/jp/WeOffer")}
            />
            <Link to="/jp/WeOffer" className="transition3">
              我々は提供しています
            </Link>
          </li>

          <li>
            <Icon
              icon="ci:building-02"
              className="icon"
              onClick={() => handleIconClick("/jp/Faculties")}
            />
            <Link to="/jp/Faculties" className="transition4">
              学部
            </Link>
          </li>

          <li>
            <Icon
              icon="iconoir:brain-research"
              className="icon"
              onClick={() => handleIconClick("/jp/Research")}
            />
            <Link to="/jp/Research" className="transition5">
              研究
            </Link>
          </li>

          <li>
            <Icon
              icon="entypo:lab-flask"
              className="icon"
              onClick={() => handleIconClick("/jp/Facilities")}
            />
            <Link to="/jp/Facilities" className="transition6">
              設備
            </Link>
          </li>

          {/* <li>
                        <Icon icon="octicon:people-16" className='icon' onClick={() => handleIconClick('/LecturePanel')}/>
                        <Link to='/jp/LecturePanel' className='transition7'>Lecture Panel</Link>
                    </li> */}

          <li>
            <Icon
              icon="emojione-monotone:newspaper"
              className="icon"
              onClick={() => handleIconClick("/jp/News")}
            />
            <Link to="/jp/News" className="transition7">
              ニュース＆イベント
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
