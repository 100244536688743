import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import WeOffer from "./pages/WeOffer";
import Faculties from "./pages/Faculties";
import Facilities from "./pages/Facilities";
import News from "./pages/News";
import Research from "./pages/Research";
import LecturePanel from "./pages/LecturePanel";
import MRC from "./pages/MRC";
import Ethics from "./pages/Ethics";

import JPHome from "./pages/JP/Home";
import JPAbout from "./pages/JP/About";
import JPWeOffer from "./pages/JP/WeOffer";
import JPFaculties from "./pages/JP/Faculties";
import JPFacilities from "./pages/JP/Facilities";
import JPNews from "./pages/JP/News";
import JPResearch from "./pages/JP/Research";
import JPLecturePanel from "./pages/JP/LecturePanel";

function App() {
  const routes = [
    { path: "/", element: <Home /> },
    { path: "/About", element: <About /> },
    { path: "/WeOffer", element: <WeOffer /> },
    { path: "/Faculties", element: <Faculties /> },
    { path: "/Facilities", element: <Facilities /> },
    { path: "/News", element: <News /> },
    { path: "/Research", element: <Research /> },
    { path: "/LecturePanel", element: <LecturePanel /> },
    { path: "/MRC", element: <MRC /> },
    { path: "/Ethics", element: <Ethics /> },
    { path: "/jp", element: <JPHome /> },
    { path: "/jp/About", element: <JPAbout /> },
    { path: "/jp/WeOffer", element: <JPWeOffer /> },
    { path: "/jp/Faculties", element: <JPFaculties /> },
    { path: "/jp/Facilities", element: <JPFacilities /> },
    { path: "/jp/News", element: <JPNews /> },
    { path: "/jp/Research", element: <JPResearch /> },
    { path: "/jp/LecturePanel", element: <JPLecturePanel /> },
  ];

  return (
    <Router>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
        {/* Catch-all route to handle unknown paths */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
