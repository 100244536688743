import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Faculty.css";

const FON = () => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #f21111", // Blue outline
    color: "#f21111", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const navigate = useNavigate();
  const handleBtnClick = (path) => {
    navigate(path);
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-title">Faculty Of Nursing</h3>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <p className="fac-descrip">
              The Faculty of Nursing of KIU is dedicated to shaping the future
              of healthcare through excellence in education, research, and
              clinical practice. As a leader in nursing education, we are
              committed to prepare a competent, caring, compassionate, and
              reflective nursing graduate skilled in the delivery of nursing
              care in collaboration with other healthcare professionals while
              successfully meeting the emerging needs of the national and
              international community.
              <br />
              The Faculty of Nursing of KIU is constituted of the Department of
              Nursing Education, Department of Clinical Nursing, Department of
              Research and Development, and Department of Care Advancement. We
              offer the undergraduate programme to BSc (Hons) in Nursing with a
              diverse range of innovative modules to meet the evolving needs of
              the healthcare sector. In addition to that, the Faculty of Nursing
              of KIU fosters an interactive academic environment in which
              nursing undergraduates are prepared to become critically
              reflective practitioners, advocates, educators, and professional
              leaders in a variety of care settings.
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-dean-hed">Dean</h3>
            <p className="fac-descrip1">
              Mr. Akila Randika Jayamaha
              <br />
              PhD (Reading), Master of Nursing,
              <br />
              Master of Epidemiology,
              <br />
              BSc in Nursing, RN
              <br />
              Email: dean_fon@kiu.ac.lk
            </p>
          </Grid>

          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-hod-hed">Heads of Departments</h3>
            <p className="fac-descrip1">
              Department of Nursing
              <br />
              Kariyawasam Hewamanage Akindra Yamunangani Kariyawasam
              <br />
              MPhil (USJ) (Reading), MCP (UOC) (Reading), <br />
              PgDCP (UOC), BScN (USJ), RN
              <br />
              <br />
              <br />
              Department of Care Advancement
              <br />
              Samanthi Nisansala Senarath
              <br />
              MPhil (Reading), PgDip (Statistics),
              <br />
              B.Sc. (Hons) in Nursing (UOR), RN
            </p>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-depts-hed">Departments</h3>
            <ul className="fac-depts-list">
              <li className="fac-depts-list-item">
                Department of Nursing Education
              </li>
              <li className="fac-depts-list-item">
                Department of Clinical Nursing
              </li>
              <li className="fac-depts-list-item">
                Department of Research and Development
              </li>
              <li className="fac-depts-list-item">
                Department of Care Advancement
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 className="fac-fas-hed">Facilities</h3>
            <p className="fac-descrip1">
              The Faculty of Nursing at KIU takes pride on cultivating a vibrant
              academic environment, offering various facilities to enhance the
              learning experience.
            </p>
            <p className="fac-descrip1">
              <ul>
                <li>
                  <strong>Caregiver Laboratory:</strong>
                  <ul>
                    Equipped with state-of-the-art resources, the Caregiver
                    Laboratory provides a simulated environment for practicing
                    essential caregiving skills in a controlled setting
                  </ul>
                </li>
                <li>
                  <strong>E-Library and Library Blog:</strong>
                  <ul>
                    Our E-Library and Library Blog offer an extensive collection
                    of academic resources. They provide a platform for students
                    and faculty to access and share information seamlessly.
                  </ul>
                </li>
                <li>
                  <strong>Research Center:</strong>
                  <ul>
                    The Research Center supports undergraduates in publishing
                    their research in local and international forums.
                  </ul>
                </li>
                <li>
                  <strong>Clinical Training at Kings Hospital:</strong>
                  <ul>
                    Under the caregiver program, students gain hands-on
                    experience that develops clinical skills, facilitates
                    patient interaction, and encourages collaboration with
                    healthcare teams. This experience enhances understanding of
                    the complexities of healthcare delivery.
                  </ul>
                </li>
              </ul>
            </p>
          </Grid>
        </Grid>
      </Container>

      {/* <Container>
                <Grid container="container" spacing={2}>
                    <Grid item="item" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button style={buttonStyles1} onClick={() => handleBtnClick('/WeOffer')}>View Courses</Button>
                    </Grid>
                </Grid>
            </Container> */}
    </div>
  );
};

export default FON;
