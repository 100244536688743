import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "../postgraduate/Pgcrs.css";
import BAPDF from "../../../brochure/sample.pdf";
import liveS from "../../../assets/liveS.jpg";

const DALM = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              農業および畜産管理のディプロマ(DALM)
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="pg-crs-des1">
              KIU 農業・畜産管理トレーニング
              スクールの「農業・畜産管理ディプロマ」プログラムは、農業の概念と畜産管理技術の強固な基礎を提供します。農業でのキャリアをスタートさせたい場合でも、この分野の知識を広げたい場合でも、この専門資格はスリランカ内外での農業と畜産の実践に関する貴重な洞察力を養います。
              <br />
              このディプロマを取得すれば、スリランカ国内でも国際舞台でも、農業と畜産管理のやりがいのあるキャリアを築く準備が整います。このダイナミックな業界でスキルを磨き、刺激的な機会にアクセスするために、ぜひご参加ください。
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={liveS}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "80%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des2">
              <p>
                レベル: 短期コース - SLQF 3
                <br />
                期間: 1 学年
                <br />
                提供モード: オンラインおよび対面の講義、現地訪問、ブレンド型評価
                <br />
                提供媒体: 英語
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des3">
              <h3>入学要件</h3>
              <ul>
                <li>
                  G.C.E. 上級レベル 2 科目 (4 科目を履修した応募者の場合は 3
                  科目) に 1 回の受験で合格していること、
                </li>
                または
                <li>
                  G.C.E. 普通レベルに 5 回の受験で合格し、関連分野で 3
                  年以上の就業経験があること、
                </li>
                または
                <li>
                  上記に記載されていないその他の資格 (KIU
                  が個別に検討し、受け入れる場合があります)、
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <h5>キャリアチャンス</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <ul>
                <li>
                  スリランカ
                  <ul>
                    <li>
                      農場管理:
                      最適な生産性のために農業および畜産事業を監督します。
                    </li>
                    <li>
                      農業普及サービス:
                      地元の農家を教育し、彼らの慣行を改善できるよう支援します。
                    </li>
                    <li>
                      農業ビジネス管理:
                      農産物のマーケティング、流通、販売に重点を置きます。
                    </li>
                    <li>研究開発: 農業研究および技術の進歩に貢献します。</li>
                    <li>畜産: 酪農、養鶏、または牛の飼育に特化します。</li>
                  </ul>
                </li>
                <li>
                  国際的に
                  <ul>
                    <li>
                      国際農業組織:
                      農業開発プロジェクトでグローバル組織と協力します。
                    </li>
                    <li>
                      コンサルタント:
                      国際的な顧客に農業および畜産管理の専門知識を提供します。
                    </li>
                    <li>
                      研究機関:
                      世界中の研究機関に参加し、グローバルな農業ソリューションに貢献します。
                    </li>
                    <li>
                      畜産輸出:
                      動物製品の需要が高まっている国での機会を探ります。
                    </li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            {/* <a href="#" target="_blank" rel="noopener noreferrer"> */}
            <Button disabled style={buttonStyles1}>
              適用する
            </Button>
            {/* </a> */}
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              パンフレットをダウンロード
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              近い
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default DALM;
