import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "../postgraduate/Pgcrs.css";
import BAPDF from "../../brochure/sample.pdf";
import liveS from "../../assets/liveS.jpg";

const DALM = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Diploma in Agriculture and Livestock Management(DALM)
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="pg-crs-des1">
              Our "Diploma in Agriculture and Livestock Management" program at
              KIU Agriculture and Livestock Management Training School provides
              a solid foundation in agricultural concepts and livestock
              management techniques. Whether you're aiming to kickstart your
              career in agriculture or expand your knowledge in the field, this
              professional qualification equips you with valuable insights into
              farming and livestock practices, both in Sri Lanka and beyond.
              <br />
              With this diploma, you'll be ready for a rewarding career in
              agriculture and livestock management, whether at home in Sri Lanka
              or on an international stage. Join us to cultivate your skills and
              access exciting opportunities in this dynamic industry.
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={liveS}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "80%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des2">
              <p>
                Level: Short Course - SLQF 3
                <br />
                Duration: One Academic Year
                <br />
                Delivery Mode: Online and Physical lectures, Field visits and
                blended assessments
                <br />
                Delivery Medium: English
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des3">
              <h3>Entry Requirements</h3>
              <ul>
                <li>
                  Passed in G.C.E. Advanced Level 2 subjects (3 subjects for the
                  applicants who did four subjects) in any stream in one
                  sitting,
                </li>
                OR
                <li>
                  Five passes in G.C.E. Ordinary Level in one sitting with more
                  than three year working experience in a related field
                </li>
                OR
                <li>
                  Any other qualification not listed above, but may be
                  considered case by case and acceptable to the KIU.
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <h5>Career Opportunities</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <ul>
                <li>
                  In Sri Lanka
                  <ul>
                    <li>
                      Farm Management: Oversee agricultural and livestock
                      operations for optimal productivity.
                    </li>
                    <li>
                      Agricultural Extension Services: Educate and assist local
                      farmers to improve their practices.
                    </li>
                    <li>
                      Agribusiness Management: Focus on marketing, distribution,
                      and sales of agricultural products.
                    </li>
                    <li>
                      Research and Development: Contribute to agricultural
                      research and technology advancements.
                    </li>
                    <li>
                      Livestock Farming: Specialize in dairy, poultry, or cattle
                      farming.
                    </li>
                  </ul>
                </li>
                <li>
                  Internationally
                  <ul>
                    <li>
                      International Agricultural Organizations: Collaborate with
                      global organizations on agricultural development projects.
                    </li>
                    <li>
                      Consultancy: Provide agricultural and livestock management
                      expertise to international clients.
                    </li>
                    <li>
                      Research Institutions: Join research institutions
                      worldwide, contributing to global agriculture solutions.
                    </li>
                    <li>
                      Livestock Export: Explore opportunities in countries with
                      growing demand for animal products.
                    </li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            {/* <a href='#' target="_blank" rel="noopener noreferrer"> */}
            <Button disabled style={buttonStyles1}>
              Apply
            </Button>
            {/* </a> */}
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              Download Brochure
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default DALM;
