import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "../postgraduate/Pgcrs.css";
import JLPimg from "../../assets/JLP.jpg";
import BAPDF from "../../brochure/sample.pdf";

const JLP = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Japanese Language Programmes
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="pg-crs-des1">
              The Department of Languages expects to meet the higher demand for
              employment opportunities in Japan through its Japanese Language
              programmes. These programmes are designed to provide the students
              with expertise and knowledge at the N3 level and enable Sri Lankan
              students to obtain a great opportunity to pursue their career
              dreams in Japan.
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={JLPimg}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "80%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des2">
              <p>
                Level: Short Course
                <br />
                Duration: Elementary Level – 350 Hours / Upper Level – 550 Hours
                <br />
                Delivery Mode: Physical(Thursday & Friday) / Online(Monday &
                Tuesday & Wednesday & Friday)
                <br />
                Available Courses: Japanese Language Programmers Online &
                physical / JFT Exam Preparing
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des3">
              <h3>Entry Requirements</h3>
              <ul>
                <li>G.C.E A/L exam should be sat</li>
                <li>Age 17+</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <h5>CURRICULUM</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des6">
              <p>
                The Department of Languages expects to meet the higher demand
                for employment opportunities in Japan through its Japanese
                Language programmes. These programmes are designed to provide
                the students with expertise and knowledge at the N3 level and
                above, enabling Sri Lankan students to obtain a great
                opportunity to pursue their career dreams in Japan. This
                Japanese language class is designed to improve the four skills
                of the language – [listening, reading, speaking, and writing] to
                acquire basic Japanese operational skills. Areas that are
                covered are,
              </p>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="pg-crs-des7">
              <ul>
                <li>Japanese Reading and Writing.</li>
                <li>Japanese Conversation.</li>
                <li>Japanese Grammar.</li>
                <li>Japanese History and Culture.</li>
                <li>Japanese Lifestyle & Rules of Japanese Society.</li>
                <li>Preparation for Technical Intern Training.</li>
                <li>Preparation for Skilled Specified Workers.</li>
              </ul>

              <p>Teaching methods used in class:</p>

              <ul>
                <li>
                  <strong>Communicative Language Teaching (CLT):</strong>
                  Based on real-life situations, to learn how to use language
                  skills to communicate in the real world.
                </li>
                <li>
                  <strong>Cooperative Language Learning (CLL):</strong>
                  Makes maximum use of cooperative activities involving pairs
                  and small groups of learners in the classroom
                  (Student-centered learning through pairing).
                </li>
                <li>
                  <strong>The Direct Method:</strong>
                  All teaching happens in Japanese, forcing the learner to think
                  and speak in that language (All-in Japanese exercises).
                </li>
                <li>
                  <strong>Grammar-Translation:</strong>
                  Prioritizes translation from English to Japanese and vice
                  versa.
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="pg-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            {/* <a href="#" target="_blank" rel="noopener noreferrer"> */}
            <Button disabled style={buttonStyles1}>
              Apply
            </Button>
            {/* </a> */}
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              Download Brochure
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default JLP;
