import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import "./Ugcrs.css";
import BAPDF from "../../../brochure/sample.pdf";
import SEimg from "../../../assets/SE.jpg";

const SE = ({ onClose }) => {
  const buttonStyles1 = {
    backgroundColor: "transparent",
    border: "2px solid #44DA18", // Blue outline
    color: "#44DA18", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const buttonStyles2 = {
    backgroundColor: "transparent",
    border: "2px solid #00AAFD", // Blue outline
    color: "#00AAFD", // Blue text color
  };
  const buttonStyles3 = {
    backgroundColor: "transparent",
    border: "2px solid #FF0B00", // Blue outline
    color: "#FF0B00", // Blue text color
    paddingLeft: "2em",
    paddingRight: "2em",
  };
  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = BAPDF;
    link.download = "Bachelor of Management Honours in Business Analytics.pdf";
    link.click();
  };
  return (
    <div>
      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Kanit, sans-serif",
                fontSize: "35px",
                marginTop: "5%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              ソフトウェア工学のBSc優等学位  
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            sx={{
              // marginLeft: '6%'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className="ug-crs-des1">
              今日、あらゆる業界では、顧客のエラーを最小限に抑えるための自動化された手動システムに対する大きな需要があります。ほとんどの業界では、自動化のためにソフトウェア
              エンジニアを社内で雇用するか、要件をアウトソーシングします。{" "}
              <br />
              この学位はソフトウェア開発ライフサイクルに重点を置いていますが、プログラミングを超えて顧客のニーズを評価して満たし、ソフトウェアを設計してテストします。これはシステムの自動化を検討する上で重要な領域です。{" "}
              <br />
              ソフトウェア
              ソリューションの開発には、多くの場合、膨大な量のコードを実用的なアプリケーションに組み立てることや、既存のソフトウェアの更新と問題の修正が含まれます。優れたコミュニケーション
              スキルがあれば、ビジネスとソフトウェアの間の管理的な橋渡し役になります。{" "}
              <br />
              さらに、KIU
              の学部生は、業界および研究に関する貴重な経験を提供する大手企業での実習に参加することもできます。これにより、KIU
              ソフトウェア エンジニアリングの卒業生は、ソフトウェア
              エンジニア、アプリケーション開発者、バックエンド
              エンジニア、データベース管理者、DevOps
              エンジニア、品質保証エンジニア、ビジネス
              アナリスト、モバイル開発者など、さまざまな職種に就く機会が得られます。
            </Typography>
          </Grid>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt="KIU Uni"
              src={SEimg}
              className="about-vc-img"
              style={{
                width: "100%",
                height: "60%",
                padding: "1em",
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des2">
              <p>
                レベル: SLQF 6 
                <br />
                期間: 第8学期 (4年間)
                <br />
                クレジットポイント: 126
                <br />
                入学時期: 3月と9月
              </p>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des3">
              <h3>キャリアの機会</h3>
              <ol>
                <li>ソフトウェアエンジニア</li>
                <li>アプリケーション開発者</li>
                <li>バックエンドエンジニア</li>
                <li>データベース管理者</li>
                <li>DevOpsエンジニア</li>
                <li>品質保証エンジニア</li>
                <li>ビジネスアナリスト</li>
                <li>モバイル開発者</li>
              </ol>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>入学条件</h5>

              <ul>
                <li>
                  スリランカ試験局が実施するG.C.E（上級レベル）試験で、物理科学分野または工学技術分野で少なくとも3回の単純合格（S）または同等の資格。
                </li>
                または
                <li>
                  スリランカ試験局が実施する G.C.E（上級レベル）試験で、1
                  回の受験でいずれかの分野で少なくとも 3
                  つの単純合格（S）を取得するか、またはスリランカ試験局が実施する
                  G.C.E（普通レベル）試験で数学のクレジット合格（C）を取得し、同等の資格を取得するか、または指定機関が承認したブリッジング
                  プログラムで同等の資格を取得します。
                </li>
                または
                <li>
                  スリランカ試験局が実施する G.C.E. (上級レベル)
                  試験のいずれの試験でも、情報通信技術の簡易合格 (S) を 1
                  回の受験で取得するか、同等の資格を持ち、スリランカ試験局が実施する
                  G.C.E. (普通レベル) 試験の数学の単位合格 (C)
                  を取得するか、同等の資格を持つ、いずれかの分野で 1
                  回の受験で少なくとも 3 つの簡易合格 (S) を取得していること。{" "}
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des5">
              <h5>プログラム構成</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>1年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>1学期</h5>
              <ul>
                <li>コンピュータシステム入門</li>
                <li>コンピュータアーキテクチャ</li>
                <li>プログラミングの基礎</li>
                <li>学術的実践と身だしなみ</li>
                <li>コンピューティングのための数学 I</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>2学期</h5>

              <ul>
                <li>データ構造とアルゴリズム</li>
                <li>オブジェクト指向プログラミング</li>
                <li>ウェブテクノロジー</li>
                <li>データ通信</li>
                <li>コンピューティングのための数学 II</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>2年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>1学期</h5>
              <ul>
                <li>ソフトウェアエンジニアリング入門</li>
                <li>データベースシステム</li>
                <li>システム分析と設計</li>
                <li>オペレーティングシステム </li>
                <li>応用統計学</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>2学期</h5>

              <ul>
                <li>ソフトウェア要件エンジニアリング</li>
                <li>計算理論</li>
                <li>コンピュータセキュリティ</li>
                <li>人間とコンピュータのインタラクション</li>
                <li>ソフトウェアアーキテクチャ</li>
                <li>キャリアプランニングと就業スキル開発 – セミナー</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>3年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>1学期</h5>
              <ul>
                <li>コンピューティングの研究方法</li>
                <li>ソフトウェアエンジニアリンググループプロジェクト</li>
                <li>ソフトウェアプロジェクト管理</li>
                <li>ソフトウェアエンジニアリングの実践</li>
                <li>エンタープライズアーキテクチャ</li>
                <li>ソフトウェア品質保証</li>
                <li>知識ベースシステム</li>
                <li>新技術</li>
                <li>エンドユーザー向けITサポート</li>
                <li>ビジュアルプログラミング</li>
                <li>コンピュータグラフィックスとマルチメディア</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>2学期</h5>

              <ul>
                <li>職場ベースの企業配置</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des6">
              <h5>4年目</h5>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des7">
              <h5>1学期</h5>
              <ul>
                <li>最終学年個人プロジェクト</li>
                <li>ITの専門性と実践</li>
                <li>ソフトウェア開発における形式手法</li>
                <li>モバイルアプリケーション開発</li>
                <li>高度なデータベースシステム</li>
                <li>データサイエンス</li>
                <li>高性能コンピューティング</li>
                <li>データベースサーバー管理</li>
                <li>高度なウェブテクノロジー</li>
              </ul>
            </Typography>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des8">
              <h5>2学期</h5>

              <ul>
                <li>クラウドコンピューティング</li>
                <li>システムプログラミング</li>
                <li>ゲーム開発とプログラミング</li>
                <li>モノのインターネット</li>
                <li>オペレーショナルリサーチ</li>
                <li>組み込みシステム設計</li>
                <li>人工知能</li>
                <li>起業家精神</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={
              {
                // marginLeft: '6%'
              }
            }
          >
            <Typography className="ug-crs-des4">
              <h5>
                ソフトウェアエンジニアリングの理学士優等学位を取得した卒業生は、以下のことができるようになります。{" "}
              </h5>

              <ul>
                <li>
                  ソフトウェア エンジニアとして実践するために必要なソフトウェア
                  エンジニアリングの知識と専門的基準のスキルを習得していることを示します。
                </li>
                <li>
                  情報管理やインテリジェントアプリケーションなどのアプリケーションの基礎を理解します。
                </li>
                <li>
                  複数のプログラミング言語、パラダイム、テクノロジーを理解していることを示します。
                </li>
                <li>
                  問題の特定と分析、ソフトウェアの設計、開発、実装、検証、および文書化の基礎となる適切な理論、モデル、および手法を適用します。
                </li>
                <li>
                  相反するプロジェクト目標を調整し、コスト、時間、知識、既存のシステム、組織の制限内で受け入れ可能な妥協点を見つけます。
                </li>
                <li>
                  個人としてもチームの一員としても作業し、高品質のソフトウェア成果物を開発して提供します。
                </li>
                組織/個人向けのソフトウェアソリューションを設計および開発するために最新のコンセプトを使用する自信を養う
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <div className="ug-horizontal-divider"></div>

      <Container>
        <Grid container="container" spacing={2}>
          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <a
              href="https://erpv2std.kiu.lk/online-registration/23"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={buttonStyles1}>適用する</Button>
            </a>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disabled
              style={buttonStyles2}
              onClick={handleDownloadBrochure}
            >
              パンフレットをダウンロード
            </Button>
          </Grid>

          <Grid
            item="item"
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Button style={buttonStyles3} onClick={onClose}>
              近い
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default SE;
